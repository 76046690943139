import React from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { useAuth } from 'hooks/useAuth';
import { ForgotPasswordForm } from 'components/forms/ForgotPasswordForm/ForgotPasswordForm';
import { FormLayout } from 'components/layouts/FormLayout/FormLayout';
import { BackgroundLayout } from 'components/layouts/BackgroundLayout/BackgroundLayout';
import useTheme from 'hooks/useTheme';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import fnStyles from '../AuthGlobalStyle';
import useAlert from '../../../providers/AlertProvider';

export type ScreenProps = NativeStackScreenProps<RootStackParamList, 'Login'>;

const ForgotPasswordScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopStyles = fnDesktopStyles(theme);
  const desktopMode = isDesktopMode();
  const { setAlert } = useAlert();
  const { t } = useTranslation();
  const styles = fnStyles(theme);

  const { forgotPassword } = useAuth();

  const handleForgotPassword = async (email: string) => {
    await forgotPassword(email);
    setAlert({
      color: theme.colors.statusSuccessHigh,
      message: "Un email a été envoyé à l'email indiqué",
      title: 'Information',
    });
    navigation.reset({
      index: 1,
      routes: [{ name: 'Welcome' }, { name: 'Login' }],
    });
  };

  return (
    <BackgroundLayout padding>
      <View
        style={[
          desktopMode && desktopStyles.boxed,
          { marginTop: desktopMode ? theme.sizings.mediumLarge : undefined },
        ]}
      >
        <FormLayout style={styles.form}>
          <ForgotPasswordForm onSubmit={handleForgotPassword} />
        </FormLayout>
      </View>
    </BackgroundLayout>
  );
};

export default ForgotPasswordScreen;
