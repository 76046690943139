import React from 'react';
import { View } from 'react-native';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import useTheme from 'hooks/useTheme';
import { UserActions } from 'components/compositions/UserActions/UserActions';
import { Section as BlocSection } from 'components/common/Section/Section';
import { Constants } from 'constants/Constants';
import { JText } from 'components/common/Text/Text';
import { Passenger } from 'types/Passenger';
import { Ride } from 'types';
import { Section } from 'types/Section';
import useAuth from 'hooks/useAuth';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'CourseDetail'
>;
type Props = {
  course: Ride | Section;
  amDriver?: boolean;
  rideId: number;
  navigation: any;
  passengers: Passenger[];
  actionContact: (userId: number) => void;
  actionDelete: (userId: number) => void;
};

const RidePassengers = ({
  course,
  amDriver = false,
  rideId,
  navigation,
  passengers,
  actionContact,
  actionDelete,
}: Props) => {
  const { theme } = useTheme();
  const { user: currentUser } = useAuth();

  return (
    <BlocSection
      title={`${passengers?.length} passagers(s)`}
      style={{
        marginTop: theme.sizings.medium,
        marginBottom: theme.sizings.medium,
      }}
    >
      <View style={{ marginTop: theme.sizings.mediumLarge }}>
        {passengers?.length ? (
          passengers.map((p: Passenger, index: number) => {
            const key = `passenger-${index}`;
            const { user } = p;
            const userId = p.user.id;
            const passengerId = p.id;
            const { notationFromDriverEntered } = p;

            return (
              <View key={key}>
                <UserActions
                  name={user.username || user.firstname}
                  userBirth={user.birthDate}
                  userRole="passenger"
                  imageIsWoman={user.gender === 'W'}
                  chatting={user.chatting}
                  music={user.music}
                  smoking={user.smoking}
                  image={user.profilePicture}
                  note={0} // todo
                  actionContact={
                    userId !== currentUser?.id ? actionContact : undefined
                  }
                  actionDelete={
                    userId === currentUser?.id ? actionDelete : undefined
                  }
                  userId={userId}
                  passengerId={passengerId}
                  actionPrimary={
                    amDriver &&
                    course.status === 'finished' &&
                    !notationFromDriverEntered
                      ? () =>
                          navigation.navigate('CourseEvaluate', {
                            targetId: userId,
                            rideId,
                            passengerId,
                            amDriver: true,
                          })
                      : undefined
                  }
                  actionPrimaryLabelKey="actions.note"
                  actionAvatar={() =>
                    navigation.navigate('ThirdPartyProfile', {
                      profileId: userId,
                    })
                  }
                />
              </View>
            );
          })
        ) : (
          <JText centered labelKey="courses.noPassengerYet" />
        )}
      </View>
    </BlocSection>
  );
};

export default RidePassengers;
