import { Theme } from 'providers/ThemeProvider';
import { StyleSheet } from 'react-native';

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      position: 'relative',
      paddingTop: theme.sizings.mediumLarge,
      marginBottom: theme.sizings.mediumLarge,
      width: '100%',
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  });
