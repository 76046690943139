import { Theme } from 'providers/ThemeProvider';
import { StyleSheet } from 'react-native';

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      paddingHorizontal: theme.sizings.smallMedium,
      borderRadius: theme.radius.medium,
      minWidth: theme.sizings.high,
      minHeight: theme.sizings.high,
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
