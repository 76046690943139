import React, { useCallback } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Card } from 'components/common/Card/Card';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import useTheme from 'hooks/useTheme';
import { Form } from 'react-final-form';
import { FormControl } from 'components/common/FormControl/FormControl';
import { useTranslation } from 'react-i18next';
import fnStyles from './ResetPasswordStyle';

type Props = {
  onSubmit: (values: any) => Promise<void>;
  style?: StyleProp<ViewStyle>;
};

export const ResetPasswordForm = (props: Props) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const styles = fnStyles(theme);

  const checkPassword = useCallback(
    (value: string) => {
      return value?.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/) !==
        null
        ? undefined
        : t('auth.passwordRulesList');
    },
    [t],
  );

  const validateForm = (values: {
    password: string;
    confirmPassword: string;
  }) => {
    if (values.password !== values.confirmPassword)
      return { confirmPassword: t('auth.passwordsDoNotMatch') };
    return undefined;
  };

  return (
    <Card style={[styles.container, props.style]}>
      <Form
        onSubmit={props.onSubmit}
        validate={validateForm}
        render={({ handleSubmit }) => (
          <>
            <FormControl
              name="password"
              titleKey="forms.newPassword"
              secure
              required
              validate={checkPassword}
            />
            <FormControl
              name="confirmPassword"
              titleKey="forms.confirmNewPassword"
              secure
              required
            />
            <GradientButton
              labelKey="auth.confirmPassword"
              onPress={handleSubmit}
              leftIconType="Feather"
              leftIconName="check"
              leftIconSizeName="larger"
              width="75%"
              style={{ marginTop: theme.sizings.mediumLarge }}
            />
          </>
        )}
      />
    </Card>
  );
};
