import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';
import { SelectableOptionsList } from '../../components/common/SelectableOptionsList/SelectableOptionsList';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const SelectableOptionsListScreen = () => {
  const [selectedValue, setSelectedValue] = useState<
    string | string[] | undefined
  >('');
  const [selectedValue2, setSelectedValue2] = useState<
    string | string[] | undefined
  >('');
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="SelectableOptionsList"
        description="This component renders a list of selectable option."
      >
        <SelectableOptionsList
          title="Pour combien de personne"
          values={['1', '2', '3', '4']}
          selectedValue={selectedValue}
          onChange={setSelectedValue}
        />
        <View style={{ height: 10 }} />
        <SelectableOptionsList
          title="Départ"
          values={[
            '10:00',
            '10:15',
            '10:30',
            '10:45',
            '11:00',
            '11:15',
            '11:30',
          ]}
          selectedValue={selectedValue2}
          onChange={setSelectedValue2}
          leftIconType="AntDesign"
          leftIconName="clockcircle"
        />
      </CatalogHeader>
      <CatalogProps propName="title" type="string" topDivider={false} />
      <CatalogProps
        propName="values"
        description="The values for each option in the list."
        type="string[ ]"
      />
      <CatalogProps
        propName="selectedValue"
        description="The value selected in the list. It should be a state so the onChange props can be the setter."
        type="string | string [ ]"
      />
      <CatalogProps
        propName="onChange"
        description="The setter of the selectedValue."
        type="(value: string | string [ ]) => void"
      />
      <CatalogProps
        propName="leftIconType"
        type="enum('MaterialCommunity', 'AntDesign', 'Entypo', 'EvilIcons', 'Feather', 'FontAwesome', 'FontAwesome5', 'FontAwesome5Pro', 'Fontisto', 'Material', 'MaterialIcons', 'IonIcons')"
      />
      <CatalogProps propName="leftIconName" type="string" />
      <CatalogProps propName="leftIconSize" type="number" />
      <CatalogProps propName="leftIconColor" type="string" />
    </ScrollView>
  );
};

export default SelectableOptionsListScreen;
