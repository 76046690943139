import { Avatar } from 'components/common/Avatar/Avatar';
import { Divider } from 'components/common/Divider/Divider';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Types } from 'types/Types';
import { Icon } from 'components/common//Icon/Icon';
import { JText } from 'components/common//Text/Text';
import { Card } from 'components/common/Card/Card';
import { DestinationStraightLine } from 'components/common/DestinationStraightLine/DestinationStraightLine';
import { ArrowRight } from 'components/common/ArrowRight/ArrowRight';
import useTheme from 'hooks/useTheme';
import dayjs from 'dayjs';
import computeAge from 'utils/computeAge';
import { Pin } from 'components/common/Pin/Pin';
import { useTranslation } from 'react-i18next';
import { City, Ride } from 'types';
import renderOverridedField from 'utils/computeOverridedField';
import fnStyles from '../CourseItemGlobalStyles';
import { I18nKey } from '../../../../i18n';

type Props = {
  onPress: () => void;
  statusOk?: boolean;
  statusWarning?: boolean;
  cardTopLegende?: I18nKey;
  cardTopLegendeCustom?: string;
  cardTopIcon?: string;
  userRole: Types['role'];
  departureLocation?: string;
  departureTime: Date;
  arrivalLocation?: string;
  arrivalTime: Date;
  date?: Date;
  ride: Ride;
  setIsInstanceModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRide?: React.Dispatch<React.SetStateAction<Ride | undefined>>;
};

export const CourseWithStatus = ({
  onPress,
  statusOk = false,
  statusWarning = false,
  cardTopLegende,
  cardTopLegendeCustom,
  cardTopIcon,
  userRole,
  departureLocation,
  departureTime,
  arrivalLocation,
  arrivalTime,
  date,
  ride,
  setIsInstanceModalVisible,
  setSelectedRide,
}: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { t } = useTranslation();
  const driverAge = computeAge(ride.driver.birthDate);

  // conditionnal values
  const music = renderOverridedField(ride.music, ride.driver.music);
  const smoking = renderOverridedField(ride.smoking, ride.driver.smoking);
  const chatting = renderOverridedField(ride.chatting, ride.driver.chatting);

  const days =
    ride.parent?.recurrentDays?.length &&
    ride.parent?.recurrentDays
      .map((rd: string, rdIndex) => {
        const day = t(`formOptions.${rd}` as I18nKey) as string;
        return day;
      })
      .toString();

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.container}>
        <Avatar
          sizeName="sz60"
          iconType="FontAwesome5"
          iconColorName={
            userRole === 'passenger'
              ? 'blueHigh'
              : userRole === 'candidate'
              ? 'statusWarningHigh'
              : 'statusWarningHigh'
          }
          iconName={
            userRole === 'passenger'
              ? 'car'
              : userRole === 'candidate'
              ? 'question'
              : 'star'
          }
          iconPosition="bottomRight"
          image={ride.driver.profilePicture}
          style={styles.avatar}
          isWoman={ride.driver?.gender === 'W'}
        />

        <Card style={styles.card}>
          <ViewRow
            style={[
              styles.cardTop,
              {
                backgroundColor: statusOk
                  ? theme.colors.statusSuccessLight
                  : statusWarning
                  ? theme.colors.statusWarningLight
                  : theme.colors.greyLight,
              },
            ]}
          >
            {cardTopIcon && (
              <ViewCol style={styles.cardTopIcon}>
                <Icon
                  sizeName="largest"
                  colorName={
                    statusOk
                      ? 'statusSuccessHigh'
                      : statusWarning
                      ? 'statusWarningHigh'
                      : 'light'
                  }
                  type="FontAwesome5"
                  name={cardTopIcon}
                />
              </ViewCol>
            )}

            <ViewCol>
              <View style={styles.row}>
                {userRole !== 'driver' ? (
                  <JText isBold labelKey="courses.iPassenger" />
                ) : (
                  <JText isBold labelKey="courses.iDrive" />
                )}
                <JText isBold label={` - ${dayjs(date).format('LL')}, `} />
              </View>
              {(cardTopLegende || cardTopLegendeCustom) && (
                <View>
                  <JText
                    isItalic
                    labelKey={cardTopLegende as I18nKey}
                    label={cardTopLegendeCustom}
                  />
                </View>
              )}
            </ViewCol>
          </ViewRow>

          <View
            style={[
              styles.cardDates,
              { marginTop: theme.sizings.medium, marginBottom: 0 },
            ]}
          >
            {ride.parent?.recurrentDays &&
              ride.parent?.recurrentDays?.length > 0 &&
              setSelectedRide &&
              setIsInstanceModalVisible && (
                <TouchableOpacity
                  onPress={() => {
                    setSelectedRide(ride.parent);
                    // activer quand popup sera opérationnelle
                    // setIsInstanceModalVisible(true);
                  }}
                >
                  <Pin
                    iconName="info-circle" // ou  infinity si abonné
                    iconType="FontAwesome5"
                    labelKey="courses.isReccurentOn"
                    valuesKey={{ days }} // ou subscribedDays si abonné
                    style={{
                      backgroundColor: theme.colors.blueLight, // ou warningLight si abonné
                      paddingVertical: theme.sizings.tiny,
                      paddingHorizontal: theme.sizings.medium,
                    }}
                    textColorName="blueHigh" // ou warningHigh si abonné
                    iconColorName="blueHigh" // ou warningHigh si abonné
                  />
                </TouchableOpacity>
              )}
          </View>

          <ViewRow style={styles.cardBottom}>
            <ViewCol size={1}>
              <DestinationStraightLine
                departureLocation={departureLocation}
                departureTime={departureTime}
                arrivalLocation={arrivalLocation}
                arrivalTime={arrivalTime}
                textTop
              />

              <View style={styles.row}>
                {userRole !== 'driver' ? (
                  <>
                    <JText isBold labelKey="courses.driver" />
                    <Divider vertical transparent />
                    <JText
                      isBold
                      labelKey="sentences.userNameAge"
                      valuesKey={{
                        name: ride.driver.username || ride.driver.firstname,
                        age: driverAge,
                      }}
                    />
                  </>
                ) : (
                  <JText isBold labelKey="courses.iDrive" />
                )}

                <Divider vertical />

                <Icon
                  type="FontAwesome5"
                  colorName={chatting ? 'greenMedium' : 'statusDangerHigh'}
                  name="comment"
                />

                <Divider vertical transparent />
                <Icon
                  type="FontAwesome5"
                  colorName={music ? 'greenMedium' : 'statusDangerHigh'}
                  name="music"
                />
                <Divider vertical transparent />

                <Icon
                  type="FontAwesome5"
                  colorName={smoking ? 'greenMedium' : 'statusDangerHigh'}
                  name="smoking"
                />
              </View>
            </ViewCol>

            <ArrowRight inCard />
          </ViewRow>
        </Card>
      </View>
    </TouchableOpacity>
  );
};
