import { useFocusEffect } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Section } from 'components/common/Section/Section';
import { JText } from 'components/common/Text/Text';
import useTheme from 'hooks/useTheme';
import { RootStackParamList } from 'navigation/RootStackParamList';
import React from 'react';
import { View } from 'react-native';
import { Ride } from 'types';
import renderOverridedField from 'utils/computeOverridedField';
import { TabsBackground } from 'components/layouts/BackgroundLayout/TabsBackgound';
import ResponsiveScrollView from 'components/layouts/ResponsiveScrollView/ResponsiveScrollView';
import { InstanceItem } from 'components/compositions/InstanceItem/InstanceItem';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import useList from '../../../../hooks/useList';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'MyCourses'
>;

const MyDriverRecurrentItemsScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const desktopStyles = fnDesktopStyles(theme);

  // futur
  const {
    items: rides,
    isFetching: isRideFetching,
    fetchItems: fetchRidesItems,
  } = useList<Ride>('rides/recurrent/me', {
    populate: ['departureLocation', 'arrivalLocation'],
  });

  useFocusEffect(
    React.useCallback(() => {
      // Do something when the screen is focused
      fetchRidesItems();

      return () => {
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [fetchRidesItems]),
  );

  return (
    <>
      <TabsBackground />
      <ResponsiveScrollView>
        <View
          style={[
            {
              padding: theme.sizings.mediumLarge,
              flex: 1,
            },
            desktopMode && desktopStyles.boxed,
          ]}
        >
          {isRideFetching && <JText centered labelKey="common.loading" />}

          {!isRideFetching && rides && (
            <View style={{ marginBottom: theme.sizings.mediumLarge * 2 }}>
              <Section titleKey="courses.iDrive">
                {rides?.length ? (
                  rides.map((ride: any, index: number) => {
                    const fkey = `course-${index}`;

                    return (
                      <View key={fkey}>
                        <InstanceItem
                          userRole="driver"
                          onPress={() =>
                            navigation.navigate('InstanceDetail', {
                              instanceId: ride.id,
                            })
                          }
                          ride={ride}
                          departureLocation={ride.departureLocation}
                          departureTime={ride.departureTime}
                          arrivalLocation={ride.arrivalLocation}
                          arrivalTime={ride.arrivalTime}
                        />
                      </View>
                    );
                  })
                ) : (
                  <JText centered labelKey="courses.noResultsInstances" />
                )}
              </Section>
            </View>
          )}
        </View>
      </ResponsiveScrollView>
    </>
  );
};

export default MyDriverRecurrentItemsScreen;
