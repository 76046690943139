import { Theme } from 'providers/ThemeProvider';
import { StyleSheet } from 'react-native';

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      height: theme.normalize(35),
      paddingHorizontal: theme.sizings.smallMedium,
      marginVertical: theme.sizings.small,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      borderRadius: theme.normalize(20),
      elevation: 3,
      shadowColor: theme.colors.shadow,
      shadowOpacity: 0.4,
      shadowRadius: theme.normalize(2),
      shadowOffset: {
        width: 0,
        height: 2,
      },
    },
    containerGradient: {
      height: theme.normalize(35),
      marginVertical: theme.sizings.small,
      paddingHorizontal: theme.sizings.mediumLarge,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      borderRadius: theme.normalize(20),
      elevation: 3,
      shadowColor: theme.colors.shadow,
      shadowOpacity: 0.4,
      shadowRadius: theme.normalize(2),
      shadowOffset: {
        width: 0,
        height: 2,
      },
    },
    label: {
      fontWeight: 'bold',
      fontSize: theme.fontSizes.large,
      textAlign: 'center',
    },
    gradient: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '100%',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      borderRadius: theme.normalize(20),
      zIndex: -1,
    },
  });
