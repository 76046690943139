/* eslint-disable react/no-array-index-key */
import React from 'react';
import { ViewStyle, StyleProp, View } from 'react-native';
import useTheme from 'hooks/useTheme';
import fnStyles from './SelectableOptionsListStyle';
import { I18nKey } from '../../../../../i18n';
import { SelectableOptionButton } from '../SelectableOptionButton/SelectableOptionButton';
import { JText } from '../../Text/Text';

export type SelectableListProps = {
  values?: string[] | boolean[] | number[];
  labelKeys?: I18nKey[];
  selectedValue?: string | string[] | boolean | number | undefined;
  onChange?: (value: string | string[] | boolean | number | undefined) => void;
  label?: string[];
};

export const SelectableOptionsList = (props: SelectableListProps) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  return (
    <View>
      {Array.isArray(props.selectedValue) && (
        <JText labelKey="common.multipleSelection" style={styles.subtitle} />
      )}

      <View style={styles.list}>
        {props.values?.map((item, index) => {
          return (
            <View
              key={index}
              style={{
                marginTop: theme.sizings.ten,
                marginRight: theme.sizings.ten,
              }}
            >
              <SelectableOptionButton
                value={item}
                label={props.label ? props.label[index] : undefined}
                labelKey={props.labelKeys ? props.labelKeys[index] : undefined}
                selectedValue={props.selectedValue}
                onChange={props.onChange}
              />
            </View>
          );
        })}
      </View>
    </View>
  );
};
