import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import dayjs from 'dayjs';
import useTheme from 'hooks/useTheme';
import React from 'react';
import { View } from 'react-native';
import { SectionDetail } from 'types';
import { Icon } from '../../Icon/Icon';
import { JText } from '../../Text/Text';
import fnStyles from './DestinationStraightLineStyle';
import { TextInput } from '../TextInput/TextInput';
import { FormControl } from '../../FormControl/FormControl';

type GlobalProps = {
  val?: SectionDetail;
  name: string;
  points?: any;
  textTop?: boolean;
  noMargin?: boolean;
  vertical?: boolean;
  editable?: boolean;
  distance?: number;
};

type LineProps = {
  vertical?: boolean;
};

const StraightLineDivider = ({ vertical = true }: LineProps) => {
  const { theme } = useTheme();

  return vertical ? (
    <View>
      <View
        style={{
          width: theme.normalize(2),
          borderStyle: 'dashed',
          borderWidth: theme.normalize(2),
          borderColor: theme.colors.greyLight,
          minHeight: theme.sizings.mediumLarge,
          marginLeft: theme.sizings.larger / 4,
          marginVertical: theme.sizings.small,
        }}
      />
    </View>
  ) : (
    <ViewCol size={1}>
      <View
        style={{
          height: theme.normalize(2),
          borderStyle: 'dashed',
          borderWidth: theme.normalize(2),
          borderColor: theme.colors.greyLight,
          width: '100%',
        }}
      />
    </ViewCol>
  );
};

export const DestinationStraightLine = ({
  val,
  name,
  textTop = false,
  noMargin = false,
  vertical = true,
  points = [],
  editable = false,
  distance = 0,
  ...otherProps
}: GlobalProps) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  const middlePoints = points.slice(1, -1);

  return vertical ? (
    <View
      style={[
        styles.container,
        {
          flexDirection: textTop ? 'column-reverse' : 'column',
          marginVertical: noMargin ? 0 : theme.sizings.mediumLarge,
        },
      ]}
    >
      {/* start */}
      <ViewRow>
        <ViewCol
          style={{
            width: theme.sizings.larger,
            justifyContent: 'center',
          }}
        >
          <Icon
            type="FontAwesome"
            name="circle"
            colorName="greenLight"
            sizeName="larger"
          />
        </ViewCol>
        <ViewCol
          size={1}
          style={{
            justifyContent: 'center',
            paddingRight: theme.sizings.mediumLarge,
          }}
        >
          <JText
            label={
              val?.departureLocation?.name ||
              (points.length > 0 ? points[0].id_ville : 'n-a')
            }
            isBold
          />
        </ViewCol>
        <ViewCol
          style={{
            maxWidth: theme.sizings.sz70,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FormControl name={`${name}.startTime`} type="time" {...otherProps} />
        </ViewCol>
      </ViewRow>

      {/* points intermédiaires */}
      <ViewRow>
        <ViewCol size={1}>
          {middlePoints.length > 0 &&
            middlePoints.map(() => (
              <>
                <StraightLineDivider vertical />
                <View style={{ width: theme.sizings.larger }}>
                  <Icon
                    type="FontAwesome"
                    name="circle"
                    colorName="blueLight"
                    sizeName="larger"
                  />
                </View>
              </>
            ))}
        </ViewCol>
      </ViewRow>

      {/* end */}
      <StraightLineDivider vertical />
      <ViewRow>
        <ViewCol
          style={{
            width: theme.sizings.larger,
            justifyContent: 'center',
          }}
        >
          <Icon
            type="FontAwesome"
            name="circle"
            colorName="greenMedium"
            sizeName="larger"
          />
        </ViewCol>
        <ViewCol
          size={1}
          style={{
            justifyContent: 'center',
            paddingRight: theme.sizings.mediumLarge,
          }}
        >
          <JText
            label={
              val?.arrivalLocation?.name ||
              (points.length > 1 ? points[points.length - 1].id_ville : 'n-a')
            }
            isBold
          />
        </ViewCol>
        <ViewCol
          inline
          style={{
            paddingRight: theme.sizings.mediumLarge,
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: theme.normalize(105),
          }}
        >
          <TextInput value={val?.distance} editable={editable} inline />
          <JText
            labelKey="forms.km"
            style={{ marginLeft: theme.sizings.medium }}
          />
        </ViewCol>
        <ViewCol
          style={{
            maxWidth: theme.sizings.sz70,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FormControl name={`${name}.endTime`} type="time" {...otherProps} />
          {/*
           <TextInput
            value={
              dayjs(arrivalTime).format('LT') ||
              (points.length > 1 ? points[points.length - 1].heure : 'n-a')
            }
            editable={editable}
          /> */}
        </ViewCol>
      </ViewRow>
      {val?.arrivalLocation?.county && (
        <JText
          label={val?.arrivalLocation?.county}
          isItalic
          centered
          colorName="blueHigh"
          style={{
            marginTop: theme.sizings.mediumLarge,
            backgroundColor: theme.colors.blueLight,
            padding: theme.sizings.smallMedium,
            borderRadius: theme.radius.medium,
          }}
        />
      )}
    </View>
  ) : (
    <View
      style={[
        styles.container,
        {
          flexDirection: textTop ? 'column-reverse' : 'column',
          marginVertical: noMargin ? 0 : theme.sizings.mediumLarge,
        },
      ]}
    >
      <ViewRow align="center">
        <ViewCol style={{ width: 20 }}>
          <Icon
            type="FontAwesome"
            name="circle"
            colorName="greenLight"
            sizeName="larger"
          />
        </ViewCol>

        {middlePoints.length > 0 &&
          middlePoints.map(() => (
            <>
              <StraightLineDivider />
              <ViewCol style={{ width: 20 }}>
                <Icon
                  type="FontAwesome"
                  name="circle"
                  colorName="blueLight"
                  sizeName="larger"
                />
              </ViewCol>
            </>
          ))}

        <StraightLineDivider />
        <ViewCol justify="flex-end" style={{ width: theme.normalize(20) }}>
          <Icon
            type="FontAwesome"
            name="circle"
            colorName="greenMedium"
            sizeName="larger"
          />
        </ViewCol>
      </ViewRow>

      <ViewRow>
        <ViewCol size={1}>
          <JText
            label={
              val?.departureLocation?.name ||
              (points.length > 0 ? points[0].id_ville : 'n-a')
            }
            isBold
          />
          <JText
            label={
              dayjs(val?.startTime).format('LT') ||
              (points.length > 0 ? points[0].heure : 'n-a')
            }
          />
        </ViewCol>

        <ViewCol size={1}>
          <JText
            label={
              val?.arrivalLocation?.name ||
              (points.length > 1 ? points[points.length - 1].id_ville : 'n-a')
            }
            right
            isBold
          />
          <JText
            label={
              dayjs(val?.endTime).format('LT') ||
              (points.length > 1 ? points[points.length - 1].heure : 'n-a')
            }
            right
          />
        </ViewCol>
      </ViewRow>
    </View>
  );
};
