import React from 'react';
import { View, ScrollView, Text } from 'react-native';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';
import { CircularIconRow } from '../../components/common/CircularIconRow/CircularIconRow';
import { Section } from '../../components/common/Section/Section';

const DocumentScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="CircularIconRow"
        description="This component renders a row with a circularIcon."
      >
        <View style={{ flexDirection: 'column' }}>
          <CircularIconRow iconType="IonIcons" iconName="laptop-outline">
            <Text>This is a simple text</Text>
          </CircularIconRow>
          <View style={{ width: 30 }} />
          <CircularIconRow iconType="IonIcons" iconName="accessibility-outline">
            <Section title="More complex stuff">
              <GradientButton label="button 1" onPress={() => {}} />
              <GradientButton label="button 2" onPress={() => {}} />
            </Section>
          </CircularIconRow>
          <View style={{ width: 30 }} />
          <CircularIconRow iconType="IonIcons" iconName="leaf-outline" />
        </View>
      </CatalogHeader>
      <CatalogProps propName="label" type="string" topDivider={false} />
      <CatalogProps
        propName="name"
        description="The name/type of the document."
        type="string"
      />
      <CatalogProps
        propName="isSupplied"
        description="Precises if the document has been provided"
        type="boolean"
      />
      <CatalogProps
        propName="isValidated"
        description="Precises if the document has been validated"
        type="boolean"
      />
      <CatalogProps propName="iconName" type="string" />
      <CatalogProps propName="iconSize" type="number" />
    </ScrollView>
  );
};

export default DocumentScreen;
