import { Theme } from 'providers/ThemeProvider';
import { StyleSheet } from 'react-native';

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.colors.light,
      flex: 1,
    },
    map: {
      /* width: Dimensions.get('window').width,
    height: Dimensions.get('window').height, */
      flex: 1,
    },
    pointCircle: {
      width: theme.sizings.larger,
      height: theme.sizings.larger,
      borderRadius: theme.radius.rounded,
      backgroundColor: theme.colors.statusDangerHigh,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    pointCirclelabel: {
      color: theme.colors.light,
      textAlign: 'center',
      width: '100%',
      height: 'auto',
      fontSize: theme.fontSizes.larger,
    },
  });

export const generatedMapStyle = [
  {
    featureType: 'all',
    elementType: 'labels.text',
    stylers: [
      {
        color: '#878787',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f9f5ed',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#aee0f4',
      },
    ],
  },
];
