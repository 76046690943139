/* eslint-disable no-console */
import React, { createContext, useContext, useEffect, useState } from 'react';

import { Platform, AppState, AppStateStatus } from 'react-native';

import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';

const NotificationContext = createContext<{ pushToken?: string }>({});

type Props = {
  children?: React.ReactNode;
};

export const NotificationProvider = ({ children }: Props) => {
  const [pushToken, setPushToken] = useState<string | undefined>();
  const [appState, setAppState] = useState(AppState.currentState);

  useEffect(() => {
    const handleAppStateChange = async (nextAppState: AppStateStatus) => {
      if (nextAppState === 'active' && Platform.OS === 'android') {
        await Notifications.dismissAllNotificationsAsync();
      }
      setAppState(nextAppState);
    };

    const handleNotification = (notification: Notifications.Notification) => {
      // for now dismiss all notification if the app is foregrounded
      if (appState === 'active') {
        Notifications.dismissNotificationAsync(notification.request.identifier);
      }
    };

    const onChangeSubscription = AppState.addEventListener(
      'change',
      handleAppStateChange,
    );

    const notificationSubscription =
      Notifications.addNotificationReceivedListener(handleNotification);

    return () => {
      onChangeSubscription.remove();
      notificationSubscription.remove();
    };
  }, [appState]);

  useEffect(() => {
    const registerForPushNotifications = async () => {
      if (Platform.OS === 'web') return;

      if (Platform.OS === 'android') {
        await Notifications.setNotificationChannelAsync('default', {
          name: 'default',
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: '#FF231F7C',
        });
      }

      if (Device.isDevice) {
        const { status: existingStatus } =
          await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
          const { status } = await Notifications.requestPermissionsAsync();
          finalStatus = status;
        }
        if (finalStatus !== 'granted') {
          // eslint-disable-next-line no-alert
          alert('Failed to get push token for push notification!');
          return;
        }
        const { data } = await Notifications.getExpoPushTokenAsync();
        setPushToken(data);
      } else {
        // eslint-disable-next-line no-alert
        alert('Must use physical device for Push Notifications');
      }
    };

    registerForPushNotifications();
  }, []);

  return (
    <NotificationContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ pushToken }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => useContext(NotificationContext);

export default useNotification;
