import React from 'react';
import { View } from 'react-native';
import { CatalogItemTitle } from './CatalogItemTitle';
import { CatalogDescription } from './CatalogDescription';
import { CatalogDivider } from './CatalogDivider';

type Props = {
  title?: string;
  description?: string;
  children: React.ReactNode;
};

export const CatalogHeader = ({
  title = '',
  description = '',
  children,
}: Props) => {
  return (
    <View>
      <CatalogItemTitle label={title} />
      <CatalogDescription label={description} />
      <CatalogDivider />
      {children}
      <CatalogDivider />
      <CatalogItemTitle label={`${title} props`} />
    </View>
  );
};
