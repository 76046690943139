import { ArrowRight } from 'components/common/ArrowRight/ArrowRight';
import { Card } from 'components/common/Card/Card';
import { CircularButton } from 'components/common/CircularButton/CircularButton';
import { Icon } from 'components/common/Icon/Icon';
import { JText } from 'components/common/Text/Text';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import dayjs from 'dayjs';
import useTheme from 'hooks/useTheme';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';

type Props = {
  onEdit: () => void;
  onDelete: () => void;
  onResults: () => void;
  date: Date;
  from: string;
  to: string;
  results: number;
};

const AlertItem = ({
  onEdit,
  onResults,
  date,
  from,
  to,
  results,
  onDelete,
}: Props) => {
  const { theme } = useTheme();

  return (
    <TouchableOpacity onPress={onResults}>
      <Card style={{ marginBottom: theme.sizings.mediumLarge }}>
        <ViewRow>
          <ViewCol
            style={{ width: theme.normalize(60), justifyContent: 'center' }}
          >
            <Icon
              name="bell"
              type="FontAwesome5"
              colorName="greyLight"
              sizeName="fs40"
            />
          </ViewCol>
          <ViewCol size={1}>
            <JText
              labelKey="sentences.dateHour"
              valuesKey={{
                day: dayjs(date).format('L'),
                hour: dayjs(date).format('LT'),
              }}
              isBold
            />
            <JText labelKey="sentences.fromTo" valuesKey={{ from, to }} />
            {/* TODO : A traiter plus tard 
            <JText
              isItalic
              labelKey="sentences.resultsCount"
              valuesKey={{ results }}
            /> */}
          </ViewCol>
        </ViewRow>
        <ViewRow style={{ marginTop: theme.sizings.smallMedium }}>
          <ViewCol inline style={{ paddingLeft: theme.normalize(60) }}>
            <CircularButton
              backgroundColorName="blueLight"
              iconColorName="blueHigh"
              onPress={onEdit}
              iconName="pen"
              iconType="FontAwesome5"
              sizeName="high"
              style={{
                alignSelf: 'flex-end',
                marginRight: theme.sizings.smallMedium,
              }}
            />
            <CircularButton
              backgroundColorName="statusDangerHigh"
              iconColorName="statusDangerLight"
              onPress={onDelete}
              iconName="trash"
              iconType="FontAwesome5"
              sizeName="high"
              style={{
                alignSelf: 'flex-end',
                marginRight: theme.sizings.smallMedium,
              }}
            />
          </ViewCol>
          <ArrowRight onPress={onResults} />
        </ViewRow>
      </Card>
    </TouchableOpacity>
  );
};

export default AlertItem;
