import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { BackgroundLayout } from 'components/layouts/BackgroundLayout/BackgroundLayout';
import useTheme from 'hooks/useTheme';
import { RootStackParamList } from 'navigation/RootStackParamList';
import useAuth from 'hooks/useAuth';
import useList from 'hooks/useList';
import { NotificationsManager } from 'components/compositions/Notifications/NotificationsManager';
import { populateUserSummary } from 'utils/populateModels';
import { JText } from 'components/common/Text/Text';
import fetchJSON from 'utils/fetchJSON';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'Notifications'
>;

const NotificationsScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopStyles = fnDesktopStyles(theme);
  const desktopMode = isDesktopMode();

  const { user: currentUser } = useAuth();
  const { items: notifications, isFetching: isNotificationsFetching } =
    useList<Notification>('notifications', {
      populate: {
        createdByUser: populateUserSummary,
        createdFor: populateUserSummary,
        notation: {
          populate: '*',
        },
        ride: {
          populate: '*',
        },
        conversation: {
          populate: '*',
        },
      },
      defaultFilters: {
        createdFor: currentUser?.id || '',
      },
      defaultSort: 'createdAt:DESC',
    });

  const markAllAsRead = useCallback(async () => {
    try {
      const payload = {
        userId: currentUser?.id,
        /* types: [
          'new_message',
          'new_message_liked',
          'new_comment',
          'new_comment_liked',
          'new_notation',
          'new_application',
          'application_accepted',
          'application_refused',
          'passenger_canceled',
          'new_result_for_alert',
          'document_accepted',
          'document_refused',
          'vehicle_accepted',
          'vehicle_refused',
          'vehicle_document_accepted',
          'vehicle_document_refused',
          'vehicle_document_waiting',
          'missing_code_for_ride',
          'missing_passenger',
          'new_points',
          'reputation_decreased',
          'reputation_increased',
          'ride_updated',
          'ride_canceled',
        ], */
      };

      const res = await fetchJSON({
        url: `notifications/acknowledge`,

        method: 'POST',
        payload: { data: payload },
      });
      if (res) {
        // console.log(res);
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }, [currentUser?.id]);

  useEffect(() => {
    if (notifications?.length) {
      markAllAsRead();
    }
  }, [notifications, markAllAsRead]);

  return (
    <BackgroundLayout isScrollView>
      <View style={desktopMode && desktopStyles.boxed}>
        <View style={{ padding: theme.sizings.mediumLarge }}>
          {isNotificationsFetching ? (
            <JText labelKey="common.loading" centered />
          ) : (
            <NotificationsManager
              notifications={notifications || []}
              navigation={navigation}
            />
          )}
        </View>
      </View>
    </BackgroundLayout>
  );
};

export default NotificationsScreen;
