import dayjs from 'dayjs';
import useAuth from 'hooks/useAuth';
import useList from 'hooks/useList';
import useAlert from 'providers/AlertProvider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { NotificationModel, Ride, Section } from 'types';
import { Notification } from 'types/Notification';
import { Application } from 'types/Application';
import { Passenger } from 'types/Passenger';
import { JText } from 'components/common/Text/Text';
import { Section as Bloc } from 'components/common/Section/Section';
import { NotificationItem } from './NotificationItem';

type Props = {
  notifications?: any;
  navigation: any;
};

export const NotificationsManager = ({
  notifications = [],
  navigation,
}: Props) => {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const myId = currentUser?.id;

  const { items: rides, isFetching: isRidesFetching } = useList<Ride>('rides', {
    populate: ['driver'],
  });

  const { setAlert } = useAlert();

  const handleNavigateCourseDetail = (currentNotif: Notification) => {
    // driver POV
    const rideId = currentNotif?.ride?.id;
    const stillExist = rideId
      ? rides?.find((r: Ride) => r.id === rideId)
      : false;

    const isDriver = currentNotif?.ride?.driver.id === myId;

    // others POV
    const sectionCandidate = currentNotif?.ride?.sections?.find((s: Section) =>
      s.applications?.find((a: Application) => a.user.id === myId),
    );

    const sectionPassenger = currentNotif?.ride?.sections?.find((s: Section) =>
      s.passengers?.find((p: Passenger) => p.user.id === myId),
    );

    const sectionId = sectionCandidate?.id || sectionPassenger?.id || undefined;

    if (stillExist) {
      if (isDriver) {
        navigation.navigate('CourseDetail', {
          rideId,
        });
      } else if (sectionId) {
        navigation.navigate('SectionDetail', {
          rideId,
          sectionId,
          seats: undefined,
        });
      } else {
        setAlert({
          message: t('alert.rideNotAccessible'),
          title: t('alertTitle.rideNotAccessible'),
        });
      }
    }
  };

  const createItem = (notification: Notification) => {
    const type = notification.status;

    const prettyDepartureTime = dayjs(
      notification.ride?.departureTime,
    ).fromNow();

    const prettyUser =
      notification.createdByUser?.username ||
      notification.createdByUser?.firstname;

    const valuesKeyDate = {
      date: prettyDepartureTime,
    };

    const valuesKeyUser = {
      user: prettyUser,
    };

    const valuesKeyUserDate = {
      user: prettyUser,
      date: prettyDepartureTime,
    };

    const displayItem = {
      iconName: 'info',
      iconColor: 'blueHigh',
      backgroundColor: 'blueLight',
      messageHeadKey: `notification.${type}`,
    };

    // NEW
    if (type === 'new_message') {
      return {
        iconName: 'envelope',
        iconColor: 'blueHigh',
        backgroundColor: 'blueLight',
        messageHeadKey: 'prettyNotifications.new_message',
        messageHeadValues: valuesKeyUser,
        messageActionKey: 'actionNotifications.see_message',
        onPress: () =>
          navigation.navigate('Chat', {
            conversation: notification.conversation.id,
            correspondantUser: notification.createdByUser.id,
          }),
      };
    }

    if (type === 'new_conversation') {
      return {
        iconName: 'envelope',
        iconColor: 'blueHigh',
        backgroundColor: 'blueLight',
        messageHeadKey: 'prettyNotifications.new_conversation',
        messageHeadValues: valuesKeyUser,
        messageActionKey: 'actionNotifications.see_conversation',
        onPress: () =>
          navigation.navigate('Chat', {
            conversation: notification.conversation.id,
            correspondantUser: notification.createdByUser.id,
          }),
      };
    }

    if (type === 'new_comment') {
      return {
        iconName: 'comment',
        iconColor: 'blueHigh',
        backgroundColor: 'blueLight',
        messageHeadKey: 'prettyNotifications.new_comment',
        messageHeadValues: valuesKeyUserDate,
        messageActionKey: 'actionNotifications.see_ride',
        onPress: () => handleNavigateCourseDetail(notification),
      };
    }

    if (type === 'new_notation') {
      return {
        iconName: 'star',
        iconColor: 'greenMedium',
        backgroundColor: 'statusSuccessLight',
        messageHeadKey: 'prettyNotifications.new_notation',
        messageHeadValues: valuesKeyUser,
        messageActionKey: 'actionNotifications.see_review',
        onPress: () => {navigation.navigate('Profile', { screen: 'Profile-reviews'})}

      };
    }

    if (type === 'new_application') {
      return {
        iconName: 'envelope',
        iconColor: 'statusWarningHigh',
        backgroundColor: 'statusWarningLight',
        messageHeadKey: 'prettyNotifications.new_application',
        messageHeadValues: valuesKeyUserDate,
        messageActionKey: 'actionNotifications.see_ride',
        onPress: () => handleNavigateCourseDetail(notification),
      };
    }

    // TODO :
    // APPLICANT CANCEL HIS APPLICATION
    if (type === 'application_canceled') {
      return {
        iconName: 'envelope',
        iconColor: 'statusWarningHigh',
        backgroundColor: 'statusWarningLight',
        messageHeadKey: 'prettyNotifications.new_application',
        messageHeadValues: valuesKeyUserDate,
        messageActionKey: 'actionNotifications.see_ride',
        onPress: () => handleNavigateCourseDetail(notification),
      };
    }

    // ACCEPTED
    if (type === 'application_accepted') {
      return {
        iconName: 'check',
        iconColor: 'greenMedium',
        backgroundColor: 'statusSuccessLight',
        messageHeadKey: 'prettyNotifications.application_accepted',
        messageHeadValues: valuesKeyDate,
        messageActionKey: 'actionNotifications.see_ride',
        onPress: () => handleNavigateCourseDetail(notification),
      };
    }

    if (type === 'document_accepted') {
      return {
        iconName: 'check',
        iconColor: 'greenMedium',
        backgroundColor: 'statusSuccessLight',
        messageHeadKey: t('notifications.document_accepted'),
        onPress: () => {navigation.navigate('Profile', { screen: 'Profile-documents'})}
      };
    }

    if (type === 'vehicle_accepted') {
      return {
        iconName: 'check',
        iconColor: 'greenMedium',
        backgroundColor: 'statusSuccessLight',
        messageHeadKey: t('notifications.vehicle_accepted'),
        onPress: () => {navigation.navigate('Profile', { screen: 'Profile-vehicles'})}
      };
    }

    if (type === 'vehicle_document_accepted') {
      return {
        iconName: 'check',
        iconColor: 'greenMedium',
        backgroundColor: 'statusSuccessLight',
        messageHeadKey: t('notifications.vehicle_document_accepted'),
        onPress: () => {navigation.navigate('Profile', { screen: 'Profile-vehicles'})}
      };
    }

    // REFUSED
    if (type === 'application_refused') {
      return {
        iconName: 'times',
        iconColor: 'statusDangerHigh',
        backgroundColor: 'statusDangerLight',
        messageHeadKey: 'prettyNotifications.application_refused',
        messageHeadValues: valuesKeyDate,
        messageActionKey: 'actionNotifications.see_ride',
        onPress: () => handleNavigateCourseDetail(notification),
      };
    }

    if (type === 'document_refused') {
      return {
        iconName: 'times',
        iconColor: 'statusDangerHigh',
        backgroundColor: 'statusDangerLight',
        messageHeadKey: t('notifications.document_refused'),
        onPress: () => {navigation.navigate('Profile', { screen: 'Profile-documents'})}
      };
    }

    if (type === 'vehicle_refused') {
      return {
        iconName: 'times',
        iconColor: 'statusDangerHigh',
        backgroundColor: 'statusDangerLight',
        messageHeadKey: t('notifications.vehicle_refused'),
        onPress: () => {navigation.navigate('Profile', { screen: 'Profile-vehicles'})}
      };
    }

    if (type === 'vehicle_document_refused') {
      return {
        iconName: 'times',
        iconColor: 'statusDangerHigh',
        backgroundColor: 'statusDangerLight',
        messageHeadKey: t('notifications.vehicle_document_refused'),
        onPress: () => {navigation.navigate('Profile', { screen: 'Profile-vehicles'})}
      };
    }

    if (type === 'vehicle_document_waiting') {
      return {
        iconName: 'hourglass',
        iconColor: 'statusWarningHigh',
        backgroundColor: 'statusWarningLight',
        messageHeadKey: t('notifications.vehicle_document_waiting'),
        onPress: () => {navigation.navigate('Profile', { screen: 'Profile-vehicles'})}
      };
    }


    // BE CAREFUL
    if (type === 'passenger_canceled') {
      return {
        iconName: 'exclamation',
        iconColor: 'statusWarningHigh',
        backgroundColor: 'statusWarningLight',
        messageHeadKey: 'prettyNotifications.passenger_canceled',
        messageHeadValues: valuesKeyDate,
        messageActionKey: 'actionNotifications.see_ride',
        onPress: () => handleNavigateCourseDetail(notification),
      };
    }

    if (type === 'ride_updated') {
      return {
        iconName: 'exclamation',
        iconColor: 'statusWarningHigh',
        backgroundColor: 'statusWarningLight',
        messageHeadKey: 'prettyNotifications.ride_updated',
        messageHeadValues: valuesKeyDate,
        messageActionKey: 'actionNotifications.see_ride',
        onPress: () => handleNavigateCourseDetail(notification),
      };
    }

    if (type === 'ride_canceled') {
      return {
        iconName: 'exclamation',
        iconColor: 'statusWarningHigh',
        backgroundColor: 'statusWarningLight',
        messageHeadKey: 'prettyNotifications.ride_canceled',
        messageHeadValues: valuesKeyDate,
      };
    }

    // DIVERS
    if (type === 'new_points') {
      return {
        iconName: 'info',
        iconColor: 'blueHigh',
        backgroundColor: 'blueLight',
        messageHeadKey: t('notifications.new_points'),
      };
    }

    if (type === 'reputation_decreased') {
      return {
        iconName: 'info',
        iconColor: 'blueHigh',
        backgroundColor: 'blueLight',
        messageHeadKey: t('notifications.reputation_decreased'),
      };
    }

    if (type === 'reputation_increased') {
      return {
        iconName: 'info',
        iconColor: 'blueHigh',
        backgroundColor: 'blueLight',
        messageHeadKey: t('notifications.reputation_increased'),
      };
    }

    if (type === 'missing_passenger') {
      return {
        iconName: 'info',
        iconColor: 'blueHigh',
        backgroundColor: 'blueLight',
        messageHeadKey: t('prettyNotifications.missing_passenger'),
        messageHeadValues: valuesKeyUser,
      };
    }

    // res
    return displayItem as NotificationModel;
  };

  return (
    <>
      {isRidesFetching && <JText centered labelKey="common.loading" />}
      {!isRidesFetching && !notifications?.length && (
        <JText centered labelKey="notifications.empty" />
      )}
      {!isRidesFetching && notifications?.length > 0 && (
        <View>
          {notifications
            .filter((n: Notification) => !n.isRead)
            .map((n: Notification, index: number) => {
              const fkey = `notification-${index}`;
              const noticationItem = createItem(n) as NotificationModel;
              return (
                <View key={fkey}>
                  <NotificationItem
                    onPress={noticationItem.onPress}
                    iconName={noticationItem.iconName}
                    iconColorName={noticationItem.iconColor}
                    backgroundColorName={noticationItem.backgroundColor}
                    messageHeadKey={noticationItem.messageHeadKey}
                    messageHeadValues={noticationItem.messageHeadValues}
                    messageActionKey={noticationItem?.messageActionKey}
                    avatar={n.createdByUser?.profilePicture}
                    avatarIsWoman={n.createdByUser?.gender === 'W'}
                    userId={n.createdByUser?.id}
                    navigation={navigation}
                  />
                </View>
              );
            })}

          {notifications.filter((n: Notification) => n.isRead).length > 0 && (
            <Bloc titleKey="notifications.alreadyRead">
              {notifications
                .filter((n: Notification) => n.isRead)
                .map((n: Notification, index: number) => {
                  const fkey = `notification-${index}`;
                  const noticationItem = createItem(n) as NotificationModel;
                  return (
                    <View key={fkey}>
                      <NotificationItem
                        onPress={noticationItem.onPress}
                        iconName={noticationItem.iconName}
                        iconColorName={noticationItem.iconColor}
                        backgroundColorName={noticationItem.backgroundColor}
                        messageHeadKey={noticationItem.messageHeadKey}
                        messageHeadValues={noticationItem.messageHeadValues}
                        messageActionKey={noticationItem?.messageActionKey}
                        avatar={n.createdByUser?.profilePicture}
                        avatarIsWoman={n.createdByUser?.gender === 'W'}
                        userId={n.createdByUser?.id}
                        navigation={navigation}
                      />
                    </View>
                  );
                })}
            </Bloc>
          )}
        </View>
      )}
    </>
  );
};
