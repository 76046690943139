import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import React from 'react';
import { JText } from 'components/common/Text/Text';
import useTheme from 'hooks/useTheme';
import Modal from 'components/common/Modal/Modal';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import { CircularButton } from 'components/common/CircularButton/CircularButton';
import { I18nKey } from '../../../../i18n';

type PropsFieldHelpWrap = {
  onHelpInfoPress?: () => void;
  children: React.ReactNode;
};

type PropsModal = {
  isVisible: boolean;
  onClose: () => void;
  titleKey: I18nKey;
  contentKey: I18nKey;
};

export const FieldHelpWrap = ({
  onHelpInfoPress,
  children,
}: PropsFieldHelpWrap) => {
  const { theme } = useTheme();
  return (
    <ViewRow>
      <ViewCol size={1}>{children}</ViewCol>

      <ViewCol
        style={{
          width: 50,
          paddingLeft: theme.sizings.smallMedium,
          paddingTop: theme.sizings.large,
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
      >
        <CircularButton
          iconType="FontAwesome5"
          iconName="info"
          iconColorName="blueHigh"
          backgroundColorName="blueLight"
          sizeName="high"
          onPress={onHelpInfoPress}
        />
      </ViewCol>
    </ViewRow>
  );
};

export const FieldHelpModal = ({
  isVisible,
  onClose,
  titleKey,
  contentKey,
}: PropsModal) => {
  const modalContent = <JText labelKey={contentKey} />;
  const modalFooter = (
    <GradientButton onPress={onClose} labelKey="actions.agree" />
  );

  return (
    <Modal
      titleKey={titleKey}
      isVisible={isVisible}
      onBackDropPress={onClose}
      renderContent={modalContent}
      renderFooter={modalFooter}
      iconName="edit"
    />
  );
};
