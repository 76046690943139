import { Divider } from 'components/common/Divider/Divider';
import { JText } from 'components/common/Text/Text';
import { TextInIcon } from 'components/common/TextInIcon/TextInIcon';
import React, { useEffect, useState } from 'react';
import {
  ScrollView,
  View,
  Image,
  ImageBackground,
  Dimensions,
  Platform,
} from 'react-native';
import { CircularButton } from 'components/common/CircularButton/CircularButton';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from 'navigation/RootStackParamList';
import useTheme from 'hooks/useTheme';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import { TextButton } from 'components/common/TextButton/TextButton';
import { getStatusBarHeight } from 'react-native-status-bar-height';
import useList from 'hooks/useList';
import { useAuth } from 'hooks/useAuth';
import { fetchJSON } from 'utils/fetchJSON';
import { useFocusEffect } from '@react-navigation/native';
import fnDesktopStyles from 'utils/desktopModeStyle';
import isDesktopMode from 'utils/desktopMode';
import ResponsiveScrollView from 'components/layouts/ResponsiveScrollView/ResponsiveScrollView';
import fnStyles from './HomeScreenStyle';
import EventItem from './components/EventItem/EventItem';
import homeBackground from '../../../../assets/home-background.png';
import homeDesktopBackground from '../../../../assets/desktop-background.jpg';
import illustration from '../../../../assets/home-illustration.png';
import desktopIllustration from '../../../../assets/desktop-car.png';
import iconReputation from '../../../../assets/icon-reputation.png';
import iconCilipoints from '../../../../assets/icon-cilipoints.png';

export type ScreenProps = NativeStackScreenProps<RootStackParamList, 'Home'>;

const HomeScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const desktopStyles = fnDesktopStyles(theme);
  const desktopMode = isDesktopMode();

  const { items: events, fetchItems: fetchEventsItems } = useList<Event>(
    'events',
    {
      populate: 'mainPicture',
    },
  );

  useFocusEffect(
    React.useCallback(() => {
      fetchEventsItems();
    }, [fetchEventsItems]),
  );

  const objEvents = events;

  const { isFetching: isUserFetching, user: currentUser } = useAuth();

  const [showHomeContent, setShowHomeContent] = useState(false);

  useEffect(() => {
    if (currentUser?.profileComplete === true) {
      setShowHomeContent(true);
    }
  }, [currentUser]);

  const handleClickCompleteProfileAfter = async () => {
    await fetchJSON({
      url: `users/${currentUser ? currentUser.id : ''}`,
      method: 'PUT',
      payload: { profileComplete: true },
    });
    setShowHomeContent(true);
  };

  const handleClickCompleteProfile = async () => {
    await fetchJSON({
      url: `users/${currentUser ? currentUser.id : ''}`,
      method: 'PUT',
      payload: { profileComplete: true },
    });
    navigation.navigate('Profile');
  };

  const currentPseudo = currentUser?.username || currentUser?.firstname;

  return (
    <View style={{ backgroundColor: theme.colors.light, flex: 1 }}>
      <ResponsiveScrollView
        style={{
          marginTop:
            desktopMode || Platform.OS === 'web'
              ? undefined
              : getStatusBarHeight(),
          paddingTop: desktopMode ? theme.headerSizes.desktop : undefined,
        }}
      >
        <View style={[{ backgroundColor: theme.colors.light }]}>
          {/* top */}
          <View>
            <ImageBackground
              source={desktopMode ? homeDesktopBackground : homeBackground}
              resizeMode={desktopMode ? 'cover' : 'contain'}
              style={[
                styles.background,
                {
                  width: Dimensions.get('window').width,
                  height: desktopMode
                    ? 0.5 * Dimensions.get('window').height
                    : 0.7 * Dimensions.get('window').width,
                },
              ]}
            />

            {isUserFetching ? (
              <JText labelKey="common.loading" />
            ) : (
              <View
                style={[
                  { flexDirection: 'row' },
                  desktopMode ? desktopStyles.boxed : styles.blocTop,
                ]}
              >
                <Image
                  source={desktopMode ? desktopIllustration : illustration}
                  resizeMode="contain"
                  style={
                    desktopMode
                      ? styles.blocIllustrationDesktop
                      : styles.blocIllustration
                  }
                />

                <View style={styles.blocTopRight}>
                  <View style={styles.topWelcome}>
                    <JText
                      isTitleText
                      textType="h1"
                      labelKey="home.welcome"
                      sizeName="fs30"
                    />

                    {currentUser && (
                      <JText
                        isTitleText
                        textType="h1"
                        label={`${currentPseudo} !`}
                        style={{ marginTop: -theme.fontSizes.medium }}
                        sizeName={
                          currentPseudo && currentPseudo?.length < 16
                            ? 'fs30'
                            : 'larger'
                        }
                      />
                    )}
                  </View>

                  {currentUser && showHomeContent === true && (
                    <View style={styles.topBadges}>
                      <View style={styles.topBadgeItem}>
                        <TextInIcon
                          image={iconReputation}
                          paddingTop={theme.normalize(25)}
                        >
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <JText
                              colorName="light"
                              isTitleText
                              sizeName="fs30"
                              label={
                                currentUser.reputation?.toString()[0] || '?'
                              }
                            />

                            {currentUser.reputation?.toString().length > 1 && (
                              <JText
                                colorName="light"
                                isTitleText
                                sizeName="fs18"
                                label={`.${
                                  currentUser.reputation?.toString()[2]
                                }`}
                                style={{ marginLeft: theme.sizings.tiny }}
                              />
                            )}
                          </View>
                        </TextInIcon>
                        <JText labelKey="reviews.reputation" />
                      </View>

                      <Divider vertical />
                      <View style={styles.topBadgeItem}>
                        <TextInIcon
                          image={iconCilipoints}
                          paddingTop={theme.normalize(48)}
                          style={{ marginTop: -theme.sizings.smallMedium }}
                        >
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'flex-end',
                            }}
                          >
                            <JText
                              colorName="light"
                              isTitleText
                              sizeName={
                                currentUser.cilipoints?.toString().length < 4
                                  ? 'largest'
                                  : 'larger'
                              }
                              label={`${currentUser.cilipoints || '0'}`}
                            />
                          </View>
                        </TextInIcon>
                        <JText labelKey="common.cilipoints" />
                      </View>
                    </View>
                  )}
                </View>
              </View>
            )}
          </View>

          {!isUserFetching &&
            /* condition : on affiche ce bloc si l'utilisateur n'a pas encore de compte, ou s'il a un compte
        ET que son profil est complet, ou qu'il a choisi d'ignorer l'étape de remplissage du profil */
            (showHomeContent === true || !currentUser ? (
              <View
                style={[styles.blocBottom, desktopMode && desktopStyles.boxed]}
              >
                {/* currentUser && (
                <>
                  <NotificationsManager displayLimit={2} />
                  <Divider />
                </>
              ) */}

                <JText
                  isTitleText
                  centered
                  labelKey="home.wantToBe"
                  textType="h2"
                />

                <View
                  style={{ flexDirection: 'row', justifyContent: 'center' }}
                >
                  <CircularButton
                    labelKey="courses.passenger"
                    backgroundColorName="blueLight"
                    sizeName="sz95"
                    iconType="Material"
                    iconName="luggage"
                    iconSizeName="fs50"
                    iconColorName="blueHigh"
                    onPress={
                      currentUser
                        ? () => navigation.navigate('CourseSearch')
                        : () => navigation.navigate('Login')
                    }
                  />
                  <View style={{ width: 30 }} />
                  <CircularButton
                    labelKey="courses.driver"
                    backgroundColorName="statusSuccessLight"
                    sizeName="sz95"
                    iconType="FontAwesome5"
                    iconName="car"
                    iconSizeName="fs50"
                    iconColorName="statusSuccessHigh"
                    onPress={
                      currentUser
                        ? () => navigation.navigate('CourseCreate')
                        : () => navigation.navigate('Login')
                    }
                  />
                </View>

                {objEvents && objEvents?.length > 0 && (
                  <>
                    <Divider />
                    <JText
                      isTitleText
                      centered
                      labelKey="home.nextEvents"
                      textType="h2"
                    />
                    {objEvents.map((event: any, index: number) => {
                      const key = `event-${index}`;
                      return (
                        <View key={key}>
                          <EventItem
                            onPress={() =>
                              navigation.navigate('EventDetail', {
                                eventId: event.id,
                              })
                            }
                            title={event.name}
                            place={`à ${event.address}`}
                            // trajects={1}
                            image={event.mainPicture}
                          />
                        </View>
                      );
                    })}
                  </>
                )}
              </View>
            ) : (
              <View
                style={[
                  styles.blocBottomFirst,
                  desktopMode && desktopStyles.boxed,
                ]}
              >
                <JText
                  centered
                  labelKey="sentences.welcomeAndComplete"
                  sizeName="fs18"
                />
                <GradientButton
                  labelKey="actions.completeMyProfile"
                  onPress={handleClickCompleteProfile}
                  leftIconType="FontAwesome5"
                  leftIconName="user"
                  leftIconSizeName="largest"
                  rightIconType="FontAwesome5"
                  rightIconName="angle-right"
                  rightIconSizeName="largest"
                  style={{ marginVertical: theme.sizings.mediumLarge }}
                />
                <TextButton
                  labelKey="actions.later"
                  onPress={handleClickCompleteProfileAfter}
                  style={{ alignItems: 'center', width: '100%' }}
                  isBold
                />
              </View>
            ))}
        </View>
      </ResponsiveScrollView>
    </View>
  );
};

export default HomeScreen;
