import React from 'react';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import useTheme from 'hooks/useTheme';
import { Section } from 'components/common/Section/Section';
import { Card } from 'components/common/Card/Card';
import { JText } from 'components/common/Text/Text';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'CourseDetail'
>;
type Props = {
  code: string;
};

const RideCode = ({ code }: Props) => {
  const { theme } = useTheme();

  return (
    <Card style={{ marginBottom: theme.sizings.larger }}>
      <Section title="Code à founir aux passagers" noMargin>
        <JText label={code} isBold centered sizeName="fs40" />
      </Section>
    </Card>
  );
};

export default RideCode;
