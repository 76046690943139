import React, { useEffect, useState } from 'react';
import { View, ScrollView } from 'react-native';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { getStatusBarHeight } from 'react-native-status-bar-height';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import CourseHeader from 'components/compositions/CourseHeader/CourseHeader';
import useTheme from 'hooks/useTheme';
import computeAge from 'utils/computeAge';
import { SectionProvider } from 'providers/SectionProvider';
import useItem from 'hooks/useItem';
import { Section } from 'types/Section';
import { Application } from 'types/Application';
import { Passenger } from 'types/Passenger';
import { populateUserSummary } from 'utils/populateModels';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import useAlert from 'providers/AlertProvider';
import ResponsiveScrollView from 'components/layouts/ResponsiveScrollView/ResponsiveScrollView';
import useList from 'hooks/useList';
import { Conversation } from 'types/Conversation';
import { User } from 'types';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import { useIsFocused } from '@react-navigation/native';
import RideDescription from './modules/RideDescription';
import RidePassengers from './modules/RidePassengers';
import RideFeeds from './modules/RideFeeds';
import {
  addEventListener,
  Listener,
  removeEventListener,
} from '../../../providers/SocketProvider';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'SectionDetail'
>;

// PAGE POUR LES PASSAGERS

const SectionDetailScreen = ({ navigation, route }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const desktopStyles = fnDesktopStyles(theme);
  const { t } = useTranslation();
  const { setAlert } = useAlert();

  const { rideId, sectionId, seats } = route.params;

  const isFocused = useIsFocused();

  const { item: section, fetchItem: fetchSectionItem } = useItem<Section>(
    `sections`,
    sectionId,
    {
      listenToEvents: [
        'new_comment',
        'new_comment_liked',
        'application_accepted',
        'application_refused',
        'ride_updated',
        'ride_canceled',
      ],
      onEvent: {
        ride_canceled: () => {
          navigation.navigate('Root');
        },
      },
      populate: {
        departureLocation: {
          populate: '*',
        },
        arrivalLocation: {
          populate: '*',
        },
        ride: {
          populate: {
            parent: {
              populate: '*',
            },
            departureLocation: {
              populate: '*',
            },
            arrivalLocation: {
              populate: '*',
            },
            driver: populateUserSummary,
            vehicle: {
              populate: 'picture',
            },
            feeds: {
              // exemple sort: ['name:ASC'],
              populate: {
                comments: {
                  populate: {
                    responseOf: {
                      populate: '*',
                    },
                    user: populateUserSummary,
                  },
                },
              },
            },
          },
        },
        applications: {
          populate: {
            user: populateUserSummary,
          },
        },
        passengers: {
          populate: {
            user: populateUserSummary,
          },
        },
      },
    },
  );

  const ride = section?.ride;

  /* user & driver */
  const { user: currentUser } = useAuth();

  const currentDriverId = ride?.driver.id;
  const currentDriver = ride?.driver;

  /* candidature and passengers */
  const currentCandidature = section?.applications.find(
    (application: Application) => application.user.id === currentUser?.id,
  );

  const [currentPassenger, setCurrentPassenger] = useState(
    section?.passengers.find(
      (passenger: Passenger) => passenger.user.id === currentUser?.id,
    ),
  );

  const points = [];

  // On rafraichit les données quand on revient sur la page
  useEffect(() => {
    if (isFocused) {
      fetchSectionItem(sectionId);
    }
  }, [fetchSectionItem, isFocused, sectionId]);

  // On rafraichit le currentPassager quand les sections changent
  useEffect(() => {
    if (section) {
      setCurrentPassenger(
        section?.passengers.find(
          (passenger: Passenger) => passenger.user.id === currentUser?.id,
        ),
      );
    }
  }, [currentUser?.id, section]);

  if (ride) {
    points.push({
      id: ride.departureLocation?.id,
      name: ride.departureLocation?.name,
      longitude: ride.departureLocation?.longitude,
      latitude: ride.departureLocation?.latitude,
    });
    points.push({
      id: ride.arrivalLocation?.id,
      name: ride.arrivalLocation?.name,
      longitude: ride.arrivalLocation?.longitude,
      latitude: ride.arrivalLocation?.latitude,
    });
  }

  /* contact */
  const { items: conversations } = useList<Conversation>('conversations', {
    populate: {
      users: populateUserSummary,
    },
    listenToEvents: ['new_message', 'new_conversation'],
    defaultFilters: {
      users: [currentUser?.id],
    },
    defaultSort: 'updatedAt:DESC',
  });

  const handleContact = (userId: number) => {
    const conversation =
      conversations?.find((c: Conversation) =>
        c.users.find((user: User) => user.id === userId),
      )?.id || null;

    // console.log(conversation, userId);

    navigation.navigate('Chat', {
      conversation,
      correspondantUser: userId,
    });
  };

  /* candidatures et passagers */
  const { createCandidature, deleteCandidature, deletePassenger } =
    SectionProvider();

  const handleCandidate = (
    userId: number | undefined,
    _rideId: number,
    _sectionId: number,
  ) => {
    createCandidature(userId, _rideId, _sectionId, seats);
    fetchSectionItem(sectionId);
    setAlert({
      message: t('alert.candidatureSent'),
      title: t('alertTitle.candidatureSent'),
    });
  };

  const handleDelete = (candidature: number) => {
    deleteCandidature(candidature);
    setAlert({
      message: t('alert.candidatureCanceled'),
      title: t('alertTitle.candidatureCanceled'),
    });
    navigation.reset({
      index: 0,
      routes: [
        {
          name: 'Root',
          state: {
            routes: [
              {
                name: 'Courses',
                state: {
                  routes: [{ name: t('courses.iPassenger') }],
                },
              },
            ],
          },
        },
      ],
    });
  };

  const handleDeletePassenger = (passengerId: number) => {
    deletePassenger(passengerId);
    fetchSectionItem(sectionId);
    setAlert({
      message: t('alert.candidatureCanceled'),
      title: t('alertTitle.candidatureCanceled'),
    });
    navigation.reset({
      index: 0,
      routes: [
        {
          name: 'Root',
          state: {
            routes: [
              {
                name: 'Courses',
                state: {
                  routes: [{ name: t('courses.iPassenger') }],
                },
              },
            ],
          },
        },
      ],
    });
  };

  return (
    <View
      style={{
        backgroundColor: theme.colors.light,
        flex: 1,
      }}
    >
      <ScrollView>
        <View
          style={[
            {
              flex: 1,
              width: '100%',
              paddingTop: getStatusBarHeight(),
            },
            desktopMode && desktopStyles.boxed,
          ]}
        >
          {section && (
            <CourseHeader
              labelKey="sentences.travelWith"
              labelValues={{
                name: currentDriver?.username || currentDriver?.firstname,
                age: computeAge(currentDriver?.birthDate),
              }}
              // TODO : V2 PASSER LA LISTE DE TOUS LES POINTS
              points={[section.departureLocation, section.arrivalLocation]}
              image={currentDriver?.profilePicture}
              imageIsWoman={currentDriver?.gender === 'W'}
            />
          )}

          {currentUser && currentDriver && ride && rideId && sectionId && (
            <View style={{ padding: theme.sizings.mediumLarge }}>
              <RideDescription
                course={ride}
                driver={currentDriver}
                section={section}
              />

              <RidePassengers
                course={section}
                rideId={Number(rideId)}
                navigation={navigation}
                passengers={section.passengers}
                actionContact={handleContact}
                actionDelete={handleDeletePassenger}
              />

              <RideFeeds
                rideId={Number(rideId)}
                currentUserId={Number(currentUser.id)}
                feeds={ride.feeds}
                navigation={navigation}
                driverId={currentDriver.id}
                fetchNewItem={fetchSectionItem}
              />
            </View>
          )}
        </View>
      </ScrollView>

      <View
        style={[
          { padding: theme.sizings.mediumLarge },
          desktopMode && desktopStyles.boxed,
        ]}
      >
        {
          // course pas commencée ET pas encore passager
          section?.status === 'incoming' &&
            !currentPassenger &&
            (currentCandidature ? (
              <GradientButton
                onPress={() => handleDelete(Number(currentCandidature.id))}
                labelKey="actions.removeCandidate"
                leftIconType="AntDesign"
                leftIconName="user"
                leftIconSizeName="largest"
                rightIconType="AntDesign"
                rightIconName="right"
                rightIconSizeName="largest"
              />
            ) : (
              <GradientButton
                onPress={() => {
                  handleCandidate(
                    currentUser?.id,
                    Number(rideId),
                    Number(sectionId),
                  );
                  navigation.reset({
                    index: 0,
                    routes: [
                      {
                        name: 'Root',
                        state: {
                          routes: [
                            {
                              name: 'Courses',
                              state: {
                                routes: [{ name: t('courses.iSearch') }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  });
                }}
                labelKey="actions.candidate"
                leftIconType="AntDesign"
                leftIconName="user"
                leftIconSizeName="largest"
                rightIconType="AntDesign"
                rightIconName="right"
                rightIconSizeName="largest"
              />
            ))
        }

        {currentPassenger &&
          (section?.status === 'finished' ||
            section?.status === 'in_progress') && (
            <>
              {!currentPassenger?.codeEntered && (
                <GradientButton
                  onPress={() =>
                    navigation.navigate('CourseValidate', {
                      rideId,
                      targetId: Number(currentDriverId),
                      passengerId: currentPassenger.id,
                    })
                  }
                  labelKey="actions.courseValidate"
                  leftIconType="FontAwesome5"
                  leftIconName="check"
                  leftIconSizeName="largest"
                  rightIconType="AntDesign"
                  rightIconName="right"
                  rightIconSizeName="largest"
                />
              )}

              {currentPassenger?.codeEntered &&
                section?.status === 'finished' &&
                !currentPassenger?.notationToDriverEntered && (
                  <GradientButton
                    onPress={() =>
                      navigation.navigate('CourseEvaluate', {
                        targetId: Number(currentDriverId),
                        rideId,
                        passengerId: currentPassenger.id,
                        amDriver: false,
                      })
                    }
                    labelKey="actions.note"
                    leftIconType="FontAwesome5"
                    leftIconName="star"
                    leftIconSizeName="largest"
                    rightIconType="AntDesign"
                    rightIconName="right"
                    rightIconSizeName="largest"
                  />
                )}
            </>
          )}
      </View>
    </View>
  );
};

export default SectionDetailScreen;
