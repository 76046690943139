import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import useTheme from 'hooks/useTheme';
import { Section } from 'components/common/Section/Section';
import { AddComment } from 'components/compositions/AddComment/AddComment';
import { UserComment } from 'components/compositions/UserComment/UserComment';
import { FeedProvider } from 'providers/FeedProvider';
import { Divider } from 'react-native-elements';
import { Feed } from 'types/Feed';
import { Comment } from 'types/Comment';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'CourseDetail'
>;
type Props = {
  feeds: any;
  rideId: number;
  navigation: any;
  currentUserId: number;
  driverId: number;
  fetchNewItem: any; // section item or ride item
};

const RideFeeds = ({
  feeds,
  rideId,
  navigation,
  currentUserId,
  driverId,
  fetchNewItem,
}: Props) => {
  const { theme } = useTheme();

  const [showFieldAnswer, setShowFieldAnswer] = useState(0);
  const [contentNewFeed, setContentNewFeed] = useState('');
  const [contentAnswer, setContentAnswer] = useState('');
  const { createFeed, createComment } = FeedProvider();

  const handleCommentSubmit = useCallback(
    (
      dataComment: any,
      feedId: number | undefined,
      responseOfId: number | undefined,
    ) => {
      const unicDate = new Date();
      const newFeedId = Number(`${currentUserId}${rideId}${Number(unicDate)}`);

      if (!feedId) {
        createFeed(rideId, newFeedId);
        createComment(dataComment, currentUserId, newFeedId, undefined);
      } else {
        createComment(dataComment, currentUserId, feedId, responseOfId);
      }
      fetchNewItem();

      setShowFieldAnswer(0);
      setContentAnswer('');
      setContentNewFeed('');
    },
    [
      createFeed,
      createComment,
      setShowFieldAnswer,
      setContentAnswer,
      rideId,
      currentUserId,
      fetchNewItem,
    ],
  );

  return (
    <Section
      title="Commentaires"
      style={{
        marginTop: theme.sizings.medium,
        marginBottom: theme.sizings.medium,
      }}
    >
      <View style={{ marginTop: theme.sizings.mediumLarge }}>
        {feeds?.length > 0 && (
          <>
            {feeds?.map((feed: Feed) => {
              const feedId = feed.id;
              const comments = feed.comments || [];

              comments.sort(
                (commentA: any, commentB: any) =>
                  commentB.createdAt - commentA.createdAt,
              );

              return comments.map((comment: Comment) => {
                const key = `comment-${comment.id}`;
                const { user } = comment;
                const userId = comment.user.id;

                return (
                  <View key={key}>
                    <UserComment
                      comment={comment}
                      feedId={feedId}
                      commentId={comment.id}
                      date={comment.createdAt}
                      message={comment.content}
                      child={!!comment?.responseOf?.id}
                      // user
                      user={user}
                      userRole={driverId === userId ? 'driver' : 'passenger'}
                      actionAvatar={() =>
                        navigation.navigate('ThirdPartyProfile', {
                          profileId: userId,
                        })
                      }
                      // publier
                      contentAnswer={contentAnswer}
                      setContentAnswer={setContentAnswer}
                      showFieldAnswer={showFieldAnswer}
                      setShowFieldAnswer={setShowFieldAnswer}
                      onAnswerSubmit={() =>
                        handleCommentSubmit(contentAnswer, feedId, comment.id)
                      }
                      onLikeSubmit={() =>
                        handleCommentSubmit('ok', feedId, comment.id)
                      }
                    />
                  </View>
                );
              });
            })}
            <Divider style={{ marginBottom: theme.sizings.large }} />
          </>
        )}

        {/* new feed */}
        <AddComment
          content={contentNewFeed}
          setContent={setContentNewFeed}
          placeholderKey="sentences.newFeed"
          onSubmit={() =>
            handleCommentSubmit(contentNewFeed, undefined, undefined)
          }
        />
      </View>
    </Section>
  );
};

export default RideFeeds;
