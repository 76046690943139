/* eslint-disable react/no-array-index-key */
import React from 'react';
import { ViewStyle, StyleProp, View } from 'react-native';
import { Types } from 'types/Types';
import useTheme from 'hooks/useTheme';
import { ColorType, FontSizeType } from 'providers/ThemeProvider';
import fnStyles from './SelectableOptionsListStyle';
import { I18nKey } from '../../../../i18n';
import { IconicText } from '../IconicText/IconicText';
import { SelectableOptionButton } from '../SelectableOptionButton/SelectableOptionButton';
import { JText } from '../Text/Text';

type Props = {
  title?: string;
  titleKey?: I18nKey;
  values: string[];
  labelKeys?: I18nKey[];
  selectedValue: string | string[] | undefined;
  onChange: (value: string | string[] | undefined) => void;
  leftIconType?: Types['iconTypes'];
  leftIconName?: string;
  leftIconSizeName?: FontSizeType;
  leftIconColorName?: ColorType;
  style?: StyleProp<ViewStyle>;
};

export const SelectableOptionsList = (props: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const {
    title,
    titleKey,
    values,
    selectedValue,
    onChange,
    leftIconType,
    leftIconName,
    leftIconSizeName = 'large',
    leftIconColorName = 'dark',
    style,
  } = props;

  return (
    <View style={[styles.container, style]}>
      {leftIconName && leftIconType ? (
        <IconicText
          label={title}
          labelKey={titleKey}
          isBold
          textColorName="dark"
          iconType={leftIconType}
          iconName={leftIconName}
          iconSizeName={leftIconSizeName}
          iconColorName={leftIconColorName}
        />
      ) : (
        <JText isBold labelKey={titleKey} label={title} />
      )}
      {Array.isArray(selectedValue) && (
        <JText labelKey="common.multipleSelection" style={styles.subtitle} />
      )}

      <View style={styles.list}>
        {values?.map((item, index) => {
          return (
            <View
              key={index}
              style={{
                marginTop: theme.sizings.ten,
                marginRight: theme.sizings.ten,
              }}
            >
              <SelectableOptionButton
                value={item}
                labekLey={props.labelKeys ? props.labelKeys[index] : undefined}
                selectedValue={selectedValue}
                onChange={onChange}
              />
            </View>
          );
        })}
      </View>
    </View>
  );
};
