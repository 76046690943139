import { useCallback } from 'react';
import { IMessage } from 'react-native-gifted-chat';
import fetchJSON from 'utils/fetchJSON';

export const ChatProvider = () => {
  const createConversation = useCallback(
    async (
      messages: IMessage[],
      userId: number | undefined,
      correspondantId: number,
      newConversationId: number,
      setVirginNewConversationId: React.Dispatch<React.SetStateAction<boolean>>,
      newMessageId: number,
    ) => {
      const payloadConversation = {
        id: newConversationId,
        createdAt: new Date(),
        users: [userId, correspondantId],
        lastMessage: messages[0].text,
        lastMessageId: newMessageId,
      };

      try {
        const res = await fetchJSON({
          url: `conversations`,
          method: 'POST',
          payload: { data: payloadConversation },
        });
        if (res) {
          // console.log(res);
        }
      } catch (error) {
        console.log(error);
      }

      await setVirginNewConversationId(false);
    },
    [],
  );

  const updateConversation = useCallback(
    async (
      messages: IMessage[],
      conversationId: number,
      newMessageId: number,
    ) => {
      const payloadUpdateConversation = {
        lastMessage: messages[0].text,
        lastMessageId: newMessageId,
      };

      if (conversationId) {
        try {
          await fetchJSON({
            url: `conversations/${conversationId}`,
            method: 'PUT',
            payload: { data: payloadUpdateConversation },
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    [],
  );

  const createMessage = useCallback(
    async (
      messages: IMessage[],
      userId: number | undefined,
      correspondantId: number,
      conversationId: number,
    ) => {
      const payloadMessage = {
        // id: newMessageId,
        content: messages[0].text,
        createdAt: new Date(),
        conversation: conversationId,
        sender: userId,
        receiver: correspondantId,
        isRead: false,
        isApproved: false,
      };

      try {
        await fetchJSON({
          url: `messages`,
          method: 'POST',
          payload: { data: payloadMessage },
        });
      } catch (error) {
        console.log(error);
      }
    },
    [],
  );

  const markMessagesAsRead = useCallback(async (receivedMessages: number[]) => {
    const payloadMessage = {
      isRead: true,
    };
    receivedMessages?.map(async (rm: number) => {
      try {
        await fetchJSON({
          url: `messages/${rm}`,
          method: 'PUT',
          payload: { data: payloadMessage },
        });
      } catch (error) {
        console.log(error);
      }
    });
  }, []);

  return {
    createConversation,
    updateConversation,
    createMessage,
    markMessagesAsRead,
  };
};
