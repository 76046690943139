import React, { useState } from 'react';
import { ScrollView } from 'react-native';
import { Rating } from '../../components/common/Rating/Rating';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const RatingScreen = () => {
  const [rating, setRating] = useState(3);
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="Rating"
        description="This component renders a star rating."
      >
        <Rating value={rating} onChange={setRating} disabled={false} />
      </CatalogHeader>
      <CatalogProps propName="value" type="number" topDivider={false} />
      <CatalogProps propName="onChange" type="(value: number) => void" />
      <CatalogProps propName="size" type="number" />
      <CatalogProps propName="disabled" type="boolean" />
      <CatalogProps propName="starMargin" type="number" />
    </ScrollView>
  );
};

export default RatingScreen;
