import { useFocusEffect } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Section } from 'components/common/Section/Section';
import { JText } from 'components/common/Text/Text';
import { TileButton } from 'components/common/TileButton/TileButton';
import { CourseWithStatus } from 'components/compositions/CourseWithStatus/CourseWithStatus';
import ResponsiveScrollView from 'components/layouts/ResponsiveScrollView/ResponsiveScrollView';
import dayjs from 'dayjs';
import useTheme from 'hooks/useTheme';
import { RootStackParamList } from 'navigation/RootStackParamList';
import React from 'react';
import { View } from 'react-native';
import { Ride } from 'types';
import renderOverridedField from 'utils/computeOverridedField';
import { TabsBackground } from 'components/layouts/BackgroundLayout/TabsBackgound';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import { Passenger } from 'types/Passenger';
import { useTranslation } from 'react-i18next';
import useList from '../../../../hooks/useList';
import { I18nKey } from '../../../../../i18n';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'MyCourses'
>;

const MyDriverItemsScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const desktopStyles = fnDesktopStyles(theme);
  const { t } = useTranslation();

  // futur
  const {
    items: rides,
    isFetching: isRideFetching,
    fetchItems: fetchRidesItems,
  } = useList<Ride>('rides/me', {
    listenToEvents: [
      'passenger_canceled',
      'new_application',
      'application_accepted',
      'application_refused',
      'application_canceled',
      'ride_updated',
    ], // TODO work but can be optimized
    populate: ['departureLocation', 'arrivalLocation', 'parent'],
  });

  const futurRides = rides
    ?.filter((ride: Ride) => {
      const res =
        Number(dayjs(ride.departureTime).toDate()) - Number(new Date());
      return res >= 0;
    })
    .sort(
      (rideA: Ride, rideB: Ride) =>
        Number(dayjs(rideA.departureTime).toDate()) -
        Number(dayjs(rideB.departureTime).toDate()),
    );

  const historicRides = rides
    ?.filter((ride: Ride) => {
      const res =
        Number(dayjs(ride.departureTime).toDate()) - Number(new Date());
      return res < 0;
    })
    .sort(
      (rideA: Ride, rideB: Ride) =>
        Number(dayjs(rideB.departureTime).toDate()) -
        Number(dayjs(rideA.departureTime).toDate()),
    );

  useFocusEffect(
    React.useCallback(() => {
      // Do something when the screen is focused
      fetchRidesItems();

      return () => {
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [fetchRidesItems]),
  );

  return (
    <>
      <TabsBackground />
      <ResponsiveScrollView>
        <View
          style={[
            {
              padding: theme.sizings.mediumLarge,
              flex: 1,
            },
            desktopMode && desktopStyles.boxed,
          ]}
        >
          <View style={{ marginBottom: theme.sizings.large }}>
            <TileButton
              leftIconType="FontAwesome5"
              leftIconName="hand-rock"
              tileColorName="greenHigh"
              // rightIconType="FontAwesome5"
              // rightIconName="angle-right"
              labelKey="courses.createRide"
              onPress={() => navigation.navigate('CourseCreate')}
            />
          </View>

          {isRideFetching && <JText centered labelKey="common.loading" />}

          {!isRideFetching && futurRides && (
            <View style={{ marginBottom: theme.sizings.mediumLarge * 2 }}>
              <Section titleKey="courses.futur">
                {futurRides?.length ? (
                  futurRides.map((ride: any, index: number) => {
                    const fkey = `course-${index}`;
                    const passengersLength = ride.passengers.length;

                    const codeEnteredLength = ride.passengers?.filter(
                      (p: Passenger) => p.codeEntered === true,
                    ).length;

                    const cardTop = () => {
                      if (ride.full) {
                        return { label: 'courses.checkFull', icon: 'check' };
                      }
                      if (!ride.full && ride.applications.length) {
                        return {
                          label: 'courses.pendingCandidatures',
                          icon: 'envelope',
                        };
                      }
                      if (ride.status === 'finished') {
                        if (passengersLength === codeEnteredLength) {
                          return {
                            label: 'courses.codeOk',
                            icon: 'check',
                          };
                        }
                        return {
                          labelCustom: `${codeEnteredLength} / ${passengersLength} ${t(
                            'courses.codeOkSome',
                          )}`,
                          icon: 'check',
                        };
                      }
                      return { label: undefined, icon: undefined };
                    };

                    return (
                      <View key={fkey}>
                        <CourseWithStatus
                          userRole="driver"
                          statusOk={
                            ride.full ||
                            (ride.status === 'finished' &&
                              passengersLength === codeEnteredLength)
                          }
                          statusWarning={
                            (!ride.full && ride.applications.length) ||
                            (ride.status === 'finished' &&
                              passengersLength > codeEnteredLength)
                          }
                          cardTopLegende={cardTop().label as I18nKey}
                          cardTopIcon={cardTop().icon}
                          onPress={() =>
                            navigation.navigate('CourseDetail', {
                              rideId: ride.id,
                            })
                          }
                          ride={ride}
                          departureLocation={ride.departureLocation}
                          departureTime={ride.departureTime}
                          arrivalLocation={ride.arrivalLocation}
                          arrivalTime={ride.arrivalTime}
                          date={ride.departureTime}
                        />
                      </View>
                    );
                  })
                ) : (
                  <JText centered labelKey="courses.noResultsFutur" />
                )}
              </Section>
            </View>
          )}

          {!isRideFetching && historicRides && (
            <View style={{ marginBottom: theme.sizings.mediumLarge * 2 }}>
              <Section titleKey="courses.historic">
                {historicRides?.length ? (
                  historicRides.map((ride: any, index: number) => {
                    const fkey = `course-${index}`;

                    const passengersLength = ride.passengers.length;

                    const codeEnteredLength = ride.passengers?.filter(
                      (p: Passenger) => p.codeEntered === true,
                    ).length;

                    const cardTop = () => {
                      if (ride.full) {
                        return { label: 'courses.checkFull', icon: 'check' };
                      }
                      if (!ride.full && ride.applications.length) {
                        return {
                          label: 'courses.pendingCandidatures',
                          icon: 'envelope',
                        };
                      }

                      if (ride.status === 'finished') {
                        if (passengersLength === codeEnteredLength) {
                          return {
                            label: 'courses.codeOk',
                            icon: 'check',
                          };
                        }
                        return {
                          labelCustom: `${codeEnteredLength} / ${passengersLength} ${t(
                            'courses.codeOkSome',
                          )}`,
                          icon: 'check',
                        };
                      }
                      return { label: undefined, icon: undefined };
                    };

                    return (
                      <View key={fkey}>
                        <CourseWithStatus
                          userRole="driver"
                          statusOk={
                            ride.full ||
                            (ride.status === 'finished' &&
                              passengersLength === codeEnteredLength)
                          }
                          statusWarning={
                            (!ride.full && ride.applications.length) ||
                            (ride.status === 'finished' &&
                              passengersLength > codeEnteredLength)
                          }
                          cardTopLegende={cardTop().label as I18nKey}
                          cardTopLegendeCustom={cardTop().labelCustom as string}
                          cardTopIcon={cardTop().icon}
                          onPress={() =>
                            navigation.navigate('CourseDetail', {
                              rideId: ride.id,
                            })
                          }
                          ride={ride}
                          departureLocation={ride.departureLocation}
                          departureTime={ride.departureTime}
                          arrivalLocation={ride.arrivalLocation}
                          arrivalTime={ride.arrivalTime}
                          date={ride.departureTime}
                        />
                      </View>
                    );
                  })
                ) : (
                  <JText centered labelKey="courses.noResultsHistoric" />
                )}
              </Section>
            </View>
          )}
        </View>
      </ResponsiveScrollView>
    </>
  );
};

export default MyDriverItemsScreen;
