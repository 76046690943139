import React, { useState } from 'react';
import { Text, View, ScrollView } from 'react-native';
import { Section } from '../../components/common/Section/Section';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import { SelectableOptionsList } from '../../components/common/SelectableOptionsList/SelectableOptionsList';
import styles from './common/CommonCatalogStyle';

const SectionScreen = () => {
  const [selectedValue, setSelectedValue] = useState<
    string | string[] | undefined
  >('');
  const [selectedValue2, setSelectedValue2] = useState<
    string | string[] | undefined
  >('');
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="Section"
        description="This component renders a section which means a row composed by two dividers with in between a title."
      >
        <Section title="Section">
          <Text>A simple Section</Text>
        </Section>
        <View style={{ height: 10 }} />
        <Section title="Section" dividerSpacing={60}>
          <Text>A section with smaller divider</Text>
        </Section>
        <View style={{ height: 10 }} />
        <Section title="Conducteur et passager">
          <SelectableOptionsList
            title="Je suis bavard"
            values={['yes', 'sometimes', 'no']}
            selectedValue={selectedValue}
            onChange={setSelectedValue}
            style={{ marginVertical: 5 }}
            leftIconType="Entypo"
            leftIconName="message"
            leftIconSizeName="largest"
            leftIconColorName="blueHigh"
          />
          <SelectableOptionsList
            title="J'aime écouter de la musique'"
            values={['yes', 'sometimes', 'no']}
            selectedValue={selectedValue2}
            onChange={setSelectedValue2}
            style={{ marginVertical: 5 }}
            leftIconType="FontAwesome"
            leftIconName="music"
            leftIconSizeName="largest"
            leftIconColorName="blueHigh"
          />
        </Section>
      </CatalogHeader>
      <CatalogProps propName="title" type="string" topDivider={false} />
      <CatalogProps
        propName="dividerSpacing"
        description="The empty space on the side of the text."
        type="number"
      />
    </ScrollView>
  );
};

export default SectionScreen;
