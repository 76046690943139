import React, { useCallback, useState } from 'react';
import { View, ScrollView } from 'react-native';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import { JText } from 'components/common/Text/Text';
import { Section } from 'components/common/Section/Section';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import { getStatusBarHeight } from 'react-native-status-bar-height';
import { FormInput } from 'components/common/FormInput/FormInput';
import { InlineRating } from 'components/common/InlineRating/InlineRating';
import CourseHeader from 'components/compositions/CourseHeader/CourseHeader';
import useTheme from 'hooks/useTheme';
import useItem from 'hooks/useItem';
import useAuth from 'hooks/useAuth';
import { SectionProvider } from 'providers/SectionProvider';
import useAlert from 'providers/AlertProvider';
import { useTranslation } from 'react-i18next';
import fetchJSON from 'utils/fetchJSON';
import ResponsiveScrollView from 'components/layouts/ResponsiveScrollView/ResponsiveScrollView';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'CourseEvaluate'
>;

const CourseEvaluateScreen = ({ navigation, route }: ScreenProps) => {
  const { theme } = useTheme();
  const { rideId, targetId, passengerId, amDriver } = route.params;
  const { confirmNotation } = SectionProvider();
  const { setAlert } = useAlert();
  const { t } = useTranslation();

  /* current user */
  const { user: currentUser } = useAuth();
  const currentUserId = currentUser?.id;

  /* target */
  const { item: target } = useItem('users', targetId);
  const userTarget = Object(target);

  /* form */
  const [note, setNote] = useState(0);
  const [comment, setComment] = useState('');

  const createNotation = useCallback(
    async (data: { note: number; comment: string }) => {
      const payloadNotation = {
        id: Number(`${rideId}${targetId}${currentUserId}`),
        ride: rideId,
        mark: data.note,
        comment: data.comment,
        userTarget: targetId,
        userAuthor: currentUserId,
      };

      try {
        const res = await fetchJSON({
          url: `notations`,
          method: 'POST',
          payload: { data: payloadNotation },
        });
        if (res) {
          // console.log(res);
          return true;
        }
      } catch (error) {
        console.log(error);
      }
      return false;
    },
    [currentUserId, rideId, targetId],
  );

  const handleSubmit = (data: { note: number; comment: string }) => {
    if (note > 0 && comment !== '') {
      createNotation(data);

      // pour qu'un passager ne recoive jamais 2 notations ni n'en donne 2
      confirmNotation(Number(passengerId), amDriver);
      navigation.navigate('Home');
    } else {
      setAlert({
        color: theme.colors.statusRed,
        message: t('alert.missingFields'),
        title: t('alertTitle.evaluateMissingFields'),
      });
    }
  };

  const points: any = [];

  return (
    <View
      style={{
        backgroundColor: theme.colors.light,
        paddingTop: getStatusBarHeight(),
        flex: 1,
      }}
    >
      <ResponsiveScrollView style={{ flex: 1 }}>
        <CourseHeader
          labelKey="sentences.traveledWith"
          labelValues={{ name: userTarget?.username || userTarget?.firstname }}
          points={points}
          image={userTarget?.profilePicture}
          imageIsWoman={userTarget?.gender === 'W'}
        />

        <View
          style={{
            padding: theme.sizings.mediumLarge,
            marginTop: theme.normalize(90),
          }}
        >
          {/* Section de texte d'évaluation de trajet */}
          <Section titleKey="section.note" />
          <ViewRow style={{ paddingVertical: theme.sizings.mediumLarge }}>
            <ViewCol align="center" size={2}>
              <InlineRating
                evaluation={note}
                colorName="greenHigh"
                clickable
                onPress={value => {
                  return setNote(value);
                }}
              />
            </ViewCol>
            <ViewCol justify="flex-end" align="center" size={1} inline>
              <JText
                label={`${note}`}
                isBold
                sizeName="fs40"
                style={{ paddingTop: theme.sizings.small }}
              />
              <JText label="/5" sizeName="larger" />
            </ViewCol>
          </ViewRow>

          <Section titleKey="section.comment" />
          <FormInput
            value={comment}
            onChange={value => setComment(value)}
            placeholderKey="placeholder.courseComment"
            multiline
            height={theme.sizings.sz95}
          />
        </View>
      </ResponsiveScrollView>

      <View style={{ padding: theme.sizings.mediumLarge }}>
        <GradientButton
          onPress={() => handleSubmit({ note, comment })}
          leftIconType="AntDesign"
          leftIconName="star"
          leftIconSizeName="largest"
          labelKey="forms.validate"
          rightIconType="AntDesign"
          rightIconName="right"
          rightIconSizeName="largest"
        />

        <GradientButton
          onPress={() => navigation.navigate('Home')}
          leftIconType="FontAwesome5"
          leftIconName="home"
          leftIconSizeName="largest"
          labelKey="actions.later"
          rightIconType="AntDesign"
          rightIconName="right"
          rightIconSizeName="largest"
          colors={[theme.colors.blueMedium, theme.colors.blueHigh]}
          style={{ marginTop: theme.sizings.mediumLarge }}
        />
      </View>
    </View>
  );
};

export default CourseEvaluateScreen;
