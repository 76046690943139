import { Card } from 'components/common/Card/Card';
import { BackgroundLayout } from 'components/layouts/BackgroundLayout/BackgroundLayout';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Section } from 'components/common/Section/Section';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import useTheme from 'hooks/useTheme';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { JText } from 'components/common/Text/Text';
import { SelectableOptionsList } from 'components/common/SelectableOptionsList/SelectableOptionsList';
import { InlineRating } from 'components/common/InlineRating/InlineRating';
import { ReviewPreview } from 'components/compositions/ReviewPreview/ReviewPreview';
import useItem from 'hooks/useItem';
import computeAge from 'utils/computeAge';
import { Notation } from 'types/Notation';
import useList from 'hooks/useList';
import { populateUserSummary } from 'utils/populateModels';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import { fetchJSON } from 'utils/fetchJSON';
import { Report, User } from 'types';
import { useAuth } from 'hooks/useAuth';
import { Modal } from 'components/common/Modal/Modal';
import { useTranslation } from 'react-i18next';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import { I18nKey } from '../../../../i18n';
import { TextInput } from '../../../components/common/Input/TextInput/TextInput';
import useAlert from '../../../providers/AlertProvider';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'ThirdPartyProfile'
>;

const ThirdPartyProfileScreen = ({ navigation, route }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const desktopStyles = fnDesktopStyles(theme);
  // user
  const userId = route.params.profileId;
  const { user: currentUser } = useAuth();
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { isFetching: isUserFetching, item: user } = useItem<User>(
    'users',
    userId,
  );

  // notations
  const {
    items: notations,
    isFetching: isNotationsFetching,
    setFilters: setNotationFilters,
  } = useList<Notation>('notations', {
    populate: {
      userAuthor: {
        populate: 'profilePicture',
      },
      userTarget: {
        populate: '*',
      },
      ride: {
        populate: {
          driver: populateUserSummary,
          vehicle: {
            populate: '*',
          },
        },
      },
    },
    defaultFilters: {
      userTarget: userId,
    },
  });

  const votes = notations?.length || 0;
  // const [note, setNote] = useState(0);
  const [isConfirmReportModalVisible, setIsConfirmReportModalVisible] =
    useState(false);
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);
  const [reportComment, setReportComment] = useState('');

  // calculer la note moyenne
  /* useEffect(() => {
    let datas = [];
    if (!isNotationsFetching && notations?.length) {
      datas = notations
        .map((notation: Notation) => notation.mark)
        .filter((m: number) => m !== null);
      const sum =
        datas.reduce(
          (previous: any, current: any) => (previous || 0) + (current || 0),
        ) || 0;
      setNote(Math.round((sum / datas.length) * 10) / 10);
    }
  }, [isNotationsFetching, notations]); */

  // divers
  const valuesYesNo: I18nKey[] = ['formOptions.yes', 'formOptions.no'];

  const handleReportUser = async (comment: string) => {
    if (user && currentUser) {
      const payload: Report = {
        userReported: user,
        userReporting: currentUser,
        commentFromUserReporting: comment,
      };
      const res = await fetchJSON({
        url: `reports`,
        method: 'POST',
        payload: { data: payload },
      });
      if (res) {
        setIsReportModalVisible(false);
        setAlert({
          message: t('alert.reportUserSuccess'),
          title: t('alertTitle.reportUserSuccess'),
        });
      } else {
        setAlert({
          color: theme.colors.statusRed,
          message: t('alert.reportUserError'),
          title: t('alertTitle.reportUserError'),
        });
      }
    }
  };

  return (
    <BackgroundLayout isScrollView padding>
      <View style={desktopMode && desktopStyles.boxed}>
        <Card
          style={{
            marginTop: theme.sizings.mediumLarge,
            marginBottom: theme.sizings.high,
            flex: 1,
            alignItems: 'flex-start',
          }}
        >
          {(isUserFetching || isNotationsFetching) && (
            <JText
              style={{ width: '100%' }}
              centered
              labelKey="common.loading"
            />
          )}
          {!isUserFetching && !isNotationsFetching && user && (
            <>
              {/* First block of the card : Name, gender, age and note of the profile */}
              <View>
                {/* Name and description */}
                <JText
                  label={`${user.lastname} ${user.firstname}, `}
                  colorName="blueHigh"
                  isBold
                  sizeName="larger"
                />
                <JText
                  labelKey={
                    user?.gender === 'M'
                      ? 'sentences.manAge'
                      : 'sentences.womanAge'
                  }
                  valuesKey={{
                    age: computeAge(user.birthDate),
                  }}
                  colorName="blueHigh"
                  sizeName="larger"
                />
              </View>

              {/* Note */}
              {!isUserFetching && !isNotationsFetching && notations && (
                <ViewRow style={{ paddingVertical: theme.sizings.mediumLarge }}>
                  <ViewCol align="center" size={2}>
                    <InlineRating
                      note={user.reputation}
                      size={theme.normalize(30)}
                      colorName="greenHigh"
                    />
                    <JText
                      labelKey="sentences.votes"
                      valuesKey={{ votes }}
                      sizeName="medium"
                    />
                  </ViewCol>
                  <ViewCol justify="flex-end" align="center" size={1} inline>
                    <JText
                      label={`${user.reputation}`}
                      isBold
                      sizeName="fs40"
                      style={{ paddingTop: theme.sizings.small }}
                    />
                    <JText label="/5" sizeName="larger" />
                  </ViewCol>
                </ViewRow>
              )}

              {/* Profile passenger informations */}
              <Section
                titleKey="section.passengerBehavior"
                style={{ marginTop: theme.sizings.large }}
              />
              <SelectableOptionsList
                titleKey="forms.talkDegree"
                values={['yes', 'no']}
                labelKeys={valuesYesNo}
                selectedValue={user.chatting ? 'yes' : 'no'}
                onChange={() => {}}
                style={{ paddingHorizontal: theme.sizings.ten }}
                leftIconType="Entypo"
                leftIconName="message"
                leftIconSizeName="largest"
                leftIconColorName="blueHigh"
              />
              <SelectableOptionsList
                titleKey="forms.musicDegree"
                values={['yes', 'no']}
                labelKeys={valuesYesNo}
                selectedValue={user.music ? 'yes' : 'no'}
                onChange={() => {}}
                style={{ paddingHorizontal: theme.sizings.ten }}
                leftIconType="FontAwesome"
                leftIconName="music"
                leftIconSizeName="largest"
                leftIconColorName="blueHigh"
              />

              {/* Profile driver informations */}
              <Section
                titleKey="section.driverBehavior"
                style={{ marginTop: theme.sizings.large }}
              />
              <SelectableOptionsList
                titleKey="forms.smokeDegree"
                values={['yes', 'no']}
                labelKeys={valuesYesNo}
                selectedValue={user.smoking ? 'yes' : 'no'}
                onChange={() => {}}
                style={{ paddingHorizontal: theme.sizings.ten }}
                leftIconType="FontAwesome5"
                leftIconName="smoking"
                leftIconSizeName="largest"
                leftIconColorName="blueHigh"
              />
              <SelectableOptionsList
                titleKey="forms.petDegree"
                values={['yes', 'no']}
                labelKeys={valuesYesNo}
                selectedValue={user.pets ? 'yes' : 'no'}
                onChange={() => {}}
                style={{ paddingHorizontal: theme.sizings.ten }}
                leftIconType="FontAwesome5"
                leftIconName="paw"
                leftIconSizeName="largest"
                leftIconColorName="blueHigh"
              />

              <Section
                titleKey="section.reviewToUser"
                valuesKey={{ user: user.username || user.firstname }}
                style={{ marginTop: theme.sizings.large }}
              >
                {notations && notations?.length > 0 ? (
                  notations.map((n: any, index: number) => {
                    const key = `notation-${index}`;
                    const notation = n;
                    return (
                      <View key={key}>
                        <ReviewPreview
                          onPress={() => {
                            navigation.navigate('ThirdPartyProfile', {
                              profileId: notation.userAuthor.id,
                            });
                            setNotationFilters({
                              userTarget: notation.userAuthor.id,
                            });
                          }}
                          date={notation.createdAt}
                          reviewTitle={notation.comment}
                          reviewNote={notation.mark}
                          reviewerWoman={notation.userAuthor.gender === 'W'}
                          reviewerName={
                            notation.userAuthor.username ||
                            notation.userAuthor.firstname
                          }
                          reviewerNote={notation.userAuthor.reputation}
                          image={notation.userAuthor.profilePicture}
                          reviewerRole={
                            notation.ride?.driver.id === notation.userAuthor.id
                              ? 'driver'
                              : 'passenger'
                          }
                          targetName={
                            notation.userTarget.username ||
                            notation.userTarget.firstname
                          }
                          targetRole={
                            notation.ride?.driver.id === notation.userTarget.id
                              ? 'driver'
                              : 'passenger'
                          }
                          vehicle={notation.ride?.vehicle.name}
                        />
                      </View>
                    );
                  })
                ) : (
                  <JText labelKey="reviews.noReviews" centered />
                )}
              </Section>
            </>
          )}
        </Card>
        <GradientButton
          labelKey="common.reportUser"
          colors={[
            theme.colors.statusWarningLight,
            theme.colors.statusWarningHigh,
          ]}
          style={{ width: '100%', marginTop: theme.sizings.mediumLarge }}
          onPress={() => setIsConfirmReportModalVisible(true)}
          leftIconType="FontAwesome5"
          leftIconName="user"
          leftIconSizeName="larger"
        />
        <Modal
          isVisible={isConfirmReportModalVisible}
          onBackDropPress={() =>
            setIsConfirmReportModalVisible(!isConfirmReportModalVisible)
          }
          titleKey="common.reportUser"
          iconName="logout"
          iconType="AntDesign"
          iconSize="larger"
          iconColor="blueHigh"
          renderContent={<JText labelKey="sentences.reportUser" />}
          renderFooter={
            <ViewRow>
              <ViewCol style={{ paddingRight: theme.sizings.medium }}>
                <GradientButton
                  onPress={() => {
                    setIsReportModalVisible(true);
                    setIsConfirmReportModalVisible(false);
                  }}
                  labelKey="common.yes"
                />
              </ViewCol>
              <ViewCol style={{ paddingRight: theme.sizings.medium }}>
                <GradientButton
                  colors={[theme.colors.statusRed, theme.colors.statusRed]}
                  onPress={() => setIsConfirmReportModalVisible(false)}
                  labelKey="actions.cancel"
                />
              </ViewCol>
            </ViewRow>
          }
        />
        <Modal
          isVisible={isReportModalVisible}
          onBackDropPress={() => setIsReportModalVisible(!isReportModalVisible)}
          titleKey="common.reportUser"
          iconName="logout"
          iconType="AntDesign"
          iconSize="larger"
          iconColor="blueHigh"
          renderContent={
            <TextInput
              placeholderKey="section.comment"
              onChange={value => setReportComment(value)}
              multiline
            />
          }
          renderFooter={
            <GradientButton
              onPress={() => handleReportUser(reportComment)}
              labelKey="actions.signal"
            />
          }
        />
      </View>
    </BackgroundLayout>
  );
};

export default ThirdPartyProfileScreen;
