import React, { useState } from 'react';
import { StyleProp, ViewStyle, Text, Linking } from 'react-native';

import useTheme from 'hooks/useTheme';
import { Form } from 'react-final-form';
import { JText } from 'components/common/Text/Text';
import { FieldHelpWrap } from 'components/compositions/FieldHelp/FieldHelp';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Card } from '../../common/Card/Card';
import { GradientButton } from '../../common/GradientButton/GradientButton';
import fnStyles from './RegisterFormStyle';
import { CheckBox } from '../../common/CheckBox/CheckBox';
import { FormControl } from '../../common/FormControl/FormControl';
import { ViewRow } from '../../layouts/FlexLayout/FlexViews';
import { FormInput } from '../../common/FormInput/FormInput';
import fetchJSON from '../../../utils/fetchJSON';

type Props = {
  onSubmit: (values: any) => Promise<void | any>;
  style?: StyleProp<ViewStyle>;
  onHelpInfoPress?: () => void;
};

export const RegisterForm = (props: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { t } = useTranslation();

  const checkPassword = (value: string) => {
    return value?.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/) !== null
      ? undefined
      : t('auth.passwordRulesList');
  };

  const [isGeneralConditionSelected, setGeneralConditionSelection] =
    useState(false);
  const onCheckBoxPressed = () => {
    setGeneralConditionSelection(!isGeneralConditionSelected);
  };
  const [content, setContent] = useState('');

  const checkEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
      ? undefined
      : t('auth.invalidEmail');
  };
  const validateForm = (values: {
    password: string;
    passwordConfirm: string;
  }) => {
    console.log("validate")
    if (values.password !== values.passwordConfirm)
      return { passwordConfirm: t('auth.passwordsDoNotMatch') };
    return undefined;
  };

  return (
    <Card style={[styles.container, props.style]}>
      <Form
        onSubmit={props.onSubmit}
        validate={validateForm}
        render={({ handleSubmit, submitError, values }) => (
          <>
            <FormControl
              name="email"
              titleKey="forms.email"
              required
              validate={checkEmail}
            />
            <FormControl name="username" titleKey="forms.username" required />
            <FormControl name="lastname" titleKey="forms.name" />
            <FormControl name="firstname" titleKey="forms.firstname" />
            <FormControl
              name="phoneNumber"
              titleKey="forms.phoneNumber"
              keyboardType="number-pad"
            />
            <FormControl
              name="birthDate"
              titleKey="forms.birth"
              type="date"
              value={values.birthDate || dayjs('2000-01-01').toDate()}
            />
            {/* TODO: Pour les 2 champs suivant :
            https://juloa.atlassian.net/jira/software/projects/CIL/boards/1/backlog?selectedIssue=CIL-112
             */}
            <FormControl
              name="company"
              titleKey="forms.companyCode"
              subtitleKey="forms.companyCodeLegend"
              inline
            />
            <FormControl
              name="sponsorshipCode"
              titleKey="forms.sponsorshipKey"
              subtitleKey="forms.sponsorshipKeyLegend"
              maxLength={5} // TODO : Define clear size for the code
              inline
            />
            <FieldHelpWrap onHelpInfoPress={props.onHelpInfoPress}>
              <FormControl
                name="password"
                titleKey="auth.password"
                secure
                validate={checkPassword}
                required
              />
            </FieldHelpWrap>
            <FormControl
              name="passwordConfirm"
              titleKey="auth.passwordConfirm"
              secure
              required
            />
            {submitError && (
              <JText colorName="statusDangerHigh" label={submitError} />
            )}
            <ViewRow>
              <CheckBox
                value={isGeneralConditionSelected}
                onPress={onCheckBoxPressed}
                backgroundColor={theme.colors.greyLight}
                sizeName="large"
                style={{ marginTop: 5 }}
              />
              <Text
                style={{
                  fontSize: theme.fontSizes.large,
                  fontFamily: theme.fonts.fontBody,
                  marginLeft: 20,
                }}
              >
                {`${t('auth.IAccept')} `}
                <Text
                  style={{
                    fontStyle: 'italic',
                    color: theme.colors.blueMedium,
                  }}
                  onPress={() => Linking.openURL('https://ciligo.fr/cgu.html')}
                >
                  {t('auth.generalCondition')}
                </Text>
              </Text>
            </ViewRow>
            <GradientButton
              labelKey="auth.createAccount"
              onPress={handleSubmit}
              leftIconType="Feather"
              leftIconName="check"
              leftIconSizeName="larger"
              width="75%"
              style={{ marginTop: theme.sizings.mediumLarge }}
              disabled={!isGeneralConditionSelected}
            />
          </>
        )}
      />
    </Card>
  );
};
