import React from 'react';
import { View, ScrollView } from 'react-native';
import { TileButton } from '../../components/common/TileButton/TileButton';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const TileButtonScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="TileButton"
        description="This component renders a button with a tile."
      >
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'space-between',
          }}
        >
          <TileButton
            label="Je conduis"
            leftIconType="MaterialCommunity"
            leftIconName="steering"
            leftIconSizeName="larger"
          />
          <TileButton
            label="Créer une alerte"
            rightIconType="MaterialCommunity"
            rightIconName="bell-ring"
            rightIconSizeName="larger"
          />
        </View>
      </CatalogHeader>
      <CatalogProps
        propName="label"
        description="The text writtent in the component."
        type="string"
        topDivider={false}
      />
      <CatalogProps propName="onPress" type="( ) => void" />
      <CatalogProps propName="tileColor" type="color" />
      <CatalogProps
        propName="leftIconType"
        type="enum('MaterialCommunity', 'AntDesign', 'Entypo', 'EvilIcons', 'Feather', 'FontAwesome', 'FontAwesome5', 'FontAwesome5Pro', 'Fontisto', 'Material', 'MaterialIcons', 'IonIcons')"
      />
      <CatalogProps propName="leftIconName" type="string" />
      <CatalogProps propName="leftIconSize" type="number" />
      <CatalogProps propName="leftIconColor" type="string" />
      <CatalogProps
        propName="rightIconType"
        type="enum('MaterialCommunity', 'AntDesign', 'Entypo', 'EvilIcons', 'Feather', 'FontAwesome', 'FontAwesome5', 'FontAwesome5Pro', 'Fontisto', 'Material', 'MaterialIcons', 'IonIcons')"
      />
      <CatalogProps propName="rightIconName" type="string" />
      <CatalogProps propName="rightIconSize" type="number" />
      <CatalogProps propName="rightIconColor" type="string" />
    </ScrollView>
  );
};

export default TileButtonScreen;
