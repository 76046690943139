import React from 'react';
import { ScrollView } from 'react-native';
import { ClickableIcon } from '../../components/common/ClickableIcon/ClickableIcon';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const ClickableIconScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="ClickableIcon"
        description="This component renders a clickable icon."
      >
        <ClickableIcon
          type="AntDesign"
          name="like2"
          onPress={() => {}}
          sizeName="larger"
          colorName="blueHigh"
        />
      </CatalogHeader>
      <CatalogProps
        propName="type"
        description="The type of the icon displayed in the component."
        type="enum('MaterialCommunity', 'AntDesign', 'Entypo', 'EvilIcons', 'Feather', 'FontAwesome', 'FontAwesome5', 'FontAwesome5Pro', 'Fontisto', 'Material', 'MaterialIcons', 'IonIcons')"
        topDivider={false}
      />
      <CatalogProps
        propName="name"
        description="The name of the icon displayed in the component."
        type="string"
      />
      <CatalogProps propName="onPress" type="( ) => void" />
      <CatalogProps propName="size" type="number" />
      <CatalogProps propName="color" type="string" />
    </ScrollView>
  );
};

export default ClickableIconScreen;
