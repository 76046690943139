import React, { useEffect, useState } from 'react';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { BackgroundLayout } from 'components/layouts/BackgroundLayout/BackgroundLayout';
import { CourseItem } from 'components/compositions/CourseItem/CourseItem';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import { TileButton } from 'components/common/TileButton/TileButton';
import useTheme from 'hooks/useTheme';
import { View } from 'react-native';
import { Pin } from 'components/common/Pin/Pin';
import { JText } from 'components/common/Text/Text';
import { Ride, SearchParams } from 'types';
import moment from 'moment';
import useAuth from 'hooks/useAuth';
import { Section } from 'types/Section';
import ResponsiveScrollView from 'components/layouts/ResponsiveScrollView/ResponsiveScrollView';
import { InstanceModal } from 'components/compositions/InstanceModal/InstanceModal';
import useAlert from 'providers/AlertProvider';
import { useTranslation } from 'react-i18next';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import { fetchJSON } from '../../../utils/fetchJSON';
import useList from '../../../hooks/useList';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'CourseResults'
>;

const CourseResultsScreen = ({ route, navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const [currentSearch, setCurrentSearch] = useState<SearchParams>();
  const [isInstanceModalVisible, setIsInstanceModalVisible] = useState(false);
  const [selectedRide, setSelectedRide] = useState<Ride>();
  const { setAlert } = useAlert();
  const desktopMode = isDesktopMode();
  const desktopStyles = fnDesktopStyles(theme);

  useEffect(() => {
    if (route.params) {
      setCurrentSearch({
        ...route.params.searchParams,
      });
    }
  }, [route.params]);

  const { items: sections, isFetching: isSectionsFetching } = useList<Section>(
    'sections',
    {
      populate: [
        'ride.driver',
        'ride.driver.profilePicture',
        'ride.vehicle',
        'ride.parent',
        'ride.parent.driver',
        'ride.parent.driver.profilePicture',
        'ride.parent.vehicle',
        'departureLocation',
        'arrivalLocation',
      ],
      fields: { ...route.params.searchParams },
    },
  );
  const onCreateAlertPress = async () => {
    if (route.params) {
      const datas = route.params.searchParams;
      await fetchJSON({
        url: 'alerts',
        method: 'POST',
        payload: { data: datas },
      });
      // TODO : Gestion du retour de res
      navigation.reset({
        index: 0,
        routes: [{ name: 'Root', state: { routes: [{ name: 'Alert' }] } }],
      });
    } else {
      // TODO : reset navigation tree
      navigation.navigate('CourseSearch');
    }
  };

  const handleSaveSubscribeInstance = async () => {
    if (selectedRide) {
      // TODO : adapter l'action
      /* await fetchJSON({
        url: `rides/${ride.id}`,
        method: 'DELETE',
      }); */
      setAlert({
        message: t('alert.instanceSubscribeSaved'),
        title: t('alertTitle.instanceSubscribeSaved'),
      });
    }
  };

  return (
    <>
      <BackgroundLayout>
        <View style={desktopMode && desktopStyles.boxed}>
          <ViewRow
            style={{
              paddingVertical: theme.sizings.mediumLarge,
              paddingHorizontal: desktopMode
                ? undefined
                : theme.sizings.mediumLarge,
            }}
          >
            <ViewCol style={{ paddingRight: theme.sizings.smallMedium }}>
              <TileButton
                tileColorName="greenLight"
                leftIconType="FontAwesome5"
                leftIconName="search"
                labelKey="actions.modify"
                onPress={() =>
                  navigation.navigate('CourseSearch', {
                    searchParams: route.params.searchParams,
                  })
                }
              />
            </ViewCol>
            {!route.params.searchParams.id && (
              <ViewCol style={{ paddingLeft: theme.sizings.smallMedium }}>
                <TileButton
                  tileColorName="greenLight"
                  leftIconType="FontAwesome5"
                  leftIconName="thumbtack"
                  labelKey="add.alert"
                  onPress={onCreateAlertPress}
                />
              </ViewCol>
            )}
          </ViewRow>
        </View>

        {currentSearch && (
          <ResponsiveScrollView
            style={{ padding: theme.sizings.mediumLarge, flex: 1 }}
          >
            <View style={desktopMode && desktopStyles.boxed}>
              <View
                style={{
                  marginVertical: theme.sizings.medium,
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {currentSearch.departureLocation &&
                  currentSearch.arrivalLocation && (
                    <Pin
                      labelKey="sentences.fromTo"
                      valuesKey={{
                        from: (
                          currentSearch.departureLocation as unknown as {
                            name: string;
                            id: string;
                          }
                        )?.name,
                        to: (
                          currentSearch.arrivalLocation as unknown as {
                            name: string;
                            id: string;
                          }
                        )?.name,
                      }}
                      textColorName="dark"
                      iconType="Entypo"
                      iconName="location-pin"
                      iconSizeName="larger"
                      iconColorName="blueHigh"
                      style={{
                        marginBottom: theme.sizings.medium,
                        marginRight: theme.sizings.small,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    />
                  )}
                <Pin
                  labelKey="sentences.dateHour"
                  valuesKey={{
                    day: moment(currentSearch.departureTime).format(
                      'DD/MM/YYYY',
                    ),
                    hour: moment(currentSearch.departureTime).format('HH:mm'),
                  }}
                  textColorName="dark"
                  iconType="FontAwesome5"
                  iconName="clock"
                  iconSizeName="larger"
                  iconColorName="blueHigh"
                  style={{
                    marginRight: theme.sizings.small,
                    marginBottom: theme.sizings.small,
                  }}
                />
                {currentSearch.smoking && (
                  <Pin
                    labelKey="forms.smokeDegree"
                    textColorName="dark"
                    iconType="FontAwesome5"
                    iconName="smoking"
                    iconSizeName="larger"
                    iconColorName="statusSuccessHigh"
                    style={{
                      marginRight: theme.sizings.small,
                      marginBottom: theme.sizings.small,
                    }}
                  />
                )}
                {currentSearch.pets && (
                  <Pin
                    labelKey="forms.petDegree"
                    textColorName="dark"
                    iconType="FontAwesome5"
                    iconName="paw"
                    iconSizeName="larger"
                    iconColorName="statusSuccessHigh"
                    style={{
                      marginRight: theme.sizings.small,
                      marginBottom: theme.sizings.small,
                    }}
                  />
                )}

                {currentSearch.music && (
                  <Pin
                    labelKey="forms.musicDegree"
                    textColorName="dark"
                    iconType="FontAwesome5"
                    iconName="music"
                    iconSizeName="larger"
                    iconColorName="statusSuccessHigh"
                    style={{
                      marginRight: theme.sizings.small,
                      marginBottom: theme.sizings.small,
                    }}
                  />
                )}

                {currentSearch.airConditioner && (
                  <Pin
                    labelKey="forms.airConditioner"
                    textColorName="dark"
                    iconType="FontAwesome5"
                    iconName="plus"
                    iconSizeName="larger"
                    iconColorName="greenHigh"
                    style={{
                      marginRight: theme.sizings.small,
                      marginBottom: theme.sizings.small,
                    }}
                  />
                )}
                {currentSearch.heatedSeats && (
                  <Pin
                    labelKey="forms.heatedSeat"
                    textColorName="dark"
                    iconType="FontAwesome5"
                    iconName="plus"
                    iconSizeName="larger"
                    iconColorName="greenHigh"
                    style={{
                      marginRight: theme.sizings.small,
                      marginBottom: theme.sizings.small,
                    }}
                  />
                )}
              </View>

              {isSectionsFetching ? (
                <JText centered labelKey="common.loading" />
              ) : (
                <View style={{ marginBottom: theme.sizings.mediumLarge * 2 }}>
                  {sections?.length ? (
                    sections.map((section: Section, index: number) => {
                      const driverId = section.ride.driver.id;
                      const fkey = `course-${index}`;

                      return (
                        <View key={fkey}>
                          <CourseItem
                            onPress={
                              driverId === currentUser?.id
                                ? () =>
                                    navigation.navigate('CourseDetail', {
                                      rideId: section.ride.id,
                                    })
                                : () =>
                                    navigation.navigate('SectionDetail', {
                                      sectionId: section.id,
                                      rideId: section.ride.id,
                                      seats: currentSearch.seats,
                                    })
                            }
                            ride={section.ride}
                            date={section.departureTime}
                            departureLocation={section.departureLocation}
                            departureTime={section.departureTime}
                            arrivalLocation={section.arrivalLocation}
                            arrivalTime={section.arrivalTime}
                            price={section.price}
                            setIsInstanceModalVisible={
                              setIsInstanceModalVisible
                            }
                            setSelectedRide={setSelectedRide}
                          />
                        </View>
                      );
                    })
                  ) : (
                    <JText centered labelKey="courses.noResults" />
                  )}
                </View>
              )}
            </View>
          </ResponsiveScrollView>
        )}
      </BackgroundLayout>
      {/* TODO : modal formulaire modif abonnement */}
      {selectedRide && currentUser?.id !== selectedRide?.driver?.id && (
        <InstanceModal
          isVisible={isInstanceModalVisible}
          onClose={() => setIsInstanceModalVisible(false)}
          titleKey="actions.subscribeInstance"
          ride={selectedRide}
          onSave={() => handleSaveSubscribeInstance()}
        />
      )}
    </>
  );
};

export default CourseResultsScreen;
