import { Theme } from 'providers/ThemeProvider';
import { StyleSheet } from 'react-native';

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      width: '100%',
    },
    header: {
      width: '100%',
      flexDirection: 'row',
      minHeight: theme.normalize(30),
      alignItems: 'center',
      marginBottom: theme.sizings.medium,
    },
    divider: {
      borderBottomColor: theme.colors.greyLight,
      borderBottomWidth: 1,
      width: '100%',
      flex: 1,
    },
    content: {
      flex: 1,
    },
  });
