import React from 'react';
import useTheme from 'hooks/useTheme';
import useAuth from 'hooks/useAuth';
import useList from 'hooks/useList';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Icon } from 'components/common/Icon/Icon';
import { Pin } from 'components/common/Pin/Pin';
import isDesktopMode from 'utils/desktopMode';
import { useFocusEffect } from '@react-navigation/native';
import { Platform, View } from 'react-native';

const HeaderNotifications = ({ route, navigation }: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const { user: currentUser } = useAuth();
  const { items: notifications, fetchItems: fetchNotificationsItems } =
    useList<Notification>('notifications', {
      defaultFilters: {
        createdFor: currentUser?.id || '',
        isRead: false,
      },
      listenToEvents: [
        'new_message',
        'new_message_liked',
        'new_conversation',
        'new_comment',
        'new_comment_liked',
        'new_notation',
        'new_application',
        'application_accepted',
        'application_refused',
        'application_canceled',
        'passenger_canceled',
        'new_result_for_alert',
        'document_accepted',
        'document_refused',
        'vehicle_accepted',
        'vehicle_refused',
        'vehicle_document_accepted',
        'vehicle_document_refused',
        'vehicle_document_wainting',
        'missing_code_for_ride',
        'missing_passenger',
        'new_points',
        'reputation_decreased',
        'reputation_increased',
        'ride_updated',
        'ride_canceled',
      ],
    });

  useFocusEffect(
    React.useCallback(() => {
      fetchNotificationsItems();

      /* return () => {
        fetchNotificationsItems();
      }; */
    }, [fetchNotificationsItems]),
  );

  return (
    <TouchableOpacity
      style={{
        paddingHorizontal: theme.sizings.mediumLarge,
        position: 'relative',
        height:
          desktopMode || Platform.OS === 'web'
            ? undefined
            : theme.headerSizes.mobile,
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        flexDirection: 'row',
      }}
      onPress={() => navigation.navigate('Notifications')}
    >
      {notifications && notifications?.length > 0 && (
        <View>
          <Pin
            label={notifications?.length.toString()}
            isBadge
            style={{
              position: 'relative',
              right: theme.normalize(2),
              top: theme.normalize(-1),
              paddingLeft: theme.sizings.smallMedium,
              paddingRight: theme.sizings.smallMedium,
              paddingTop: 0,
              paddingBottom: 0,
            }}
          />
        </View>
      )}
      <Icon
        type="FontAwesome5"
        name="bell"
        colorName="greenHigh"
        sizeName="largest"
      />
    </TouchableOpacity>
  );
};

export default HeaderNotifications;
