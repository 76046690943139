import { StyleSheet } from 'react-native';
import { Theme } from 'providers/themeProvider';

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      width: '100%',
    },
    distanceBloc: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    header: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.sizings.small,
      marginRight: theme.sizings.medium,
    },
    headerLeft: {
      minWidth: '40%',
      marginBottom: 0,
    },
    fieldRight: {
      flex: 1,
      minHeight: theme.normalize(40),
    },
    fieldBottom: {
      width: '100%',
    },
    icon: {
      marginRight: theme.sizings.medium,
    },
    headerText: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginVertical: theme.sizings.small,
    },
  });
