import React from 'react';
import { View, ScrollView } from 'react-native';
import { Document } from '../../components/common/Document/Document';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const DocumentScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="Document"
        description="This component renders a document row with status."
      >
        <View style={{ flexDirection: 'column' }}>
          <Document
            labelKey="document.identification"
            iconType="IonIcons"
            iconName="finger-print-outline"
            status="accepted"
          />
          <View style={{ width: 30 }} />
          <Document
            labelKey="document.registration"
            iconType="IonIcons"
            iconName="book-outline"
            status="in_waiting"
          />
          <View style={{ width: 30 }} />
          <Document
            labelKey="document.insurrance"
            iconType="IonIcons"
            iconName="newspaper-outline"
            status="refused"
          />
          <View style={{ width: 30 }} />
          <Document labelKey="document.other" status="" />
        </View>
      </CatalogHeader>
      <CatalogProps propName="label" type="string" topDivider={false} />
      <CatalogProps
        propName="name"
        description="The name/type of the document."
        type="string"
      />
      <CatalogProps
        propName="isSupplied"
        description="Precises if the document has been provided"
        type="boolean"
      />
      <CatalogProps
        propName="isValidated"
        description="Precises if the document has been validated"
        type="boolean"
      />
      <CatalogProps propName="iconName" type="string" />
      <CatalogProps propName="iconSize" type="number" />
    </ScrollView>
  );
};

export default DocumentScreen;
