import { Theme } from 'providers/ThemeProvider';
import { Dimensions, StyleSheet } from 'react-native';

export default (theme: Theme) =>
  StyleSheet.create({
    eventTop: {
      paddingTop: theme.normalize(80),
      paddingHorizontal: theme.sizings.mediumLarge,
      marginBottom: theme.sizings.mediumLarge,
    },
    blocIllustration: {
      marginBottom: theme.sizings.mediumLarge,
      width: '100%',
      height: theme.normalize(200),
      maxHeight: theme.normalize(800),
    },
    blocImage: {
      marginBottom: theme.sizings.mediumLarge,
      width: '100%',
      height: Dimensions.get('window').width,
      maxHeight: theme.normalize(800),
    },
    row: {
      flexDirection: 'row',
    },
    sectionTitle: {
      flexDirection: 'row',
      paddingHorizontal: theme.sizings.mediumLarge,
    },
    sectionBlue: {
      padding: theme.sizings.mediumLarge,
      backgroundColor: theme.colors.blueLight,
      marginBottom: theme.sizings.mediumLarge,
    },
    sectionGreen: {
      padding: theme.sizings.mediumLarge,
      backgroundColor: theme.colors.statusSuccessLight,
    },
  });
