import React from 'react';
import { Text, View, ScrollView } from 'react-native';
import { Card } from '../../components/common/Card/Card';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const CardScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader title="Card" description="This component renders a card.">
        <Card>
          <Text>A simple card</Text>
        </Card>
        <View style={{ height: 10 }} />
        <Card style={{ backgroundColor: 'blue', paddingVertical: 20 }}>
          <Text>A card with a style</Text>
        </Card>
      </CatalogHeader>
      <CatalogProps
        propName="style"
        description="The style of the card."
        type="object"
        topDivider={false}
      />
    </ScrollView>
  );
};

export default CardScreen;
