/* eslint-disable no-underscore-dangle */
import { Icon } from 'components/common/Icon/Icon';
import { BackgroundLayout } from 'components/layouts/BackgroundLayout/BackgroundLayout';
import { ROOT_URL } from 'constants/Constants';
import useAuth from 'hooks/useAuth';
import useList from 'hooks/useList';
import useTheme from 'hooks/useTheme';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, View } from 'react-native';
import { GiftedChat, IMessage, Send } from 'react-native-gifted-chat';
import { Message } from 'types/Message';
import { populateUserSummary } from 'utils/populateModels';
import { ChatProvider } from '../../../providers/ChatProvider';
import fetchJSON from '../../../utils/fetchJSON';
import { User } from '../../../types';
import Modal from '../../../components/common/Modal/Modal';
import { JText } from '../../../components/common/Text/Text';
import { TextInput } from '../../../components/common/Input/TextInput/TextInput';
import {
  ViewCol,
  ViewRow,
} from '../../../components/layouts/FlexLayout/FlexViews';
import { GradientButton } from '../../../components/common/GradientButton/GradientButton';

const ChatScreen = ({ route }: any) => {
  const { theme } = useTheme();

  const {
    createConversation,
    updateConversation,
    createMessage,
    markMessagesAsRead,
  } = ChatProvider();

  // current user
  const { user: currentUser } = useAuth();
  const [loading, setLoading] = useState(false);

  const userId = currentUser?.id;

  /* conversation courante */
  const conversationId = route.params.conversation;
  const correspondantId = route.params.correspondantUser;

  /*  id à appliquer pour les messages orphelins */
  const newConversationId = useMemo(() => {
    const unicDate = new Date();
    return Number(`0${userId}${correspondantId}${Number(unicDate)}`);
  }, [correspondantId, userId]);

  const [virginNewConversationId, setVirginNewConversationId] = useState(true);
  const [modalVisibility, setReportVisibility] = useState<boolean>(false);
  const [commentFromUserReporting, setCommentFromUserReporting] = useState('');
  const [selectedMessage, setSelectedMessage] = useState({});

  /* messages */
  const {
    items: messages,
    allItemsFetched,
    itemsCount,
    itemsPerPage,
    pageIndex,
    fetchNextItems,
  } = useList<Message>('messages', {
    populate: {
      sender: populateUserSummary,
      receiver: populateUserSummary,
      conversation: {
        fields: ['id'],
      },
    },
    listenToEvents: ['new_message'],
    defaultFilters: {
      conversation: conversationId,
    },
    defaultSort: 'createdAt:DESC',
  });

  /* formatter les messages pour le composant chat */
  const [chatMessages, setChatMessages] = useState<IMessage[]>();

  const msgContainerRef = useRef();

  useEffect(() => {
    const prepareMessages = messages?.map((message: Message, index: number) => {
      return {
        _id: `old_${index}`,
        text: message.content,
        createdAt: message.createdAt,
        user: {
          _id: message.sender.id,
          name: message.sender.username,
          avatar: message.sender.profilePicture
            ? `${ROOT_URL}${message.sender.profilePicture?.url}`
            : undefined,
        },
      };
    }) as IMessage[];

    if (prepareMessages) {
      const orderedMessages = prepareMessages.reverse();
      setChatMessages(orderedMessages);
    }

    // marquer tous les messages qui me sont destinés en "lu"
    const unreadMessages = messages
      ?.filter((message: Message) => message.isRead !== true)
      .map((message: Message) => message.id);

    if (unreadMessages?.length) {
      markMessagesAsRead(unreadMessages);
    }
  }, [messages, markMessagesAsRead, pageIndex]);

  useEffect(() => {
    if (chatMessages && pageIndex !== 1) {
      setTimeout(() => {
        msgContainerRef?.current?._messageContainerRef.current.scrollToOffset({
          offset: (chatMessages.length - (pageIndex - 1) * itemsPerPage) * 50,
          animated: false,
        });
      }, 200);
    }
  }, [chatMessages, pageIndex]);

  const { t } = useTranslation();
  const signalMessage = async (
    message: Message,
    commentFromUserReporting: string,
  ) => {
    try {
      const res = await fetchJSON({
        url: 'reports',
        method: 'POST',
        payload: {
          data: {
            userReporting: currentUser,
            userReported: message.receiver,
            commentFromUserReporting,
            message,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onSend = useCallback(
    msgs => {
      const unicDate = new Date();
      const newMessageId = Number(
        `1${userId}${correspondantId}${Number(unicDate)}`,
      );

      if (!conversationId) {
        if (virginNewConversationId) {
          createConversation(
            msgs,
            userId,
            correspondantId,
            newConversationId,
            setVirginNewConversationId,
            newMessageId,
          );
        }
        createMessage(
          msgs,
          userId,
          correspondantId,
          newConversationId,
          newMessageId,
        );
      } else {
        createMessage(
          msgs,
          userId,
          correspondantId,
          conversationId,
          newMessageId,
        );
        updateConversation(msgs, conversationId, newMessageId);
      }

      // pour le visuel immédiat
      setChatMessages(previousMessages =>
        GiftedChat.prepend(previousMessages, msgs),
      );
    },
    [
      conversationId,
      newConversationId,
      virginNewConversationId,
      setVirginNewConversationId,
      userId,
      correspondantId,
      createConversation,
      createMessage,
      updateConversation,
    ],
  );

  const handleLoadOlderMessages = () => {
    fetchNextItems();
  };

  return (
    <BackgroundLayout>
      <Modal
        titleKey="alertTitle.reportMessage"
        isVisible={modalVisibility}
        onBackDropPress={() => {
          setReportVisibility(false);
        }}
        renderContent={
          <>
            <JText labelKey="alertTitle.reportMessageDescription" />
            <TextInput
              multiline
              onChange={value => setCommentFromUserReporting(value)}
            />
          </>
        }
        renderFooter={
          <ViewRow>
            <ViewCol style={{ paddingRight: theme.sizings.medium }}>
              <GradientButton
                onPress={async () => {
                  await signalMessage(
                    selectedMessage,
                    commentFromUserReporting,
                  );
                  setReportVisibility(false);
                }}
                labelKey="common.yes"
              />
            </ViewCol>
            <ViewCol style={{ paddingRight: theme.sizings.medium }}>
              <GradientButton
                colors={[theme.colors.statusRed, theme.colors.statusRed]}
                onPress={() => setReportVisibility(false)}
                labelKey="actions.cancel"
              />
            </ViewCol>
          </ViewRow>
        }
      />

      <View style={{ flex: 1 }}>
        {userId && (
          <GiftedChat
            ref={msgContainerRef}
            messages={chatMessages}
            onSend={onSend}
            inverted={false}
            alwaysShowSend
            // isTyping
            loadEarlier={!allItemsFetched}
            infiniteScroll
            onLongPress={message => {
              setReportVisibility(true);
              setSelectedMessage(message);
            }}
            isLoadingEarlier={loading}
            onLoadEarlier={handleLoadOlderMessages}
            locale="fr"
            placeholder={t('chat.yourMessage') as string}
            renderSend={props => (
              <Send {...props}>
                <View
                  style={{
                    marginRight: theme.sizings.ten,
                    marginBottom: theme.sizings.ten,
                  }}
                >
                  <Icon
                    type="FontAwesome5"
                    colorName="blueHigh"
                    name="paper-plane"
                  />
                </View>
              </Send>
            )}
            user={{
              _id: userId,
            }}
          />
        )}
      </View>
    </BackgroundLayout>
  );
};

export default ChatScreen;
