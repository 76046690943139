import { Avatar } from 'components/common/Avatar/Avatar';
import { Divider } from 'components/common/Divider/Divider';
import { TextButton } from 'components/common/TextButton/TextButton';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import React, {useState} from 'react';
import {Alert, ImageSourcePropType, View} from 'react-native';
import { Types } from 'types/Types';
import { JText } from 'components/common//Text/Text';
import { Card } from 'components/common/Card/Card';
import { Icon } from 'components/common/Icon/Icon';
import { TouchableOpacity } from 'react-native-gesture-handler';
import useTheme from 'hooks/useTheme';
import dayjs from 'dayjs';
import styles from './UserCommentStyles';
import { AddComment } from '../AddComment/AddComment';
import {Comment} from "../../../types/Comment";
import Modal from "../../common/Modal/Modal";
import {TextInput} from "../../common/Input/TextInput/TextInput";
import {GradientButton} from "../../common/GradientButton/GradientButton";
import fetchJSON from "../../../utils/fetchJSON";
import useAuth from "../../../hooks/useAuth";




type Props = {
  comment: Comment;
  feedId: number;
  commentId: number;
  child?: boolean;
  date: Date;
  message: string;
  userRole: Types['role'];
  actionAvatar: () => void;
  contentAnswer: string;
  setContentAnswer: React.Dispatch<React.SetStateAction<string>>;
  showFieldAnswer: number;
  setShowFieldAnswer: React.Dispatch<React.SetStateAction<number>>;
  onAnswerSubmit: (contentAnswer: string, feedId: number | undefined) => void;
  onLikeSubmit: (feedId: number | undefined) => void;
  user: any; // todo type User quand y'aura plus les "attributes"
};




export const UserComment = ({
  comment,
  feedId,
  commentId,
  child = false,
  date,
  message,
  userRole,
  actionAvatar,
  contentAnswer,
  setContentAnswer,
  showFieldAnswer,
  setShowFieldAnswer,
  onAnswerSubmit,
  onLikeSubmit,
  user,
}: Props) => {
  const [commentFromUserReporting, setCommentFromUserReporting] = useState('');
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const { theme } = useTheme();
  const {user: currentUser} = useAuth();


  const signalComment = async (comment: Comment, commentFromUserReporting: string) => {
    try {

      const res = await fetchJSON({
        url: 'reports/',
        method: 'POST',
        payload: {
          data: {
            userReporting: currentUser,
            userReported: comment.user,
            commentFromUserReporting: commentFromUserReporting,
            comment: comment,
          }
        }
      })

    } catch (e) {
      console.log(e)
    }

  }


  return (
    <>
      <Modal
        titleKey= "alertTitle.reportComment"
        isVisible={modalVisibility}
        onBackDropPress={() => {setModalVisibility(false)}}
        renderContent={
        <>
          <JText labelKey="alertTitle.reportCommentDescription"/>
          <TextInput
            multiline
            onChange={value => setCommentFromUserReporting(value)}
          />
        </>
        }
        renderFooter={
        <>
          <ViewRow>
            <ViewCol style={{ paddingRight: theme.sizings.medium }}>
              <GradientButton
                onPress={async () => {
                  await signalComment(comment, commentFromUserReporting)
                  setModalVisibility(false)

                }}
                labelKey="common.yes"
              />
            </ViewCol>
            <ViewCol style={{ paddingRight: theme.sizings.medium }}>
              <GradientButton
                colors={[theme.colors.statusRed, theme.colors.statusRed]}
                onPress={() => setModalVisibility(false)}
                labelKey="actions.cancel"
              />
            </ViewCol>
          </ViewRow>
        </>
        }

      />

      <ViewRow
        style={{
          paddingLeft: child ? theme.normalize(70) : 0,
        }}
      >
        <ViewCol
          style={{
            width: child ? theme.sizings.sz50 : theme.normalize(70),
          }}
        >
          <TouchableOpacity onPress={actionAvatar}>
            <Avatar
              sizeName={child ? 'high' : 'sz50'}
              iconSizeName="small"
              iconType="FontAwesome5"
              iconColorName={
                userRole === 'passenger'
                  ? 'blueHigh'
                  : userRole === 'candidate'
                  ? 'statusWarningHigh'
                  : 'statusWarningHigh'
              }
              iconName={
                userRole === 'passenger'
                  ? 'car'
                  : userRole === 'candidate'
                  ? 'question'
                  : 'star'
              }
              iconPosition="bottomLeft"
              image={user.profilePicture}
              isWoman={user.gender === 'W'}
            />
          </TouchableOpacity>
        </ViewCol>
        <ViewCol size={1}>
          <Card
            style={{
              padding: theme.sizings.medium,
              backgroundColor:
                message === 'ok'
                  ? theme.colors.statusSuccessLight
                  : theme.colors.light,
            }}
          >
            <View style={styles.row}>
              <JText
                isBold
                labelKey="sentences.authorDate"
                valuesKey={{
                  name: user.username || user.firstname,
                  date: dayjs(date).fromNow(),
                }}
              />
            </View>

            {message === 'ok' ? (
              <View style={[styles.row, { alignItems: 'center' }]}>
                <Icon
                  name="thumbs-up"
                  type="FontAwesome5"
                  colorName="statusSuccessHigh"
                  sizeName="fs30"
                  style={{
                    marginTop: theme.sizings.small,
                    marginRight: theme.sizings.small,
                  }}
                />
                <JText
                  label="Ok"
                  sizeName="large"
                  style={{ marginTop: theme.sizings.small }}
                />
              </View>
            ) : (
              <JText label={message} style={{ width: '100%' }} />
            )}
          </Card>
          <ViewRow
            style={{
              paddingVertical: theme.sizings.medium,
            }}
          >
            <ViewCol>
              <TextButton onPress={async () => {
                setModalVisibility(true)


              }} labelKey="actions.signal" />
            </ViewCol>

            <ViewCol inline>
              <TextButton
                onPress={() => {
                  onLikeSubmit(feedId);
                }}
                label=""
                iconType="FontAwesome5"
                iconColorName="greenMedium"
                iconName="thumbs-up"
              />
              <Divider vertical />
              <TextButton
                onPress={() => {
                  setShowFieldAnswer(
                    showFieldAnswer === commentId ? 0 : commentId,
                  );
                  setContentAnswer('');
                }}
                labelKey="actions.answer"
              />
            </ViewCol>
          </ViewRow>
        </ViewCol>
      </ViewRow>

      {/* answer to feed */}

      {showFieldAnswer === commentId && (
        <View
          style={{
            paddingLeft: theme.normalize(70),
          }}
        >
          <AddComment
            feedId={feedId}
            content={contentAnswer}
            setContent={setContentAnswer}
            placeholderKey="sentences.newAnswer"
            onSubmit={onAnswerSubmit}
          />
        </View>
      )}
    </>
  );
};
