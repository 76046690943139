import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { TileButton } from 'components/common/TileButton/TileButton';
import { BackgroundLayout } from 'components/layouts/BackgroundLayout/BackgroundLayout';
import useTheme from 'hooks/useTheme';
import { RootStackParamList } from 'navigation/RootStackParamList';
import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';
import moment from 'moment';
import AlertItem from 'components/compositions/AlertItem/AlertItem';
import useList from 'hooks/useList';
import { Alert } from 'types/Alert';
import { fetchJSON } from 'utils/fetchJSON';
import useAuth from 'hooks/useAuth';
import ResponsiveScrollView from 'components/layouts/ResponsiveScrollView/ResponsiveScrollView';
import fnDesktopStyles from 'utils/desktopModeStyle';
import isDesktopMode from 'utils/desktopMode';
import { JText } from '../../../components/common/Text/Text';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'MyAlerts'
>;

const AlertsScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopStyles = fnDesktopStyles(theme);
  const desktopMode = isDesktopMode();
  const { user: currentUser } = useAuth();

  const {
    items: alerts,
    fetchItems: fetchAlerts,
    isFetching: isAlertsFetching,
  } = useList<Alert>('alerts', {
    populate: ['departureLocation', 'arrivalLocation'],
    defaultFilters: {
      user: [currentUser?.id],
    },
  });

  useEffect(() => {
    fetchAlerts();
  }, [fetchAlerts]);

  const onDeleteAlert = useCallback(
    async (alert: Alert) => {
      const res = await fetchJSON({
        url: `alerts/${alert.id}`,
        method: 'DELETE',
      });
      fetchAlerts();
    },
    [fetchAlerts],
  );

  return (
    <BackgroundLayout>
      <View
        style={[
          {
            padding: theme.sizings.mediumLarge,
            alignItems: desktopMode ? 'center' : 'flex-end',
          },
          desktopMode && desktopStyles.boxed,
        ]}
      >
        <TileButton
          tileColorName="greenLight"
          leftIconType="FontAwesome5"
          leftIconName="thumbtack"
          labelKey="add.alert"
          onPress={() =>
            navigation.navigate('CourseSearch', { isCreating: true })
          }
          style={{ width: '50%' }}
        />
      </View>

      <ResponsiveScrollView
        style={{ padding: theme.sizings.mediumLarge, flex: 1 }}
      >
        <View style={desktopMode && desktopStyles.boxed}>
          {!isAlertsFetching &&
            alerts &&
            alerts.map((alert: Alert) => (
              <AlertItem
                key={alert.id}
                onDelete={() => onDeleteAlert(alert)}
                onEdit={() =>
                  navigation.navigate('CourseSearch', {
                    searchParams: {
                      ...alert,
                      departureTime: moment(alert.departureTime),
                    },
                    isCreating: true,
                  })
                }
                onResults={() =>
                  navigation.navigate('CourseResults', {
                    searchParams: {
                      ...alert,
                      departureTime: moment(alert.departureTime),
                      departureDate: moment(alert.departureTime).toDate(),
                    },
                  })
                }
                date={new Date(moment(alert.departureTime).toDate())}
                from={
                  (
                    alert.departureLocation as unknown as {
                      name: string;
                      id: string;
                    }
                  ).name
                }
                to={
                  (
                    alert.arrivalLocation as unknown as {
                      name: string;
                      id: string;
                    }
                  ).name
                }
                results={alert.result}
              />
            ))}
          {!isAlertsFetching && alerts?.length === 0 && (
            <JText labelKey="titlePages.noAlert" centered />
          )}
        </View>
      </ResponsiveScrollView>
    </BackgroundLayout>
  );
};

export default AlertsScreen;
