import React, { useEffect, useState } from 'react';
import { Card } from 'components/common/Card/Card';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import { JText } from 'components/common/Text/Text';
import { Section } from 'components/common/Section/Section';
import { Document } from 'components/common/Document/Document';
import { BackgroundLayout } from 'components/layouts/BackgroundLayout/BackgroundLayout';
import useTheme from 'hooks/useTheme';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Form } from 'react-final-form';
import { FormControl } from 'components/common/FormControl/FormControl';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { UserVehicle } from 'types/UserVehicle';
import fetchJSON from 'utils/fetchJSON';
import { ImageUploader } from 'components/common/ImagePicker/ImagePicker';
import { StrapiFile } from 'types/StrapiFile';
import ResponsiveScrollView from 'components/layouts/ResponsiveScrollView/ResponsiveScrollView';
import { useTranslation } from 'react-i18next';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import { View } from 'react-native';
import { I18nKey } from '../../../../../../i18n';
import useAlert from '../../../../../providers/AlertProvider';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'CreateVehicle'
>;

const fuelValues = ['gasoline', 'sp', 'diesel', 'electric'];
const fuelValuesKeys: I18nKey[] = [
  'formOptions.gasoline',
  'formOptions.sp',
  'formOptions.diesel',
  'formOptions.electric',
];

const availablePlaces = [2, 3, 4, 5, 6, 7, 8, 9];

const luggageSizes = ['small', 'medium', 'large'];

const luggageSizesKeys: I18nKey[] = [
  'formOptions.small',
  'formOptions.medium',
  'formOptions.large',
];

const labelKeysYesNo: I18nKey[] = ['formOptions.yes', 'formOptions.no'];
const valuesYesNo: boolean[] = [true, false];

const CreateVehicleScreen = ({ navigation, route }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const desktopStyles = fnDesktopStyles(theme);
  const { t } = useTranslation();
  const { setAlert } = useAlert();

  const [vehicle, setVehicle] = useState<UserVehicle>();
  const [vehicleId, setVehicleId] = useState<number | undefined>(undefined);
  const [isCreateVehicle, setIsCreateVehicle] = useState(true);
  const [consumption, setConsumption] = React.useState<string | undefined>(
    undefined,
  );

  const [vehicleImage, setVehicleImage] = useState<StrapiFile>();

  useEffect(() => {
    if (route.params.vehicle) {
      setVehicle({
        id: route.params.vehicle.id,
        ...route.params.vehicle,
      });
      setVehicleId(route.params.vehicle.id);
      setConsumption(`${route.params.vehicle.consumption}`);
      setIsCreateVehicle(false);

      /* const applyDocRegistrationCard = route.params.vehicle.vehicle_documents.filter((doc:VehicleDocument) => {
        doc.isCarRegistrationCard
      }).sort((a, b) => (Number(a.updatedAt) - Number(b.updatedAt)))?.[0];

      const applyInsuranceCard = route.params.vehicle.vehicle_documents.filter((doc:VehicleDocument) => {
        doc.isInsurance
      }).sort((a, b) => (Number(a.updatedAt) - Number(b.updatedAt)))?.[0];

      setCurrentDocRegistrationCard(applyDocRegistrationCard);
      setCurrentDocInsurance(applyInsuranceCard);

      console.log({applyDocRegistrationCard, applyInsuranceCard}) */
    }
  }, [route.params]);

  const handleSubmitVehicle = (values: any) => {
    try {
      const newValues = values;

      if (vehicleImage) {
        newValues.picture = vehicleImage;
      }

      if (consumption) {
        newValues.consumption = consumption;
        if (isCreateVehicle) {
          fetchJSON({
            url: `vehicles`,
            method: 'POST',
            payload: { data: newValues },
          }).then(res => {
            setVehicleId(res.id);
          });
        } else {
          fetchJSON({
            url: `vehicles/${newValues.id}`,
            method: 'PUT',
            payload: { data: newValues },
          });
        }
      }
    } catch (error) {
      // TODO : LABEL : modifier le message d'erreur pour qu'il soit + explicite
      setAlert({
        color: theme.colors.statusRed,
        message: t('alert.error'),
        title: t('alert.error'),
      });
    }
  };

  const onSubmit = (values: any) => {
    try {
      const newValues = values;

      if (vehicleImage) {
        newValues.picture = vehicleImage;
      }

      if (consumption) {
        newValues.consumption = consumption;
      }

      if (vehicleId && consumption) {
        newValues.id = vehicleId;

        fetchJSON({
          url: `vehicles/${newValues.id}`,
          method: 'PUT',
          payload: { data: newValues },
        }).then(() => navigation.goBack());
      }
    } catch (error) {
      // TODO : LABEL : modifier le message d'erreur pour qu'il soit + explicite
      setAlert({
        color: theme.colors.statusRed,
        message: t('alert.error'),
        title: t('alert.error'),
      });
    }
  };

  return (
    <BackgroundLayout>
      <ResponsiveScrollView>
        <View
          style={[
            {
              padding: theme.sizings.mediumLarge,
              flex: 1,
            },
            desktopMode && desktopStyles.boxed,
          ]}
        >
          <Card style={{ marginBottom: theme.sizings.mediumLarge * 2 }}>
            <Form
              onSubmit={onSubmit}
              initialValues={vehicle ?? {}}
              render={({ handleSubmit, values }) => (
                <>
                  <Section titleKey="section.newVehicle" />

                  <FormControl titleKey="forms.model" name="name" required />

                  <ImageUploader
                    titleKey="forms.vehiclePicture"
                    value={vehicleImage ?? values.picture}
                    onChange={(image: StrapiFile) => {
                      setVehicleImage(image);
                    }}
                  />

                  <FormControl
                    type="select-list"
                    name="fuel"
                    required
                    titleKey="forms.fuel"
                    values={fuelValues}
                    labelKeys={fuelValuesKeys}
                    style={{
                      width: '100%',
                      marginBottom: theme.sizings.medium,
                    }}
                    selectedValue={values.fuel}
                  />

                  <FormControl
                    titleKey="forms.consumption"
                    name="consumption"
                    required
                    onChange={value => {
                      const newConsumption = Number(value.replace(',', '.'));
                      if (!Number.isNaN(newConsumption)) {
                        setConsumption(`${newConsumption}`);
                      }
                    }}
                  />

                  <FormControl
                    type="select-list"
                    name="seats"
                    titleKey="forms.vehiclePlaces"
                    required
                    values={availablePlaces}
                    style={{
                      width: '100%',
                      marginBottom: theme.sizings.medium,
                    }}
                    selectedValue={values.seats}
                  />

                  <FormControl
                    type="select-list"
                    name="luggageSize"
                    required
                    titleKey="forms.luggageSize"
                    values={luggageSizes}
                    labelKeys={luggageSizesKeys}
                    style={{
                      width: '100%',
                      marginBottom: theme.sizings.medium,
                    }}
                    selectedValue={values.luggageSize}
                  />

                  <FormControl
                    type="select-list"
                    name="heatedSeats"
                    titleKey="forms.heatedSeat"
                    values={valuesYesNo}
                    labelKeys={labelKeysYesNo}
                    required
                    style={{
                      width: '100%',
                      marginBottom: theme.sizings.medium,
                    }}
                    selectedValue={values.heatedSeats}
                  />
                  <FormControl
                    type="select-list"
                    name="airConditioner"
                    titleKey="forms.airConditioner"
                    required
                    values={valuesYesNo}
                    labelKeys={labelKeysYesNo}
                    style={{
                      width: '100%',
                      marginBottom: theme.sizings.medium,
                    }}
                    selectedValue={values.airConditioner}
                  />

                  {!vehicleId && (
                    <GradientButton
                      onPress={() => handleSubmitVehicle(values)}
                      labelKey="forms.continue"
                      leftIconType="AntDesign"
                      leftIconName="check"
                      leftIconSizeName="largest"
                      rightIconType="AntDesign"
                      rightIconName="right"
                      rightIconSizeName="largest"
                      style={{
                        width: '100%',
                        marginBottom: theme.sizings.medium,
                        marginTop: theme.sizings.large,
                      }}
                    />
                  )}

                  {vehicleId && (
                    <>
                      <JText
                        isBold
                        labelKey="forms.vehicleDocuments"
                        style={{ marginBottom: theme.sizings.tiny }}
                      />
                      <JText
                        isItalic
                        labelKey="forms.vehicleDocumentsLegende"
                        style={{ marginBottom: theme.sizings.medium }}
                      />
                      <Document
                        labelKey="forms.vehicleRegistrationDocument"
                        iconType="FontAwesome5"
                        iconName="car"
                        documentType="isCarRegistrationCard"
                        documentUrl="vehicle-documents"
                        documentEvents={[
                          'vehicle_document_accepted',
                          'vehicle_document_refused',
                          'vehicle_document_waiting',
                        ]}
                        parent="vehicle"
                        parentId={vehicleId}
                      />
                      <Document
                        labelKey="forms.vehicleAssurance"
                        iconType="FontAwesome5"
                        iconName="car"
                        documentType="isInsurance"
                        documentUrl="vehicle-documents"
                        documentEvents={[
                          'vehicle_document_accepted',
                          'vehicle_document_refused',
                          'vehicle_document_waiting',
                        ]}
                        parent="vehicle"
                        parentId={vehicleId}
                      />

                      <GradientButton
                        onPress={handleSubmit}
                        labelKey="save.vehicle"
                        leftIconType="AntDesign"
                        leftIconName="check"
                        leftIconSizeName="largest"
                        rightIconType="AntDesign"
                        rightIconName="right"
                        rightIconSizeName="largest"
                        style={{
                          width: '100%',
                          marginBottom: theme.sizings.medium,
                          marginTop: theme.sizings.large,
                        }}
                      />
                    </>
                  )}
                </>
              )}
            />
          </Card>
        </View>
      </ResponsiveScrollView>
    </BackgroundLayout>
  );
};

export default CreateVehicleScreen;
