import React from 'react';
import { ScrollView } from 'react-native';
import { ClassicButton } from '../../components/common/ClassicButton/ClassicButton';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const ClassicButtonScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="ClassicButton"
        description="This component renders a classic button."
      >
        <ClassicButton label="A classic button" onPress={() => {}} />
      </CatalogHeader>
      <CatalogProps
        propName="label"
        description="The text written in the component."
        type="string"
        topDivider={false}
      />
      <CatalogProps propName="onPress" type="( ) => void" />
    </ScrollView>
  );
};

export default ClassicButtonScreen;
