import { Dimensions, ImageSourcePropType, Platform, View } from 'react-native';
import React from 'react';
import useTheme from 'hooks/useTheme';
import { StrapiFile } from 'types/StrapiFile';
import isDesktopMode from 'utils/desktopMode';
import Map from '../../common/Map/Map';
// import MapNative from '../../common/Map/Map.native';
import { ViewCol, ViewRow } from '../../layouts/FlexLayout/FlexViews';
import { JText } from '../../common/Text/Text';
import { Avatar } from '../../common/Avatar/Avatar';
import { I18nKey } from '../../../../i18n';

type Points = {
  id: number;
  name: string;
  longitude: string;
  latitude: string;
};

type ScreenProps = {
  points: Points[];
  labelKey: I18nKey;
  labelValues?: object;
  image?: ImageSourcePropType | StrapiFile;
  imageIsWoman?: boolean;
};

const CourseHeader = ({
  points,
  labelKey,
  labelValues,
  image,
  imageIsWoman = false,
}: ScreenProps) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();

  return (
    <View>
      {/* Bloc Map + avatar */}
      <View
        style={{
          backgroundColor: theme.colors.greyLight,
          height: desktopMode
            ? 0.3 * Dimensions.get('window').height + theme.headerSizes.desktop
            : 0.3 * Dimensions.get('window').height + theme.headerSizes.mobile,
          overflow: 'hidden',
          borderBottomLeftRadius: theme.radius.rounded,
          borderBottomRightRadius: theme.radius.rounded,
        }}
      >
        <Map
          points={points}
          height={
            desktopMode
              ? 0.3 * Dimensions.get('window').height +
                theme.headerSizes.desktop
              : 0.3 * Dimensions.get('window').height + theme.headerSizes.mobile
          }
        />
        {/* Platform.OS === 'web' ? (
          <Map
            points={points}
            height={
              desktopMode
                ? 0.3 * Dimensions.get('window').height +
                  theme.headerSizes.desktop
                : 0.3 * Dimensions.get('window').height +
                  theme.headerSizes.mobile
            }
          />
        ) : (
          <MapNative
            points={points}
            height={
              desktopMode
                ? 0.3 * Dimensions.get('window').height +
                  theme.headerSizes.desktop
                : 0.3 * Dimensions.get('window').height +
                  theme.headerSizes.mobile
            }
          />
        ) */}
      </View>
      <ViewRow style={{ paddingHorizontal: theme.sizings.mediumLarge }}>
        <ViewCol size={1} style={{ paddingRight: theme.sizings.large }}>
          <JText
            isTitleText
            labelKey={labelKey}
            valuesKey={labelValues}
            style={{ marginTop: theme.sizings.mediumLarge }}
            sizeName="large"
          />
        </ViewCol>
        <ViewCol style={{ width: theme.normalize(120) }}>
          <Avatar
            sizeName="sz120"
            style={{ marginTop: -theme.normalize(60) }}
            image={image}
            isWoman={imageIsWoman}
          />
        </ViewCol>
      </ViewRow>
    </View>
  );
};

export default CourseHeader;
