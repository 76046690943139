import React, { useState } from 'react';
import {
  Image,
  View,
  ImageSourcePropType,
  Modal,
  Text,
  TouchableOpacity,
  Platform,
} from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import useTheme from 'hooks/useTheme';
import { StrapiFile } from 'types/StrapiFile';
import { getStoredData } from 'utils/fnAsyncStorage';
import { API_URL } from 'constants/Constants';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import { I18nKey } from '../../../../i18n';
import fnStyles from '../FormInput/FormInputStyle';
import { JText } from '../Text/Text';
import { Icon } from '../Icon/Icon';
import { CircularButton } from '../CircularButton/CircularButton';
import ImageFromStrapiMedia from '../ImageFromStrapiMedia/ImageFromStrapiMedia';
import { GradientButton } from '../GradientButton/GradientButton';

export type ImagePickerProps = {
  title?: string;
  titleKey?: I18nKey;
  value?: ImageSourcePropType | any;
  rounded?: boolean;
  iconic?: boolean;
  ratio?: [number, number] | undefined;
  onChange?: (image: StrapiFile) => void;
  showImage?: boolean;
};

const uploadImage = async (imageResult: ImageInfo) => {
  let file = null;

  if (Platform.OS !== 'web') {
    const localUri = imageResult.uri;
    const filename = localUri.split('/').pop() ?? '';

    // Infer the type of the image
    const match = /\.(\w+)$/.exec(filename);
    const type = match ? `image/${match[1]}` : `image`;

    file = {
      uri: localUri,
      name: filename,
      type,
    };
  } else {
    const response = await fetch(imageResult.uri);
    file = await response.blob();
  }

  const formData = new FormData();
  formData.append('files', file);

  const token = await getStoredData('token');

  const headers = new Headers();

  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  const res = await fetch(`${API_URL}/upload`, {
    method: 'POST',
    headers,
    body: formData,
  });

  if (!res.ok) {
    let message = await res.text();

    try {
      message = JSON.parse(message).message;
    } catch (e) {
      // Not JSON
    }
    throw new Error(message);
  }

  const json = await res.json();

  return json?.[0];
};

export const ImageUploader = ({
  title,
  titleKey,
  value,
  rounded = false,
  iconic = false,
  ratio = undefined,
  onChange,
  showImage = true,
}: ImagePickerProps) => {
  const { theme } = useTheme();
  const [modalVisible, setModalVisible] = useState(false);

  const styles = fnStyles(theme);

  const pickCameraImage = async () => {
    // Ask the user for the permission to access the camera
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync();

    if (permissionResult.granted === false) {
      setModalVisible(false);
      return;
    }

    const capturedImage = await ImagePicker.launchCameraAsync({
      allowsEditing: false,
      aspect: [4, 3],
      base64: false,
    }).catch(err => {
      console.log(err);
    });
    setModalVisible(false);

    if (!capturedImage?.cancelled) {
      const result = await uploadImage(capturedImage as ImageInfo);
      if (onChange) onChange(result);
    }
  };

  const pickGalleryImage = async () => {
    // Ask the user for the permission to access the camera
    const permissionResult =
      await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted === false) {
      setModalVisible(false);
      return;
    }

    const capturedImage = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: ratio !== undefined,
      aspect: ratio,
      quality: 1,
      base64: false,
    });
    setModalVisible(false);

    if (!capturedImage?.cancelled) {
      const result = await uploadImage(capturedImage as ImageInfo);
      if (onChange) onChange(result);
    }
  };

  return (
    <View>
      <Modal
        visible={modalVisible}
        animationType="slide"
        hardwareAccelerated
        transparent
      >
        <TouchableOpacity
          onPress={() => {
            setModalVisible(false);
          }}
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#00000055',
          }}
        >
          <View
            style={{
              width: '75%',
              padding: 10,
              backgroundColor: '#fff',
              borderRadius: 10,
            }}
          >
            <Text style={{ marginBottom: 20 }}>
              Veuillez sélectionner la source{' '}
            </Text>
            <GradientButton
              style={{ marginBottom: 20 }}
              label="Camera"
              onPress={pickCameraImage}
            />
            <GradientButton
              style={{ marginBottom: 20 }}
              label="Galerie Photo"
              onPress={pickGalleryImage}
            />
          </View>
        </TouchableOpacity>
      </Modal>
      {iconic ? (
        <CircularButton
          backgroundColorName="transparent"
          sizeName="larger"
          iconType="IonIcons"
          iconName="add-outline"
          iconSizeName="fs30"
          iconColorName="dark"
          onPress={() => {
            setModalVisible(true);
          }}
          style={[styles.icon]}
        />
      ) : (
        <View style={styles.container}>
          <View style={styles.headerText}>
            {(title || titleKey) && (
              <JText isBold label={title} labelKey={titleKey} />
            )}
          </View>

          {value && showImage ? (
            <TouchableOpacity
              onPress={() => {
                setModalVisible(true);
              }}
            >
              <View
                style={{
                  alignItems: rounded ? 'flex-start' : 'center',
                  position: 'relative',
                  width: '100%',
                }}
              >
                <Image
                  source={value?.url ? ImageFromStrapiMedia(value) : value}
                  style={{
                    width: rounded
                      ? theme.normalize(110)
                      : theme.normalize(200),
                    height: rounded
                      ? theme.normalize(110)
                      : theme.normalize(200),
                    marginVertical: theme.sizings.small,
                    borderRadius: rounded ? theme.normalize(55) : 0,
                    backgroundColor: theme.colors.light,
                  }}
                />
                <Icon
                  type="FontAwesome"
                  name="pencil"
                  sizeName="larger"
                  colorName="blueHigh"
                  style={{
                    position: 'absolute',
                    top: theme.sizings.smallMedium,
                    right: theme.sizings.smallMedium,
                    backgroundColor: theme.colors.light,
                    borderRadius: theme.sizings.ten,
                  }}
                />
              </View>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              onPress={() => {
                setModalVisible(true);
              }}
            >
              <View
                style={[
                  {
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: theme.sizings.medium,
                    backgroundColor: theme.colors.greyLight,
                  },
                  {
                    width: rounded ? theme.normalize(110) : '100%',
                    height: rounded ? theme.normalize(110) : undefined,
                    borderRadius: rounded
                      ? theme.normalize(55)
                      : theme.sizings.smallMedium,
                  },
                ]}
              >
                <Icon
                  name="camera"
                  type="FontAwesome5"
                  customSize={theme.normalize(55)}
                  colorName="greyMedium"
                />
                {!rounded && (
                  <JText
                    labelKey="forms.uploadPicture"
                    style={{ marginTop: theme.sizings.mediumLarge }}
                  />
                )}
              </View>
            </TouchableOpacity>
          )}
        </View>
      )}
    </View>
  );
};
