import { Card } from 'components/common/Card/Card';
import { Document } from 'components/common/Document/Document';
import { TabsBackground } from 'components/layouts/BackgroundLayout/TabsBackgound';
import { ViewCol } from 'components/layouts/FlexLayout/FlexViews';
import ResponsiveScrollView from 'components/layouts/ResponsiveScrollView/ResponsiveScrollView';
import useAuth from 'hooks/useAuth';
import useTheme from 'hooks/useTheme';
import React from 'react';
import { View } from 'react-native';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';

const DocumentsScreen = () => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const desktopStyles = fnDesktopStyles(theme);
  const { user: currentUser } = useAuth();

  return (
    <>
      <TabsBackground />
      <ResponsiveScrollView>
        <View
          style={[
            {
              padding: theme.sizings.mediumLarge,
              flex: 1,
            },
            desktopMode && desktopStyles.boxed,
          ]}
        >
          <Card style={{ flexDirection: 'row' }}>
            <ViewCol size={1}>
              <Document
                labelKey="document.identification"
                iconType="IonIcons"
                iconName="finger-print-outline"
                documentType="isID"
                documentUrl="user-documents"
                documentEvents={['document_accepted', 'document_refused']}
                parent="user"
                parentId={currentUser?.id}
              />

              <Document
                labelKey="document.license"
                iconType="FontAwesome5"
                iconName="car"
                documentType="isLicense"
                documentUrl="user-documents"
                documentEvents={['document_accepted', 'document_refused']}
                parent="user"
                parentId={currentUser?.id}
              />
            </ViewCol>
          </Card>
        </View>
      </ResponsiveScrollView>
    </>
  );
};

export default DocumentsScreen;
