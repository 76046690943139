import Calendar from 'components/common/Calendar/Calendar';
import React from 'react';
import { ScrollView } from 'react-native';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const CalendarScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader title="Card" description="This component renders a card.">
        <Calendar />
      </CatalogHeader>
    </ScrollView>
  );
};

export default CalendarScreen;
