import useTheme from 'hooks/useTheme';
import React from 'react';
import { TouchableOpacity, ViewStyle, StyleProp } from 'react-native';
import { I18nKey } from '../../../../i18n';
import { JText } from '../Text/Text';
import fnStyles from './SelectableOptionButtonStyle';

type Props = {
  value: string;
  labekLey?: I18nKey;
  selectedValue: string | string[] | undefined;
  onChange: (value: string | string[] | undefined) => void;
  style?: StyleProp<ViewStyle>;
};

export const SelectableOptionButton = (props: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  const onOptionPressed = () => {
    if (typeof props.selectedValue === 'string') {
      props.onChange(props.value);
    } else if (props.selectedValue?.includes(props.value)) {
      props.onChange(props.selectedValue?.filter(item => item !== props.value));
    } else {
      // TODO ca marche pas ce code
      // props.onChange(value => [...value, props.value]);
    }
  };

  const isSelected = () => {
    if (typeof props.selectedValue === 'string') {
      return props.selectedValue === props.value;
    }

    return props.selectedValue?.includes(props.value);
  };

  return (
    <TouchableOpacity
      style={[
        styles.container,
        props.style,
        {
          backgroundColor: isSelected()
            ? theme.colors.blueLight
            : theme.colors.greyLight,
        },
      ]}
      onPress={onOptionPressed}
    >
      <JText
        labelKey={props.labekLey}
        label={props.value}
        colorName="blueHigh"
        isBold
      />
    </TouchableOpacity>
  );
};
