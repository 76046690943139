import { LinearGradient } from 'expo-linear-gradient';
import useTheme from 'hooks/useTheme';
import { ColorType } from 'providers/ThemeProvider';
import React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { Types } from 'types/Types';
import { I18nKey } from '../../../../i18n';
import { Icon } from '../Icon/Icon';
import { JText } from '../Text/Text';
import fnStyles from './ClassicButtonStyle';

type Props = {
  label?: string;
  labelKey?: I18nKey;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  fillName?: ColorType;
  textColorName?: ColorType;
  type?: Types['classicButton'];
};

export const ClassicButton = (props: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  const {
    label,
    labelKey,
    onPress = () => {},
    style,
    fillName = 'greyLight',
    textColorName = 'blueHigh',
    type,
  } = props;

  const isGradient = type === 'main' || type === 'accept';

  return (
    <TouchableOpacity
      style={[
        isGradient ? styles.containerGradient : styles.container,
        {
          backgroundColor: isGradient ? 'transparent' : theme.colors[fillName],
        },
        style,
      ]}
      onPress={onPress}
    >
      {isGradient && (
        <LinearGradient
          style={styles.gradient}
          colors={[theme.colors.greenLight, theme.colors.greenMedium]}
          start={[0, 0.5]}
          end={[1, 0.5]}
        />
      )}

      {type !== undefined && (
        <Icon
          style={{
            marginRight: label || labelKey ? theme.sizings.smallMedium : 0,
          }}
          type="FontAwesome"
          name={
            isGradient
              ? 'angle-right'
              : type === 'decline'
              ? 'times'
              : 'comment'
          }
          colorName={
            isGradient
              ? 'light'
              : type === 'decline'
              ? 'statusDangerHigh'
              : textColorName
          }
          sizeName={label || labelKey ? 'medium' : 'large'}
        />
      )}

      {(label || labelKey) && (
        <JText
          label={label}
          labelKey={labelKey}
          isBold
          colorName={
            isGradient
              ? 'light'
              : type === 'decline'
              ? 'statusDangerHigh'
              : 'blueHigh'
          }
          sizeName="large"
        />
      )}
    </TouchableOpacity>
  );
};
