import { Card } from 'components/common/Card/Card';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import {ViewCol, ViewRow} from 'components/layouts/FlexLayout/FlexViews';
import React, {useEffect, useState} from 'react';
import { TouchableOpacity, View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import useTheme from 'hooks/useTheme';
import { RootStackParamList } from 'navigation/RootStackParamList';
import useList from 'hooks/useList';
import useAuth from 'hooks/useAuth';
import { useFocusEffect } from '@react-navigation/native';
import { Vehicle } from 'components/common/Vehicle/Vehicle';
import { UserVehicle } from 'types';
import {
  addEventListener,
  Listener,
  removeEventListener,
} from 'providers/SocketProvider';
import ResponsiveScrollView from 'components/layouts/ResponsiveScrollView/ResponsiveScrollView';
import { TabsBackground } from 'components/layouts/BackgroundLayout/TabsBackgound';
import { JText } from 'components/common/Text/Text';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import fetchJSON from "../../../../../utils/fetchJSON";
import Modal from "../../../../../components/common/Modal/Modal";

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'Vehicles'
>;

const VehiclesScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const desktopStyles = fnDesktopStyles(theme);
  const { user: currentUser } = useAuth();
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [vehicleId, setVehicleId] = useState(-1);



  const {
    isFetching: isVehiclesFetching,
    items: vehicles,
    setFilter: setVehiclesFilter,
    fetchItems: fetchVehiclesItems,
  } = useList<UserVehicle>('vehicles', {
    populate: [
      'picture',
      'user',
      'vehicle_documents',
      'vehicle_documents.document',
    ],
  });

  useFocusEffect(
    React.useCallback(() => {
      // Do something when the screen is focused
      fetchVehiclesItems();

      return () => {
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [fetchVehiclesItems]),
  );

  const modifyVehicle = (vehicle:  UserVehicle) => {
    navigation.navigate('CreateVehicle', {
      vehicle,
    })
  }

  const deleteVehicle = async (vehicleId: number) => {
      try {
        if(vehicleId !== -1) {
          const res = await fetchJSON({
            url: `vehicles/${vehicleId}`,
            method: 'DELETE'
          })
          fetchVehiclesItems()
        }
      } catch (e) {
        console.log(e)
      }
  }

  useEffect(() => {
    if (currentUser) {
      setVehiclesFilter('user', currentUser?.id);
    }
  }, [setVehiclesFilter, currentUser]);

  useEffect(() => {
    const listener: Listener = {
      events: ['vehicle_document_accepted', 'vehicle_document_refused', 'vehicle_document_waiting'],
      handleEvent: () => {
        fetchVehiclesItems();
      },
    };
    addEventListener(listener);

    return () => {
      removeEventListener(listener);
    };
  }, [fetchVehiclesItems]);

  return (
    <>
      <TabsBackground />
      <ResponsiveScrollView>
        <Modal
          titleKey='alertTitle.deleteVehicle'
          isVisible={modalVisibility}
          onBackDropPress={() => {setModalVisibility(false)}}
          renderContent={
            <>
              <JText labelKey='alertTitle.deleteVehicleDescription'/>
            </>
          }
          renderFooter={
            <>
              <ViewRow>
                <ViewCol style={{ paddingRight: theme.sizings.medium }}>
                  <GradientButton
                    onPress={async () => {
                      await deleteVehicle(vehicleId)
                      setModalVisibility(false)

                    }}
                    labelKey="common.yes"
                  />
                </ViewCol>
                <ViewCol style={{ paddingRight: theme.sizings.medium }}>
                  <GradientButton
                    colors={[theme.colors.statusRed, theme.colors.statusRed]}
                    onPress={() => setModalVisibility(false)}
                    labelKey="actions.cancel"
                  />
                </ViewCol>
              </ViewRow>
            </>
          }

        />
        <View
          style={[
            {
              padding: theme.sizings.mediumLarge,
              flex: 1,
            },
            desktopMode && desktopStyles.boxed,
          ]}
        >
          <Card style={{ flexDirection: 'row' }}>
            <ViewCol size={1}>
              {!isVehiclesFetching && !vehicles?.length && (
                <JText labelKey="alertTitle.noVehicles" centered />
              )}
              {!isVehiclesFetching ? (
                vehicles !== null &&
                vehicles.map(vehicle => (
                    <Vehicle
                      key={vehicle.name + vehicle.id}
                      label={vehicle.name}
                      vehicle={vehicle}
                      deleteVehicle={()=> {
                        setModalVisibility(true)
                        setVehicleId(vehicle.id ?? -1)
                    }}
                      modifyVehicle={() => {
                        modifyVehicle(vehicle)
                      }

                    } />

                ))
              ) : (
                <JText centered labelKey="common.loading" />
              )}
              <GradientButton
                onPress={() => navigation.navigate('CreateVehicle', {})}
                labelKey="add.vehicle"
                style={{ marginTop: theme.sizings.mediumLarge }}
              />
            </ViewCol>
          </Card>
        </View>
      </ResponsiveScrollView>
    </>
  );
};

export default VehiclesScreen;
