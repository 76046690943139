import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';
import { SelectableOptionButton } from '../../components/common/SelectableOptionButton/SelectableOptionButton';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const SelectableOptionButtonScreen = () => {
  const [selectedValue, setSelectedValue] = useState<
    string | string[] | undefined
  >('');
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="SelectableOptionButton"
        description="This component renders a button clickable which renders it like a selected value. IMPORTANT! You should not be facing a case where you need to use this component alone. There is the component SelectableOptionsList which is using it."
      >
        <View style={{ flexDirection: 'row' }}>
          <SelectableOptionButton
            labekLey="formOptions.yes"
            value="Oui"
            selectedValue={selectedValue}
            onChange={setSelectedValue}
          />
          <View style={{ width: 10 }} />
          <SelectableOptionButton
            labekLey="formOptions.sometimes"
            value="Parfois"
            selectedValue={selectedValue}
            onChange={setSelectedValue}
          />
          <View style={{ width: 10 }} />
          <SelectableOptionButton
            labekLey="formOptions.no"
            value="Non"
            selectedValue={selectedValue}
            onChange={setSelectedValue}
          />
        </View>
      </CatalogHeader>
      <CatalogProps
        propName="value"
        description="The value of the button."
        type="string"
        topDivider={false}
      />
      <CatalogProps
        propName="selectedValue"
        description="The value selected in the list. Should be from a useState."
        type="string | string[ ]"
      />
      <CatalogProps
        propName="onChange"
        description="Should the setter of the selectedValue."
        type="(value: string | string [ ]) => void"
      />
    </ScrollView>
  );
};

export default SelectableOptionButtonScreen;
