import React from 'react';
import {
  ScrollView,
  View,
  Image,
  ImageBackground,
  Dimensions,
  Platform,
} from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { getStatusBarHeight } from 'react-native-status-bar-height';

import { CircularButton } from 'components/common/CircularButton/CircularButton';
import { ClassicButton } from 'components/common/ClassicButton/ClassicButton';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import { JText } from 'components/common/Text/Text';

import { useAuth } from 'hooks/useAuth';
import useTheme from 'hooks/useTheme';

import {
  SafeAreaView,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';
import fnDesktopStyles from 'utils/desktopModeStyle';
import isDesktopMode from 'utils/desktopMode';
import ResponsiveScrollView from 'components/layouts/ResponsiveScrollView/ResponsiveScrollView';
import homeBackground from '../../../assets/home-background.png';
import homeDesktopBackground from '../../../assets/desktop-background.jpg';
import illustration from '../../../assets/home-illustration.png';
import desktopIllustration from '../../../assets/desktop-car.png';

import fnStyles from './WelcomeScreenStyle';

// Develop boolean for catalog access
const develop = false;

export type ScreenProps = NativeStackScreenProps<RootStackParamList, 'Home'>;

const WelcomeScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const desktopStyles = fnDesktopStyles(theme);
  const desktopMode = isDesktopMode();

  const insets = useSafeAreaInsets();

  const { isFetching: isUserFetching, user: currentUser } = useAuth();

  const handleAlreadyHaveAccountPressed = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: 'Login' }],
    });
  };

  const handleCatalogPressed = () => {
    navigation.navigate('Catalog');
  };

  const handleAboutPressed = () => {
    navigation.navigate('About');
  };

  return (
    <>
      <ResponsiveScrollView
        style={{
          marginTop:
            desktopMode || Platform.OS === 'web'
              ? undefined
              : getStatusBarHeight(),
          paddingTop: desktopMode ? theme.headerSizes.desktop : undefined,
          backgroundColor: theme.colors.light,
        }}
      >
        <ImageBackground
          source={desktopMode ? homeDesktopBackground : homeBackground}
          resizeMode={desktopMode ? 'cover' : 'contain'}
          style={[
            styles.background,
            {
              width: Dimensions.get('window').width,
              height: desktopMode
                ? 0.5 * Dimensions.get('window').height
                : 0.7 * Dimensions.get('window').width,
            },
          ]}
        />
        {isUserFetching ? (
          <JText labelKey="common.loading" />
        ) : (
          <View>
            <View
              style={[
                { flexDirection: 'row' },
                desktopMode ? desktopStyles.boxed : styles.blocTop,
              ]}
            >
              <Image
                source={desktopMode ? desktopIllustration : illustration}
                resizeMode="contain"
                style={
                  desktopMode
                    ? styles.blocIllustrationDesktop
                    : styles.blocIllustration
                }
              />

              <View style={styles.blocTopRight}>
                <View style={styles.topWelcome}>
                  <JText
                    isTitleText
                    textType="h1"
                    labelKey="home.welcomeCiligo"
                    sizeName="fs30"
                  />
                </View>
              </View>
            </View>
            <View
              style={[
                styles.blocBottom,
                desktopMode && desktopStyles.boxed,
                {
                  marginTop: desktopMode
                    ? theme.sizings.sz50
                    : theme.normalize(145),
                },
              ]}
            >
              <JText
                isTitleText
                centered
                labelKey="home.wantToBe"
                textType="h2"
              />

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: theme.sizings.mediumLarge,
                }}
              >
                <CircularButton
                  labelKey="courses.passenger"
                  backgroundColorName="blueLight"
                  sizeName="sz95"
                  iconType="Material"
                  iconName="luggage"
                  iconSizeName="fs50"
                  iconColorName="blueHigh"
                  onPress={
                    currentUser
                      ? () => navigation.navigate('CourseSearch')
                      : () => navigation.navigate('Register')
                  }
                />
                <View style={{ width: 30 }} />
                <CircularButton
                  labelKey="courses.driver"
                  backgroundColorName="statusSuccessLight"
                  sizeName="sz95"
                  iconType="FontAwesome5"
                  iconName="car"
                  iconSizeName="fs50"
                  iconColorName="statusSuccessHigh"
                  onPress={
                    currentUser
                      ? () => navigation.navigate('CourseCreate')
                      : () => navigation.navigate('Register')
                  }
                />
              </View>
            </View>
          </View>
        )}
      </ResponsiveScrollView>
      <View
        style={[
          {
            padding: theme.sizings.mediumLarge,
            backgroundColor: theme.colors.light,
            marginBottom: insets.bottom,
          },
        ]}
      >
        <View style={desktopMode && desktopStyles.boxed}>
          <GradientButton
            labelKey="auth.login"
            style={styles.button}
            onPress={handleAlreadyHaveAccountPressed}
            leftIconType="FontAwesome5"
            leftIconName="user"
            leftIconSizeName="larger"
          />
          <GradientButton
            labelKey="auth.about"
            style={[styles.button, { marginBottom: 20 }]}
            onPress={handleAboutPressed}
            colors={[theme.colors.blueMedium, theme.colors.blueHigh]}
            leftIconType="FontAwesome5"
            leftIconName="info"
            leftIconSizeName="larger"
          />
          {develop && (
            <ClassicButton
              label="Aller au catalogue"
              style={styles.button}
              onPress={handleCatalogPressed}
            />
          )}
        </View>
      </View>
    </>
  );
};

export default WelcomeScreen;
