import { ROOT_URL } from 'constants/Constants';

const ImageFromStrapiMedia = (media: any) => ({
  uri: `${ROOT_URL}${media?.url}`,
});

/*
profilePicture: {
  alternativeText: "avataaars.png",
  caption: "avataaars.png",
  createdAt: "2022-03-23T12:49:24.888Z",
  ext: ".png",
  formats: {thumbnail: {…}, small: {…}},
  hash: "avataaars_ca8a58ea3b",
  height: 560,
  id: 1,
  mime: "image/png",
  name: "avataaars.png",
  previewUrl: null,
  provider: "local",
  provider_metadata: null,
  size: 31.59,
  updatedAt: "2022-03-23T12:49:24.888Z",
  url: "/uploads/avataaars_ca8a58ea3b.png",
  width: 528,
}
*/

export default ImageFromStrapiMedia;
