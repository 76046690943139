import { Avatar } from 'components/common/Avatar/Avatar';
import { ClassicButton } from 'components/common/ClassicButton/ClassicButton';
import { Divider } from 'components/common/Divider/Divider';
import { IconicText } from 'components/common/IconicText/IconicText';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import React from 'react';
import { ImageSourcePropType, View } from 'react-native';
import { Types } from 'types/Types';
import { Icon } from 'components/common//Icon/Icon';
import { JText } from 'components/common//Text/Text';
import { TouchableOpacity } from 'react-native-gesture-handler';
import useTheme from 'hooks/useTheme';
import computeAge from 'utils/computeAge';
import { StrapiFile } from 'types/StrapiFile';
import styles from './UserActionsStyles';
import { I18nKey } from '../../../../i18n';

type Props = {
  image?: ImageSourcePropType | StrapiFile;
  imageIsWoman?: boolean;
  name: string;
  userBirth: Date;
  chatting: boolean;
  music: boolean;
  smoking: boolean;
  note?: number;
  userRole: Types['role'];
  userId: number;
  passengerId?: number;
  actionAvatar: () => void;
  actionContact?: (userId: number) => void;
  actionDelete?: (userId: number) => void;
  actionDecline?: () => void;
  actionPrimary?: () => void;
  actionPrimaryLabelKey?: I18nKey;
};

export const UserActions = ({
  image,
  imageIsWoman = false,
  name,
  userBirth,
  chatting,
  music,
  smoking,
  note = 0,
  userRole,
  userId,
  passengerId = 0,
  actionAvatar,
  actionContact,
  actionDelete,
  actionDecline,
  actionPrimary,
  actionPrimaryLabelKey = 'actions.accept',
}: Props) => {
  const { theme } = useTheme();
  const userAge = computeAge(userBirth);

  return (
    <ViewRow style={{ marginBottom: theme.sizings.mediumLarge }}>
      <ViewCol
        style={{
          width: theme.sizings.sz50 + theme.sizings.mediumLarge,
          justifyContent: 'center',
        }}
      >
        <TouchableOpacity onPress={actionAvatar}>
          <Avatar
            sizeName="sz50"
            iconType="FontAwesome5"
            iconColorName={
              userRole === 'passenger'
                ? 'blueHigh'
                : userRole === 'candidate'
                ? 'statusWarningHigh'
                : 'blueHigh'
            }
            iconName={
              userRole === 'passenger'
                ? 'car'
                : userRole === 'candidate'
                ? 'question'
                : 'hand-rock'
            }
            iconPosition="bottomLeft"
            iconSizeName="small"
            image={image}
            isWoman={imageIsWoman}
          />
        </TouchableOpacity>
      </ViewCol>
      <ViewCol size={1}>
        <View style={styles.row}>
          <JText
            isBold
            labelKey="sentences.userNameAge"
            valuesKey={{ name, age: userAge }}
          />
          <Divider vertical />
          <Icon
            type="FontAwesome5"
            colorName={chatting ? 'greenMedium' : 'statusDangerHigh'}
            name="comment"
          />

          <Divider vertical transparent />
          <Icon
            type="FontAwesome5"
            colorName={music ? 'greenMedium' : 'statusDangerHigh'}
            name="music"
          />
          <Divider vertical transparent />

          <Icon
            type="FontAwesome5"
            colorName={smoking ? 'greenMedium' : 'statusDangerHigh'}
            name="smoking"
          />
          <Divider vertical />
          <IconicText
            iconType="AntDesign"
            iconColorName="greenMedium"
            iconName="star"
            label={note > 0 ? `${note}` : '?'}
          />
        </View>

        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          {actionContact && (
            <>
              <ClassicButton
                type="contact"
                onPress={() => actionContact(userId)}
                labelKey={
                  actionDecline && actionPrimary ? undefined : 'actions.contact'
                }
              />
              <Divider
                vertical
                transparent={
                  actionDecline === undefined || actionPrimary === undefined
                }
              />
            </>
          )}

          {actionDecline && (
            <>
              <ClassicButton
                type="decline"
                onPress={actionDecline}
                labelKey="actions.decline"
              />
              <Divider vertical transparent />
            </>
          )}

          {actionDelete && passengerId && (
            <>
              <ClassicButton
                type="decline"
                onPress={() => actionDelete(passengerId)}
                labelKey="actions.cancel"
              />
              <Divider vertical transparent />
            </>
          )}

          {actionPrimary && (
            <ClassicButton
              type="accept"
              onPress={actionPrimary}
              labelKey={actionPrimaryLabelKey}
            />
          )}
        </View>
      </ViewCol>
    </ViewRow>
  );
};
