import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';
import { FormInput } from '../../components/common/FormInput/FormInput';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const FormInputScreen = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [details, setDetails] = useState('');

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="FormInput"
        description="This component renders an input with a title, a subtitle and an icon."
      >
        <FormInput
          title="Email"
          value={email}
          leftIconType="Fontisto"
          leftIconName="email"
          leftIconSizeName="larger"
          onChange={setEmail}
          placeholder="example@email.com"
        />
        <View style={{ height: 20 }} />
        <FormInput
          title="Code de votre entreprise"
          subtitle="Bénéficiez de vos avantages"
          inline
          value={code}
          onChange={setCode}
          keyboardType="number-pad"
        />
        <View style={{ height: 20 }} />
        <FormInput
          title="Autres précisions sur le trajet"
          placeholder="Écrivez quelque chose..."
          value={details}
          onChange={setDetails}
          height={150}
          multiline
        />
      </CatalogHeader>
      <CatalogProps propName="title" type="string" topDivider={false} />
      <CatalogProps propName="subtitle" type="string" />
      <CatalogProps
        propName="leftIconType"
        type="enum('MaterialCommunity', 'AntDesign', 'Entypo', 'EvilIcons', 'Feather', 'FontAwesome', 'FontAwesome5', 'FontAwesome5Pro', 'Fontisto', 'Material', 'MaterialIcons', 'IonIcons')"
      />
      <CatalogProps propName="leftIconName" type="string" />
      <CatalogProps propName="leftIconSize" type="number" />
      <CatalogProps propName="leftIconColor" type="string" />
      <CatalogProps
        propName="inline"
        description="If true, put the header next to the input. Default to false."
        type="boolean"
      />
      <CatalogProps propName="multiline" type="boolean" />
      <CatalogProps propName="placeholder" type="string" />
      <CatalogProps
        propName="value"
        description="The value of the input. Use a useState."
        type="string"
      />
      <CatalogProps
        propName="onChange"
        description="The function modifying the text. Use a useState."
        type="(text: string) => void"
      />
      <CatalogProps
        propName="height"
        description="The height of the input."
        type="number | string"
      />
      <CatalogProps
        propName="keyboardType"
        type="enum('default', 'nunmber-pad', 'decimal-pad', 'numeric', 'email-address', 'phone-pad')"
      />
      <CatalogProps
        propName="editable"
        description="If false, the value is fixed and displayed but the user can't edit it. Default to true."
        type="boolean"
      />
      <CatalogProps propName="maxLength" type="number" />
      <CatalogProps
        propName="secure"
        description="If true, hide the text (like for password). Doesn't work with multiline. Default to false."
        type="boolean"
      />
      <CatalogProps
        propName="selectionColor"
        description="The color of the cursor."
        type="string"
      />
    </ScrollView>
  );
};

export default FormInputScreen;
