import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import io, { Socket } from 'socket.io-client';
// import AsyncStorage from '@react-native-community/async-storage';

import { ROOT_URL } from 'constants/Constants';
import useAuth from 'hooks/useAuth';
import { getStoredData } from 'utils/fnAsyncStorage';
import { useTranslation } from 'react-i18next';
import useAlert from './AlertProvider';
import { I18nKey } from '../../i18n';

type Props = {
  children?: React.ReactNode;
};

const SocketContext = createContext<object | null>(null);

export type Listener = {
  events: string[];
  handleEvent: (notificationString: string, update: object) => void;
};

let listeners = [] as Listener[];

export const addEventListener = (listener: Listener) => {
  listeners.push(listener);
};
export const removeEventListener = (listener: Listener) => {
  listeners = listeners.filter(l => l !== listener);
};

export const SocketProvider = ({ children }: Props) => {
  const [socket, setSocket] = useState<Socket>();
  const { setAlert } = useAlert();
  const { user } = useAuth();
  const { t } = useTranslation();

  const authenticate = useCallback(async () => {
    const token = await getStoredData('token');
    if (socket) {
      socket.emit('authenticate', { token });
    }
  }, [socket]);

  // init socket
  useEffect(() => {
    setSocket(io(ROOT_URL));
  }, []);

  // setAlert not available the first time, so we have to reset events callback
  useEffect(() => {
    if (socket) {
      socket.off('Notification');

      socket.on('Notification', (eventType: string, data: object) => {
        console.log(eventType);
        const translatedEventType = t(
          `notifications.${eventType}` as I18nKey,
        ) as string;
        listeners
          .filter(l => l.events.includes(eventType))
          .forEach(listener => {
            listener.handleEvent(eventType, data);
          });
        setAlert({ message: translatedEventType });
      });
      socket.on('reconnect', async () => {
        authenticate();
      });
    }
  }, [setAlert, user, authenticate, socket, t]);

  // if user is logged in, authenticate socket
  useEffect(() => {
    if (user) {
      authenticate();
    }
  }, [user, authenticate]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <SocketContext.Provider value={{}}>{children}</SocketContext.Provider>;
};

const useSocket = () => useContext(SocketContext);

export default useSocket;
