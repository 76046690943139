import React from 'react';
import { View } from 'react-native';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import useTheme from 'hooks/useTheme';
import { UserActions } from 'components/compositions/UserActions/UserActions';
import { Section } from 'components/common/Section/Section';
import { Application } from 'types/Application';
import { Constants } from 'constants/Constants';
import { SectionProvider } from 'providers/SectionProvider';
import { JText } from 'components/common/Text/Text';
import useAlert from 'providers/AlertProvider';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'CourseDetail'
>;
type Props = {
  rideId: number;
  navigation: any;
  candidates: Application[];
  fetchNewItem: any;
  actionContact: (userId: number) => void;
};

const RideApplications = ({
  rideId,
  navigation,
  candidates,
  fetchNewItem,
  actionContact,
}: Props) => {
  const { theme } = useTheme();

  const { createPassenger, declineCandidature } = SectionProvider();
  const { setAlert } = useAlert();
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();

  const handleAccept = (candidature: number) => {
    const accept = async () => {
      await createPassenger(candidature);
      fetchNewItem();
      setAlert({
        message: t('alert.candidatureAcceptedDone'),
        title: t('alertTitle.candidatureAcceptedDone'),
      });
    };
    accept();
  };

  const handleDelete = (candidature: number) => {
    const cancel = async () => {
      await declineCandidature(candidature);
      fetchNewItem();
      setAlert({
        message: t('alert.candidatureRefusedDone'),
        title: t('alertTitle.candidatureRefusedDone'),
      });
    };
    cancel();
  };

  return (
    <Section
      title={`${candidates.length} candidature(s)`}
      style={{
        marginTop: theme.sizings.medium,
        marginBottom: theme.sizings.medium,
      }}
    >
      <View style={{ marginTop: theme.sizings.mediumLarge }}>
        {candidates.length ? (
          candidates.map((application: Application, index: number) => {
            const key = `candidat-${index}`;
            const { user } = application;
            const userId = application?.user?.id;
            const candidatureId = application?.id;

            return (
              <View key={key}>
                <UserActions
                  name={user.username || user.firstname}
                  userBirth={user.birthDate}
                  userRole="candidate"
                  imageIsWoman={user.gender === 'W'}
                  chatting={user.chatting}
                  music={user.music}
                  smoking={user.smoking}
                  image={user.profilePicture}
                  note={0} // todo
                  actionContact={
                    userId !== currentUser?.id ? actionContact : undefined
                  }
                  userId={userId}
                  actionDecline={() => {
                    handleDelete(Number(candidatureId));
                  }}
                  actionPrimary={() => {
                    handleAccept(Number(candidatureId));
                  }}
                  actionAvatar={() =>
                    navigation.navigate('ThirdPartyProfile', {
                      profileId: userId,
                    })
                  }
                />
              </View>
            );
          })
        ) : (
          <JText centered labelKey="courses.noCandidatureYet" />
        )}
      </View>
    </Section>
  );
};

export default RideApplications;
