import React, { useEffect } from 'react';
import { View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { useAuth } from 'hooks/useAuth';
import { LoginForm } from 'components/forms/LoginForm/LoginForm';
import { FormLayout } from 'components/layouts/FormLayout/FormLayout';
import { BackgroundLayout } from 'components/layouts/BackgroundLayout/BackgroundLayout';
import useTheme from 'hooks/useTheme';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import { useTranslation } from 'react-i18next';
import { SafeAreaView } from 'react-native-safe-area-context';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import fnStyles from '../AuthGlobalStyle';
import useAlert from '../../../providers/AlertProvider';

export type ScreenProps = NativeStackScreenProps<RootStackParamList, 'Login'>;

const LoginScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopStyles = fnDesktopStyles(theme);
  const desktopMode = isDesktopMode();
  const { setAlert } = useAlert();
  const { t } = useTranslation();
  const styles = fnStyles(theme);

  const { signin, isSignInError: isFetchingError } = useAuth();

  const handleCreateAccountPressed = () => {
    navigation.navigate('Register');
  };

  const handleAboutPressed = () => {
    navigation.navigate('About');
  };

  const handleForgotPasswordPressed = () => {
    navigation.navigate('ForgotPassword');
  };

  const handleLogin = async (email: string, password: string) => {
    const isUserLogged = await signin(email, password);

    if (typeof isUserLogged === 'object' && 'id' in isUserLogged) {
      navigation.navigate('Root');
    }
  };

  useEffect(() => {
    if (isFetchingError) {
      setAlert({
        color: theme.colors.statusRed,
        message: t('alert.invalidCredentials'),
        title: t('alertTitle.invalidCredentials'),
      });
    }
  }, [isFetchingError, setAlert, t, theme]);

  return (
    <BackgroundLayout padding>
      <SafeAreaView style={{ flex: 1 }}>
        <View
          style={[
            desktopMode && desktopStyles.boxed,
            { marginTop: desktopMode ? theme.sizings.mediumLarge : undefined },
          ]}
        >
          <FormLayout style={styles.form}>
            <LoginForm
              onSubmit={handleLogin}
              handleForgotPasswordPressed={handleForgotPasswordPressed}
            />
            <View style={styles.footer}>
              <GradientButton
                labelKey="auth.noAccountYet"
                style={styles.button}
                onPress={handleCreateAccountPressed}
                leftIconType="FontAwesome5"
                leftIconName="pen"
                leftIconSizeName="larger"
              />

              <GradientButton
                labelKey="auth.about"
                style={styles.button}
                onPress={handleAboutPressed}
                colors={[theme.colors.blueMedium, theme.colors.blueHigh]}
                leftIconType="FontAwesome5"
                leftIconName="info"
                leftIconSizeName="larger"
              />
            </View>
          </FormLayout>
        </View>
      </SafeAreaView>
    </BackgroundLayout>
  );
};

export default LoginScreen;
