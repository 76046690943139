import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import React from 'react';
import { ImageSourcePropType, TouchableOpacity } from 'react-native';
import { JText } from 'components/common/Text/Text';
import { ArrowRight } from 'components/common/ArrowRight/ArrowRight';
import { ColorType } from 'providers/ThemeProvider';
import useTheme from 'hooks/useTheme';
import { Avatar } from 'components/common/Avatar/Avatar';
import { StrapiFile } from 'types/StrapiFile';
import { Icon } from 'components/common/Icon/Icon';
import fnStyles from './NotificationItemStyle';
import { I18nKey } from '../../../../i18n';

type Props = {
  onPress?: () => void;
  iconName?: string;
  iconColorName?: ColorType;
  backgroundColorName?: ColorType;
  messageHeadKey?: I18nKey;
  messageHeadValues?: object;
  messageActionKey?: I18nKey;
  avatar?: ImageSourcePropType | StrapiFile;
  avatarIsWoman?: boolean;
  userId?: number;
  navigation: any;
};

export const NotificationItem = ({
  onPress,
  iconName = 'information',
  iconColorName = 'blueHigh',
  backgroundColorName = 'blueLight',
  messageHeadKey,
  messageHeadValues,
  messageActionKey,
  avatar,
  avatarIsWoman = false,
  userId,
  navigation,
}: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  return (
    <ViewRow
      style={[
        styles.container,
        { backgroundColor: theme.colors[backgroundColorName] },
      ]}
    >
      <ViewCol
        style={{
          width: theme.sizings.sz50 + theme.sizings.mediumLarge,
          justifyContent: 'center',
        }}
      >
        <TouchableOpacity
          onPress={() => {
            /* navigation.navigate('ThirdPartyProfile', {
              profileId: userId,
            }); */
          }}
        >
          {avatar ? (
            <Avatar
              sizeName="sz50"
              iconSizeName="large"
              iconType="FontAwesome5"
              iconColorName={iconColorName}
              iconName={iconName}
              iconPosition="topRight"
              image={avatar}
              isWoman={avatarIsWoman}
            />
          ) : (
            <Icon
              sizeName="fs30"
              type="FontAwesome5"
              colorName={iconColorName}
              name={iconName}
            />
          )}
        </TouchableOpacity>
      </ViewCol>

      <ViewCol size={1} style={{ justifyContent: 'center' }}>
        {onPress ? (
          <TouchableOpacity onPress={onPress}>
            <JText labelKey={messageHeadKey} valuesKey={messageHeadValues} />
            {messageActionKey && <JText labelKey={messageActionKey} isBold />}
          </TouchableOpacity>
        ) : (
          <>
            <JText labelKey={messageHeadKey} valuesKey={messageHeadValues} />
            {messageActionKey && <JText labelKey={messageActionKey} isBold />}
          </>
        )}
      </ViewCol>

      {onPress && <ArrowRight colorName={iconColorName} onPress={onPress} />}
    </ViewRow>
  );
};
