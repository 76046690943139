import { Card } from 'components/common/Card/Card';
import { FormControl } from 'components/common/FormControl/FormControl';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import { Section } from 'components/common/Section/Section';
import { JText } from 'components/common/Text/Text';
import {
  FieldHelpModal,
  FieldHelpWrap,
} from 'components/compositions/FieldHelp/FieldHelp';
import useAuth from 'hooks/useAuth';
import useTheme from 'hooks/useTheme';
import useAlert from 'providers/AlertProvider';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import fetchJSON from 'utils/fetchJSON';
import { removeStoredData, storeObjectData } from 'utils/fnAsyncStorage';
import * as Linking from 'expo-linking';
import ResponsiveScrollView from 'components/layouts/ResponsiveScrollView/ResponsiveScrollView';
import { TabsBackground } from 'components/layouts/BackgroundLayout/TabsBackgound';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';

const ParametersScreen = () => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const desktopStyles = fnDesktopStyles(theme);
  const { user: currentUser } = useAuth();
  const { setAlert } = useAlert();
  const { t } = useTranslation();

  const onSubmit = async (values: any) => {
    let submitable = true;

    if (values.password) {
      if (
        values.password.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/,
        ) === null
      ) {
        submitable = false;
        setAlert({
          color: theme.colors.statusRed,
          message: t('auth.passwordRulesList'),
          title: t('alertTitle.beCareful'),
        });
      }

      if (values.password !== values.newPassword) {
        submitable = false;
        setAlert({
          color: theme.colors.statusRed,
          message: t('auth.passwordsDoNotMatch'),
          title: t('alertTitle.beCareful'),
        });
      }
    }

    if (submitable) {
      try {
        const res = await fetchJSON({
          url: `users/${values.id}`,
          method: 'PUT',
          payload: values,
        });
        if (res) {
          await removeStoredData('user');
          await storeObjectData('user', res);

          setAlert({
            message: t('alert.profileUpdated'),
            title: t('alertTitle.success'),
          });
        }
      } catch (error) {
        // TODO: afficher modale erreur
        console.log(error);
      }
    }
  };

  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);

  return (
    <>
      <TabsBackground />
      <ResponsiveScrollView>
        <View
          style={[
            {
              padding: theme.sizings.mediumLarge,
              flex: 1,
            },
            desktopMode && desktopStyles.boxed,
          ]}
        >
          {currentUser && (
            <Form
              onSubmit={onSubmit}
              initialValues={currentUser}
              render={({ handleSubmit, values }) => (
                <Card style={{ marginBottom: theme.sizings.mediumLarge * 2 }}>
                  <FormControl name="email" titleKey="forms.email" />

                  <FormControl name="phoneNumber" titleKey="forms.phone" />

                  <FieldHelpWrap
                    onHelpInfoPress={() => {
                      setIsPasswordModalVisible(true);
                    }}
                  >
                    <FormControl
                      name="password"
                      secure
                      titleKey="forms.newPassword"
                    />
                  </FieldHelpWrap>

                  <FormControl
                    name="newPassword"
                    titleKey="forms.confirmNewPassword"
                    secure
                  />

                  <Section title="Parrainage" />

                  <FormControl
                    name="refferalCode"
                    titleKey="forms.sponsorshipKey"
                    subtitleKey="forms.sponsorshipKeyLegend"
                    keyboardType="number-pad"
                    inline
                  />

                  {currentUser.refferalCode && (
                    <>
                      <JText isBold labelKey="forms.sponsorshipKeyDone" />
                      <JText isBold label={currentUser.refferalCode} />
                      <JText labelKey="forms.sponsorshipKeyDoneLegend" />
                    </>
                  )}

                  <View
                    style={{
                      marginTop: theme.sizings.mediumLarge,
                      width: '100%',
                    }}
                  >
                    <GradientButton
                      onPress={handleSubmit}
                      labelKey="add.parameters"
                      leftIconType="AntDesign"
                      leftIconName="check"
                      leftIconSizeName="largest"
                      rightIconType="AntDesign"
                      rightIconName="right"
                      rightIconSizeName="largest"
                    />

                    <GradientButton
                      labelKey="common.legals"
                      onPress={() => {
                        Linking.openURL('http://ciligo.fr/cgu.html');
                      }}
                      colors={[theme.colors.blueMedium, theme.colors.blueHigh]}
                      leftIconType="FontAwesome5"
                      leftIconName="info"
                      leftIconSizeName="larger"
                      style={{ marginTop: theme.sizings.mediumLarge }}
                    />
                  </View>
                </Card>
              )}
            />
          )}
        </View>
      </ResponsiveScrollView>

      <FieldHelpModal
        isVisible={isPasswordModalVisible}
        onClose={() => setIsPasswordModalVisible(false)}
        titleKey="auth.passwordRules"
        contentKey="auth.passwordRulesList"
      />
    </>
  );
};

export default ParametersScreen;
