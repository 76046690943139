import React, { Suspense } from 'react';
import { Text } from 'react-native';
import { AuthProvider } from 'providers/AuthProvider';
import { LoadingProvider } from 'providers/LoadingProvider';
import { ThemeProvider } from 'providers/ThemeProvider';
import { AlertProvider } from 'providers/AlertProvider';
import { NotificationProvider } from 'providers/NotificationProvider';
import AppStateHandler from 'providers/AppStateHandler';
import { SocketProvider } from 'providers/SocketProvider';

type Props = {
  children: JSX.Element;
};

const AppProvider = ({ children }: Props) => (
  <Suspense fallback={<Text />}>
    <ThemeProvider>
      <LoadingProvider>
        <AlertProvider>
          <NotificationProvider>
            <AuthProvider>
              <SocketProvider>
                <AppStateHandler>{children}</AppStateHandler>
              </SocketProvider>
            </AuthProvider>
          </NotificationProvider>
        </AlertProvider>
      </LoadingProvider>
    </ThemeProvider>
  </Suspense>
);

export default AppProvider;
