import { API_URL } from 'constants/Constants';
import { getStoredData } from './fnAsyncStorage';

type Props = {
  url: string;
  method: string;
  payload?: object | undefined;
  signal?: AbortSignal | null | undefined;
};

export const fetchJSON = async ({ url, method, payload, signal }: Props) => {
  const apiUrl = API_URL;
  const token = await getStoredData('token');

  const headers = new Headers();
  let baseUrl = url;
  if (!url.includes('http')) {
    baseUrl = `${apiUrl}/${url}`;
    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }
  }

  headers.append('Content-Type', 'application/json');

  const res = await fetch(baseUrl, {
    method,
    headers,
    signal,
    body: JSON.stringify(payload),
  });

  if (!res.ok) {
    let message = await res.text();

    try {
      message = JSON.parse(message).message;
    } catch (e) {
      // Not JSON
    }
    throw new Error(message);
  }

  return res.json();
};

export default fetchJSON;
