import { Avatar } from 'components/common/Avatar/Avatar';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import React from 'react';
import { ImageSourcePropType, TouchableOpacity, View } from 'react-native';
import { Types } from 'types/Types';
import { Icon } from 'components/common//Icon/Icon';
import { JText } from 'components/common//Text/Text';
import { ArrowRight } from 'components/common/ArrowRight/ArrowRight';
import { useTranslation } from 'react-i18next';
import useTheme from 'hooks/useTheme';
import dayjs from 'dayjs';
import { StrapiFile } from 'types/StrapiFile';
import { InlineRating } from '../../common/InlineRating/InlineRating';
import fnStyles from './ReviewPreviewStyle';

type Props = {
  onPress: () => void;
  reviewerWoman: boolean;
  reviewerRole: Types['role'];
  reviewerName: string;
  reviewerNote?: number;
  reviewTitle: string;
  reviewNote: number;
  targetName: string;
  targetRole: Types['role'];
  date?: Date;
  vehicle?: string;
  image?: ImageSourcePropType | StrapiFile;
};

export const ReviewPreview = ({
  onPress,
  reviewerWoman,
  reviewerRole,
  reviewerName,
  reviewerNote,
  reviewTitle,
  reviewNote,
  targetName,
  targetRole,
  date,
  vehicle,
  image,
}: Props) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const prettyDate = dayjs(date).fromNow();

  return (
    <TouchableOpacity onPress={onPress} style={{ width: '100%' }}>
      <View style={styles.container}>
        <ViewRow>
          <ViewCol style={{ width: 60 + theme.sizings.mediumLarge }}>
            <Avatar
              sizeName="sz60"
              iconType="FontAwesome5"
              iconColorName="blueHigh"
              iconName={
                reviewerRole === 'passenger'
                  ? 'car'
                  : reviewerRole === 'driver'
                  ? 'hand-rock'
                  : 'question'
              }
              iconPosition="bottomLeft"
              image={image}
              isWoman={reviewerWoman}
            />
          </ViewCol>

          <ViewCol size={1}>
            <JText isBold label={reviewTitle} colorName="blueHigh" />
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              <Icon
                type="FontAwesome5"
                name={
                  targetRole === 'passenger'
                    ? 'car'
                    : targetRole === 'driver'
                    ? 'hand-rock'
                    : 'question'
                }
                sizeName="medium"
                colorName="blueHigh"
                style={{
                  paddingTop: theme.sizings.small,
                  marginRight: theme.sizings.small,
                }}
              />

              <JText
                labelKey="sentences.userRole"
                valuesKey={{
                  name: targetName,
                  role: t(`courses.${targetRole}`),
                }}
                colorName="greyMedium"
              />
              {vehicle && (
                <JText label={` (${vehicle})`} colorName="greyMedium" />
              )}
            </View>
            <InlineRating
              note={reviewNote}
              size={theme.fontSizes.large}
              colorName="greenHigh"
            />
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              <JText
                labelKey="sentences.postedBy"
                valuesKey={{
                  name: reviewerName,
                  note: reviewerNote || '?',
                  date: prettyDate,
                }}
                colorName="greyMedium"
              />
            </View>
          </ViewCol>

          <ArrowRight />
        </ViewRow>
      </View>
    </TouchableOpacity>
  );
};
