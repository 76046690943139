import { useState } from 'react';

const useForgotPasswordForm = (callback: (email: string) => Promise<void>) => {
  const [email, setEmail] = useState('');

  const onSubmit = async () => {
    await callback(email).then(() => {});
  };

  return {
    email,
    setEmail,
    onSubmit,
  };
};

export default useForgotPasswordForm;
