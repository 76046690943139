import React, { useEffect, useState } from 'react';
import { View, Dimensions } from 'react-native';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { BackgroundLayout } from 'components/layouts/BackgroundLayout/BackgroundLayout';
import Wizard from 'components/layouts/Wizard/Wizard';
import WizardStep from 'components/layouts/Wizard/WizardStep';
import { Step, Alert, SearchParams } from 'types';
import useTheme from 'hooks/useTheme';
import { JText } from 'components/common/Text/Text';
import { Form } from 'react-final-form';
import moment from 'moment';
import { fetchJSON } from 'utils/fetchJSON';
import { FormControl } from 'components/common/FormControl/FormControl';
import { useTranslation } from 'react-i18next';

import fnDesktopStyles from 'utils/desktopModeStyle';
import isDesktopMode from 'utils/desktopMode';
import { I18nKey } from '../../../../i18n';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'CourseSearch'
>;

const CourseSearchScreen = ({ route, navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopStyles = fnDesktopStyles(theme);
  const desktopMode = isDesktopMode();

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useState<
    | (SearchParams & {
        departureDate: moment.Moment;
        departureHour: moment.Moment;
      })
    | undefined
  >();
  const [isCreating, setIsCreating] = useState<boolean | undefined>(false);

  useEffect(() => {
    if (route.params) {
      if (route.params.searchParams) {
        setSearchParams({
          ...route.params.searchParams,
          departureDate: moment(route.params.searchParams.departureTime),
          departureHour: moment(route.params.searchParams.departureTime),
        });
      }
      if (route.params.isCreating) {
        setIsCreating(route.params.isCreating);
      }
    }
  }, [route.params]);

  const requiredField = (value: any) => {
    return value ? undefined : t('forms.requiredField');
  };

  const step1 = () => (
    <>
      <FormControl
        inline
        name="departureLocation"
        titleKey="forms.from"
        leftIconColorName="blueHigh"
        leftIconType="FontAwesome5"
        leftIconName="map-marker"
        type="autocomplete"
        autoCompleteUrl="cities"
      />
      <FormControl
        inline
        name="arrivalLocation"
        titleKey="forms.to"
        leftIconColorName="blueHigh"
        leftIconType="FontAwesome5"
        leftIconName="map-marker"
        type="autocomplete"
        autoCompleteUrl="cities"
      />
    </>
  );

  const step2 = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isTablet = Dimensions.get('window').width > 767;
    const isHorizontal =
      Dimensions.get('window').width > Dimensions.get('window').height;

    return (
      <>
        <View style={{ marginBottom: theme.sizings.mediumLarge }}>
          <FormControl
            name="departureDate"
            type="calendar"
            // titleKey="forms.startHour"
          />
        </View>

        <View
          style={{
            marginVertical: theme.sizings.mediumLarge,
            width:
              isTablet || isHorizontal
                ? Dimensions.get('window').height * 0.5 -
                  theme.sizings.mediumLarge
                : '100%',
          }}
        >
          <FormControl
            name="departureHour"
            type="time"
            titleKey="forms.startHour"
            leftIconColorName="blueHigh"
            leftIconType="FontAwesome5"
            leftIconName="clock"
          />
        </View>
      </>
    );
  };

  const step3 = (values: any) => {
    return (
      <>
        <FormControl
          name="seats"
          type="select-list"
          titleKey="forms.passengersNumber"
          values={[1, 2, 3, 4, 5, 6, 7, 8]}
          selectedValue={values.seats}
          required
          validate={requiredField}
        />

        <JText isBold labelKey="section.duringCourse" />

        <FormControl
          name="music"
          type="card-check"
          rightIconName="music"
          labelKey="forms.musicDegree"
        />
        <FormControl
          name="pets"
          type="card-check"
          rightIconName="paw"
          labelKey="forms.petDegree"
        />
        <FormControl
          name="smoking"
          type="card-check"
          rightIconName="smoking"
          labelKey="forms.smokeDegree"
          rightIconColorName="statusRed"
        />
        <FormControl
          name="airConditioner"
          type="card-check"
          labelKey="forms.airConditioner"
        />
        <FormControl
          name="heatedSeats"
          type="card-check"
          labelKey="forms.heatedSeat"
        />
      </>
    );
  };

  const steps = (values: any): Step[] => {
    return [
      {
        id: 1,
        name: 'step1',
        iconName: 'map-marker',
        labelKey: 'section.placeFromTo',
        fields: step1(),
      },
      {
        id: 2,
        name: 'step2',
        iconName: 'calendar',
        labelKey: 'section.startDate',
        fields: step2(),
      },
      {
        id: 3,
        name: 'step3',
        iconName: 'cog',
        labelKey: 'section.otherDetails',
        fields: step3(values),
      },
    ];
  };
  const [currentStep, setcurrentStep] = useState(1);

  const handleChangeStep = async (target: number) => {
    setcurrentStep(target);
  };

  const onSubmit = async (values: any) => {
    const payload = values;
    let date;
    let hours;

    try {
      date = payload.departureDate
        ? payload.departureDate.format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');
      hours = payload.departureHour
        ? payload.departureHour.format('HH:mm:ss')
        : moment().format('HH:mm:ss');
      delete payload.departureHour;

      payload.departureTime = moment(`${date} ${hours}`);
      payload.departureDate = moment(`${date} ${hours}`).toDate();

      if (isCreating || (searchParams && searchParams.id)) {
        if (searchParams) {
          const res = await fetchJSON({
            url: `alerts/${payload.id}`,
            method: 'PUT',
            payload: { data: payload },
          });
          // TODO : Gestion du retour de res
        } else {
          const res = await fetchJSON({
            url: 'alerts',
            method: 'POST',
            payload: { data: payload },
          });
          if (res) {
            navigation.reset({
              index: 0,
              routes: [
                { name: 'Root', state: { routes: [{ name: 'Alert' }] } },
              ],
            });
            return;
          }
        }
      }
      navigation.navigate('CourseResults', { searchParams: payload });
    } catch (error) {
      // TODO: afficher modale erreur
      console.log(error);
    }
  };

  const handleClick = async (origin: any, destination: any) => {
    const result = await fetchJSON({
      url: `distance?origin=place_id:${origin.place_id}&destination=place_id:${destination.place_id}`,
      method: 'GET',
    });
    console.log(result);
  };

  // TODO : Améliorer les contraintes méta
  return (
    <BackgroundLayout padding>
      <View style={desktopMode && desktopStyles.boxed}>
        <Form
          onSubmit={onSubmit}
          initialValues={searchParams}
          render={({ handleSubmit, values }) => (
            <Wizard
              steps={steps(values)}
              currentStep={currentStep}
              changeStep={handleChangeStep}
              onSubmit={handleSubmit}
            >
              {steps(values).map(
                step =>
                  currentStep === step.id && (
                    <WizardStep
                      labelKey={step.labelKey as I18nKey}
                      iconName={step.iconName}
                      key={step.id}
                    >
                      {step.fields}
                    </WizardStep>
                  ),
              )}
            </Wizard>
          )}
        />
      </View>
    </BackgroundLayout>
  );
};

export default CourseSearchScreen;
