import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { BackgroundLayout } from 'components/layouts/BackgroundLayout/BackgroundLayout';
import TextButtonScreen from './TextButtonScreen';
import ClassicButtonScreen from './ClassicButtonScreen';
import GradientButtonScreen from './GradientButtonScreen';
import ClickableIconScreen from './ClickableIconScreen';
import IconScreen from './IconScreen';
import IconicTextScreen from './IconicTextScreen';
import CardScreen from './CardScreen';
import FormInputScreen from './FormInputScreen';
import SelectableOptionsListScreen from './SelectableOptionsListScreen';
import SelectableOptionButtonScreen from './SelectableOptionButtonScreen';
import SectionScreen from './SectionScreen';
import TileButtonScreen from './TileButtonScreen';
import AvatarScreen from './AvatarScreen';
import PinScreen from './PinScreen';
import CircularButtonScreen from './CircularButtonScreen';
import CircularIconScreen from './CircularIconScreen';
import RatingScreen from './RatingScreen';
import CheckBoxScreen from './CheckBoxScreen';
import CircularIconRowScreen from './CircularIconRowScreen';
import DocumentScreen from './DocumentScreen';
import MapScreen from './MapScreen';
import CircularIconStatusScreen from './CircularIconStatusScreen';
import CalendarScreen from './CalendarScreen';

const Tab = createMaterialTopTabNavigator();

const CatalogScreen = () => {
  const insets = useSafeAreaInsets();

  return (
    <BackgroundLayout>
      <Tab.Navigator
        initialRouteName="Map"
        screenOptions={{
          tabBarStyle: {
            backgroundColor: 'white',
            marginTop: insets.top,
          },
          tabBarLabelStyle: {
            fontSize: 12,
          },
          tabBarScrollEnabled: true,
        }}
      >
        <Tab.Screen name="Calendar" component={CalendarScreen} />
        <Tab.Screen name="Map" component={MapScreen} />
        <Tab.Screen name="Icon" component={IconScreen} />
        <Tab.Screen name="CircularIcon" component={CircularIconScreen} />
        <Tab.Screen name="ClickableIcon" component={ClickableIconScreen} />
        <Tab.Screen
          name="CircularIconStatusScreen"
          component={CircularIconStatusScreen}
        />
        <Tab.Screen name="CircularIconRow" component={CircularIconRowScreen} />
        <Tab.Screen name="Document" component={DocumentScreen} />
        <Tab.Screen name="IconicText" component={IconicTextScreen} />
        <Tab.Screen name="TextButton" component={TextButtonScreen} />
        <Tab.Screen name="ClassicButton" component={ClassicButtonScreen} />
        <Tab.Screen name="GradientButton" component={GradientButtonScreen} />
        <Tab.Screen name="CircularButton" component={CircularButtonScreen} />
        <Tab.Screen name="Card" component={CardScreen} />
        <Tab.Screen name="FormInput" component={FormInputScreen} />
        <Tab.Screen
          name="SelectableOptionButton"
          component={SelectableOptionButtonScreen}
        />
        <Tab.Screen
          name="SelectableOptionsList"
          component={SelectableOptionsListScreen}
        />
        <Tab.Screen name="Section" component={SectionScreen} />
        <Tab.Screen name="TileButton" component={TileButtonScreen} />
        <Tab.Screen name="Pin" component={PinScreen} />
        <Tab.Screen name="Avatar" component={AvatarScreen} />
        <Tab.Screen name="Rating" component={RatingScreen} />
        <Tab.Screen name="CheckBox" component={CheckBoxScreen} />
      </Tab.Navigator>
    </BackgroundLayout>
  );
};

export default CatalogScreen;
