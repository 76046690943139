import React from 'react';
import useTheme from 'hooks/useTheme';
import isDesktopMode from 'utils/desktopMode';
import { Image, ImageBackground, Platform, View } from 'react-native';
import headerImage from '../../../assets/header.png';
import logoCiligo from '../../../assets/ciligo-logo.png';

export const HeaderLogo = () => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  return (
    <Image
      source={logoCiligo}
      style={{
        height:
          desktopMode || Platform.OS === 'web'
            ? theme.normalize(50)
            : theme.headerSizes.mobile,
        width: theme.normalize(130),
        position: 'relative',
        top:
          desktopMode || Platform.OS === 'web'
            ? undefined
            : -theme.sizings.large,
      }}
      resizeMode="contain"
    />
  );
};

const HeaderShape = () => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();

  return desktopMode ? (
    <View
      style={{
        backgroundColor: theme.colors.light,
        height: theme.headerSizes.desktop,
        shadowColor: theme.colors.shadow,
        shadowOpacity: 0.4,
        shadowRadius: theme.normalize(2),
      }}
    />
  ) : (
    <ImageBackground
      source={headerImage}
      style={{
        width: theme.screen.width,
        height: theme.headerSizes.mobile,
        left: 0,
        top: 0,
        zIndex: -1,
      }}
    />
  );
};

export default HeaderShape;
