import { ArrowRight } from 'components/common/ArrowRight/ArrowRight';
import { Avatar } from 'components/common/Avatar/Avatar';
import { Divider } from 'components/common/Divider/Divider';
import { Pin } from 'components/common/Pin/Pin';
import { JText } from 'components/common/Text/Text';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import dayjs from 'dayjs';
import useTheme from 'hooks/useTheme';
import React from 'react';
import { ImageSourcePropType, TouchableOpacity, View } from 'react-native';
import { StrapiFile } from 'types/StrapiFile';

type Props = {
  onAvatarPress: () => void;
  onMessagePress: () => void;
  user: string;
  date: Date;
  image?: ImageSourcePropType | StrapiFile;
  message: string;
  unreadCounter?: number;
  lastIndex?: boolean;
};

const MessageItem = ({
  onAvatarPress,
  onMessagePress,
  user,
  date,
  image,
  message,
  unreadCounter = 0,
  lastIndex = false,
}: Props) => {
  const { theme } = useTheme();

  return (
    <>
      <ViewRow>
        <ViewCol
          style={{ width: theme.sizings.sz60 + theme.sizings.mediumLarge }}
        >
          <TouchableOpacity onPress={onAvatarPress}>
            <Avatar sizeName="sz60" image={image} />
          </TouchableOpacity>
        </ViewCol>
        <ViewCol size={1} style={{ justifyContent: 'center' }}>
          <TouchableOpacity onPress={onMessagePress}>
            <ViewRow>
              {unreadCounter > 0 && (
                <ViewCol
                  style={{
                    width: theme.normalize(50),
                  }}
                >
                  <Pin
                    // label={`${unreadCounter}`}
                    iconName="envelope"
                    iconType="FontAwesome5"
                    isBadge
                  />
                </ViewCol>
              )}
              <ViewCol size={1} style={{ justifyContent: 'center' }}>
                <JText
                  isBold={unreadCounter > 0}
                  labelKey="sentences.lastMessage"
                  valuesKey={{
                    name: user,
                    date: dayjs(date).fromNow(),
                  }}
                  style={{
                    marginLeft:
                      unreadCounter > 0 ? theme.sizings.smallMedium : undefined,
                  }}
                />
              </ViewCol>
            </ViewRow>

            <JText
              label={message}
              isBold={unreadCounter > 0}
              colorName={unreadCounter ? 'dark' : 'greyMedium'}
              style={{ marginTop: theme.sizings.small }}
            />
          </TouchableOpacity>
        </ViewCol>
        <ArrowRight onPress={onMessagePress} />
      </ViewRow>
      {!lastIndex && <Divider />}
    </>
  );
};

export default MessageItem;
