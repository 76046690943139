import React from 'react';
import { View, ScrollView } from 'react-native';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { getStatusBarHeight } from 'react-native-status-bar-height';
import useTheme from 'hooks/useTheme';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import CourseHeader from 'components/compositions/CourseHeader/CourseHeader';
import computeAge from 'utils/computeAge';
import { SectionProvider } from 'providers/SectionProvider';
import useItem from 'hooks/useItem';
import { Ride, User } from 'types';
import { populateUserSummary } from 'utils/populateModels';
import useAuth from 'hooks/useAuth';
import { fetchJSON } from 'utils/fetchJSON';
import useAlert from 'providers/AlertProvider';
import { useTranslation } from 'react-i18next';
import useList from 'hooks/useList';
import { Conversation } from 'types/Conversation';
import dayjs from 'dayjs';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import RideDescription from './modules/RideDescription';
import RidePassengers from './modules/RidePassengers';
import RideFeeds from './modules/RideFeeds';
import RideApplications from './modules/RideApplications';
import RideCode from './modules/RideCode';
import { ViewCol } from '../../../components/layouts/FlexLayout/FlexViews';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'CourseDetail'
>;

// PAGE POUR LES CONDUCTEURS

const CourseDetailScreen = ({ navigation, route }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const desktopStyles = fnDesktopStyles(theme);
  const { setAlert } = useAlert();
  const { t } = useTranslation();

  /* course */
  const { rideId } = route.params;
  const { item: ride, fetchItem: fetchRideItem } = useItem<Ride>(
    `rides`,
    rideId,
    {
      listenToEvents: [
        'new_comment',
        'new_comment_liked',
        'passenger_canceled',
        'new_application',
        'application_accepted',
        'application_refused',
        'application_canceled',
        'ride_updated',
      ], // TODO work but can be optimized
      populate: {
        parent: {
          populate: '*',
        },
        departureLocation: {
          populate: '*',
        },
        arrivalLocation: {
          populate: '*',
        },
        driver: populateUserSummary,
        vehicle: {
          populate: 'picture',
        },
        feeds: {
          // exemple sort: ['name:ASC'],
          populate: {
            comments: {
              populate: {
                responseOf: {
                  populate: '*',
                },
                user: populateUserSummary,
              },
            },
          },
        },
        applications: {
          populate: {
            user: populateUserSummary,
            section: {
              fields: ['id'],
            },
          },
        },
        passengers: {
          populate: {
            user: populateUserSummary,
            section: {
              populate: '*',
            },
          },
        },
        sections: {
          populate: '*',
        },
      },
    },
  );

  const { finishRide, startRide } = SectionProvider();

  /* user & driver */
  const { user: currentUser } = useAuth();
  const currentDriver = ride?.driver;

  /* commentaires */
  const feeds = ride?.feeds;

  /* contact */
  const { items: conversations } = useList<Conversation>('conversations', {
    populate: {
      users: populateUserSummary,
    },
    listenToEvents: ['new_message', 'new_conversation'],
    defaultFilters: {
      users: [currentUser?.id],
    },
    defaultSort: 'updatedAt:DESC',
  });

  const handleContact = (userId: number) => {
    const conversation =
      conversations?.find((c: Conversation) =>
        c.users.find((user: User) => user.id === userId),
      )?.id || null;

    // console.log(conversation, userId);

    navigation.navigate('Chat', {
      conversation,
      correspondantUser: userId,
    });
  };

  /* geo */

  const points = [];

  if (ride) {
    points.push({
      id: ride.departureLocation?.id,
      name: ride.departureLocation?.name,
      longitude: ride.departureLocation?.longitude,
      latitude: ride.departureLocation?.latitude,
    });
    points.push({
      id: ride.arrivalLocation?.id,
      name: ride.arrivalLocation?.name,
      longitude: ride.arrivalLocation?.longitude,
      latitude: ride.arrivalLocation?.latitude,
    });
  }

  const handleStartRide = async () => {
    await startRide(rideId);
    fetchRideItem(rideId);
    setAlert({
      message: t('alert.rideFinished'),
      title: t('alertTitle.rideFinished'),
    });
  };

  const handleFinishRide = async () => {
    await finishRide(rideId);
    fetchRideItem(rideId);
    setAlert({
      message: t('alert.rideFinished'),
      title: t('alertTitle.rideFinished'),
    });
  };

  const handleDeleteRide = async () => {
    if (ride) {
      await fetchJSON({
        url: `rides/${ride.id}`,
        method: 'DELETE',
      });
      navigation.reset({
        index: 0,
        routes: [{ name: 'Root' }],
      });
      setAlert({
        message: t('alert.rideDeleted'),
        title: t('alertTitle.rideDeleted'),
      });
    }
  };

  return (
    <View
      style={{
        backgroundColor: theme.colors.light,
        flex: 1,
      }}
    >
      <ScrollView>
        <View
          style={[
            {
              flex: 1,
              width: '100%',
              paddingTop: getStatusBarHeight(),
            },
            desktopMode && desktopStyles.boxed,
          ]}
        >
          {ride && (
            <CourseHeader
              labelKey="courses.iDrive"
              labelValues={{
                name: currentDriver?.username || currentDriver?.firstname,
                age: computeAge(currentDriver?.birthDate),
              }}
              points={points}
              image={currentDriver?.profilePicture}
              imageIsWoman={currentDriver?.gender === 'W'}
            />
          )}
          {currentUser && currentDriver && ride && rideId && (
            <View style={{ padding: theme.sizings.mediumLarge }}>
              {ride?.status === 'incoming' && (
                <RideApplications
                  navigation={navigation}
                  candidates={ride.applications}
                  rideId={rideId}
                  fetchNewItem={fetchRideItem}
                  actionContact={handleContact}
                />
              )}

              <RidePassengers
                course={ride}
                rideId={rideId}
                navigation={navigation}
                passengers={ride.passengers}
                amDriver
                actionContact={handleContact}
              />

              {ride.code &&
                (ride.status === 'finished' || ride.status === 'in_progress') &&
                ride.passengers.length > 0 && <RideCode code={ride.code} />}

              <RideFeeds
                rideId={rideId}
                currentUserId={Number(currentUser.id)}
                feeds={feeds}
                navigation={navigation}
                driverId={currentDriver.id}
                fetchNewItem={fetchRideItem}
              />

              <RideDescription course={ride} driver={currentDriver} />
            </View>
          )}
        </View>
        {/* Footer : Spécial driver  */}
        <View
          style={[
            { padding: theme.sizings.mediumLarge },
            desktopMode && desktopStyles.boxed,
          ]}
        >
          {ride?.status === 'incoming' &&
            dayjs(ride?.departureTime).diff(dayjs()) > 0 && (
              <ViewCol>
                <GradientButton
                  colors={[theme.colors.greenLight, theme.colors.greenMedium]}
                  onPress={handleStartRide}
                  labelKey="actions.startRide"
                  leftIconType="AntDesign"
                  leftIconName="flag"
                  leftIconSizeName="largest"
                  rightIconType="AntDesign"
                  rightIconName="right"
                  rightIconSizeName="largest"
                  style={{ marginBottom: 10 }}
                />
                <GradientButton
                  colors={[theme.colors.statusRed, theme.colors.statusRed]}
                  onPress={handleDeleteRide}
                  labelKey="actions.deleteRide"
                  leftIconType="AntDesign"
                  leftIconName="delete"
                  leftIconSizeName="largest"
                  rightIconType="AntDesign"
                  rightIconName="right"
                  rightIconSizeName="largest"
                />
              </ViewCol>
            )}

          {ride?.status === 'incoming' &&
            dayjs(ride?.departureTime).diff(dayjs()) < 0 && (
              <GradientButton
                onPress={handleFinishRide}
                labelKey="actions.finish"
                leftIconType="AntDesign"
                leftIconName="play"
                leftIconSizeName="largest"
                rightIconType="AntDesign"
                rightIconName="right"
                rightIconSizeName="largest"
                style={{ marginTop: theme.sizings.mediumLarge }}
              />
            )}
          {ride?.status === 'in_progress' && (
            <GradientButton
              onPress={handleFinishRide}
              labelKey="actions.finish"
              leftIconType="AntDesign"
              leftIconName="play"
              leftIconSizeName="largest"
              rightIconType="AntDesign"
              rightIconName="right"
              rightIconSizeName="largest"
              style={{ marginTop: theme.sizings.mediumLarge }}
            />
          )}
        </View>
      </ScrollView>
    </View>
  );
};

export default CourseDetailScreen;
