/* eslint-disable react/style-prop-object */
import { NavigationContainer } from '@react-navigation/native';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { registerRootComponent } from 'expo';
import { useFonts } from 'expo-font';
import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import * as Sentry from 'sentry-expo';
import './i18n';

import {
  Montserrat_100Thin,
  Montserrat_100Thin_Italic,
  Montserrat_200ExtraLight,
  Montserrat_200ExtraLight_Italic,
  Montserrat_300Light,
  Montserrat_300Light_Italic,
  Montserrat_400Regular,
  Montserrat_400Regular_Italic,
  Montserrat_500Medium,
  Montserrat_500Medium_Italic,
  Montserrat_600SemiBold,
  Montserrat_600SemiBold_Italic,
  Montserrat_700Bold,
  Montserrat_700Bold_Italic,
  Montserrat_800ExtraBold,
  Montserrat_800ExtraBold_Italic,
  Montserrat_900Black,
  Montserrat_900Black_Italic,
} from '@expo-google-fonts/montserrat';
import {
  Roboto_100Thin,
  Roboto_100Thin_Italic,
  Roboto_300Light,
  Roboto_300Light_Italic,
  Roboto_400Regular,
  Roboto_400Regular_Italic,
  Roboto_500Medium,
  Roboto_500Medium_Italic,
  Roboto_700Bold,
  Roboto_700Bold_Italic,
  Roboto_900Black,
  Roboto_900Black_Italic,
} from '@expo-google-fonts/roboto';
import dayjs from 'dayjs';
import { View } from 'react-native';
import { getStatusBarHeight } from 'react-native-status-bar-height';
import AppProvider from './src/AppProvider';
import { RootStack } from './src/navigation/RootStackNavigator';

Sentry.init({
  dsn: "https://934e3e41f3dafb15cc55ee99b70c2a80@o432100.ingest.sentry.io/4506156674908160",
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  tracesSampleRate: 1.0,
  enableInExpoDevelopment: true,
  debug: true,
});

dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(duration);

const config = {
  screens: {
    Login: 'Login',
    ForgotPassword: 'ForgotPassword',
    ResetPassword: 'ResetPassword',
  },
};

const linking = {
  prefixes: ['http://localhost:19006/', 'ciligo://', 'https://app.ciligo.fr/'],
  config,
};

const App = () => {
  const [fontsLoaded] = useFonts({
    // eslint-disable-next-line global-require
    Avenir: require('./assets/fonts/Avenir/Avenir-Medium.ttf'),
    Roboto_100Thin,
    Roboto_100Thin_Italic,
    Roboto_300Light,
    Roboto_300Light_Italic,
    Roboto_400Regular,
    Roboto_400Regular_Italic,
    Roboto_500Medium,
    Roboto_500Medium_Italic,
    Roboto_700Bold,
    Roboto_700Bold_Italic,
    Roboto_900Black,
    Roboto_900Black_Italic,
    Montserrat_100Thin,
    Montserrat_200ExtraLight,
    Montserrat_300Light,
    Montserrat_400Regular,
    Montserrat_500Medium,
    Montserrat_600SemiBold,
    Montserrat_700Bold,
    Montserrat_800ExtraBold,
    Montserrat_900Black,
    Montserrat_100Thin_Italic,
    Montserrat_200ExtraLight_Italic,
    Montserrat_300Light_Italic,
    Montserrat_400Regular_Italic,
    Montserrat_500Medium_Italic,
    Montserrat_600SemiBold_Italic,
    Montserrat_700Bold_Italic,
    Montserrat_800ExtraBold_Italic,
    Montserrat_900Black_Italic,
  });

  return (
    <SafeAreaProvider>
      <View
        style={{ backgroundColor: '#ffffff', height: getStatusBarHeight() }}
      >
        <StatusBar style="dark" translucent backgroundColor="#00000000" />
      </View>
      <NavigationContainer linking={linking}>
        <AppProvider>{fontsLoaded ? <RootStack /> : null}</AppProvider>
      </NavigationContainer>
    </SafeAreaProvider>
  );
};

registerRootComponent(App);
export default App;
