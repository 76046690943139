import { Avatar } from 'components/common/Avatar/Avatar';
import { Divider } from 'components/common/Divider/Divider';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import React from 'react';
import { ImageSourcePropType, TouchableOpacity, View } from 'react-native';
import { Icon } from 'components/common/Icon/Icon';
import { JText } from 'components/common/Text/Text';
import { Card } from 'components/common/Card/Card';
import { IconicText } from 'components/common/IconicText/IconicText';
import { DestinationStraightLine } from 'components/common/DestinationStraightLine/DestinationStraightLine';
import { ArrowRight } from 'components/common/ArrowRight/ArrowRight';
import useTheme from 'hooks/useTheme';
import computeAge from 'utils/computeAge';
import { StrapiFile } from 'types/StrapiFile';
import dayjs from 'dayjs';
import { Pin } from 'components/common/Pin/Pin';
import { useTranslation } from 'react-i18next';
import renderOverridedField from 'utils/computeOverridedField';
import fnStyles from '../CourseItemGlobalStyles';
import { City, Ride } from '../../../types';
import { I18nKey } from '../../../../i18n';

type Props = {
  onPress: () => void;
  departureLocation?: City;
  departureTime: Date;
  arrivalLocation: City;
  arrivalTime: Date;
  points?: any;
  vehicle?: string;
  price?: number;
  date?: Date;
  setIsInstanceModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRide: React.Dispatch<React.SetStateAction<Ride | undefined>>;
  ride: Ride;
};

export const CourseItem = ({
  onPress,
  departureLocation,
  departureTime,
  points,
  arrivalLocation,
  arrivalTime,
  price,
  date,
  setIsInstanceModalVisible,
  setSelectedRide,
  ride,
}: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { t } = useTranslation();
  const driverAge = computeAge(ride.driver?.birthDate);

  // conditionnal values
  const music = renderOverridedField(ride.music, ride.driver?.music);
  const smoking = renderOverridedField(ride.smoking, ride.driver?.smoking);
  const chatting = renderOverridedField(ride.chatting, ride.driver?.chatting);

  const days =
    ride.parent?.recurrentDays?.length &&
    ride.parent?.recurrentDays
      .map((rd: string, rdIndex) => {
        const day = t(`formOptions.${rd}` as I18nKey) as string;
        return day;
      })
      .toString();

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.container}>
        <Avatar
          sizeName="sz60"
          image={ride.driver?.profilePicture || undefined}
          style={styles.avatar}
          isWoman={ride.driver?.gender === 'W'}
        />
        <Card style={styles.card}>
          <ViewRow style={styles.cardTop}>
            <ViewCol>
              <JText
                isBold
                sizeName="larger"
                labelKey="sentences.userNameAge"
                valuesKey={{
                  name: ride.driver?.username || ride.driver?.firstname,
                  age: driverAge,
                }}
              />
              <View style={styles.row}>
                <IconicText
                  iconType="FontAwesome5"
                  iconName="car"
                  iconColorName="greenMedium"
                  label={ride.vehicle?.name}
                />
                <Divider vertical />
                <Icon
                  type="FontAwesome5"
                  colorName={chatting ? 'greenMedium' : 'statusDangerHigh'}
                  name="comment"
                />

                <Divider vertical transparent />
                <Icon
                  type="FontAwesome5"
                  colorName={music ? 'greenMedium' : 'statusDangerHigh'}
                  name="music"
                />
                <Divider vertical transparent />

                <Icon
                  type="FontAwesome5"
                  colorName={smoking ? 'greenMedium' : 'statusDangerHigh'}
                  name="smoking"
                />
              </View>
            </ViewCol>

            {price !== undefined && (
              <ViewCol justify="flex-end">
                <JText label={`${price} €`} isBold sizeName="largest" />
              </ViewCol>
            )}
          </ViewRow>

          <View style={styles.cardDates}>
            <JText
              isBold
              label={`${dayjs(date).format('LL')}`}
              style={{ marginRight: theme.sizings.medium }}
            />
            {ride.parent?.recurrentDays &&
              ride.parent?.recurrentDays?.length > 0 && (
                <TouchableOpacity
                  onPress={() => {
                    setSelectedRide(ride.parent);
                    // activer quand popup sera opérationnelle
                    // setIsInstanceModalVisible(true);
                  }}
                >
                  <Pin
                    iconName="info-circle" // ou  infinity si abonné
                    iconType="FontAwesome5"
                    labelKey="courses.isReccurentOn"
                    valuesKey={{ days }} // ou subscribedDays si abonné
                    style={{
                      backgroundColor: theme.colors.blueLight, // ou warningLight si abonné
                      paddingVertical: theme.sizings.tiny,
                      paddingHorizontal: theme.sizings.medium,
                    }}
                    textColorName="blueHigh" // ou warningHigh si abonné
                    iconColorName="blueHigh" // ou warningHigh si abonné
                  />
                </TouchableOpacity>
              )}
          </View>

          <View style={styles.cardBottom}>
            <ViewRow>
              <ViewCol size={1}>
                <DestinationStraightLine
                  departureLocation={departureLocation}
                  departureTime={departureTime}
                  arrivalLocation={arrivalLocation}
                  arrivalTime={arrivalTime}
                  points={points}
                  noMargin
                />
              </ViewCol>
              <ArrowRight inCard />
            </ViewRow>
          </View>
        </Card>
      </View>
    </TouchableOpacity>
  );
};
