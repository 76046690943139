import React from 'react';
import { ScrollView } from 'react-native';
// import * as Linking from 'expo-linking';
import { Icon } from '../../components/common/Icon/Icon';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const IconScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="Icon"
        description="This component renders an icon. You can choose every icon from the react native vector icons link, accessible by clicking the icon in the demo."
      >
        <Icon
          type="AntDesign"
          name="like2"
          // onPress={handleLink}
          sizeName="larger"
          colorName="blueHigh"
        />
      </CatalogHeader>
      <CatalogProps
        propName="type"
        description="The type of the icon."
        type="enum('MaterialCommunity', 'AntDesign', 'Entypo', 'EvilIcons', 'Feather', 'FontAwesome', 'FontAwesome5', 'FontAwesome5Pro', 'Fontisto', 'Material', 'MaterialIcons', 'IonIcons')"
        topDivider={false}
      />
      <CatalogProps
        propName="name"
        description="The name of the icon in the library."
        type="string"
      />
      <CatalogProps propName="size" type="number" />
      <CatalogProps propName="color" type="string" />
    </ScrollView>
  );
};

export default IconScreen;
