import { Theme } from 'providers/ThemeProvider';
import { StyleSheet } from 'react-native';

export default (theme: Theme) =>
  StyleSheet.create({
    form: {
      // flex: 1,
      justifyContent: 'space-between',
      paddingVertical: theme.sizings.mediumLarge,
    },
    footer: {
      alignItems: 'center',
      marginBottom: 30,
    },
    label: {
      color: 'black',
      fontWeight: 'bold',
      fontSize: theme.fontSizes.large,
      textAlign: 'center',
      marginTop: theme.sizings.ten,
      alignItems: 'center',
      width: '100%',
    },
    button: {
      width: '100%',
      marginTop: theme.sizings.mediumLarge,
    },
    card: {
      marginTop: theme.sizings.sz120,
      paddingHorizontal: theme.sizings.sz50,
      paddingVertical: theme.sizings.larger,
    },
  });
