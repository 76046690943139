import { useCallback } from 'react';
import fetchJSON from 'utils/fetchJSON';

export const SectionProvider = () => {
  const createCandidature = useCallback(
    async (
      userId: number | undefined,
      rideId: number,
      sectionId: number,
      seats?: number,
    ) => {
      const payloadCandidature = {
        user: userId,
        ride: rideId,
        section: sectionId,
        seats,
      };

      try {
        const res = await fetchJSON({
          url: `applications`,
          method: 'POST',
          payload: { data: payloadCandidature },
        });
        if (res) {
          // console.log(res);
          return true;
        }
      } catch (error) {
        console.log(error);
      }
      return false;
    },
    [],
  );

  const deleteCandidature = useCallback(async (applicationId: number) => {
    try {
      const res = await fetchJSON({
        url: `applications/${applicationId}`,
        method: 'DELETE',
      });

      if (res) {
        // console.log(res);
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }, []);

  const declineCandidature = useCallback(async (applicationId: number) => {
    try {
      const payloadPassenger = {
        status: 'declined',
      };

      const res = await fetchJSON({
        url: `applications/${applicationId}`,
        method: 'PUT',
        payload: { data: payloadPassenger },
      });

      if (res) {
        // console.log(res);
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }, []);

  const createPassenger = useCallback(async (applicationId: number) => {
    const payloadPassenger = {
      status: 'accepted',
    };

    try {
      const res = await fetchJSON({
        url: `applications/${applicationId}`,
        method: 'PUT',
        payload: { data: payloadPassenger },
      });
      if (res) {
        // console.log(res);
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }, []);

  const deletePassenger = useCallback(async (passengerId: number) => {
    try {
      const res = await fetchJSON({
        url: `passengers/${passengerId}`,
        method: 'DELETE',
      });

      if (res) {
        // console.log(res);
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }, []);

  const confirmCodePassenger = useCallback(
    async (passengerId: number | undefined, codeValue: string) => {
      const payload = {
        code: codeValue,
      };

      try {
        const res = await fetchJSON({
          url: `passengers/${passengerId}/code`,
          method: 'POST',
          payload: { data: payload },
        });
        if (res) {
          // console.log(res);
          return true;
        }
      } catch (error) {
        console.log(error);
      }
      return false;
    },
    [],
  );

  const confirmNotation = useCallback(
    async (passengerId: number, amDriver: boolean) => {
      const payloadUpdatePassenger = amDriver
        ? {
            notationFromDriverEntered: true,
          }
        : {
            notationToDriverEntered: true,
          };

      try {
        const res = await fetchJSON({
          url: `passengers/${passengerId}`,
          method: 'PUT',
          payload: { data: payloadUpdatePassenger },
        });
        if (res) {
          // console.log(res);
          return true;
        }
      } catch (error) {
        console.log(error);
      }
      return false;
    },
    [],
  );

  const startRide = useCallback(async (rideId: number) => {
    try {
      const res = await fetchJSON({
        url: `rides/${rideId}/start`,
        method: 'POST',
      });
      if (res) {
        // console.log(res);
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }, []);

  const finishRide = useCallback(async (rideId: number) => {
    try {
      const res = await fetchJSON({
        url: `rides/${rideId}/end`,
        method: 'POST',
      });
      if (res) {
        // console.log(res);
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }, []);

  return {
    createCandidature,
    createPassenger,
    deleteCandidature,
    declineCandidature,
    confirmCodePassenger,
    confirmNotation,
    startRide,
    finishRide,
    deletePassenger,
  };
};
