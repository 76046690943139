import useTheme from 'hooks/useTheme';
import { ColorType } from 'providers/ThemeProvider';
import React from 'react';
import { View, ViewStyle, StyleProp } from 'react-native';
import { I18nKey } from '../../../../i18n';
import { Divider } from '../Divider/Divider';
import { JText } from '../Text/Text';
import fnStyles from './SectionStyle';

type Props = {
  title?: string;
  titleKey?: I18nKey;
  valuesKey?: object;
  dividerSpacing?: number;
  style?: StyleProp<ViewStyle>;
  titleColor?: ColorType;
  noMargin?: boolean;
  children?: React.ReactNode;
};

export const Section = (props: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const {
    title,
    titleKey,
    valuesKey,
    dividerSpacing = theme.normalize(20),
    style,
    titleColor = 'blueHigh',
    noMargin = false,
    children,
  } = props;

  return (
    <View
      style={[
        styles.container,
        { marginBottom: noMargin ? 0 : theme.sizings.mediumLarge },
        style,
      ]}
    >
      <View style={styles.header}>
        <Divider section />
        <JText
          colorName={titleColor}
          isBold
          // isTitleText
          style={{
            marginHorizontal: dividerSpacing,
            flex: 1,
            marginBottom: 0,
          }}
          label={title}
          labelKey={titleKey}
          valuesKey={valuesKey}
          centered
          sizeName="larger"
        />

        <Divider section />
      </View>
      <View style={styles.content}>{children}</View>
    </View>
  );
};
