import { useCallback } from 'react';
import fetchJSON from 'utils/fetchJSON';

export const FeedProvider = () => {
  const createFeed = useCallback(
    async (rideId: number | undefined, newFeedId: number) => {
      const payloadFeed = {
        id: newFeedId,
        ride: rideId,
        createdAt: new Date(),
      };

      try {
        const res = await fetchJSON({
          url: 'feeds',
          method: 'POST',
          payload: { data: payloadFeed },
        });
        if (res) {
          return true;
        }
      } catch (error) {
        console.log(error);
      }
      return false;
    },
    [],
  );

  const createComment = useCallback(
    async (
      content: string,
      userId: number | undefined | string,
      feedId: number,
      responseOfId: number | undefined,
    ) => {
      const payloadComment = {
        content,
        user: userId,
        feed: feedId,
        responseOf: responseOfId,
      };

      try {
        const res = await fetchJSON({
          url: 'comments',
          method: 'POST',
          payload: { data: payloadComment },
        });
        if (res) {
          return true;
        }
      } catch (error) {
        console.log(error);
      }
      return false;
    },
    [],
  );

  return {
    createFeed,
    createComment,
  };
};
