import React, { useCallback, useEffect, useState } from 'react';
import { Types } from 'types/Types';
import { ViewCol } from 'components/layouts/FlexLayout/FlexViews';
import useTheme from 'hooks/useTheme';
import { StrapiFile } from 'types/StrapiFile';
import useDocument from 'hooks/useDocument';
import { useAuth } from 'hooks/useAuth';
import { UserDocument } from 'types/UserDocument';
import { ViewRow } from '../../layouts/FlexLayout/FlexViews';
import { CircularIconRow } from '../CircularIconRow/CircularIconRow';
import { I18nKey } from '../../../../i18n';

import fnStyles from './DocumentStyle';
import { JText } from '../Text/Text';
import { ImageUploader } from '../ImagePicker/ImagePicker';

// TODO remove optional to value and onChange
type Props = {
  label?: string;
  labelKey?: I18nKey;
  documentType: string;
  documentUrl: string;
  documentEvents: string[];
  iconType?: Types['iconTypes'];
  iconName?: string;
  parent?: string;
  parentId?: number;
  onChange?: (newImg: UserDocument) => void;
  fallbackImage?: UserDocument;
};

const colors = {
  accepted: {
    iconType: 'IonIcons' as Types['iconTypes'],
    iconName: 'checkmark-outline',
  },
  refused: {
    iconType: 'IonIcons' as Types['iconTypes'],
    iconName: 'close-outline',
  },
  in_waiting: {
    iconType: 'IonIcons' as Types['iconTypes'],
    iconName: 'hourglass-outline',
  },
};

export const Document = ({
  label,
  labelKey,
  documentType,
  documentUrl,
  documentEvents = [],
  iconType = 'IonIcons',
  iconName = 'document-outline',
  parent,
  parentId,
  onChange,
  fallbackImage,
}: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { user: currentUser } = useAuth();

  const [image, setImage] = useState<StrapiFile>();
  const [status, setStatus] = useState<Types['documentStatus']>('');

  /* docs */
  const { document, isFetching, updateDocument } = useDocument({
    docUrl: documentUrl,
    docType: documentType,
    listenToEvents: documentEvents,
    parent,
    parentId,
  });

  useEffect(() => {
    if (!parent || !parentId) {
      if (fallbackImage) {
        setStatus(fallbackImage?.status);
        setImage(fallbackImage?.document);
      }
    }
  }, [fallbackImage, parent, parentId]);

  useEffect(() => {
    if (!isFetching) {
      if (document) {
        setStatus(document?.status);
        setImage(document?.document);
      }
    }
  }, [document, isFetching, currentUser]);

  const handleChange = useCallback(
    (newFile: StrapiFile) => {
      updateDocument(newFile).then((updatedDocument: UserDocument | null) => {
        if (updatedDocument) {
          setImage(updatedDocument.document);
          if (onChange) onChange(updatedDocument);
        }
      });
    },
    [onChange, updateDocument],
  );

  return (
    <CircularIconRow
      iconBackgroundColorName={
        status === 'accepted'
          ? 'statusSuccessLight'
          : status === 'in_waiting'
          ? 'statusWarningLight'
          : 'statusDangerLight'
      }
      iconType={iconType}
      iconName={iconName}
      iconSizeName="fs50"
      iconColorName={
        status === 'accepted'
          ? 'statusSuccessHigh'
          : status === 'in_waiting'
          ? 'statusWarningHigh'
          : 'statusDangerHigh'
      }
      statusIconType={
        status === 'accepted'
          ? colors.accepted.iconType
          : status === 'in_waiting'
          ? colors.in_waiting.iconType
          : colors.refused.iconType
      }
      statusIconName={
        status === 'accepted'
          ? colors.accepted.iconName
          : status === 'in_waiting'
          ? colors.in_waiting.iconName
          : colors.refused.iconName
      }
      statusIconColorName={
        status === 'accepted'
          ? 'statusSuccessHigh'
          : status === 'in_waiting'
          ? 'statusWarningHigh'
          : 'statusDangerHigh'
      }
      style={[styles.icon]}
    >
      <ViewRow justify="space-between">
        <ViewCol>
          <JText isBold labelKey={labelKey} label={label} />
          <JText
            colorName={
              status === 'accepted'
                ? 'statusSuccessHigh'
                : status === 'in_waiting'
                ? 'statusWarningHigh'
                : 'statusDangerHigh'
            }
            labelKey={
              status === 'accepted'
                ? 'common.stateApproved'
                : status === 'in_waiting'
                ? 'common.stateWaitingForApproval'
                : status === 'refused'
                ? 'common.stateRefused'
                : 'common.stateMissing'
            }
          />
        </ViewCol>
        <ImageUploader
          titleKey="forms.avatar"
          value={image}
          onChange={handleChange}
          iconic
          showImage={false}
        />
        {/* )} */}
      </ViewRow>
    </CircularIconRow>
  );
};
