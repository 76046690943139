import { useWindowDimensions } from 'react-native';

const isDesktopMode = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { width, height } = useWindowDimensions();
  const desktopMode = width > 991;
   //&& height > 500;
  return desktopMode;
};

export default isDesktopMode;
