import { Avatar } from 'components/common/Avatar/Avatar';
import { Divider } from 'components/common/Divider/Divider';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import React from 'react';
import { ImageSourcePropType, TouchableOpacity, View } from 'react-native';
import { Types } from 'types/Types';
import { Icon } from 'components/common//Icon/Icon';
import { JText } from 'components/common//Text/Text';
import { Card } from 'components/common/Card/Card';
import { DestinationStraightLine } from 'components/common/DestinationStraightLine/DestinationStraightLine';
import { ArrowRight } from 'components/common/ArrowRight/ArrowRight';
import useTheme from 'hooks/useTheme';
import { StrapiFile } from 'types/StrapiFile';
import computeAge from 'utils/computeAge';
import { useTranslation } from 'react-i18next';
import renderOverridedField from 'utils/computeOverridedField';
import fnStyles from '../CourseItemGlobalStyles';
import { I18nKey } from '../../../../i18n';
import { City } from '../../../types';

type Props = {
  onPress: () => void;
  userRole: Types['role'];
  driverName?: string;
  driverBirth: Date;
  departureLocation?: City;
  departureTime: Date;
  arrivalLocation?: City;
  arrivalTime: Date;
  ride: Ride;
};

export const InstanceItem = ({
  onPress,
  userRole,
  driverName,
  driverBirth,
  departureLocation,
  departureTime,
  arrivalLocation,
  arrivalTime,
  ride,
}: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { t } = useTranslation();
  const driverAge = computeAge(ride.driver?.birthDate);
  // conditionnal values
  const music = renderOverridedField(ride.music, ride.driver?.music);
  const smoking = renderOverridedField(ride.smoking, ride.driver?.smoking);
  const chatting = renderOverridedField(ride.chatting, ride.driver?.chatting);

  const days = ride.recurrentDays.map((rd: string, rdIndex) => {
    let day = t(`formOptions.${rd}` as I18nKey) as string;
    if (rdIndex < ride.recurrentDays.length - 1) {
      day += ', ';
    }
    return <JText key={rd} label={day} />;
  });

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.container}>
        <Avatar
          sizeName="sz60"
          iconType="FontAwesome5"
          iconColorName={
            userRole === 'passenger' ? 'blueHigh' : 'statusWarningHigh'
          }
          iconName={userRole === 'passenger' ? 'car' : 'star'}
          iconPosition="bottomRight"
          image={ride.driver.profilePicture}
          style={styles.avatar}
          isWoman={ride.driver.gender === 'W'}
        />

        <Card style={styles.card}>
          <ViewRow
            style={[
              styles.cardTop,
              {
                backgroundColor: theme.colors.greyLight,
              },
            ]}
          >
            <ViewCol style={styles.cardTopIcon}>
              <Icon
                sizeName="largest"
                colorName="blueHigh"
                type="FontAwesome5"
                name="infinity"
              />
            </ViewCol>

            <ViewCol style={{ paddingLeft: theme.sizings.medium }}>
              <View style={styles.row}>
                {userRole !== 'driver' ? (
                  <JText isBold labelKey="courses.iPassenger" />
                ) : (
                  <JText isBold labelKey="courses.iDrive" />
                )}
              </View>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {days}
              </View>
            </ViewCol>
          </ViewRow>

          <ViewRow style={styles.cardBottom}>
            <ViewCol size={1}>
              <DestinationStraightLine
                departureLocation={departureLocation}
                departureTime={departureTime}
                arrivalLocation={arrivalLocation}
                arrivalTime={arrivalTime}
                textTop
              />

              <View style={styles.row}>
                {userRole !== 'driver' ? (
                  <>
                    <JText isBold labelKey="courses.driver" />
                    <Divider vertical transparent />
                    <JText
                      isBold
                      labelKey="sentences.userNameAge"
                      valuesKey={{
                        name: ride.driver?.username || ride.driver?.firstname,
                        age: driverAge,
                      }}
                    />
                  </>
                ) : (
                  <JText isBold labelKey="courses.iDrive" />
                )}

                <Divider vertical />

                <Icon
                  type="FontAwesome5"
                  colorName={chatting ? 'greenMedium' : 'statusDangerHigh'}
                  name="comment"
                />

                <Divider vertical transparent />
                <Icon
                  type="FontAwesome5"
                  colorName={music ? 'greenMedium' : 'statusDangerHigh'}
                  name="music"
                />
                <Divider vertical transparent />

                <Icon
                  type="FontAwesome5"
                  colorName={smoking ? 'greenMedium' : 'statusDangerHigh'}
                  name="smoking"
                />
              </View>
            </ViewCol>

            <ArrowRight inCard />
          </ViewRow>
        </Card>
      </View>
    </TouchableOpacity>
  );
};
