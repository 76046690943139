import React from 'react';
import { Text } from 'react-native';
import styles from './CommonCatalogStyle';

type Props = {
  label: string;
};

export const CatalogPropsTitle = (props: Props) => {
  return <Text style={styles.catalogPropsTitle}>{props.label}</Text>;
};
