/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
// import StarRating from 'react-native-star-rating';
import styles from './RatingStyle';

type Props = {
  value: number;
  onChange?: (value: number) => void;
  size?: number;
  disabled?: boolean;
  starMargin?: number;
  style?: StyleProp<ViewStyle>;
};

export const Rating = (props: Props) => {
  return (
    <View style={[styles.container, props.style]}>
      {/* <StarRating
        disabled={props.disabled ?? true}
        maxStars={5}
        starSize={props.size ?? 20}
        starStyle={{ marginHorizontal: props.starMargin ?? 5 }}
        rating={props.value}
        selectedStar={rating => props.onChange(rating)}
        fullStarColor="#42AA58"
        emptyStarColor="#42AA58"
        emptyStar="star-o"
        fullStar="star"
        halfStar="star-half-o"
        iconSet="FontAwesome"
      /> */}
    </View>
  );
};
