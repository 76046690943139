import React from 'react';
import { View, ImageBackground } from 'react-native';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { JText } from 'components/common/Text/Text';
import { IconicText } from 'components/common/IconicText/IconicText';
import { Divider } from 'components/common/Divider/Divider';
import { TileButton } from 'components/common/TileButton/TileButton';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import useTheme from 'hooks/useTheme';
import { ScrollView } from 'react-native-gesture-handler';
import { getStatusBarHeight } from 'react-native-status-bar-height';
import { Event } from 'types/Event';
import moment from 'moment';
import useItem from 'hooks/useItem';
import dayjs from 'dayjs';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import fnStyles from './EventDetailStyle';
import ImageFromStrapiMedia from 'components/common/ImageFromStrapiMedia/ImageFromStrapiMedia';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'EventDetail'
>;

const EventDetailScreen = ({ navigation, route }: ScreenProps) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const desktopStyles = fnDesktopStyles(theme);
  const desktopMode = isDesktopMode();

  const { item: event, isFetching: isEventFetching } = useItem<Event>(
    'events',
    route.params.eventId,
    {
      populate: ['mainPicture', 'backgroundPicture', 'otherMedia'],
    },
  );

  // exemple réutilisé pour les 4 cas de la maquette
  const exempleDate = new Date();
  const exempleBirth = new Date();
  const exemple = {
    departureLocation: 'Amiens',
    departureTime: exempleDate,
    arrivalLocation: 'Paris Nord',
    arrivalTime: exempleDate,
    date: exempleDate,
    price: 42,
    pets: true,
    smoking: false,
    chatting: true,
    music: true,
    driver: {
      username: 'Toto',
      firstname: 'Titi',
      birthDate: exempleBirth,
      pets: true,
      smoking: false,
      chatting: true,
      music: true,
    },
    vehicle: {
      name: 'Fiat 500',
    },
  };

  return (
    <View style={{ flex: 1, backgroundColor: theme.colors.light }}>
      <ScrollView>
        <View
          style={[
            {
              flex: 1,
              paddingVertical: theme.sizings.mediumLarge,
              marginTop: getStatusBarHeight(),
              width: '100%',
            },
            desktopMode && desktopStyles.boxed,
          ]}
        >
          {isEventFetching || !event ? (
            <JText centered labelKey="common.loading" />
          ) : (
            <>
              <View style={styles.eventTop}>
                <JText
                  isTitleText
                  label={event.name}
                  style={{ marginTop: theme.sizings.mediumLarge }}
                  sizeName="large"
                />
                <IconicText
                  label={event.address}
                  iconType="FontAwesome5"
                  iconName="map-marker"
                  iconColorName="greenMedium"
                  style={{
                    marginLeft: theme.sizings.mediumLarge,
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                />
                <IconicText
                  label={dayjs(event.date).format('DD/MM/YYYY')}
                  iconType="FontAwesome5"
                  iconName="calendar"
                  iconColorName="greenMedium"
                  style={{
                    marginLeft: theme.sizings.mediumLarge,
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                />
              </View>

              {event.backgroundPicture?.url !== undefined && (
              <ImageBackground
                source={
                 ImageFromStrapiMedia(event.backgroundPicture)
                }
                resizeMode="cover"
                style={styles.blocIllustration}
              />
              )}

              {/* Allées */}

              <View style={styles.sectionTitle}>
                <JText
                  isTitleText
                  labelKey="event.titleGoTo"
                  valuesKey={{ address: event.address, adsCount: 0 }}
                  colorName="blueHigh"
                  sizeName="large"
                />
              </View>

              <ViewRow style={styles.sectionBlue}>
                <ViewCol>
                  <TileButton
                    tileColorName="blueHigh"
                    leftIconType="FontAwesome5"
                    leftIconName="hand-rock"
                    labelKey="courses.iDrive"
                    onPress={() =>
                      navigation.navigate('CourseCreate', {
                        searchParams: { arrivalTime: moment(event.date) },
                      })
                    }
                  />
                </ViewCol>
                <ViewCol style={{ width: theme.sizings.mediumLarge }}>
                  <Divider vertical transparent />
                </ViewCol>
                <ViewCol>
                  <TileButton
                    tileColorName="blueHigh"
                    leftIconType="FontAwesome5"
                    leftIconName="thumbtack"
                    labelKey="add.alert"
                    onPress={() =>
                      navigation.navigate('CourseSearch', {
                        searchParams: { departureTime: moment(event.date) },
                      })
                    }
                  />
                </ViewCol>
              </ViewRow>
              {/*
            <View
              style={[
                styles.sectionBlue,
                { marginBottom: theme.sizings.large },
              ]}
            >
            
             <CourseItem
                          onPress={
                            driverId === currentUser?.id
                              ? () =>
                                  navigation.navigate('CourseDetail', {
                                    rideId: section.ride.id,
                                  })
                              : () =>
                                  navigation.navigate('SectionDetail', {
                                    sectionId: section.id,
                                    rideId: section.ride.id,
                                    seats: currentSearch.seats,
                                  })
                          }
                          ride={section.ride}
                          date={section.departureTime}
                          departureLocation={section.departureLocation}
                          departureTime={section.departureTime}
                          arrivalLocation={section.arrivalLocation}
                          arrivalTime={section.arrivalTime}
                          price={section.price}
                          setIsInstanceModalVisible={setIsInstanceModalVisible}
                          setSelectedRide={setSelectedRide}
                        />
           
            </View>
   */}
              {/* Retours  */}

              <View style={styles.sectionTitle}>
                <JText
                  isTitleText
                  labelKey="event.titleComeFrom"
                  valuesKey={{ address: event.address, adsCount: 0 }}
                  colorName="greenHigh"
                  sizeName="large"
                />
              </View>

              <ViewRow style={styles.sectionGreen}>
                <ViewCol>
                  <TileButton
                    tileColorName="greenLight"
                    leftIconType="FontAwesome5"
                    leftIconName="hand-rock"
                    labelKey="courses.iDrive"
                    onPress={() =>
                      navigation.navigate('CourseCreate', {
                        searchParams: { arrivalTime: moment(event.date) },
                      })
                    }
                  />
                </ViewCol>
                <ViewCol style={{ width: theme.sizings.mediumLarge }}>
                  <Divider vertical transparent />
                </ViewCol>
                <ViewCol>
                  <TileButton
                    tileColorName="greenLight"
                    leftIconType="FontAwesome5"
                    leftIconName="thumbtack"
                    labelKey="add.alert"
                    onPress={() =>
                      navigation.navigate('CourseSearch', {
                        searchParams: { departureTime: moment(event.date) },
                      })
                    }
                  />
                </ViewCol>
              </ViewRow>

              {/* description */}

              <View style={{ padding: theme.sizings.mediumLarge }}>
                <JText
                  isTitleText
                  labelKey="event.description"
                  colorName="dark"
                  sizeName="large"
                />

                <JText label={event.description} />
              </View>

              {/* image secondaire */}
              {event.otherMedia?.url !== undefined && (
                <ImageBackground
                  source={
                  ImageFromStrapiMedia(event.otherMedia)
                  }
                  resizeMode="contain"
                  style={styles.blocImage}
                />
              )}
            </>
          )}
        </View>
      </ScrollView>
    </View>
  );
};

export default EventDetailScreen;
