import React from 'react';
import { ScrollView } from 'react-native';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import { Pin } from '../../components/common/Pin/Pin';
import styles from './common/CommonCatalogStyle';

const PinScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader title="Pin" description="This component renders a pin.">
        <Pin
          label="De Toulouse vers Montpellier"
          textColorName="dark"
          iconType="Entypo"
          iconName="location-pin"
          iconSizeName="larger"
          iconColorName="dark"
        />
      </CatalogHeader>
      <CatalogProps
        propName="label"
        description="The text displayed in the component."
        type="string"
        topDivider={false}
      />
      <CatalogProps propName="textColor" type="string" />
      <CatalogProps
        propName="fontWeight"
        type="enum('normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900')"
      />
      <CatalogProps propName="fontSize" type="number" />
      <CatalogProps
        propName="iconType"
        type="enum('MaterialCommunity', 'AntDesign', 'Entypo', 'EvilIcons', 'Feather', 'FontAwesome', 'FontAwesome5', 'FontAwesome5Pro', 'Fontisto', 'Material', 'MaterialIcons', 'IonIcons')"
      />
      <CatalogProps propName="iconName" type="string" />
      <CatalogProps propName="iconSize" type="number" />
      <CatalogProps propName="iconColor" type="string" />
    </ScrollView>
  );
};

export default PinScreen;
