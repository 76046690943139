import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import dayjs from 'dayjs';
import useTheme from 'hooks/useTheme';
import React from 'react';
import { View } from 'react-native';
import { Icon } from '../Icon/Icon';
import { JText } from '../Text/Text';
import styles from './DestinationStraightLineStyle';
import { City } from '../../../types';

type GlobalProps = {
  departureLocation?: City;
  departureTime: Date;
  arrivalLocation?: City;
  arrivalTime: Date;
  points?: any;
  textTop?: boolean;
  noMargin?: boolean;
  vertical?: boolean;
};

type LineProps = {
  vertical?: boolean;
};

const StraightLineDivider = ({ vertical = true }: LineProps) => {
  const { theme } = useTheme();
  return vertical ? (
    <View>
      <View
        style={{
          width: theme.normalize(2),
          borderStyle: 'dashed',
          borderWidth: theme.normalize(2),
          borderColor: theme.colors.greyLight,
          minHeight: theme.sizings.high,
          marginLeft: theme.sizings.larger / 4,
          marginVertical: theme.sizings.smallMedium,
        }}
      />
    </View>
  ) : (
    <ViewCol size={1}>
      <View
        style={{
          height: theme.normalize(2),
          borderStyle: 'dashed',
          borderWidth: theme.normalize(2),
          borderColor: theme.colors.greyLight,
          width: '100%',
        }}
      />
    </ViewCol>
  );
};

export const DestinationStraightLine = ({
  departureLocation,
  departureTime,
  arrivalLocation,
  arrivalTime,
  textTop = false,
  noMargin = false,
  vertical = true,
  points = [],
}: GlobalProps) => {
  const { theme } = useTheme();

  const middlePoints = points.slice(1, -1);

  return vertical ? (
    <View
      style={[
        styles.container,
        {
          flexDirection: 'column',
          marginVertical: noMargin ? 0 : theme.sizings.mediumLarge,
        },
      ]}
    >
      {/* start */}
      <ViewRow>
        <ViewCol style={{ width: theme.sizings.larger }}>
          <Icon
            type="FontAwesome"
            name="circle"
            colorName="greenLight"
            sizeName="larger"
          />
        </ViewCol>
        <ViewCol size={1} inline>
          <View style={{ flex: 1 }}>
            <JText
              label={
                departureLocation?.name ||
                (points.length > 0 ? points[0].id_ville : 'n-a')
              }
              isBold
            />
            {departureLocation?.county && (
              <JText label={departureLocation?.county} isItalic />
            )}
          </View>
          <JText
            label={
              dayjs(departureTime).format('LT') ||
              (points.length > 0 ? points[0].heure : 'n-a')
            }
            style={{ marginLeft: theme.sizings.smallMedium }}
          />
        </ViewCol>
      </ViewRow>

      {middlePoints.length > 0 &&
        middlePoints.map(() => (
          <>
            <StraightLineDivider vertical />
            <View style={{ width: theme.sizings.larger }}>
              <Icon
                type="FontAwesome"
                name="circle"
                colorName="blueLight"
                sizeName="larger"
              />
            </View>
          </>
        ))}

      {/* end */}
      <StraightLineDivider vertical />
      <ViewRow>
        <ViewCol style={{ width: theme.sizings.larger }}>
          <Icon
            type="FontAwesome"
            name="circle"
            colorName="greenMedium"
            sizeName="larger"
          />
        </ViewCol>
        <ViewCol size={1} inline>
          <View style={{ flex: 1 }}>
            <JText
              label={
                arrivalLocation?.name ||
                (points.length > 1 ? points[points.length - 1].id_ville : 'n-a')
              }
              isBold
            />
            {arrivalLocation?.county && (
              <JText label={arrivalLocation?.county} isItalic />
            )}
          </View>
          <JText
            label={
              dayjs(arrivalTime).format('LT') ||
              (points.length > 1 ? points[points.length - 1].heure : 'n-a')
            }
            style={{ marginLeft: theme.sizings.smallMedium }}
          />
        </ViewCol>
      </ViewRow>
    </View>
  ) : (
    <View
      style={[
        styles.container,
        {
          flexDirection: textTop ? 'column-reverse' : 'column',
          marginVertical: noMargin ? 0 : theme.sizings.mediumLarge,
        },
      ]}
    >
      <ViewRow align="center">
        <ViewCol style={{ width: theme.sizings.larger }}>
          <Icon
            type="FontAwesome"
            name="circle"
            colorName="greenLight"
            sizeName="larger"
          />
        </ViewCol>

        {middlePoints.length > 0 &&
          middlePoints.map(() => (
            <>
              <StraightLineDivider />
              <ViewCol style={{ width: theme.sizings.larger }}>
                <Icon
                  type="FontAwesome"
                  name="circle"
                  colorName="blueLight"
                  sizeName="larger"
                />
              </ViewCol>
            </>
          ))}

        <StraightLineDivider />
        <ViewCol justify="flex-end" style={{ width: theme.normalize(20) }}>
          <Icon
            type="FontAwesome"
            name="circle"
            colorName="greenMedium"
            sizeName="larger"
          />
        </ViewCol>
      </ViewRow>

      <ViewRow>
        <ViewCol size={1}>
          <JText
            label={
              departureLocation?.name ||
              (points.length > 0 ? points[0].id_ville : 'n-a')
            }
            isBold
          />
          <JText
            label={
              dayjs(departureTime).format('LT') ||
              (points.length > 0 ? points[0].heure : 'n-a')
            }
          />
        </ViewCol>

        <ViewCol size={1}>
          <JText
            label={
              arrivalLocation?.name ||
              (points.length > 1 ? points[points.length - 1].id_ville : 'n-a')
            }
            right
            isBold
          />
          <JText
            label={
              dayjs(arrivalTime).format('LT') ||
              (points.length > 1 ? points[points.length - 1].heure : 'n-a')
            }
            right
          />
        </ViewCol>
      </ViewRow>
    </View>
  );
};
