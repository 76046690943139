import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import { Section } from 'components/common/Section/Section';
import { getStatusBarHeight } from 'react-native-status-bar-height';
import { FormInput } from 'components/common/FormInput/FormInput';
import CourseHeader from 'components/compositions/CourseHeader/CourseHeader';
import useTheme from 'hooks/useTheme';
import useItem from 'hooks/useItem';
import { SectionProvider } from 'providers/SectionProvider';
import useAlert from 'providers/AlertProvider';
import { useTranslation } from 'react-i18next';
import ResponsiveScrollView from 'components/layouts/ResponsiveScrollView/ResponsiveScrollView';
import { Ride } from '../../../types';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'CourseValidate'
>;

const CourseValidateScreen = ({ navigation, route }: ScreenProps) => {
  const { theme } = useTheme();
  const { rideId, targetId, passengerId } = route.params;
  const { item: ride } = useItem<Ride>('rides', rideId?.toString());
  const { setAlert } = useAlert();
  const { t } = useTranslation();

  /* target */
  const { item: target } = useItem('users', targetId);
  const userTarget = Object(target);

  /* form */
  const { confirmCodePassenger } = SectionProvider();
  const [code, setCode] = useState('');
  const correctCode = Object(ride)?.code;

  const handleSubmit = async (dataCode: string) => {
    if (code === correctCode) {
      const res = await confirmCodePassenger(Number(passengerId), dataCode);
      if (res) {
        if (ride?.status === 'finished') {
          navigation.navigate('CourseEvaluate', {
            targetId,
            rideId,
            passengerId,
            amDriver: false,
          });
        }
        navigation.pop();
      }
    } else {
      setAlert({
        color: theme.colors.statusRed,
        message: t('alert.wrongCode'),
        title: t('alertTitle.wrongCode'),
      });
    }
  };

  /* fake */
  const points: any = [];

  return (
    <View
      style={{
        backgroundColor: theme.colors.light,
        paddingTop: getStatusBarHeight(),
        flex: 1,
      }}
    >
      <ResponsiveScrollView style={{ flex: 1 }}>
        <CourseHeader
          labelKey="sentences.traveledWith"
          labelValues={{ name: userTarget?.username || userTarget?.firstname }}
          points={points}
          image={userTarget?.profilePicture}
          imageIsWoman={userTarget?.gender === 'W'}
        />
        <View
          style={{
            padding: theme.sizings.mediumLarge,
            marginTop: theme.normalize(90),
          }}
        >
          <Section titleKey="section.enterCode" />
          <FormInput value={code} onChange={value => setCode(value)} />
        </View>
      </ResponsiveScrollView>

      <View style={{ padding: theme.sizings.mediumLarge }}>
        <GradientButton
          onPress={() => handleSubmit(code)}
          labelKey="actions.confirmTravel"
          rightIconType="AntDesign"
          rightIconName="right"
          rightIconSizeName="largest"
        />
      </View>
    </View>
  );
};

export default CourseValidateScreen;
