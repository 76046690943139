import React, { useState } from 'react';
import useTheme from 'hooks/useTheme';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Icon } from 'components/common/Icon/Icon';
import { Pin } from 'components/common/Pin/Pin';
import isDesktopMode from 'utils/desktopMode';
import { ContactHelpModal } from 'components/compositions/ContactHelpModal/ContactHelpModal';
import { Platform, View } from 'react-native';
import { JText } from 'components/common/Text/Text';

const HeaderHelp = ({ route, navigation }: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false)

  return (
    <>
        <TouchableOpacity
        style={{
            paddingHorizontal: theme.sizings.mediumLarge,
            position: 'relative',
            height:
            desktopMode || Platform.OS === 'web'
                ? undefined
                : theme.headerSizes.mobile,
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexDirection: 'row',
        }}
        onPress={() => setIsHelpModalVisible(true)}
        >
            <Icon
                type="FontAwesome5"
                name="question"
                colorName="blueHigh"
                sizeName="largest"
            />
            <JText
                labelKey="actions.help"
                style={{
                position: 'relative',
                right: theme.normalize(2),
                top: theme.normalize(-1),
                paddingLeft: theme.sizings.smallMedium,
                paddingRight: theme.sizings.smallMedium,
                paddingTop: 0,
                paddingBottom: 0,
                }}
                isBold
            />
        </TouchableOpacity>
        <ContactHelpModal
            isVisible={isHelpModalVisible}
            onClose={() => setIsHelpModalVisible(false)}
        />
    </>
  );
};

export default HeaderHelp;
