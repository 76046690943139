import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useAuth } from 'hooks/useAuth';
import moment from 'moment';
import { RootStackParamList } from 'navigation/RootStackParamList';
import React, { useEffect } from 'react';
import { City, SearchParams } from 'types';
import fetchJSON from 'utils/fetchJSON';

export type ScreenProps = NativeStackScreenProps<RootStackParamList, 'Boot'>;

const getPlace = async (
  placeId: string,
  dataType: 'place_id' | 'latlng',
): Promise<City | undefined> => {
  const res = await fetchJSON({
    url: `place?${dataType}=${placeId}`,
    method: 'GET',
  });

  if (res?.results) {
    const city = res.results[0].address_components?.find(({ types }: any) =>
      types.includes('locality'),
    )?.short_name;
    const code = res.results[0].address_components?.find(({ types }: any) =>
      types.includes('postal_code'),
    )?.short_name;

    return {
      id: 0,
      name: city || res.results[0].formatted_address,
      place_id: placeId,
      county: res.results[0].formatted_address,
      longitude: res.results[0].geometry.location.lng,
      latitude: res.results[0].geometry.location.lat,
      postalCode: code,
    };
  }
  return undefined;
};

const getSearchParams = async (
  queryParams: any,
): Promise<SearchParams | undefined> => {
  if (queryParams) {
    // Google maps id or Lat/Lng
    const { fromId, toId, fromLatLng, toLatLng, when, nb } = queryParams;

    if (when && nb) {
      const date = moment(when).format('YYYY-MM-DD');
      const hours = moment(when).format('HH:mm:ss');
      const departure = moment(`${date} ${hours}`);

      const searchParams: SearchParams = {
        departureDate: departure.toDate(),
        departureTime: departure,
        seats: Number(nb),
      };

      if (fromId && toId) {
        searchParams.departureLocation = await getPlace(fromId, 'place_id');
        searchParams.arrivalLocation = await getPlace(toId, 'place_id');
      } else if (fromLatLng && toLatLng) {
        searchParams.departureLocation = await getPlace(fromLatLng, 'latlng');
        searchParams.arrivalLocation = await getPlace(toLatLng, 'latlng');
      }

      return searchParams;
    }
  }
  return undefined;
};

const BootScreen = ({ route, navigation }: ScreenProps) => {
  const { refreshUser } = useAuth();

  useEffect(() => {
    const fetchUser = async () => {
      const user = await refreshUser();

      if (user) {
        if (route.params?.queryParams) {
          getSearchParams(route.params.queryParams).then(searchParams => {
            const navRoutes: any[] = [{ name: 'Root' }];
            if (searchParams) {
              navRoutes.push({
                name: 'CourseResults',
                params: { searchParams },
              });
            }
            navigation.reset({
              index: navRoutes.length - 1,
              routes: navRoutes,
            });
          });
        } else {
          navigation.reset({
            index: 0,
            routes: [{ name: 'Root' }],
          });
        }
      } else {
        navigation.reset({
          index: 0,
          routes: [{ name: 'Welcome' }],
        });
      }
    };
    fetchUser();
  }, [navigation, refreshUser, route]);

  return null;
};

export default BootScreen;
