import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { JText } from 'components/common/Text/Text';
import { TileButton } from 'components/common/TileButton/TileButton';
import { CourseWithStatus } from 'components/compositions/CourseWithStatus/CourseWithStatus';
import useTheme from 'hooks/useTheme';
import { RootStackParamList } from 'navigation/RootStackParamList';
import React, { useState } from 'react';
import { View } from 'react-native';
import useList from 'hooks/useList';
import useAuth from 'hooks/useAuth';
import { useFocusEffect } from '@react-navigation/native';
import { Section as SectionBox } from 'components/common/Section/Section';
import { Section } from 'types/Section';
import dayjs from 'dayjs';
import renderOverridedField from 'utils/computeOverridedField';
import ResponsiveScrollView from 'components/layouts/ResponsiveScrollView/ResponsiveScrollView';
import { TabsBackground } from 'components/layouts/BackgroundLayout/TabsBackgound';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import { Passenger } from 'types/Passenger';
import { InstanceModal } from 'components/compositions/InstanceModal/InstanceModal';
import { Ride } from 'types';
import useAlert from 'providers/AlertProvider';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../../../i18n';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'MyCourses'
>;

const MyPassengerItemsScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const desktopStyles = fnDesktopStyles(theme);
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const [isInstanceModalVisible, setIsInstanceModalVisible] = useState(false);
  const [selectedRide, setSelectedRide] = useState<Ride>();
  const { setAlert } = useAlert();

  // futur
  const {
    items: sections,
    isFetching: isSectionsFetching,
    fetchItems: fetchSectionsItems,
  } = useList<Section>('sections/me', {
    listenToEvents: [
      'application_accepted',
      'application_refused',
      'ride_updated',
      'ride_canceled',
    ], // TODO work but can be optimized
    populate: [
      'departureLocation',
      'arrivalLocation',
      'parent',
      'parent.driver',
      'parent.driver.profilePicture',
      'parent.vehicle',
    ],
  });

  const futurSections = sections
    ?.filter((section: Section) => {
      const res =
        Number(dayjs(section.section.departureTime).toDate()) -
        Number(new Date());
      return res >= 0;
    })
    .sort(
      (sectionA: Section, sectionB: Section) =>
        Number(dayjs(sectionA.section.departureTime).toDate()) -
        Number(dayjs(sectionB.section.departureTime).toDate()),
    );

  const historicSections = sections
    ?.filter((section: Section) => {
      const res =
        Number(dayjs(section.section.departureTime).toDate()) -
        Number(new Date());
      return res < 0;
    })
    .sort(
      (sectionA: Section, sectionB: Section) =>
        Number(dayjs(sectionB.section.departureTime).toDate()) -
        Number(dayjs(sectionA.section.departureTime).toDate()),
    );

  // passé

  useFocusEffect(
    React.useCallback(() => {
      // Do something when the screen is focused
      fetchSectionsItems();

      return () => {
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [fetchSectionsItems]),
  );

  const handleSaveSubscribeInstance = async () => {
    if (selectedRide) {
      // TODO : adapter l'action
      /* await fetchJSON({
        url: `rides/${ride.id}`,
        method: 'DELETE',
      }); */
      setAlert({
        message: t('alert.instanceSubscribeSaved'),
        title: t('alertTitle.instanceSubscribeSaved'),
      });
    }
  };

  return (
    <>
      <TabsBackground />
      <ResponsiveScrollView>
        <View
          style={[
            {
              padding: theme.sizings.mediumLarge,
              flex: 1,
            },
            desktopMode && desktopStyles.boxed,
          ]}
        >
          <View style={{ marginBottom: theme.sizings.large }}>
            <TileButton
              leftIconType="FontAwesome5"
              leftIconName="search"
              tileColorName="greenHigh"
              labelKey="courses.iSearch"
              onPress={() => navigation.navigate('CourseSearch')}
            />
          </View>
          {isSectionsFetching && <JText centered labelKey="common.loading" />}

          {!isSectionsFetching && futurSections && (
            <View style={{ marginBottom: theme.sizings.mediumLarge * 2 }}>
              <SectionBox titleKey="courses.futur">
                {futurSections?.length ? (
                  futurSections.map((section: any, index: number) => {
                    const fkey = `course-${index}`;

                    const amPassengerConfirmed =
                      section.section.ride?.driver.id !== currentUser?.id &&
                      section.type !== 'application';

                    const codeEnteredPending =
                      section.section.status === 'finished' &&
                      section.section.passengers?.find(
                        (p: Passenger) =>
                          p.id === currentUser?.id && !p.codeEntered,
                      );

                    const codeEnteredOk =
                      section.section.status === 'finished' &&
                      section.section.passengers?.find(
                        (p: Passenger) =>
                          p.id === currentUser?.id && p.codeEntered,
                      );

                    const cardTop = () => {
                      if (
                        amPassengerConfirmed &&
                        section.type !== 'application'
                      ) {
                        return { label: 'courses.checkAccept', icon: 'check' };
                      }

                      if (
                        !amPassengerConfirmed ||
                        section.type === 'application'
                      ) {
                        return {
                          label: 'courses.pendingAccept',
                          icon: 'hourglass',
                        };
                      }

                      if (codeEnteredPending) {
                        return {
                          label: 'courses.codePending',
                          icon: 'hourglass',
                        };
                      }

                      if (codeEnteredOk) {
                        return {
                          label: 'courses.codeOk',
                          icon: 'check',
                        };
                      }
                      return { label: undefined, icon: undefined };
                    };

                    return (
                      <View key={fkey}>
                        <CourseWithStatus
                          userRole={
                            section.type === 'passenger'
                              ? 'passenger'
                              : 'candidate'
                          }
                          statusOk={
                            (amPassengerConfirmed &&
                              section.type !== 'application') ||
                            codeEnteredOk
                          }
                          statusWarning={
                            !amPassengerConfirmed || codeEnteredPending
                          }
                          cardTopLegende={cardTop().label as I18nKey}
                          cardTopIcon={cardTop().icon}
                          onPress={() =>
                            navigation.navigate('SectionDetail', {
                              rideId: section.section.ride.id,
                              sectionId: section.section.id,
                              seats: undefined,
                            })
                          }
                          ride={section.section.ride}
                          departureLocation={section.section.departureLocation}
                          departureTime={section.section.departureTime}
                          arrivalLocation={section.section.arrivalLocation}
                          arrivalTime={section.section.arrivalTime}
                          date={section.section.departureTime}
                          setIsInstanceModalVisible={setIsInstanceModalVisible}
                          setSelectedRide={setSelectedRide}
                        />
                      </View>
                    );
                  })
                ) : (
                  <JText centered labelKey="courses.noResultsFutur" />
                )}
              </SectionBox>
            </View>
          )}

          {!isSectionsFetching && historicSections && (
            <View style={{ marginBottom: theme.sizings.mediumLarge * 2 }}>
              <SectionBox titleKey="courses.historic">
                {historicSections?.length ? (
                  historicSections.map((section: any, index: number) => {
                    const fkey = `course-${index}`;

                    const amPassengerConfirmed =
                      section.section.ride?.driver.id !== currentUser?.id &&
                      section.type !== 'application';

                    const codeEnteredPending =
                      section.section.status === 'finished' &&
                      section.section.passengers?.find(
                        (p: Passenger) =>
                          p.id === currentUser?.id && !p.codeEntered,
                      );

                    const codeEnteredOk =
                      section.section.status === 'finished' &&
                      section.section.passengers?.find(
                        (p: Passenger) =>
                          p.id === currentUser?.id && p.codeEntered,
                      );

                    const cardTop = () => {
                      if (
                        amPassengerConfirmed &&
                        section.type !== 'application'
                      ) {
                        return { label: 'courses.checkAccept', icon: 'check' };
                      }

                      if (
                        !amPassengerConfirmed ||
                        section.type === 'application'
                      ) {
                        return {
                          label: 'courses.pendingAccept',
                          icon: 'hourglass',
                        };
                      }

                      if (codeEnteredPending) {
                        return {
                          label: 'courses.codePending',
                          icon: 'hourglass',
                        };
                      }

                      if (codeEnteredOk) {
                        return {
                          label: 'courses.codeOk',
                          icon: 'check',
                        };
                      }
                      return { label: undefined, icon: undefined };
                    };

                    return (
                      <View key={fkey}>
                        <CourseWithStatus
                          userRole={
                            section.type === 'passenger'
                              ? 'passenger'
                              : 'candidate'
                          }
                          statusOk={
                            (amPassengerConfirmed &&
                              section.type !== 'application') ||
                            codeEnteredOk
                          }
                          statusWarning={
                            !amPassengerConfirmed || codeEnteredPending
                          }
                          cardTopLegende={cardTop().label as I18nKey}
                          cardTopIcon={cardTop().icon}
                          onPress={() =>
                            navigation.navigate('SectionDetail', {
                              rideId: section.section.ride.id,
                              sectionId: section.section.id,
                              seats: undefined,
                            })
                          }
                          ride={section.section.ride}
                          departureLocation={section.section.departureLocation}
                          departureTime={section.section.departureTime}
                          arrivalLocation={section.section.arrivalLocation}
                          arrivalTime={section.section.arrivalTime}
                          date={section.section.departureTime}
                          setIsInstanceModalVisible={setIsInstanceModalVisible}
                          setSelectedRide={setSelectedRide}
                        />
                      </View>
                    );
                  })
                ) : (
                  <JText centered labelKey="courses.noResultsHistoric" />
                )}
              </SectionBox>
            </View>
          )}
        </View>
      </ResponsiveScrollView>

      {/* TODO : modal formulaire modif abonnement */}
      {selectedRide && currentUser?.id !== selectedRide?.driver?.id && (
        <InstanceModal
          isVisible={isInstanceModalVisible}
          onClose={() => setIsInstanceModalVisible(false)}
          titleKey="actions.subscribeInstance"
          ride={selectedRide}
          onSave={() => handleSaveSubscribeInstance()}
        />
      )}
    </>
  );
};

export default MyPassengerItemsScreen;
