import { useCallback, useEffect } from 'react';
import CancellablePromise from 'utils/CancellablePromise';
import { parseStrapiFormat } from 'utils/strapi';
import qs from 'qs';
import {
  addEventListener,
  Listener,
  removeEventListener,
} from 'providers/SocketProvider';
import useFetch, { UseFetchOptions } from './useFetch';

// const postExecute = (data: { results: any }): any => data.results;

interface EventMap {
  [key: string]: (notification?: object) => void;
}

export type UseItemOptions = {
  populate?: string | object;
  fields?: string | object;
  listenToEvents?: string[];
  onEvent?: EventMap;
} & UseFetchOptions;

export type UseItemType<T> = {
  isFetching: boolean;
  item: T | null;
  error: string | null;
  isPreviousItem: boolean;
  fetch: () => CancellablePromise<unknown>;
  fetchItem: (id: any) => CancellablePromise<unknown>;
  saveItem: (payload: any) => CancellablePromise<unknown>;
  updateItem: (id: any, payload: any) => CancellablePromise<unknown>;
  removeItem: (id: any) => CancellablePromise<unknown>;
};

const useItem = <T,>(
  url: string,
  itemId: number | string,
  options: UseItemOptions = {},
): UseItemType<T> => {
  const {
    populate,
    fields,
    listenToEvents = [],
    onEvent = {},
    ...opts
  } = options;

  const getUrl = (url_orig: string) => {
    const query = {} as {
      populate?: string | object;
      fields?: string | object;
    };

    if (populate) {
      query.populate = populate;
    }
    if (fields) {
      query.fields = fields;
    }

    const queryParams = qs.stringify(query, {
      encodeValuesOnly: true,
    });

    return `${url_orig}${queryParams ? '?' : ''}${queryParams}`;
  };

  const getDetailUrl = getUrl(`${url}/${itemId}`);
  const getSimpleUrl = getUrl(url);

  opts.postExecute = parseStrapiFormat;

  const { fetchData, data, error, isFetching, isPreviousData, setData } =
    useFetch<T>(getSimpleUrl, {
      sharePromise: false,
      manual: true,
      // postExecute,
      ...opts,
    });

  const fetch = useCallback(
    () => fetchData({ url: getSimpleUrl, method: 'GET' }),
    [fetchData, getSimpleUrl],
  );

  const fetchItem = useCallback(
    () => fetchData({ url: getDetailUrl, method: 'GET' }),
    [fetchData, getDetailUrl],
  );

  const saveItem = useCallback(
    payload => fetchData({ method: 'POST', payload }),
    [fetchData],
  );

  const removeItem = useCallback(
    () => fetchData({ url: getDetailUrl, method: 'DELETE' }),
    [fetchData, getDetailUrl],
  );

  const updateItem = useCallback(
    (id, payload) => fetchData({ url: getDetailUrl, method: 'PUT', payload }),
    [fetchData, getDetailUrl],
  );

  useEffect(() => {
    setData(null);
    if (itemId) {
      fetchItem();
    }
  }, [fetchItem, setData, itemId]);

  useEffect(() => {
    if (listenToEvents && listenToEvents.length > 0) {
      const listener: Listener = {
        events: listenToEvents,
        handleEvent: (notificationString, update) => {
          if (onEvent) {
            const onEventCallback = onEvent[notificationString] as
              | ((notification: object) => void)
              | undefined
              | null;
            if (onEventCallback) {
              onEventCallback(update);
            } else {
              fetchItem();
            }
          } else {
            fetchItem();
          }
        },
      };
      addEventListener(listener);

      return () => {
        removeEventListener(listener);
      };
    }
    return () => {};
  }, [listenToEvents, fetchItem, itemId, onEvent]);

  return {
    isFetching,
    item: data,
    error,
    isPreviousItem: isPreviousData,
    fetch,
    fetchItem,
    saveItem,
    updateItem,
    removeItem,
  };
};

export default useItem;
