import React, { useEffect, useMemo, useState } from 'react';
import { View, ActivityIndicator } from 'react-native';
import moment from 'moment';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from 'navigation/RootStackParamList';

import { BackgroundLayout } from 'components/layouts/BackgroundLayout/BackgroundLayout';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import Wizard from 'components/layouts/Wizard/Wizard';
import WizardStep from 'components/layouts/Wizard/WizardStep';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import { Divider } from 'components/common/Divider/Divider';
import { JText } from 'components/common/Text/Text';
import { FormControl } from 'components/common/FormControl/FormControl';
import { Section } from 'components/common/Section/Section';
import { DestinationStraightLine } from 'components/common/Input/DestinationStraightLine/DestinationStraightLine';
import { IconicText } from 'components/common/IconicText/IconicText';
import { CircularButton } from 'components/common/CircularButton/CircularButton';

import useAuth from 'hooks/useAuth';
import useList from 'hooks/useList';
import useTheme from 'hooks/useTheme';
import useAlert from 'providers/AlertProvider';

import { City, Ride, SectionDetail, Step, UserVehicle } from 'types';
import { fetchJSON } from 'utils/fetchJSON';

import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';

import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../../i18n';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'CourseCreate'
>;

type FormatedVehicleList = [
  values: number[],
  labels: string[],
  seats: number[],
];

const setFormatedVehicleList = (vehicles: UserVehicle[]) => {
  const newList: FormatedVehicleList = [[], [], []];
  vehicles.forEach((vehicle: UserVehicle) => {
    if (vehicle.id) {
      newList[0].push(vehicle.id);
      newList[1].push(vehicle.name);
      newList[2].push(vehicle.seats);
    }
  });
  return newList;
};

const labelKeysYesNo: I18nKey[] = ['formOptions.yes', 'formOptions.no'];
const valuesYesNo: boolean[] = [true, false];

const valuesDays: string[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const labelKeysDays: I18nKey[] = [
  'formOptions.monday',
  'formOptions.tuesday',
  'formOptions.wednesday',
  'formOptions.thursday',
  'formOptions.friday',
  'formOptions.saturday',
  'formOptions.sunday',
];

const CourseCreateScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopStyles = fnDesktopStyles(theme);
  const desktopMode = isDesktopMode();
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const { setAlert } = useAlert();
  const [isGoogleFetching, setIsGoogleFetching] = useState(false);
  const [valuePrice, setValuePrice] = React.useState<number | undefined>(
    undefined,
  );
  const [kmPrice, setKmPrice] = useState(0);

  useEffect(() => {
    const fetchPrice = async () => {
      const result = await fetchJSON({ url: 'kilometer-cost', method: 'GET' });
      console.log(result);
      setKmPrice(result?.data?.attributes?.kilometerCost);
    };
    fetchPrice();
  }, []);

  /* vehicule + populate */
  const {
    isFetching: isVehiclesFetching,
    isItemsValid: isVehiclesValid,
    items: vehicles,
  } = useList<UserVehicle>(`vehicles`, {
    populate: ['user', 'user.profilePicture'],
    defaultFilters: {
      status: 'accepted', // todo voir avec Ariane
      user: currentUser?.id ?? 0,
    },
  });

  // On vérifie si on a un véhicule existant et valide sinon on redirige vers création de véhicule
  useEffect(() => {
    if (!isVehiclesFetching && isVehiclesValid) {
      if (!vehicles || vehicles.length === 0) {
        navigation.reset({
          index: 1,
          routes: [{ name: 'Root' }, { name: 'CreateVehicle', params: {} }],
        });
        setAlert({
          color: theme.colors.statusRed,
          message: t('alert.noVehicles'),
          title: t('alertTitle.noVehicles'),
        });
      }
    }
  }, [
    isVehiclesFetching,
    isVehiclesValid,
    navigation,
    setAlert,
    t,
    theme,
    vehicles,
  ]);

  const vehicleList = useMemo(() => {
    return vehicles ? setFormatedVehicleList(vehicles) : null;
  }, [vehicles]);

  const requiredField = (value: any) => {
    return value ? undefined : t('forms.requiredField');
  };

  // Call GMaps api in order to get duration and distance and return SectionDetail object
  const calculateAndFillSectionDetail = async (values: SectionDetail) => {
    const detail: SectionDetail = {
      departureLocation: values.departureLocation,
      startTime: values.startTime,
      arrivalLocation: values.arrivalLocation,
      endTime: values.endTime,
    };

    const durationAndDistance = await fetchJSON({
      url: `distance?destination=${detail.arrivalLocation.latitude},${detail.arrivalLocation.longitude}&origin=${detail.departureLocation.latitude},${detail.departureLocation.longitude}`,
      method: 'GET',
    });

    detail.duration = durationAndDistance.routes[0].legs[0].duration.value;
    detail.distance = Math.round(
      durationAndDistance.routes[0].legs[0].distance.value / 1000,
    );
    detail.endTime = moment(detail.startTime).add(detail.duration, 's');

    return detail;
  };

  // Set SectionDetail array in order to display and send them to the back
  const setSectionDetails = async (values: any) => {
    const details: Array<SectionDetail> = [];

    if (
      values.departureLocation &&
      values.departureDate &&
      values.departureHour &&
      values.arrivalLocation
    ) {
      setIsGoogleFetching(true);
      const date = values.departureDate.format('YYYY-MM-DD');
      const departureHour = values.departureHour.format('HH:mm:ss');

      const stepValues = {
        departureLocation: values.departureLocation,
        startTime: moment(`${date} ${departureHour}`),
        arrivalLocation: values.arrivalLocation,
        endTime: moment(`${date} ${departureHour}`),
      };

      // On a pas de step intermédiaire
      if (
        values.intermediateSteps === undefined ||
        values.intermediateSteps?.length === 0
      ) {
        details.push(await calculateAndFillSectionDetail(stepValues));
      }
      // On a des step intermédiaire
      else {
        // On push la première section
        // eslint-disable-next-line prefer-destructuring
        stepValues.arrivalLocation = values.intermediateSteps[0];
        details.push(await calculateAndFillSectionDetail(stepValues));
        stepValues.startTime = details[0].endTime;

        // eslint-disable-next-line no-restricted-syntax,no-unreachable-loop
        for await (const step of values.intermediateSteps) {
          const index = values.intermediateSteps.indexOf(step);

          // Si on est sur la dernière étape
          if (index === values.intermediateSteps.length - 1) {
            stepValues.departureLocation = step;
            stepValues.arrivalLocation = values.arrivalLocation;
            const sectionValues = await calculateAndFillSectionDetail(
              stepValues,
            );

            details.push(sectionValues);
          } else {
            // Sinon si on est sur des étapes intermédiaires
            stepValues.departureLocation = step;
            stepValues.arrivalLocation = values.intermediateSteps[index + 1];
            const sectionValues = await calculateAndFillSectionDetail(
              stepValues,
            );
            stepValues.startTime = sectionValues.endTime;
            details.push(sectionValues);
          }
        }
      }
      return details;
    }
    return details;
  };

  const setSeatLabelValue = (seatLength: number) => {
    const seats = [];

    for (let i = 0; i < seatLength - 1; i++) {
      seats.push(i + 1);
    }
    return seats;
  };
  const step1 = () => (
    <>
      <FormControl
        inline
        name="departureLocation"
        titleKey="forms.from"
        leftIconColorName="blueHigh"
        leftIconType="FontAwesome5"
        leftIconName="map-marker"
        required
        validate={requiredField}
        type="autocomplete"
        autoCompleteUrl="cities"
        style={{ marginBottom: 0 }}
      />

      <FieldArray name="intermediateSteps">
        {({ fields }) => (
          <View style={{ width: '100%' }}>
            {fields.map((field, index) => (
              <View
                key={field}
                style={{
                  flex: 1,
                }}
              >
                <>
                  <Divider />
                  <ViewRow style={{ alignItems: 'center' }}>
                    <ViewCol
                      style={{
                        maxWidth: theme.sizings.high + theme.sizings.medium,
                        alignItems: 'center',
                      }}
                      inline
                    >
                      <CircularButton
                        onPress={() => {
                          fields.remove(index);
                        }}
                        iconType="FontAwesome5"
                        iconName="trash"
                        iconColorName="statusRed"
                        iconSizeName="medium"
                        sizeName="high"
                        backgroundColorName="statusDangerLight"
                      />
                    </ViewCol>

                    <ViewCol size={1}>
                      <FormControl
                        inline
                        titleKey="sentences.steps"
                        titleValuesKey={{ number: index + 1 }}
                        name={field}
                        type="autocomplete"
                        autoCompleteUrl="cities"
                        style={{ marginBottom: 0 }}
                      />
                    </ViewCol>
                  </ViewRow>
                </>
              </View>
            ))}
            <Divider />
            <View style={{ alignItems: 'center' }}>
              <GradientButton
                style={{
                  width: '60%',
                  height: theme.sizings.high,
                }}
                leftIconColorName="light"
                leftIconName="plus"
                leftIconType="FontAwesome5"
                labelKey="forms.intermediateSteps"
                onPress={() => {
                  fields.push({ name: '', id: '' });
                }}
              />
            </View>
            <Divider />
          </View>
        )}
      </FieldArray>

      <FormControl
        inline
        name="arrivalLocation"
        titleKey="forms.to"
        leftIconColorName="blueHigh"
        leftIconType="FontAwesome5"
        leftIconName="map-marker"
        required
        validate={requiredField}
        type="autocomplete"
        autoCompleteUrl="cities"
        style={{ marginBottom: 0 }}
      />
    </>
  );

  const step2 = (values: any) => (
    <>
      <FormControl
        name="isRecurrent"
        type="select-list"
        titleKey="forms.isHebdo"
        values={valuesYesNo}
        labelKeys={labelKeysYesNo}
        selectedValue={values.isRecurrent}
        style={{ marginVertical: theme.sizings.medium, width: '100%' }}
        required
      />

      {values.isRecurrent && (
        <FormControl
          name="recurrentDays"
          required
          type="select-list"
          titleKey="forms.recurrentDays"
          values={valuesDays}
          labelKeys={labelKeysDays}
          selectedValue={values.recurrentDays as string[]}
          style={{ marginVertical: theme.sizings.small, width: '100%' }}
        />
      )}

      <View style={{ marginBottom: theme.sizings.mediumLarge }}>
        <FormControl name="departureDate" type="calendar" required />
      </View>

      <FormControl
        name="departureHour"
        type="time"
        titleKey="forms.startHourDiver"
        leftIconColorName="blueHigh"
        leftIconType="FontAwesome5"
        leftIconName="clock"
        required
        validate={requiredField}
      />
    </>
  );

  const step3 = (values: any) => (
    <View style={{ width: '100%' }}>
      <KeyboardAwareScrollView style={{ width: '100%' }}>
        <FormControl
          name="vehicleId"
          type="select-list"
          titleKey="forms.vehicle"
          required
          values={vehicleList ? vehicleList[0] : []}
          label={vehicleList ? vehicleList[1] : []}
          selectedValue={values.vehicleId}
        />

        <FormControl
          name="seats"
          required
          type="select-list"
          titleKey="forms.passengersNumber"
          values={setSeatLabelValue(
            vehicleList
              ? vehicleList[2][
                  vehicleList[0].findIndex(id => id === values.vehicleId)
                ]
              : 0,
          )}
          selectedValue={values.seats}
        />

        <FormControl
          name="smoking"
          type="select-list"
          required
          titleKey="forms.smokeDegree"
          values={valuesYesNo}
          labelKeys={labelKeysYesNo}
          selectedValue={values.smoking}
          leftIconType="FontAwesome5"
          leftIconName="smoking"
          leftIconSizeName="largest"
          leftIconColorName="blueHigh"
        />

        <FormControl
          name="pets"
          type="select-list"
          titleKey="forms.petDegree"
          required
          values={valuesYesNo}
          labelKeys={labelKeysYesNo}
          selectedValue={values.pets}
          leftIconType="FontAwesome5"
          leftIconName="paw"
          leftIconSizeName="largest"
          leftIconColorName="blueHigh"
        />
        <FormControl
          name="otherDetails"
          titleKey="forms.otherDetails"
          multiline
          height={150}
        />
      </KeyboardAwareScrollView>
    </View>
  );

  const step4 = (values: any) => (
    <>
      <View style={{ width: '100%' }}>
        {!isGoogleFetching ? (
          <FieldArray name="sectionDetail">
            {({ fields }) => (
              <View style={{ width: '100%' }}>
                {values?.sectionDetail?.length &&
                  values?.sectionDetail[0] &&
                  values?.sectionDetail[0].departureLocation?.county && (
                    <JText
                      label={values.sectionDetail[0].departureLocation.county}
                      isItalic
                      centered
                      colorName="blueHigh"
                      style={{
                        marginBottom: theme.sizings.medium,
                        backgroundColor: theme.colors.blueLight,
                        padding: theme.sizings.smallMedium,
                        borderRadius: theme.radius.medium,
                      }}
                    />
                  )}
                {fields.map((name, index) => (
                  <DestinationStraightLine
                    key={name}
                    name={name}
                    val={fields.value[index]}
                  />
                ))}
              </View>
            )}
          </FieldArray>
        ) : (
          <View style={{ paddingVertical: theme.sizings.mediumLarge }}>
            <ActivityIndicator size="large" color={theme.colors.blueHigh} />
          </View>
        )}
      </View>

      <Section titleKey="courses.moreDetail">
        <ViewCol style={{ alignItems: 'flex-start' }}>
          {values.seats !== undefined && (
            <IconicText
              labelKey="sentences.vehicleNameAndAvailableSeats"
              // TODO : GET VEHICULE NAME
              valuesKey={{
                vehicle:
                  vehicleList[1][
                    vehicleList[0].findIndex(id => id === values.vehicleId)
                  ],
                seats: values.seats,
              }}
              iconType="FontAwesome5"
              iconName="car"
              iconColorName="blueHigh"
            />
          )}

          {values.pets !== undefined && (
            <IconicText
              labelKey={values.pets ? 'forms.petDegree' : 'forms.petDegreeNo'}
              iconType="FontAwesome5"
              iconName="paw"
              iconColorName={values.pets ? 'greenMedium' : 'statusDangerHigh'}
            />
          )}

          {values.smoking !== undefined && (
            <IconicText
              labelKey={
                values.smoking ? 'forms.smokeDegree' : 'forms.smokeDegreeNo'
              }
              iconType="FontAwesome5"
              iconName="smoking"
              iconColorName={
                values.smoking ? 'greenMedium' : 'statusDangerHigh'
              }
            />
          )}
          <FormControl
            name="price"
            titleKey="forms.price"
            required
            onChange={value => {
              const newValuePrice = Number(value.replace(',', '.'));
              if (!Number.isNaN(newValuePrice)) {
                setValuePrice(newValuePrice);
              }
            }}
          />
        </ViewCol>
      </Section>
    </>
  );

  const steps = (values: any, form: any): Step[] => {
    return [
      {
        id: 1,
        name: 'step1',
        iconName: 'map-marker',
        labelKey: 'section.placeFromTo',
        fields: step1(),
      },
      {
        id: 2,
        name: 'step2',
        iconName: 'calendar',
        labelKey: 'section.startDate',
        fields: step2(values),
      },
      {
        id: 3,
        name: 'step3',
        iconName: 'cog',
        labelKey: 'section.otherDetails',
        fields: step3(values),
      },
      {
        id: 4,
        name: 'step4',
        iconName: 'list',
        labelKey: 'section.sumUp',
        fields: step4(values, form),
      },
    ];
  };
  const [currentStep, setcurrentStep] = useState(1);

  // Fonction qui regarde si tous les champs sont présent avant de passer à l'étape suivante
  const checkFields = (target: number, values: any) => {
    switch (target) {
      case 2:
        return !(!values.departureLocation || !values.arrivalLocation);
      case 3:
        return !(!values.departureDate || !values.departureHour);
      case 4:
        return !(!values.vehicleId || !values.seats);
      default:
        return true;
    }
  };

  const handleChangeStep = async (target: number, values: any) => {
    const isCorrectlyFilled = checkFields(target, values);

    if (isCorrectlyFilled) {
      setcurrentStep(target);
      if (target === 4) {
        // TODO : recharger que si changement de ville ou d'heure de départ
        values.sectionDetail = await setSectionDetails(values);
        values.price =
          values.sectionDetail.reduce(
            (p: number, c: any) => p + c.distance,
            0,
          ) * kmPrice;
        setIsGoogleFetching(false);
      }
    } else {
      return { [FORM_ERROR]: 'Des champs sont manquants' };
    }
  };

  const onSubmit = async (
    values: Ride & {
      departureHour?: moment.Moment;
      departureDate?: moment.Moment;
      vehicleId?: string;
      intermediateSteps: Array<City>;
      intermediateLocations?: Array<City>;
      sectionDetail: Array<SectionDetail>;
    },
  ) => {
    const payload = values;

    try {
      // On regarde si tous les champs requis sont présent
      //    - si oui : on continue
      //    - si non : on envoie une erreur qui bloque le formulaire
      if (
        !payload.departureLocation ||
        !payload.arrivalLocation ||
        !payload.departureDate ||
        !payload.departureHour ||
        !payload.vehicleId ||
        !payload.seats
      ) {
        return { [FORM_ERROR]: 'Des champs sont manquants' };
      }
      // Si on a une date de départ et heure de départ et d'arrivée,
      // on les concat pour les mettre dans un seul champ : departureTime et arrivalTime
      if (payload.departureHour && payload.departureDate) {
        const date = payload.departureDate.format('YYYY-MM-DD');
        const departureHours = payload.departureHour.format('HH:mm:ss');

        payload.departureTime = moment(`${date} ${departureHours}`).toDate();
      }

      // On récupère le véhicule
      if (payload.vehicleId) {
        payload.vehicle = vehicles?.find(
          vehicle => vehicle.id === payload.vehicleId,
        ) as UserVehicle;
      }

      // Si on a des étapes intermédiaires on les passe au back
      if (payload?.intermediateSteps?.length >= 0) {
        payload.intermediateLocations = payload.intermediateSteps;
      }

      // prix
      if (valuePrice) {
        payload.price = valuePrice;
      }

      const res = await fetchJSON({
        url: 'rides',
        method: 'POST',
        payload: { data: payload },
      });

      // Si le res de la requête est bon, on retourne à la page "Mes courses"
      if (res) {
        navigation.reset({
          index: 0,
          routes: [
            {
              name: 'Root',
              state: {
                routes: [
                  {
                    name: 'Courses',
                    state: {
                      routes: [{ name: t('courses.iDrive') }],
                    },
                  },
                ],
              },
            },
          ],
        });
        return res;

        // TODO : Bloque le submit si erreurs
      }
      return res;
    } catch (error) {
      // TODO: afficher modale erreur
      console.log(error);
      return error;
    }
  };

  const initialValues = useMemo(
    () => ({
      vehicleId:
        vehicleList && vehicleList[0].length === 1 ? vehicleList[0][0] : null,
      departureDate: moment(),
      intermediateSteps: [],
      sectionDetail: [],
      recurrentDays: [],
    }),
    [vehicleList],
  );

  return (
    <BackgroundLayout padding>
      <View style={desktopMode && desktopStyles.boxed}>
        {vehicles === null ||
        (vehicles && (!vehicles.length || vehicles.length === 0)) ? (
          <JText centered labelKey="common.loading" />
        ) : (
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            mutators={{
              // potentially other mutators could be merged here
              handleChangeSectionDetail: (args, state, utils) => {
                console.log(args, state, utils);
                // utils.changeValue(state, 'apples', () => 1);
              },
              ...arrayMutators,
            }}
            render={({ form, handleSubmit, values, submitError }) => (
              <Wizard
                steps={steps(values, form)}
                values={values}
                currentStep={currentStep}
                changeStep={handleChangeStep}
                onSubmit={handleSubmit}
                submitError={submitError}
              >
                {steps(values, form).map(
                  step =>
                    currentStep === step.id && (
                      <WizardStep
                        key={step.id}
                        labelKey={step.labelKey as I18nKey}
                        iconName={step.iconName}
                      >
                        {step.fields}
                      </WizardStep>
                    ),
                )}
              </Wizard>
            )}
          />
        )}
      </View>
    </BackgroundLayout>
  );
};

export default CourseCreateScreen;
