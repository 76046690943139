import { Theme } from 'providers/ThemeProvider';
import { StyleSheet } from 'react-native';

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      paddingHorizontal: theme.sizings.large,
      paddingVertical: theme.sizings.larger,
    },
  });
