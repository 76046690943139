import React from 'react';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import { ColorType } from 'providers/ThemeProvider';
import useTheme from 'hooks/useTheme';
import { Icon } from '../Icon/Icon';
import { Button } from '../Button/Button';

type Props = {
  note?: number;
  maximum?: number;
  colorName?: ColorType;
  size?: number;
  clickable?: boolean;
  onPress?: (value: number) => void;
  evaluation?: number;
};

export const InlineRating = (props: Props) => {
  const { theme } = useTheme();
  const {
    note = 0,
    maximum = 5,
    colorName = 'blueHigh',
    size = theme.normalize(45),
    clickable,
    onPress = () => {},
    evaluation = 0,
  } = props;
  return (
    <ViewRow justify="flex-end">
      <ViewCol inline size={2}>
        {Array.from(Array(maximum), (e, index) => {
          if (clickable) {
            return (
              <Button key={`star_${index}`} onPress={() => onPress(index + 1)}>
                <Icon
                  key={`star_${index}`}
                  name={
                    evaluation <= index
                      ? 'star-o'
                      : evaluation > index && evaluation < index + 0.7
                      ? 'star-half-o'
                      : 'star'
                  }
                  type="FontAwesome"
                  colorName={colorName}
                  customSize={size}
                />
              </Button>
            );
          }
          return (
            <Icon
              key={`start_${index}`}
              name={
                note <= index
                  ? 'star-o'
                  : note > index && note < index + 0.7
                  ? 'star-half-o'
                  : 'star'
              }
              type="FontAwesome"
              colorName={colorName}
              customSize={size}
            />
          );
        })}
      </ViewCol>
    </ViewRow>
  );
};
