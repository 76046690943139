import { useCallback, useEffect, useState } from 'react';
import CancellablePromise from 'utils/CancellablePromise';
import qs from 'qs';
import { parseStrapiFormat } from 'utils/strapi';
import {
  addEventListener,
  Listener,
  removeEventListener,
} from 'providers/SocketProvider';
import useFetch, { UseFetchOptions } from './useFetch';

interface FilterMap {
  [key: string]: string | number | boolean | object;
}

interface EventMap {
  [key: string]: (notification?: object) => void;
}

export type UseListOptions = {
  defaultFilters?: FilterMap;
  defaultSort?: string;
  defaultGroupBy?: string;
  populate?: string | object;
  fields?: string | object;
  listenToEvents?: string[];
  onEvent?: EventMap;
} & UseFetchOptions;

export type UseListType<T> = {
  items: T[] | null;
  allItemsFetched: boolean;
  isPreviousItems: boolean;
  isItemsValid: boolean;
  error: string | null;
  isFetching: boolean;
  fetchItems: (...args: any[]) => CancellablePromise<unknown>;

  pageCount: number;
  pageIndex: number;
  itemsCount: number;
  setPageIndex: (value: number) => void;
  itemsPerPage: number;
  setItemsPerPage: (value: number) => void;

  sort: string | undefined;
  setSort: ((value: string | undefined) => void) | undefined;

  groupBy: string | undefined;
  setGroupBy: ((value: string | undefined) => void) | undefined;

  filters: FilterMap;
  setFilter: (key: string, value: number | boolean | string) => void;
  setFilters: (value: FilterMap) => void;

  removeListItem: (id: number | string) => void;
  addListItem: (item: T) => void;

  fetchNextItems: () => void;
};

const useList = <T,>(
  url: string,
  options: UseListOptions = {},
): UseListType<T> => {
  const {
    defaultFilters = {},
    defaultSort,
    defaultGroupBy,
    populate = null,
    fields = null,
    listenToEvents = [],
    onEvent = {},
    ...opts
  } = options;

  opts.sharePromise = false;

  opts.cachePrefix = opts.cachePrefix ?? `list_${url.replace(/[/?&]/g, '_')}`;

  const [itemsCount, setItemsCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1000);
  const [filters, setFilters] = useState(defaultFilters);
  const [sort, setSort] = useState(defaultSort);
  const [groupBy, setGroupBy] = useState(defaultGroupBy);
  const [items, setItems] = useState<T[]>([]);

  opts.postExecute = useCallback(
    data => {
      let newItems: any = null;
      if (Array.isArray(data)) {
        setItemsCount(data.length);
        newItems = data;
      } else {
        setItemsCount(data.meta.pagination.total);
        newItems = parseStrapiFormat(data);
      }
      setItems(oldItems => {
        if (newItems) {
          if (pageIndex === 1) {
            return newItems;
          }
          const mergedItems = [...oldItems, ...newItems];
          return mergedItems;
        }
        return oldItems;
      });

      return newItems;
    },
    [pageIndex],
  );

  const getUrl = () => {
    const query = {
      sort: [],
      filters: {},
      pagination: {
        page: pageIndex,
        pageSize: itemsPerPage as number,
      },
    } as {
      sort: string[];
      filters: {
        [key: string]:
          | {
              [key: string]: string | number | boolean;
            }
          | object;
      };
      pagination?: {
        page: number;
        pageSize: number;
      };
      populate?: string | object;
      fields?: string | object;
    };

    if (populate) {
      query.populate = populate;
    }
    if (fields) {
      query.fields = fields;
    }

    if (groupBy) {
      query.sort.push(groupBy);
    }
    if (sort) {
      query.sort.push(sort);
    }

    Object.keys(filters).forEach(filter => {
      if (typeof filters[filter] === 'string') {
        const [fieldName, operator = 'eq'] = filter.split('$');
        query.filters[fieldName] = { [`$${operator}`]: filters[filter] };
      } else {
        query.filters[filter] = filters[filter] as object;
      }
    });

    const queryParams = qs.stringify(query, {
      encodeValuesOnly: true,
    });

    return `${url}${queryParams ? '?' : ''}${queryParams}`;
  };

  const { isDataValid, error, setData, isFetching, isPreviousData, fetchData } =
    useFetch<T[]>(getUrl(), opts);

  const setFilter = useCallback(
    (key, value) => {
      setFilters((prevFilters: FilterMap) => {
        if (value) {
          return { ...prevFilters, [key]: value };
        }
        const newFilters = { ...prevFilters };

        delete newFilters[key];
        return newFilters;
      });
      setPageIndex(1);
    },
    [setFilters, setPageIndex],
  );

  const removeListItem = useCallback(
    id => {
      setData((oldData: T[]) =>
        oldData.filter(d => (d as T & { id: string | number }).id !== id),
      );
    },
    [setData],
  );
  const addListItem = useCallback(
    (item: T) => {
      setData((oldData: T[]) => (oldData ?? []).concat(item));
    },
    [setData],
  );

  const allItemsFetched = itemsCount - pageIndex * itemsPerPage <= 0;

  const fetchNextItems = useCallback(() => {
    setPageIndex(prev => {
      return prev + 1;
    });
  }, [setPageIndex]);

  useEffect(() => {
    if (listenToEvents && listenToEvents.length > 0) {
      const listener: Listener = {
        events: listenToEvents,
        handleEvent: (notificationString, update) => {
          if (onEvent) {
            const onEventCallback = onEvent[notificationString] as
              | ((notification: object) => void)
              | undefined
              | null;
            if (onEventCallback) {
              onEventCallback(update);
            } else if (pageIndex !== 1) {
              setPageIndex(1);
            } else {
              fetchData();
            }
          } else if (pageIndex !== 1) {
            setPageIndex(1);
          } else {
            fetchData();
          }
        },
      };
      addEventListener(listener);

      return () => {
        removeEventListener(listener);
      };
    }
    return () => {};
  }, [listenToEvents, fetchData, pageIndex, onEvent]);

  return {
    items,
    isItemsValid: isDataValid,
    isPreviousItems: isPreviousData,
    error,
    isFetching,
    fetchItems: fetchData,

    pageCount: Math.ceil(itemsCount / itemsPerPage),
    pageIndex,
    setPageIndex,
    itemsPerPage,
    itemsCount,
    setItemsPerPage,
    allItemsFetched,

    sort,
    setSort,

    groupBy,
    setGroupBy,

    filters,
    setFilter,
    setFilters,

    removeListItem,
    addListItem,

    fetchNextItems,
  };
};

export default useList;
