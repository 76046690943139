import React from 'react';
import useTheme from 'hooks/useTheme';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import { FormInput } from 'components/common/FormInput/FormInput';
import { CircularButton } from 'components/common/CircularButton/CircularButton';
import { I18nKey } from '../../../../i18n';

type Props = {
  feedId?: number | undefined;
  responseOfId?: number | undefined;
  content: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  placeholderKey: I18nKey;
  onSubmit: (
    contentAnswer: string,
    feedId: number | undefined,
    responseOfId: number | undefined,
  ) => void;
};

export const AddComment = ({
  feedId,
  responseOfId,
  content,
  setContent,
  placeholderKey,
  onSubmit,
}: Props) => {
  const { theme } = useTheme();
  return (
    <ViewRow
      style={{
        alignItems: 'flex-start',
        marginBottom: theme.sizings.medium,
      }}
    >
      <ViewCol size={1}>
        <FormInput
          title=""
          multiline
          height={theme.sizings.sz120}
          value={content}
          onChange={setContent}
          placeholderKey={placeholderKey}
        />
      </ViewCol>
      <ViewCol
        style={{
          maxWidth: theme.sizings.sz60,
          paddingLeft: theme.sizings.medium,
        }}
        align="right"
      >
        <CircularButton
          onPress={() => onSubmit(content, feedId, responseOfId)}
          iconName="paper-plane"
          iconType="FontAwesome5"
          iconColorName="blueHigh"
          iconSizeName="medium"
          sizeName="high"
          backgroundColorName="blueLight"
        />
      </ViewCol>
    </ViewRow>
  );
};
