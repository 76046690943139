import React, { useState } from 'react';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useAuth } from 'hooks/useAuth';
import useTheme from 'hooks/useTheme';
import { RegisterForm } from 'components/forms/RegisterForm/RegisterForm';
import { TextButton } from 'components/common/TextButton/TextButton';
import { ScrollableFormLayout } from 'components/layouts/ScrollableFormLayout/ScrollableFormLayout';
import { BackgroundLayout } from 'components/layouts/BackgroundLayout/BackgroundLayout';
import { FORM_ERROR } from 'final-form';
import { FieldHelpModal } from 'components/compositions/FieldHelp/FieldHelp';
import { useTranslation } from 'react-i18next';
import useAlert from 'providers/AlertProvider';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { View } from 'react-native';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import fnStyles from '../AuthGlobalStyle';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'Register'
>;

const RegisterScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopStyles = fnDesktopStyles(theme);
  const desktopMode = isDesktopMode();
  const insets = useSafeAreaInsets();
  const styles = fnStyles(theme);
  const { t } = useTranslation();

  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);

  const { register, cleanError } = useAuth();

  const { setAlert } = useAlert();

  const handleAlreadyHaveAnAccountPressed = () => {
    navigation.navigate('Login');
  };

  const handleCreateAccount = async (values: object) => {
    const user: any = { ...values };
    delete user.passwordConfirm;

    try {
      await register(user);
      setAlert({
        message: t('alert.registerSuccess'),
        title: t('alertTitle.registerSuccess'),
      });
      navigation.reset({
        index: 1,
        routes: [{ name: 'Welcome' }, { name: 'ValidateAccount', params: {} }],
      });
      cleanError();
      return undefined;
    } catch (e: any) {
      console.log('error');
      return { [FORM_ERROR]: e.message };
    }
  };

  return (
    <BackgroundLayout padding>
      <ScrollableFormLayout style={{ marginBottom: insets.bottom }}>
        <View
          style={[
            desktopMode && desktopStyles.boxed,
            { marginTop: desktopMode ? theme.sizings.mediumLarge : undefined },
          ]}
        >
          <RegisterForm
            onSubmit={handleCreateAccount}
            onHelpInfoPress={() => setIsPasswordModalVisible(true)}
          />
          <TextButton
            labelKey="auth.alreadyHaveAnAccount"
            isBold
            style={[styles.label, { alignSelf: 'center', marginBottom: 30 }]}
            onPress={handleAlreadyHaveAnAccountPressed}
          />
        </View>
      </ScrollableFormLayout>
      <FieldHelpModal
        isVisible={isPasswordModalVisible}
        onClose={() => setIsPasswordModalVisible(false)}
        titleKey="auth.passwordRules"
        contentKey="auth.passwordRulesList"
      />
    </BackgroundLayout>
  );
};

export default RegisterScreen;
