import React from 'react';
import { ImageBackground, Dimensions, View } from 'react-native';
import isDesktopMode from 'utils/desktopMode';
import defaultBackground from '../../../../assets/tabs-background.png';

export const TabsBackground = () => {
  const desktopMode = isDesktopMode();
  return desktopMode ? (
    <View
      style={{
        height: Dimensions.get('window').width * 1.78,
        width: '100%',
        position: 'absolute',
        top: 0,
      }}
    />
  ) : (
    <ImageBackground
      source={defaultBackground}
      resizeMode="contain"
      style={{
        height: Dimensions.get('window').width * 1.78,
        width: '100%',
        position: 'absolute',
        top: 0,
      }}
    />
  );
};
