/* eslint-disable no-console */
import { useCallback, useEffect } from 'react';
import {
  AppState,
  AppStateStatus,
  EmitterSubscription,
  Platform,
} from 'react-native';
import * as Updates from 'expo-updates';
import * as Linking from 'expo-linking';
import { useNavigation } from '@react-navigation/native';

type Props = {
  children: JSX.Element;
};

const AppStateHandler = ({ children }: Props) => {
  const navigation = useNavigation();

  const handleUrlChange = useCallback(
    event => {
      if (event.url.includes('ResetPassword')) {
        const match = /^.*code=(.*)$/.exec(event.url);
        const code = match ? match[1] : null;
        navigation.reset({
          index: 1,
          routes: [
            { name: 'Welcome' },
            { name: 'ResetPassword', params: { code } },
          ],
        });
      }
    },
    [navigation],
  );

  useEffect(() => {
    const handleAppStateChange = async (nextAppState: AppStateStatus) => {
      if (!__DEV__ && Platform.OS !== 'web' && nextAppState === 'active') {
        try {
          const update = await Updates.checkForUpdateAsync();

          if (update.isAvailable) {
            await Updates.fetchUpdateAsync();
            await Updates.reloadAsync();
          }
        } catch (e) {
          console.log(e);
        }
      }

      if (nextAppState === 'active') {
        // Refresh chat etc...
      }
    };

    const onChangeSubscription = AppState.addEventListener(
      'change',
      handleAppStateChange,
    );
    let onUrlSubscription: EmitterSubscription;

    if (Platform.OS !== 'web') {
      onUrlSubscription = Linking.addEventListener('url', handleUrlChange);
    }
    const checkInitialUrl = async () => {
      const initialUrl = await Linking.getInitialURL();
      handleUrlChange({ url: initialUrl });
    };
    checkInitialUrl();

    return () => {
      onChangeSubscription.remove();
      if (Platform.OS !== 'web' && onUrlSubscription) {
        onUrlSubscription.remove();
      }
    };
  }, [handleUrlChange]);

  return children;
};

export default AppStateHandler;
