import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import useTheme from 'hooks/useTheme';
import { RootStackParamList } from 'navigation/RootStackParamList';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import isDesktopMode from 'utils/desktopMode';
import MyDriverItemsScreen from './views/MyDriverItemsScreen';
import MyPassengerItemsScreen from './views/MyPassengerItemsScreen';
import MyDriverRecurrentItemsScreen from './views/MyDriverRecurrentItemsScreen';

const Tab = createMaterialTopTabNavigator();

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'MyCoursesRoot'
>;

const MyCoursesScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const { t } = useTranslation();

  return (
    <View style={{ backgroundColor: theme.colors.light, flex: 1 }}>
      <Tab.Navigator
        initialRouteName="Informations"
        // tabBar={(props) => <MyTabBar {...props} />
        screenOptions={{
          tabBarStyle: {
            marginTop:
              desktopMode || Platform.OS === 'web'
                ? undefined
                : theme.sizings.mediumLarge,
            backgroundColor: theme.colors.greenLight,
            paddingBottom: theme.sizings.mediumLarge,
            paddingTop: desktopMode
              ? theme.sizings.mediumLarge + theme.headerSizes.desktop
              : theme.sizings.medium + theme.normalize(90),
            shadowColor: theme.colors.greenLight,
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowRadius: 0,
            shadowOpacity: 1,
            // special 2 tabs / desktop
            justifyContent: 'center',
            flexDirection: 'row',
          },
          tabBarContentContainerStyle: {
            maxWidth: desktopMode ? 800 : undefined,
          },
          tabBarItemStyle: {
            marginHorizontal: theme.sizings.smallMedium,
            backgroundColor: theme.colors.light,
            borderRadius: theme.radius.large,
            padding: 0,
            flex: undefined,
            minHeight: theme.normalize(35),
          },
          tabBarActiveTintColor: theme.colors.blueHigh,
          tabBarLabelStyle: {
            paddingHorizontal: theme.sizings.medium,
            paddingVertical: 0,
            textTransform: undefined,
            fontSize: theme.fontSizes.medium,
          },
          tabBarIndicatorStyle: {
            backgroundColor: 'transparent',
          },
          tabBarScrollEnabled: true,
        }}
      >
        <Tab.Screen
          name={t('courses.iPassenger')}
          component={MyPassengerItemsScreen}
        />
        <Tab.Screen
          name={t('courses.iDrive')}
          component={MyDriverItemsScreen}
        />
        <Tab.Screen
          name={t('courses.iDriveRecurrent')}
          component={MyDriverRecurrentItemsScreen}
        />
      </Tab.Navigator>
    </View>
  );
};

export default MyCoursesScreen;
