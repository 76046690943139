import React from 'react';
import { ScrollView } from 'react-native';
import { CircularIconStatus } from '../../components/common/CircularIconStatus/CircularIconStatus';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const CircularIconStatusScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="CircularIcon"
        description="This component renders an icon in a circle with a status in the corner."
      >
        <CircularIconStatus
          backgroundColorName="blueLight"
          padding={5}
          iconType="IonIcons"
          iconName="car-sport-outline"
          iconColorName="blueHigh"
          iconSizeName="fs30"
          statusIconType="IonIcons"
          statusIconName="checkmark-outline"
          statusIconColorName="blueHigh"
        />
      </CatalogHeader>
      <CatalogProps
        propName="backgroundColor"
        description="The scolor of the background circle."
        type="string"
        topDivider={false}
      />
      <CatalogProps
        propName="padding"
        description="The empty space between the circle and the icon."
        type="number"
      />
      <CatalogProps
        propName="iconType"
        type="enum('MaterialCommunity', 'AntDesign', 'Entypo', 'EvilIcons', 'Feather', 'FontAwesome', 'FontAwesome5', 'FontAwesome5Pro', 'Fontisto', 'Material', 'MaterialIcons', 'IonIcons')"
      />
      <CatalogProps propName="iconName" type="string" />
      <CatalogProps propName="iconSize" type="number" />
      <CatalogProps propName="iconColor" type="string" />
    </ScrollView>
  );
};

export default CircularIconStatusScreen;
