import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Card } from 'components/common/Card/Card';
import { FormInput } from 'components/common/FormInput/FormInput';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import useTheme from 'hooks/useTheme';
import useForgotPasswordForm from './useForgotPasswordForm';
import fnStyles from './ForgotPasswordStyle';

type Props = {
  onSubmit: (email: string) => Promise<void>;
  style?: StyleProp<ViewStyle>;
};

export const ForgotPasswordForm = (props: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { email, setEmail, onSubmit } = useForgotPasswordForm(props.onSubmit);

  return (
    <Card style={[styles.container, props.style]}>
      <FormInput titleKey="forms.email" value={email} onChange={setEmail} />
      <GradientButton
        labelKey="auth.sendMail"
        onPress={onSubmit}
        leftIconType="Feather"
        leftIconName="check"
        leftIconSizeName="larger"
        width="55%"
        style={{ marginTop: theme.sizings.mediumLarge }}
      />
    </Card>
  );
};
