import useTheme from 'hooks/useTheme';
import React from 'react';
import { TouchableOpacity, ViewStyle, StyleProp } from 'react-native';
import { I18nKey } from '../../../../../i18n';
import { JText } from '../../Text/Text';
import fnStyles from './SelectableOptionButtonStyle';

type Props = {
  value: string | boolean | number;
  labelKey?: I18nKey;
  label?: string;
  selectedValue?: string | string[] | boolean | number | undefined;
  onChange?: (value: string | string[] | boolean | number | undefined) => void;
  style?: StyleProp<ViewStyle>;
};

export const SelectableOptionButton = ({
  value,
  labelKey,
  label,
  selectedValue,
  style,
  onChange = () => {},
}: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  const onOptionPressed = () => {
    if (
      typeof selectedValue === 'string' ||
      typeof selectedValue === 'boolean' ||
      typeof selectedValue === 'number' ||
      selectedValue === null
    ) {
      onChange(value);
    } else if (Array.isArray(selectedValue)) {
      if (selectedValue?.length === 0) {
        onChange([value as string]);
      } else if (selectedValue?.includes(value as string)) {
        onChange(selectedValue?.filter(item => item !== value));
      } else {
        const newTab: string[] = [...selectedValue, value as string];
        onChange(newTab);
      }
    } else {
      onChange(value);
    }
  };

  const isSelected = () => {
    if (
      typeof selectedValue === 'string' ||
      typeof selectedValue === 'boolean' ||
      typeof selectedValue === 'number'
    ) {
      return selectedValue === value;
    }

    return selectedValue?.includes(value as string);
  };

  return (
    <TouchableOpacity
      style={[
        styles.container,
        style,
        {
          backgroundColor: isSelected()
            ? theme.colors.blueLight
            : theme.colors.greyLight,
        },
      ]}
      onPress={onOptionPressed}
    >
      <JText
        labelKey={labelKey}
        label={
          label ||
          (typeof value === 'number' ? (value as unknown as string) : value)
        }
        colorName="blueHigh"
        isBold
      />
    </TouchableOpacity>
  );
};
