/* const populateImage = {
  fields: ['url'],
}; */

const populateUserSummary = {
  populate: 'profilePicture',
  fields: ['username', 'firstname', 'reputation', 'birthDate'],
};

export { populateUserSummary };
