import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import useTheme from 'hooks/useTheme';
import { RootStackParamList } from 'navigation/RootStackParamList';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import { getStatusBarHeight } from 'react-native-status-bar-height';
import isDesktopMode from 'utils/desktopMode';
import DocumentsScreen from './views/documents/DocumentsScreen';
import ParametersScreen from './views/parameters/ParametersScreen';
import ReviewsScreen from './views/reviews/ReviewsScreen';
import VehiclesScreen from './views/vehicles/VehiclesScreen';
import InformationsScreen from './views/informations/InformationsScreen';

const Tab = createMaterialTopTabNavigator();

export type ScreenProps = NativeStackScreenProps<RootStackParamList, 'Profile'>;

const ProfileScreen = () => {
  /* CODE GARDE VOLONTAIREMENT POUR PLUS TARD */
  /* const MyTabBar = ({ state, descriptors, navigation, position }) => {
    return (
      <View style={{ flexDirection: 'row', paddingTop: 20 }}>
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];
          const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name;

          const isFocused = state.index === index;

          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
            });

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name);
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            });
          };
          // modify inputRange for custom behavior
          const inputRange = state.routes.map((_, i) => i);
          const opacity = Animated.interpolate(position, {
            inputRange,
            outputRange: inputRange.map(i => (i === index ? 1 : 0)),
          });

          return (
            <TouchableOpacity
              accessibilityRole="button"
              accessibilityState={isFocused ? { selected: true } : {}}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              onLongPress={onLongPress}
              style={{ flex: 1 }}
            >
              <Animated.Text style={{ opacity }}>{label}</Animated.Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }; */

  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const { t } = useTranslation();

  return (
    <View style={{ backgroundColor: theme.colors.light, flex: 1 }}>
      <Tab.Navigator
        initialRouteName="Informations"
        // tabBar={(props) => <MyTabBar {...props} />
        screenOptions={{
          tabBarStyle: {
            marginTop:
              desktopMode || Platform.OS === 'web'
                ? undefined
                : theme.sizings.mediumLarge,
            backgroundColor: theme.colors.greenLight,
            paddingBottom: theme.sizings.mediumLarge,
            paddingTop: desktopMode
              ? theme.sizings.mediumLarge + theme.headerSizes.desktop
              : theme.sizings.medium + theme.normalize(90),
            shadowColor: theme.colors.greenLight,
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowRadius: 0,
            shadowOpacity: 1,
            // special desktop
            justifyContent: desktopMode ? 'center' : undefined,
            flexDirection: desktopMode ? 'row' : undefined,
          },
          tabBarContentContainerStyle: {
            maxWidth: desktopMode ? 800 : undefined,
          },
          tabBarItemStyle: {
            marginHorizontal: theme.sizings.medium,
            backgroundColor: theme.colors.light,
            borderRadius: theme.radius.large,
            padding: 0,
            flex: undefined,
            minHeight: theme.normalize(35),
          },
          tabBarActiveTintColor: theme.colors.blueHigh,
          tabBarLabelStyle: {
            paddingHorizontal: theme.sizings.medium,
            paddingVertical: 0,
            textTransform: undefined,
            fontSize: theme.fontSizes.medium,
          },
          tabBarIndicatorStyle: {
            backgroundColor: 'transparent',
          },
          tabBarScrollEnabled: true,
        }}
      >
        <Tab.Screen
          name="Profile-informations"
          options={{
            tabBarLabel: t('titlePages.profileInformations'),
            title: t('titlePages.profileInformations'),
          }}
          component={InformationsScreen}
        />
        <Tab.Screen 
          name="Profile-vehicles"
          options={{
            tabBarLabel: t('titlePages.profileVehicles'),
            title: t('titlePages.profileVehicles'),
          }}
          component={VehiclesScreen}
        />
        <Tab.Screen
          name="Profile-documents"
          options={{
            tabBarLabel: t('titlePages.profileDocuments'),
            title: t('titlePages.profileDocuments'),
          }}
          component={DocumentsScreen}
        />
        <Tab.Screen
          name="Profile-reviews"
          options={{
            tabBarLabel: t('titlePages.profileReviews'),
            title: t('titlePages.profileReviews'),
          }}
          component={ReviewsScreen}
        />
        <Tab.Screen
          name="Profile-parameters"
          options={{
            tabBarLabel: t('titlePages.profileParameters'),
            title: t('titlePages.profileParameters'),
          }}
          component={ParametersScreen}
        />
      </Tab.Navigator>
    </View>
  );
};

export default ProfileScreen;
