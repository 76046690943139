import React from 'react';
import {
  View,
  ImageBackground,
  ViewStyle,
  StyleProp,
  ImageSourcePropType,
  Dimensions,
} from 'react-native';
import { getStatusBarHeight } from 'react-native-status-bar-height';
import useTheme from 'hooks/useTheme';
import isDesktopMode from 'utils/desktopMode';
import styles from './BackgroundLayoutStyle';
import defaultBackground from '../../../../assets/main-background.png';
import defaultDesktopBackground from '../../../../assets/desktop-background.jpg';
import ResponsiveScrollView from '../ResponsiveScrollView/ResponsiveScrollView';

type Props = {
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
  image?: ImageSourcePropType;
  padding?: boolean;
  isScrollView?: boolean;
};

export const BackgroundLayout = ({
  style,
  children,
  image = defaultBackground,
  padding = false,
  isScrollView = false,
}: Props) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.colors.light,
      }}
    >
      <ImageBackground
        source={desktopMode ? defaultDesktopBackground : image}
        resizeMode="cover"
        style={{
          marginTop: getStatusBarHeight(),
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: desktopMode
            ? Dimensions.get('window').height
            : Dimensions.get('window').height - getStatusBarHeight(),
          ...styles.background,
        }}
      />
      {isScrollView ? (
        <ResponsiveScrollView>
          <View
            style={[
              styles.container,
              style,
              {
                paddingHorizontal: padding ? theme.sizings.mediumLarge : 0,
                paddingBottom: padding ? theme.sizings.mediumLarge : 0,
                paddingTop: theme.headerSizes.mobile + getStatusBarHeight(),
              },
            ]}
          >
            {children}
          </View>
        </ResponsiveScrollView>
      ) : (
        <View
          style={[
            styles.container,
            style,
            {
              paddingHorizontal: padding ? theme.sizings.mediumLarge : 0,
              paddingBottom: padding ? theme.sizings.mediumLarge : 0,
              paddingTop: desktopMode
                ? theme.headerSizes.desktop
                : theme.headerSizes.mobile + getStatusBarHeight(),
              flex: 1,
            },
          ]}
        >
          {children}
        </View>
      )}
    </View>
  );
};
