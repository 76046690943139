import React from 'react';
import { ScrollView } from 'react-native';
import { Avatar } from '../../components/common/Avatar/Avatar';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const AvatarScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="Avatar"
        description="This component renders an avatar with an optional progress bar and an optional icon."
      >
        <Avatar
          sizeName="sz50"
          // value={90}
          iconSizeName="largest"
          iconPosition="bottomLeft"
          iconType="MaterialCommunity"
          iconName="steering"
        />
      </CatalogHeader>
      <CatalogProps
        propName="image"
        description="The image displayed in the avatar."
        type="string"
        topDivider={false}
      />
      <CatalogProps
        propName="size"
        description="The size of the avatar"
        type="number"
      />
      <CatalogProps
        propName="value"
        description="The value of the progress bar."
        type="number"
      />
      <CatalogProps
        propName="color"
        description="The color of the progress bar."
        type="string"
      />
      <CatalogProps
        propName="iconType"
        type="enum('MaterialCommunity', 'AntDesign', 'Entypo', 'EvilIcons', 'Feather', 'FontAwesome', 'FontAwesome5', 'FontAwesome5Pro', 'Fontisto', 'Material', 'MaterialIcons', 'IonIcons')"
      />
      <CatalogProps propName="iconName" type="string" />
      <CatalogProps propName="iconColor" type="string" />
      <CatalogProps propName="iconSize" type="number" />
      <CatalogProps
        propName="iconPosition"
        description="The icon position."
        type="enum('bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight')"
      />
    </ScrollView>
  );
};

export default AvatarScreen;
