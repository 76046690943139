import React from 'react';
import { ScrollView, StyleProp, View, ViewStyle } from 'react-native';
import isDesktopMode from 'utils/desktopMode';

type Props = {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
};

const ResponsiveScrollView = ({ children, style }: Props) => {
  const desktopMode = isDesktopMode();
  return desktopMode ? (
    /*
    <View style={[style, { flex: undefined }]}>{children}</View>
    */
    <ScrollView style={style}>{children}</ScrollView>
  ) : (
    <ScrollView style={style}>{children}</ScrollView>
  );
};

export default ResponsiveScrollView;
