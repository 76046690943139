import { Theme } from 'providers/ThemeProvider';
import { StyleSheet } from 'react-native';

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      marginBottom: theme.sizings.mediumLarge,
      borderRadius: theme.radius.large,
      backgroundColor: theme.colors.greyLight,
      paddingHorizontal: theme.sizings.mediumLarge,
      paddingVertical: theme.sizings.smallMedium,
    },
  });
