import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 60,
  },
  catalogArray: {
    flexDirection: 'column',
    borderColor: 'grey',
    borderBottomWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderTopWidth: 1,
    marginBottom: 5,
    marginTop: 10,
  },
  catalogArrayRow: {
    borderColor: 'grey',
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 5,
    paddingLeft: 10,
  },
  catalogArrayHeader: {
    backgroundColor: '#cacaca',
  },
  catalogArrayHeaderLabel: {
    fontWeight: 'bold',
    color: 'grey',
  },
  catalogDescription: {
    fontSize: 16,
  },
  catalogDivider: {
    borderBottomColor: '#dadde1',
    borderBottomWidth: 1,
    marginVertical: 10,
  },
  catalogItemTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 20,
  },
  catalogPropsTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    backgroundColor: '#cacaca',
    marginTop: 10,
    alignSelf: 'flex-start',
  },
  link: {
    color: 'purple',
    textDecorationLine: 'underline',
    alignSelf: 'flex-start',
    margin: 2,
  },
});
