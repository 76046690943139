import React, { useEffect, useState } from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { useAuth } from 'hooks/useAuth';
import { FormLayout } from 'components/layouts/FormLayout/FormLayout';
import { BackgroundLayout } from 'components/layouts/BackgroundLayout/BackgroundLayout';
import useTheme from 'hooks/useTheme';
import { ResetPasswordForm } from 'components/forms/ResetPasswordForm/ResetPasswordForm';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import fnStyles from '../AuthGlobalStyle';
import useAlert from '../../../providers/AlertProvider';

export type ScreenProps = NativeStackScreenProps<RootStackParamList, 'Login'>;

const ResetPasswordScreen = ({ navigation, route }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopStyles = fnDesktopStyles(theme);
  const desktopMode = isDesktopMode();
  const { setAlert } = useAlert();
  const { t } = useTranslation();
  const styles = fnStyles(theme);

  const code = route?.params?.code;

  const { resetPassword } = useAuth();

  const onSubmit = async (values: any) => {
    try {
      await resetPassword(values.password, code);
      setAlert({
        message: 'Le mot de passe a été changé',
        title: 'Information',
        color: theme.colors.statusSuccessHigh,
      });
      navigation.reset({
        index: 1,
        routes: [{ name: 'Welcome' }, { name: 'Login' }],
      });
      return undefined;
    } catch (e) {
      setAlert({
        title: 'Erreur',
        message: 'Erreur lors du changement de mot de passe',
        color: theme.colors.statusRed,
      });
      return undefined;
    }
  };

  return (
    <BackgroundLayout padding>
      <View
        style={[
          desktopMode && desktopStyles.boxed,
          { marginTop: desktopMode ? theme.sizings.mediumLarge : undefined },
        ]}
      >
        <FormLayout style={styles.form}>
          <ResetPasswordForm onSubmit={onSubmit} />
        </FormLayout>
      </View>
    </BackgroundLayout>
  );
};

export default ResetPasswordScreen;
