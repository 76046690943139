import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Overlay } from 'react-native-elements';
import fetchJSON from 'utils/fetchJSON';
import debounce from 'lodash.debounce';
import { ScrollView } from 'react-native-gesture-handler';
import { View, TouchableOpacity, Dimensions } from 'react-native';
import useTheme from 'hooks/useTheme';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import isDesktopMode from 'utils/desktopMode';
import { TextInput } from '../TextInput/TextInput';
import { JText } from '../../Text/Text';
import { GradientButton } from '../../GradientButton/GradientButton';

export type AutoCompleteProps = {
  url: string;
  value: { name: string; id: string };
  onChange: (value: any) => void;
};

export const AutoComplete = ({
  value,
  onChange,
  url = 'autocomplete',
}: AutoCompleteProps) => {
  const { theme } = useTheme();
  const insets = useSafeAreaInsets();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [inputStr, setInputStr] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<string[]>([]);

  const getSuggestions = useCallback(
    async (newWord: string) => {
      try {
        const location = newWord.split(' ').join('-');

        const res = await fetchJSON({
          url: `${url}?input=${newWord}`,
          // url: `${url}?filters[$or][0][name][$contains]=${location}&filters[$or][1][postalCode][$contains]=${location}`,
          method: 'GET',
        });
        const suggestions = res?.predictions?.map(p => ({
          place_id: p.place_id,
          name: p.description,
        }));
        return suggestions;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    [url],
  );

  useEffect(() => {
    if (inputStr) {
      setLoading(true);
      getSuggestions(inputStr).then((responses: string[]) => {
        setOptions(responses);
        setLoading(false);
      });
    } else {
      setOptions([]);
    }
  }, [getSuggestions, inputStr]);

  const setDebouncedInputStr = useMemo(
    () => debounce(setInputStr, 300),
    [setInputStr],
  );

  const updateValue = (newValue: string) => {
    setDebouncedInputStr(newValue);
  };

  const handleOpenModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleSelect = useCallback(
    async val => {
      const res = await fetchJSON({
        url: `place?place_id=${val.place_id}`,
        // url: `${url}?filters[$or][0][name][$contains]=${location}&filters[$or][1][postalCode][$contains]=${location}`,
        method: 'GET',
      });

      if (res?.results) {
        const city = res.results[0].address_components?.find(({ types }: any) =>
          types.includes('locality'),
        )?.short_name;
        const code = res.results[0].address_components?.find(({ types }: any) =>
          types.includes('postal_code'),
        )?.short_name;

        onChange({
          name: city || res.results[0].formatted_address,
          place_id: val.place_id,
          county: res.results[0].formatted_address,
          longitude: res.results[0].geometry.location.lng,
          latitude: res.results[0].geometry.location.lat,
          postalCode: code,
        });
      } else {
        onChange({ name: val.name, id: val.place_id });
      }
    },
    [onChange],
  );

  const desktopMode = isDesktopMode();

  return (
    <>
      <TouchableOpacity
        onPress={handleOpenModal}
        style={{
          paddingLeft: theme.sizings.medium,
          paddingRight: theme.sizings.medium,
          flex: 1,
          backgroundColor: theme.colors.greyLight,
          borderRadius: theme.sizings.smallMedium,
          height: theme.normalize(40),
          justifyContent: 'center',
        }}
      >
        <JText label={value.name} />
      </TouchableOpacity>
      <Overlay
        isVisible={showModal}
        overlayStyle={{
          width: desktopMode
            ? 800
            : Dimensions.get('window').width - theme.sizings.mediumLarge * 2,
          flex: 1,
          marginTop: theme.sizings.large + insets.top,
          marginBottom: theme.sizings.large,
          flexDirection: 'column',
          borderRadius: theme.radius.medium,
        }}
        onBackdropPress={() => setShowModal(false)}
      >
        <View style={{ flexDirection: 'row' }}>
          <TextInput onChange={updateValue} autoFocus autoCorrect={false} />
        </View>
        <ScrollView
          style={{
            flex: 1,
            width: '100%',
            paddingVertical: theme.sizings.mediumLarge,
          }}
          contentContainerStyle={{ justifyContent: 'center' }}
        >
          {loading && <JText labelKey="common.loading" />}
          {!loading &&
            options?.map((_value: any) => (
              <TouchableOpacity
                key={`${inputStr}_${_value.place_id}`}
                style={{
                  flex: 1,
                  paddingVertical: theme.sizings.mediumLarge,
                  paddingHorizontal: theme.sizings.mediumLarge,
                  borderColor: theme.colors.greyLight,
                  borderWidth: theme.normalize(1),
                  borderRadius: theme.radius.medium,
                  marginBottom: theme.sizings.medium,
                }}
                onPress={() => {
                  setOptions([]);
                  handleSelect(_value);
                  setShowModal(false);
                }}
              >
                <JText label={`${_value.name}`} />
              </TouchableOpacity>
            ))}
          {!options && <JText labelKey="forms.noSuggestions" />}
          {!inputStr && <JText labelKey="forms.enterSomethingToSuggest" />}
        </ScrollView>
        <GradientButton
          onPress={() => setShowModal(false)}
          labelKey="common.back"
          leftIconName="angle-left"
          leftIconType="FontAwesome5"
        />
      </Overlay>
    </>
  );
};

export default AutoComplete;
