import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Card } from 'components/common/Card/Card';
import { FormInput } from 'components/common/FormInput/FormInput';
import { TextButton } from 'components/common/TextButton/TextButton';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import useTheme from 'hooks/useTheme';
import useLoginForm from './useLoginForm';
import fnStyles from './LoginFormStyle';

type Props = {
  onSubmit: (email: string, password: string) => Promise<void>;
  handleForgotPasswordPressed: () => void;
  style?: StyleProp<ViewStyle>;
};

export const LoginForm = (props: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { email, setEmail, password, setPassword, onSubmit } = useLoginForm(
    props.onSubmit,
  );

  return (
    <Card style={[styles.container, props.style]}>
      <FormInput titleKey="forms.email" value={email} onChange={setEmail} />
      <FormInput
        titleKey="forms.password"
        value={password}
        onChange={setPassword}
        secure
      />

      <TextButton
        onPress={props.handleForgotPasswordPressed}
        labelKey="auth.forgotPassword"
        style={{ alignSelf: 'flex-start' }}
      />
      <GradientButton
        labelKey="auth.login"
        onPress={onSubmit}
        leftIconType="Feather"
        leftIconName="check"
        leftIconSizeName="larger"
        width="55%"
        style={{ marginTop: theme.sizings.mediumLarge }}
      />
    </Card>
  );
};
