import React, { useEffect } from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { BackgroundLayout } from 'components/layouts/BackgroundLayout/BackgroundLayout';
import { Card } from 'components/common/Card/Card';
import { View } from 'react-native';
import { User } from 'types/User';
import { populateUserSummary } from 'utils/populateModels';
import { Message } from 'types/Message';
import { Conversation } from 'types/Conversation';
import useAuth from 'hooks/useAuth';
import useList from 'hooks/useList';
import { JText } from 'components/common/Text/Text';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import useTheme from 'hooks/useTheme';
import { useFocusEffect } from '@react-navigation/native';
import MessageItem from '../../../components/compositions/MessageItem/MessageItem';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'Messages'
>;

const MessagesScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const desktopStyles = fnDesktopStyles(theme);
  const { user: currentUser } = useAuth();

  // conversations
  const { items: conversations, fetchItems } = useList<Conversation>(
    'conversations',
    {
      populate: {
        users: populateUserSummary,
        messages: {
          populate: {
            receiver: populateUserSummary,
          },
        },
      },
      listenToEvents: ['new_message', 'new_conversation'],
      defaultFilters: {
        users: [currentUser?.id],
      },
      defaultSort: 'updatedAt:DESC',
    },
  );

  useFocusEffect(
    React.useCallback(() => {
      fetchItems();
    }, [fetchItems]),
  );

  return (
    <BackgroundLayout isScrollView padding>
      <View style={desktopMode && desktopStyles.boxed}>
        <Card>
          {currentUser && conversations && conversations?.length > 0 ? (
            conversations.map((conversation: Conversation, index: number) => {
              const key = `message-${index}`;
              const correspondantUser = conversation.users.find(
                (user: User) => user.id !== currentUser.id,
              );

              //  pour l'instant ça renvoit des tableaux de max 1 message non-lu, améliorer en v2
              const lastMessageUnread = conversation.messages?.filter(
                (message: Message) => {
                  return (
                    message.id === Number(conversation.lastMessageId) &&
                    !message.isRead &&
                    message.receiver.id === currentUser.id
                  );
                },
              );

              return (
                correspondantUser && (
                  <View key={key} style={{ width: '100%' }}>
                    <MessageItem
                      onMessagePress={() =>
                        navigation.navigate('Chat', {
                          conversation: conversation.id,
                          correspondantUser: correspondantUser.id,
                        })
                      }
                      onAvatarPress={() =>
                        navigation.navigate('ThirdPartyProfile', {
                          profileId: correspondantUser.id,
                        })
                      }
                      user={
                        correspondantUser.username ||
                        correspondantUser.firstname
                      }
                      image={correspondantUser.profilePicture}
                      date={conversation.updatedAt}
                      unreadCounter={lastMessageUnread?.length}
                      message={conversation.lastMessage}
                      lastIndex={index + 1 === conversations.length}
                    />
                  </View>
                )
              );
            })
          ) : (
            <JText labelKey="chat.noConversationYet" centered />
          )}
        </Card>
      </View>
    </BackgroundLayout>
  );
};

export default MessagesScreen;
