import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Card } from 'components/common/Card/Card';
import { InlineRating } from 'components/common/InlineRating/InlineRating';
import { Section } from 'components/common/Section/Section';
import { JText } from 'components/common/Text/Text';
import { ReviewPreview } from 'components/compositions/ReviewPreview/ReviewPreview';
import { TabsBackground } from 'components/layouts/BackgroundLayout/TabsBackgound';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import ResponsiveScrollView from 'components/layouts/ResponsiveScrollView/ResponsiveScrollView';
import useAuth from 'hooks/useAuth';
import useList from 'hooks/useList';
import useTheme from 'hooks/useTheme';
import { RootStackParamList } from 'navigation/RootStackParamList';
import React from 'react';
import { View } from 'react-native';
import { Notation } from 'types/Notation';
import isDesktopMode from 'utils/desktopMode';
import { populateUserSummary } from 'utils/populateModels';
import fnDesktopStyles from 'utils/desktopModeStyle';

export type ScreenProps = NativeStackScreenProps<RootStackParamList, 'Reviews'>;

const ReviewsScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const desktopStyles = fnDesktopStyles(theme);

  /* user */
  const { user: currentUser } = useAuth();

  /* avis */
  const { items: notations, isFetching: isNotationsFetching } =
    useList<Notation>('notations', {
      populate: {
        userAuthor: populateUserSummary,
        userTarget: populateUserSummary,
        ride: {
          populate: {
            driver: populateUserSummary,
            vehicle: {
              populate: 'picture',
            },
          },
        },
      },
      defaultFilters: {
        userTarget: currentUser?.id || '',
      },
    });

  const votes = notations?.length || 0;
  // const [note, setNote] = useState(0);

  // calculer la note moyenne
  /* useEffect(() => {
    let datas = [];
    if (!isNotationsFetching && notations?.length) {
      datas = notations
        .map((notation: Notation) => notation.mark)
        .filter((m: number) => m !== null);
      const sum =
        datas.reduce(
          (previous: any, current: any) => (previous || 0) + (current || 0),
        ) || 0;
      setNote(Math.round((sum / datas.length) * 10) / 10);
    }
  }, [isNotationsFetching, notations]);
*/
  return (
    <>
      <TabsBackground />
      <ResponsiveScrollView>
        <View
          style={[
            {
              padding: theme.sizings.mediumLarge,
              flex: 1,
            },
            desktopMode && desktopStyles.boxed,
          ]}
        >
          <Card style={{ marginBottom: theme.sizings.mediumLarge * 2 }}>
            <Section titleKey="reviews.globalTitle">
              <ViewRow style={{ paddingVertical: theme.sizings.mediumLarge }}>
                <ViewCol align="center" size={2}>
                  <InlineRating
                    note={currentUser?.reputation}
                    size={theme.normalize(30)}
                    colorName="greenHigh"
                  />
                  <JText
                    labelKey="sentences.votes"
                    valuesKey={{ votes }}
                    sizeName="medium"
                  />
                </ViewCol>
                <ViewCol inline justify="flex-end" align="center" size={1}>
                  <JText
                    label={`${currentUser?.reputation || '?'}`}
                    isBold
                    sizeName="fs40"
                    style={{ paddingTop: theme.sizings.small }}
                  />
                  <JText label="/5" sizeName="larger" />
                </ViewCol>
              </ViewRow>
            </Section>

            <Section titleKey="reviews.reviewsTitle">
              {notations && notations.length > 0 ? (
                notations.map((notation: Notation, index: number) => {
                  const key = `notation-${index}`;
                  return (
                    <View key={key}>
                      <ReviewPreview
                        onPress={() =>
                          navigation.navigate('ThirdPartyProfile', {
                            profileId: notation.userAuthor.id,
                          })
                        }
                        date={notation.createdAt}
                        reviewTitle={notation.comment}
                        reviewNote={notation.mark}
                        reviewerWoman={notation.userAuthor.gender === 'W'}
                        reviewerName={
                          notation.userAuthor.username ||
                          notation.userAuthor.firstname
                        }
                        reviewerNote={notation.userAuthor.reputation}
                        reviewerRole={
                          notation.ride?.driver.id === notation.userAuthor.id
                            ? 'driver'
                            : 'passenger'
                        }
                        targetRole={
                          notation.ride?.driver?.id === notation.userTarget.id
                            ? 'driver'
                            : 'passenger'
                        }
                        targetName={
                          notation.userTarget.username ||
                          notation.userTarget.firstname
                        }
                        vehicle={notation.ride?.vehicle.name}
                        image={notation.userAuthor.profilePicture}
                      />
                    </View>
                  );
                })
              ) : (
                <JText labelKey="reviews.noReviews" centered />
              )}
            </Section>
          </Card>
        </View>
      </ResponsiveScrollView>
    </>
  );
};

export default ReviewsScreen;
