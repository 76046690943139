import React, { useCallback } from 'react';
import { JText } from 'components/common/Text/Text';
import Modal from 'components/common/Modal/Modal';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import { I18nKey } from '../../../../i18n';
import { Alert, Linking } from 'react-native';


type PropsModal = {
  isVisible: boolean;
  onClose: () => void;
};

export const ContactHelpModal = ({
  isVisible,
  onClose,
}: PropsModal) => {

    const OpenURLButton = ({ url }: any) => {
        const handlePress = useCallback(async () => {
          // Checking if the link is supported for links with custom URL scheme.
          const supported = await Linking.canOpenURL(url);
      
          if (supported) {
            // Opening the link with some app, if the URL scheme is "http" the web link should be opened
            // by some browser in the mobile
            await Linking.openURL(url);
          } else {
            Alert.alert(`Don't know how to open this URL: ${url}`);
          }
        }, [url]);
      
        return (
          <GradientButton
            labelKey="actions.contactUs"
            onPress={handlePress}
          />
        );
      };

  const modalContent = <JText labelKey="actions.contactUsLegende" />;
  const modalFooter = (
    <OpenURLButton url="mailto:conseil@ciligo.fr" />
  );

  return (
    <Modal
      titleKey="actions.help"
      isVisible={isVisible}
      onBackDropPress={onClose}
      renderContent={modalContent}
      renderFooter={modalFooter}
      iconName="question"
      iconType="FontAwesome5"
    />
  );
};
