import React from 'react';
import { View, ScrollView } from 'react-native';
import { CircularButton } from '../../components/common/CircularButton/CircularButton';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const CircularButtonScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="CircularButton"
        description="This component renders a circular button."
      >
        <View style={{ flexDirection: 'row' }}>
          <CircularButton
            labelKey="courses.passenger"
            iconType="Material"
            iconName="luggage"
            iconColorName="blueHigh"
            backgroundColorName="blueLight"
            sizeName="sz95"
            iconSizeName="fs50"
          />
          <View style={{ width: 30 }} />
          <CircularButton
            labelKey="courses.driver"
            iconType="IonIcons"
            iconName="car-sport-outline"
            iconColorName="blueHigh"
            backgroundColorName="blueLight"
            sizeName="sz95"
            iconSizeName="fs50"
          />
        </View>
      </CatalogHeader>
      <CatalogProps propName="label" type="string" topDivider={false} />
      <CatalogProps
        propName="color"
        description="The color of the background of the button."
        type="string"
      />
      <CatalogProps
        propName="size"
        description="The size of the button."
        type="number"
      />
      <CatalogProps propName="onPress" type="( ) => void" />
      <CatalogProps
        propName="iconType"
        type="enum('MaterialCommunity', 'AntDesign', 'Entypo', 'EvilIcons', 'Feather', 'FontAwesome', 'FontAwesome5', 'FontAwesome5Pro', 'Fontisto', 'Material', 'MaterialIcons', 'IonIcons')"
      />
      <CatalogProps propName="iconName" type="string" />
      <CatalogProps propName="iconSize" type="number" />
      <CatalogProps propName="iconColor" type="string" />
    </ScrollView>
  );
};

export default CircularButtonScreen;
