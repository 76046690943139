import { useCallback, useEffect, useState } from 'react';
import fetchJSON from 'utils/fetchJSON';
import { UserDocument } from 'types/UserDocument';
import useAuth from 'hooks/useAuth';
import { StrapiFile } from 'types/StrapiFile';
import {
  addEventListener,
  Listener,
  removeEventListener,
} from 'providers/SocketProvider';

export type UseDocumentProps = {
  docUrl: string;
  docType: string;
  listenToEvents?: string[];
  filteringConditions?: string[];
  parent?: string;
  parentId?: number;
};

export const useDocument = ({
  docUrl,
  docType,
  listenToEvents = [],
  parent,
  parentId,
}: UseDocumentProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingError, setIsFetchingError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [document, setDocument] = useState<UserDocument>();
  const { user: currentUser } = useAuth();

  const formatDocument = (strapiDoc: any): UserDocument => {
    return {
      id: strapiDoc.id,
      isID: strapiDoc.isID,
      isLicense: strapiDoc.isLicense,
      isCarRegistrationCard: strapiDoc.isCarRegistrationCard,
      isInsurance: strapiDoc.isInsurance,
      user: strapiDoc.user,
      validationDate: strapiDoc.validationDate ?? undefined,
      status: strapiDoc.status,
      document: strapiDoc.document,
    };
  };

  const getExistingDoc = useCallback(async (): Promise<UserDocument | null> => {
    if (currentUser && docType) {
      const filters = [`filters[${docType}][$eq]=true`];
      if (parent) {
        filters.push(`filters[${parent}][id][$eq]=${parentId}`);
      }
      const populate =
        '&populate[0]=user&populate[1]=document&populate[2]=vehicle';
      const sort = '&_sort=updatedAt:DESC';
      // console.log(`${docUrl}/me?${filter.join('&')}${populate}${sort}`);
      try {
        const existingDocs = await fetchJSON({
          url: `${docUrl}?${filters.join('&')}${populate}${sort}`,
          method: 'GET',
        });

        const orderedAndSortedDocs = existingDocs.sort(
          (a: any, b: any) => b.id - a.id,
        );
        return orderedAndSortedDocs?.length
          ? formatDocument(orderedAndSortedDocs?.[0])
          : null;
      } catch (error) {
        console.log(error);
        return null;
      }
    }
    return null;
  }, [currentUser, docType, docUrl, parent, parentId]);

  const fetchDocument = useCallback(async (): Promise<boolean> => {
    if (!currentUser) return false;

    setIsFetching(true);

    try {
      const userDocument = await getExistingDoc();
      setDocument(userDocument ?? undefined);

      return true;
    } catch (error) {
      setIsFetchingError(true);
      return false;
    } finally {
      setIsFetching(false);
    }
  }, [currentUser, getExistingDoc]);

  useEffect(() => {
    if (currentUser?.id) {
      fetchDocument();
    }
  }, [currentUser, fetchDocument]);

  const updateDocument = useCallback(
    async (file: StrapiFile): Promise<UserDocument | null> => {
      if (!currentUser) return null;

      const payload = {
        document: file,
        [docType]: true,
        status: 'in_waiting',
        user: currentUser,
        validationDate: null,
        refusalDate: null,
        [parent ?? 'unused']: parentId,
      };

      setIsFetching(true);

      const userDocument = await getExistingDoc();
      if (userDocument) {
        // Update
        try {
          const res = await fetchJSON({
            url: `${docUrl}/${userDocument.id}`,
            method: 'PUT',
            payload: { data: payload },
          });
          await fetchDocument();
          return res;
        } catch (e) {
          console.log(e);
        } finally {
          setIsFetching(false);
        }
      } else {
        // Create
        try {
          const res = await fetchJSON({
            url: docUrl,
            method: 'POST',
            payload: { data: payload },
          });
          await fetchDocument();
          return res;
        } catch (e) {
          console.log(e);
        } finally {
          setIsFetching(false);
        }
      }

      return null;
    },
    [
      currentUser,
      docType,
      docUrl,
      fetchDocument,
      getExistingDoc,
      parent,
      parentId,
    ],
  );

  useEffect(() => {
    if (listenToEvents && listenToEvents.length > 0) {
      const listener: Listener = {
        events: listenToEvents,
        handleEvent: () => {
          fetchDocument();
        },
      };
      addEventListener(listener);

      return () => {
        removeEventListener(listener);
      };
    }
    return () => {};
  }, [listenToEvents, fetchDocument]);

  const cleanError = useCallback(() => {
    setIsFetchingError(false);
    setErrorMessage(undefined);
  }, []);

  return {
    document,
    fetchDocument,
    updateDocument,
    isFetching,
    isFetchingError,
    errorMessage,
    cleanError,
  };
};

export default useDocument;
