import { Theme } from 'providers/ThemeProvider';
import { StyleSheet } from 'react-native';

export default (theme: Theme) =>
  StyleSheet.create({
    boxed: {
      width: 800,
      maxWidth: '100%',
      alignSelf: 'center',
    },
  });
