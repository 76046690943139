/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Icon } from 'components/common/Icon/Icon';
import { Platform, View } from 'react-native';
import useTheme from 'hooks/useTheme';
import { useTranslation } from 'react-i18next';
import {
  MyProfileRightAvatar,
  MyProfileTitle,
} from 'components/headers/ProfileHeaders';
import HeaderNotifications from 'components/headers/HeaderNotifications';
import isDesktopMode from 'utils/desktopMode';
import HeaderShape, { HeaderLogo } from 'components/headers/HeaderShape';
import MyCoursesRootScreen from './mycourses/MyCoursesRootScreen';
import AlertsScreen from './alerts/AlertsScreen';
import ProfileScreen from './profile/ProfileScreen';
import MessagesScreen from './messages/MessagesScreen';
import HomeScreen from './home/HomeScreen';
import { useAuth } from '../../hooks/useAuth';

export type ScreenProps = NativeStackScreenProps<RootStackParamList, 'Root'>;

const Tab = createBottomTabNavigator();

const RootScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();

  const HomeIcon = () => (
    <View style={{ height: theme.normalize(26) }}>
      <Icon
        type="MaterialIcons"
        name="home"
        sizeName="largest"
        customSize={theme.normalize(26)}
      />
    </View>
  );

  const AlertsIcon = () => (
    <View style={{ height: theme.normalize(26) }}>
      <Icon
        type="FontAwesome5"
        name="thumbtack"
        sizeName="larger"
        style={{ position: 'relative', top: theme.sizings.tiny }}
      />
    </View>
  );

  const CoursesIcon = () => (
    <View style={{ height: theme.normalize(26) }}>
      <Icon type="FontAwesome5" name="car" sizeName="largest" />
    </View>
  );

  const ProfileIcon = () => (
    <View style={{ height: theme.normalize(26) }}>
      <Icon type="MaterialIcons" name="account-circle" sizeName="largest" />
    </View>
  );

  const MessagesIcon = () => (
    <View style={{ height: theme.normalize(26) }}>
      <Icon type="FontAwesome" name="comments" sizeName="largest" />
    </View>
  );

  if (!currentUser) {
    navigation.reset({
      index: 0,
      routes: [{ name: 'Login' }],
    });
  }

  const desktopMode = isDesktopMode();

  return (
    <Tab.Navigator
      initialRouteName="Home"
      screenOptions={{
        headerTitleAlign: 'center',
        headerTintColor: theme.colors.blueHigh,
        headerBackTitleVisible: false,
        headerBackImage: () => (
          <View
            style={{
              width: theme.normalize(50),
              alignItems: 'center',
              height:
                desktopMode || Platform.OS === 'web'
                  ? undefined
                  : theme.headerSizes.mobile,
            }}
          >
            <Icon name="arrow-left" type="FontAwesome5" sizeName="larger" />
          </View>
        ),
        headerTitleStyle: {
          fontSize: theme.fontSizes.fs18,
          fontWeight: '700',
          fontFamily: theme.fonts.fontTitle,
          height:
            desktopMode || Platform.OS === 'web'
              ? undefined
              : theme.headerSizes.mobile,
        },
        headerStyle: {
          // height: theme.normalize(89),
        },
        headerTransparent: true,
        headerBackground: () => <HeaderShape />,
        tabBarStyle: {
          backgroundColor: theme.colors.light,
          height: theme.normalize(Platform.OS === 'ios' ? 100 : 75),
        },
        tabBarItemStyle: {
          justifyContent: 'center',
        },
        tabBarLabelPosition: 'below-icon',
        tabBarLabelStyle: {
          fontSize: theme.normalize(16),
          fontFamily: theme.fonts.fontBody,
          marginTop: theme.sizings.small,
          marginBottom: theme.sizings.medium,
        },
        tabBarIconStyle: {
          marginTop: theme.sizings.large,
          marginBottom: theme.sizings.small,
        },
        tabBarActiveTintColor: theme.colors.blueHigh,
        tabBarInactiveTintColor: theme.colors.greyMedium,
        tabBarActiveBackgroundColor: theme.colors.greyLight,
        // tabBarScrollEnabled: true,
      }}
    >
      <Tab.Screen
        name="Home"
        component={HomeScreen}
        options={({ route }: any) => ({
          tabBarLabel: t('titlePages.home'),
          tabBarIcon: HomeIcon,
          headerRight: () => (
            <HeaderNotifications route={route} navigation={navigation} />
          ),
          headerTitle: () => <HeaderLogo />,
          title: t('titlePages.home'),
        })}
      />
      <Tab.Screen
        name="Alert"
        component={AlertsScreen}
        options={({ route }: any) => ({
          tabBarLabel: t('titlePages.alertsTab'),
          tabBarIcon: AlertsIcon,
          headerRight: () => (
            <HeaderNotifications route={route} navigation={navigation} />
          ),
          headerTitle: t('titlePages.alerts'),
          title: t('titlePages.alerts'),
        })}
      />
      <Tab.Screen
        name="Courses"
        component={MyCoursesRootScreen}
        options={({ route }: any) => ({
          tabBarLabel: t('titlePages.coursesTab'),
          tabBarIcon: CoursesIcon,
          headerRight: () => (
            <HeaderNotifications route={route} navigation={navigation} />
          ),
          headerTitle: t('titlePages.courses'),
          title: t('titlePages.courses'),
        })}
      />
      <Tab.Screen
        name="Profile"
        component={ProfileScreen}
        options={{
          tabBarLabel: t('titlePages.profileTab'),
          tabBarIcon: ProfileIcon,
          headerRight: () => <MyProfileRightAvatar />,
          headerTitle: () => <MyProfileTitle />,
          title: t('titlePages.profile'),
        }}
      />

      <Tab.Screen
        name="Messages"
        component={MessagesScreen}
        options={({ route }: any) => ({
          tabBarLabel: t('titlePages.messagesTab'),
          tabBarIcon: MessagesIcon,
          headerRight: () => (
            <HeaderNotifications route={route} navigation={navigation} />
          ),
          headerTitle: t('titlePages.messages'),
          title: t('titlePages.messages'),
        })}
      />
    </Tab.Navigator>
  );
};

export default RootScreen;
