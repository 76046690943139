import React, { useCallback } from 'react';
import { Alert, Linking, Image, Text, View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from 'navigation/RootStackParamList';
import useTheme from 'hooks/useTheme';
import { JText } from 'components/common/Text/Text';
import { Section } from 'components/common/Section/Section';
import { Card } from 'components/common/Card/Card';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import { TileButton } from 'components/common/TileButton/TileButton';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Divider } from 'react-native-elements';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import { BackgroundLayout } from '../../../components/layouts/BackgroundLayout/BackgroundLayout';
import imgRegion from '../../../../assets/about/about-region.png';
import imgCompany from '../../../../assets/about/Ciligo-entreprise.jpg';
import imgDriver from '../../../../assets/about/about-driver.jpg';
import imgPassenger from '../../../../assets/about/about-passenger.jpg';

export type ScreenProps = NativeStackScreenProps<RootStackParamList, 'About'>;

const OpenURLButton = ({ url }: any) => {
  const handlePress = useCallback(async () => {
    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(url);
    } else {
      Alert.alert(`Don't know how to open this URL: ${url}`);
    }
  }, [url]);

  return (
    <TileButton
      tileColorName="greenLight"
      leftIconType="FontAwesome5"
      leftIconName="envelope"
      labelKey="actions.contactUs"
      onPress={handlePress}
    />
  );
};

const AboutScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopStyles = fnDesktopStyles(theme);
  const desktopMode = isDesktopMode();
  const insets = useSafeAreaInsets();

  return (
    <>
      <BackgroundLayout isScrollView padding>
        <View style={desktopMode && desktopStyles.boxed}>
          <Card style={{ marginBottom: theme.sizings.mediumLarge }}>
            <JText
              label="Simple, rapide et ergonomique, notre plateforme est un facilitateur
            de mise en relation. Covoiturez, partagez, économisez!"
              sizeName="large"
              centered
            />
          </Card>
          <Card style={{ marginBottom: theme.sizings.mediumLarge }}>
            <Section title="Comment ça marche ?">
              <JText
                sizeName="larger"
                colorName="greenHigh"
                isTitleText
                label="Je suis passager"
              />
              <JText
                label="Vous êtes à la recherche d’un conducteur pour partager un trajet
              sur les routes du département ? Ciligo est là pour vous aider !"
              />

              <Image
                source={imgPassenger}
                style={{
                  width: '100%',
                  height: theme.normalize(200),
                  borderRadius: theme.radius.rounded,
                  marginVertical: theme.sizings.mediumLarge,
                }}
                resizeMode="cover"
              />
              <JText label="1) Recherchez votre trajet !" />
              <JText label="2) Choississez votre trajet dans la liste proposée !" />
              <JText label="3) Postulez directement depuis le descriptif du trajet !" />
              <JText label="4) Si le conducteur accepte votre trajet sera réservé !" />

              <Divider style={{ marginVertical: theme.sizings.mediumLarge }} />

              <JText
                sizeName="larger"
                colorName="greenHigh"
                isTitleText
                label="Je conduis"
              />

              <JText
                label="Vous avez de la place dans votre véhicule et souhaitez proposer un
              trajet pour covoiturer avec un ou plusieurs passagers de manière
              ponctuelle ou régulière ? C’est super ! Bienvenue sur Ciligo !"
              />

              <Image
                source={imgDriver}
                style={{
                  width: '100%',
                  height: theme.normalize(200),
                  borderRadius: theme.radius.rounded,
                  marginVertical: theme.sizings.mediumLarge,
                }}
                resizeMode="cover"
              />
              <JText label="1) Cliquez sur « Ajouter un trajet »" />
              <JText label="2) Remplissez le formulaire !" />
              <JText label="3) Publiez !" />

              <Divider style={{ marginVertical: theme.sizings.mediumLarge }} />

              <JText
                sizeName="larger"
                colorName="greenHigh"
                isTitleText
                label="Je suis une entreprise"
              />

              <JText
                label="Devenir partenaire Entreprise, collectivité ou association… vous
              souhaitez proposer à vos collaborateurs une solution pour
              optimiser leurs déplacements ? Ciligo peut facilement s’intégrer à
              votre plan de mobilité."
              />

              <Image
                source={imgCompany}
                style={{
                  width: '100%',
                  height: theme.normalize(200),
                  borderRadius: theme.radius.rounded,
                  marginVertical: theme.sizings.mediumLarge,
                }}
                resizeMode="cover"
              />
              <JText label="1) Un accompagnement dans la mise en place de la plateforme" />
              <JText label="2) Un Kit de communication pour relai auprès de vos collaborateurs" />
              <JText
                label="3) Une visibilité sur le site ciligo.fr auprès de tous les
              covoitureurs"
              />
            </Section>
          </Card>

          <Card style={{ marginTop: theme.sizings.mediumLarge }}>
            <Section title="Qui sommes-nous ?">
              <JText
                label=" « Il existe dans la région Nouvelle-Aquitaine des zones blanches
              de mobilité, où les transports en commun ne sont pas compatibles
              avec les heures de travail. En raison du prix du carburant et pour
              préserver le pouvoir d’achat, le développement du covoiturage de
              proximité est indispensable. »"
              />

              <JText label="Muriel Boulmier, fondatrice de Ciligo" isBold />

              <Divider style={{ marginVertical: theme.sizings.mediumLarge }} />

              <JText
                sizeName="larger"
                colorName="greenHigh"
                isTitleText
                label="Ciligo, une plateforme de covoiturage « made en Nouvelle-Aquitaine » !"
              />
              <JText
                label="Ciligo est la première plateforme collaborative de covoiturage de
              proximité pour la Nouvelle-Aquitaine. Née en zone rurale - fondée
              à Agen en 2019 par l’association Cilinnov - Ciligo s’est
              développée et rayonne depuis dans la région Nouvelle-Aquitaine.
              Ciligo, c’est un facilitateur, un incubateur afin d'inciter les
              gens à pratiquer les déplacements autrement. Ciligo permet ainsi
              aux usagers de faire des économies et donc de gagner en pouvoir
              d’achat tout en réduisant l'usage de leur voiture personnelle et
              donc leur impact sur l’environnement !"
              />

              <Divider style={{ marginVertical: theme.sizings.mediumLarge }} />

              <JText
                sizeName="larger"
                colorName="greenHigh"
                isTitleText
                label="Ciligo, une application pour simplifier la vie au covoiturage"
              />
              <JText
                label="Dans le prolongement du site internet lancé en 2019 et pour
              répondre aux exigences de mobilité de ses usagers, Ciligo a lancé,
              en mars 2022, son application mobile pour faciliter l’utilisation
              de son service au quotidien. L’objectif : pouvoir covoiturer de
              manière rapide et efficace ! Cette application, entièrement
              personnalisable, s'adapte à tous les profils d'utilisateurs en
              fonction de leurs habitudes de covoiturage. L’application est
              gratuite et disponible en français sur IOS et Android."
              />

              <Divider style={{ marginVertical: theme.sizings.mediumLarge }} />

              <JText
                sizeName="larger"
                colorName="greenHigh"
                isTitleText
                label="Ciligo, une plateforme pour tous"
              />
              <JText
                label="Ciligo est portée par l’association Cilinnov, filiale du groupe
              Ciliopée Initiatives. L’association est spécialisée dans
              l’innovation et le développement de solutions innovantes et
              s’appuie sur les valeurs fortes du groupe Ciliopée Initiatives,
              groupe labellisé RSE depuis 2008. En menant le projet Ciligo, dont
              l’objectif s’accorde avec sa vocation sociale et environnementale,
              Cilinnov croit en l’investissement humain de chacun et
              l’amélioration de l’attractivité du territoire Nouvelle-Aquitaine
              notamment des zones rurales pour l’emploi et l’activité."
              />

              <Divider style={{ marginVertical: theme.sizings.mediumLarge }} />

              <JText
                sizeName="larger"
                colorName="greenHigh"
                isTitleText
                label="Ciligo, une plateforme inspirée par la mobilité lot-et-garonnaise"
              />
              <JText
                label="En 2016, le Livre Blanc sur l’attractivité du Lot-et-Garonne avait
              noté la nécessité de repenser les trajets du quotidien en zone
              rurale, préoccupations souvent au cœur des smart cities. Les
              nouvelles formes de mobilité s’inscrivent dans « l’économie du
              partage » ou « l’économie collaborative ». Il s’agit de traiter de
              courtes distances de proximité, ou plus longues, à des fréquences
              régulières ou aléatoires, ou encore « le dernier kilomètre »."
              />
            </Section>
          </Card>
        </View>
      </BackgroundLayout>

      <View style={desktopMode && desktopStyles.boxed}>
        <ViewRow
          style={{
            padding: theme.sizings.mediumLarge,
            marginBottom: insets.bottom,
          }}
        >
          <ViewCol
            style={{ paddingRight: theme.sizings.smallMedium, marginBottom: 5 }}
          >
            <TileButton
              tileColorName="greenLight"
              leftIconType="FontAwesome5"
              leftIconName="pen"
              labelKey="actions.register"
              onPress={() => navigation.navigate('Register')}
            />
          </ViewCol>
          <ViewCol style={{ paddingLeft: theme.sizings.smallMedium }}>
            <OpenURLButton url="mailto:conseil@ciligo.fr" />
          </ViewCol>
        </ViewRow>
      </View>
    </>
  );
};

export default AboutScreen;
