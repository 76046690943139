const renderOverridedField = (
  primaryElement: boolean | number | string,
  secondaryElement: boolean | number | string,
) => {
  if (primaryElement !== undefined) {
    return primaryElement;
  }
  if (secondaryElement !== undefined) {
    return secondaryElement;
  }
  return null;
};

export default renderOverridedField;
