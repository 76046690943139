/* eslint-disable no-console */
import { Card } from 'components/common/Card/Card';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import { Section } from 'components/common/Section/Section';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import React, { useState } from 'react';
import { View } from 'react-native';
import Modal from 'components/common/Modal/Modal';
import useTheme from 'hooks/useTheme';
import { JText } from 'components/common/Text/Text';
import { ClassicButton } from 'components/common/ClassicButton/ClassicButton';
import { Form } from 'react-final-form';
import { FormControl } from 'components/common/FormControl/FormControl';
import useAuth from 'hooks/useAuth';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import ResponsiveScrollView from 'components/layouts/ResponsiveScrollView/ResponsiveScrollView';
import useAlert from 'providers/AlertProvider';
import { TabsBackground } from 'components/layouts/BackgroundLayout/TabsBackgound';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import * as url from 'url';
import { I18nKey } from '../../../../../../i18n';
import { RootStackParamList } from '../../../../../navigation/RootStackParamList';
import { FormInput } from '../../../../../components/common/FormInput/FormInput';
import fetchJSON from '../../../../../utils/fetchJSON';
import { Company } from '../../../../../types/Company';
import { TextInput } from '../../../../../components/common/Input/TextInput/TextInput';
import { Avatar } from '../../../../../components/common/Avatar/Avatar';

const labelKeysYesNo: I18nKey[] = ['formOptions.yes', 'formOptions.no'];
const valuesYesNo: boolean[] = [true, false];

export type ScreenProps = NativeStackScreenProps<RootStackParamList, 'Profile'>;
const InformationsScreen = ({ navigation }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const desktopStyles = fnDesktopStyles(theme);
  const { user: currentUser, updateUser, signout, refreshUser } = useAuth();
  const { setAlert } = useAlert();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [content, setContent] = useState('');
  const verifyEntreprise = async () => {
    try {
      const res = await fetchJSON({
        url: `companies?filters[code][$eq]=${content}`,
        method: 'GET',
      });
      if (res.data.length > 2) {
        setAlert({
          message:
            'Deux entreprise possèdent le même code, veillez-contactez le service client.',
        });
        return;
      }
      if (res.data.length > 0) {
        console.log(res.data[0]);

        await updateUser({
          company: res.data[0].id,
        });
        await refreshUser();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const modalContent = <JText labelKey="sentences.logoutConfirm" />;

  const modalFooter = (
    <ViewRow align="center" justify="space-between" style={{ flex: 1 }}>
      <ClassicButton
        style={{
          borderColor: theme.colors.statusDangerHigh,
          borderStyle: 'solid',
          borderWidth: 3,
        }}
        onPress={() => {
          setIsModalVisible(false);
        }}
        labelKey="actions.cancel"
        type="decline"
      />
      <ClassicButton
        style={{
          backgroundColor: theme.colors.statusDangerHigh,
          borderColor: theme.colors.statusDangerHigh,
          borderStyle: 'solid',
          borderWidth: 3,
        }}
        onPress={() => {
          signout();
        }}
        labelKey="auth.logout"
        type="main"
      />
    </ViewRow>
  );

  /* TODO : Afficher une alert de succès ou d'erreur */
  const onSubmit = (values: any) => {
    updateUser(values);
    setAlert({ message: 'Informations mises à jour' });
  };

  return (
    <>
      <TabsBackground />
      <ResponsiveScrollView>
        <View
          style={[
            {
              padding: theme.sizings.mediumLarge,
              flex: 1,
            },
            desktopMode && desktopStyles.boxed,
          ]}
        >
          <Card style={{ marginBottom: theme.sizings.mediumLarge * 2 }}>
            {currentUser && (
              <Form
                onSubmit={onSubmit}
                initialValues={currentUser}
                render={({ handleSubmit, values }) => (
                  <>
                    <ViewRow>
                      <ViewCol
                        style={{
                          width:
                            theme.sizings.sz120 + theme.sizings.mediumLarge,
                        }}
                      >
                        <FormControl
                          titleKey="forms.avatar"
                          name="profilePicture"
                          rounded
                          type="image"
                        />
                      </ViewCol>
                      <ViewCol>
                        <FormControl name="lastname" titleKey="forms.name" />

                        <FormControl
                          name="firstname"
                          titleKey="forms.firstname"
                        />

                        <FormControl
                          name="username"
                          titleKey="forms.username"
                        />
                      </ViewCol>
                    </ViewRow>

                    <FormControl
                      type="select-list"
                      name="gender"
                      titleKey="forms.gender"
                      values={['W', 'M']}
                      labelKeys={['formOptions.woman', 'formOptions.man']}
                      selectedValue={values.gender}
                      inline
                    />

                    <FormControl
                      name="birthDate"
                      titleKey="forms.birth"
                      type="date"
                    />

                    <Section titleKey="section.driverPassenger">
                      <FormControl
                        name="chatting"
                        type="select-list"
                        titleKey="forms.talkDegree"
                        values={valuesYesNo}
                        labelKeys={labelKeysYesNo}
                        selectedValue={values.chatting}
                        style={{ marginVertical: theme.sizings.small }}
                        leftIconType="Entypo"
                        leftIconName="message"
                        leftIconSizeName="largest"
                        leftIconColorName="blueHigh"
                      />
                      <FormControl
                        name="music"
                        type="select-list"
                        titleKey="forms.musicDegree"
                        values={valuesYesNo}
                        labelKeys={labelKeysYesNo}
                        selectedValue={values.music}
                        style={{ marginVertical: theme.sizings.small }}
                        leftIconType="FontAwesome"
                        leftIconName="music"
                        leftIconSizeName="largest"
                        leftIconColorName="blueHigh"
                      />
                    </Section>

                    <Section titleKey="section.driver">
                      <FormControl
                        name="smoking"
                        type="select-list"
                        titleKey="forms.smokeDegree"
                        values={valuesYesNo}
                        labelKeys={labelKeysYesNo}
                        selectedValue={values.smoking}
                        style={{ marginVertical: theme.sizings.small }}
                        leftIconType="FontAwesome5"
                        leftIconName="smoking"
                        leftIconSizeName="largest"
                        leftIconColorName="blueHigh"
                      />
                      <FormControl
                        name="pets"
                        type="select-list"
                        titleKey="forms.petDegree"
                        values={valuesYesNo}
                        labelKeys={labelKeysYesNo}
                        selectedValue={values.pets}
                        style={{ marginVertical: theme.sizings.small }}
                        leftIconType="FontAwesome5"
                        leftIconName="paw"
                        leftIconSizeName="largest"
                        leftIconColorName="blueHigh"
                      />
                    </Section>

                    <Section titleKey="section.company" />
                    {!currentUser.company && (
                      <FormInput
                        titleKey="forms.companyCode"
                        subtitleKey="forms.companyCodeLegend"
                        inline
                        value={content}
                        height={theme.sizings.sz50}
                        onChange={setContent}
                        onEndEditingFunction={async () => {
                          await verifyEntreprise();
                        }}
                      />
                    )}
                    {currentUser.company && (
                      <ViewRow style={{ marginBottom: 40 }} align="center">
                        <Avatar
                          sizeName="sz60"
                          iconSizeName="large"
                          iconType="FontAwesome5"
                          iconPosition="topRight"
                          image={currentUser.company.logo}
                          isCompany
                        />
                        <ViewCol style={{ paddingLeft: 10 }}>
                          <JText
                            label={currentUser.company?.name}
                            sizeName="largest"
                            isBold
                          />
                          <JText
                            label={currentUser.company?.address}
                            colorName="greyMedium"
                          />
                        </ViewCol>
                      </ViewRow>
                    )}

                    <GradientButton
                      onPress={handleSubmit}
                      labelKey="save.informations"
                      leftIconType="AntDesign"
                      leftIconName="check"
                      leftIconSizeName="largest"
                      rightIconType="AntDesign"
                      rightIconName="right"
                      rightIconSizeName="largest"
                      style={{
                        width: '100%',
                        marginBottom: theme.sizings.medium,
                      }}
                    />
                  </>
                )}
              />
            )}

            <View
              style={{ marginTop: theme.sizings.mediumLarge, width: '100%' }}
            >
              <GradientButton
                colors={[theme.colors.statusDangerHigh, theme.colors.statusRed]}
                onPress={() => {
                  navigation.reset({
                    index: 0,
                    routes: [{ name: 'Welcome' }],
                  });
                  signout(currentUser?.id);
                }}
                labelKey="auth.logout"
                leftIconType="FontAwesome5"
                leftIconName="power-off"
                leftIconSizeName="largest"
                leftIconColorName="light"
                rightIconType="AntDesign"
                rightIconName="right"
                rightIconSizeName="largest"
                style={{ marginVertical: theme.sizings.mediumLarge }}
              />
            </View>
          </Card>
        </View>
      </ResponsiveScrollView>

      <Modal
        iconName="logout"
        iconType="AntDesign"
        iconSize="larger"
        iconColor="blueHigh"
        titleKey="auth.logout"
        isVisible={isModalVisible}
        onBackDropPress={() => setIsModalVisible(!isModalVisible)}
        renderContent={modalContent}
        renderFooter={modalFooter}
      />
    </>
  );
};

export default InformationsScreen;
