import React from 'react';
import { ScrollView } from 'react-native';
// import * as Linking from 'expo-linking';
import Map from 'components/common/Map/Map';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const MapScreen = (props: any) => {
  const { points = [] } = props;

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader title="Map" description="This component renders an Map">
        <Map points={points} />
      </CatalogHeader>
      <CatalogProps
        propName="points"
        description="list of points"
        type="[{id,name,lat,lon}...]"
        topDivider={false}
      />
    </ScrollView>
  );
};

export default MapScreen;
