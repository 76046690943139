import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { getStatusBarHeight } from 'react-native-status-bar-height';
import useTheme from 'hooks/useTheme';
import CourseHeader from 'components/compositions/CourseHeader/CourseHeader';
import computeAge from 'utils/computeAge';
import useItem from 'hooks/useItem';
import { Ride } from 'types';
import { populateUserSummary } from 'utils/populateModels';
import useAuth from 'hooks/useAuth';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import { JText } from 'components/common/Text/Text';
import { useTranslation } from 'react-i18next';
import { Card } from 'components/common/Card/Card';
import { Icon } from 'components/common/Icon/Icon';
import { InstanceModal } from 'components/compositions/InstanceModal/InstanceModal';
import useAlert from 'providers/AlertProvider';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import RideDescription from './modules/RideDescription';
import { I18nKey } from '../../../../i18n';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'InstanceDetail'
>;

// PAGE POUR LES CONDUCTEURS

const InstanceDetailScreen = ({ navigation, route }: ScreenProps) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const desktopStyles = fnDesktopStyles(theme);
  const { t } = useTranslation();
  const [isInstanceModalVisible, setIsInstanceModalVisible] = useState(false);
  const { setAlert } = useAlert();

  /* course */
  const { instanceId } = route.params;
  const { item: ride, fetchItem: fetchRideItem } = useItem<Ride>(
    `rides`,
    instanceId,
    {
      populate: {
        departureLocation: {
          populate: '*',
        },
        arrivalLocation: {
          populate: '*',
        },
        driver: populateUserSummary,
        vehicle: {
          populate: 'picture',
        },
        sections: {
          populate: '*',
        },
      },
    },
  );

  /* user & driver */
  const { user: currentUser } = useAuth();
  const currentDriver = ride?.driver;

  /* geo */

  const points = [];

  if (ride) {
    points.push({
      id: ride.departureLocation?.id,
      name: ride.departureLocation?.name,
      longitude: ride.departureLocation?.longitude,
      latitude: ride.departureLocation?.latitude,
    });
    points.push({
      id: ride.arrivalLocation?.id,
      name: ride.arrivalLocation?.name,
      longitude: ride.arrivalLocation?.longitude,
      latitude: ride.arrivalLocation?.latitude,
    });
  }

  const days = ride?.recurrentDays.map((rd: string, rdIndex) => {
    let day = t(`formOptions.${rd}` as I18nKey) as string;
    if (rdIndex < ride.recurrentDays.length - 1) {
      day += ', ';
    }
    return <JText label={day} />;
  });

  /* form */

  // todo : détecter si un abonnement est en cours pour cette instance de trajet
  const currentInstanceSubscription = true;

  const handleDeleteInstance = async () => {
    if (ride) {
      // TODO : adapter l'action
      /* await fetchJSON({
        url: `rides/${ride.id}`,
        method: 'DELETE',
      }); */
      navigation.reset({
        index: 0,
        routes: [{ name: 'Root' }],
      });
      setAlert({
        message: t('alert.instanceDeleted'),
        title: t('alertTitle.instanceDeleted'),
      });
    }
  };

  const handleSaveSubscribeInstance = async () => {
    if (ride) {
      // TODO : adapter l'action
      /* await fetchJSON({
        url: `rides/${ride.id}`,
        method: 'DELETE',
      }); */
      setAlert({
        message: t('alert.instanceSubscribeSaved'),
        title: t('alertTitle.instanceSubscribeSaved'),
      });
    }
  };

  return (
    <>
      <View
        style={{
          backgroundColor: theme.colors.light,
          flex: 1,
        }}
      >
        <ScrollView>
          <View
            style={[
              {
                flex: 1,
                width: '100%',
                paddingTop: getStatusBarHeight(),
              },
              desktopMode && desktopStyles.boxed,
            ]}
          >
            <CourseHeader
              labelKey={
                currentDriver?.id === currentUser?.id
                  ? 'courses.iDrive'
                  : 'sentences.travelWith'
              }
              labelValues={{
                name: currentDriver?.username || currentDriver?.firstname,
                age: computeAge(currentDriver?.birthDate),
              }}
              points={points}
              image={currentDriver?.profilePicture}
              imageIsWoman={currentDriver?.gender === 'W'}
            />

            {currentUser && currentDriver && instanceId && (
              <View style={{ padding: theme.sizings.mediumLarge }}>
                <Card>
                  <View
                    style={{
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Icon
                      sizeName="largest"
                      colorName="blueHigh"
                      type="FontAwesome5"
                      name="infinity"
                    />
                    <View
                      style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        marginLeft: theme.sizings.medium,
                      }}
                    >
                      {days}
                    </View>
                  </View>
                </Card>
                {/* TODO passenger : liste (statique) des jours récurrents auxquels je suis abonné 
                + bouton modification (popup) */}
                {currentUser.id !== currentDriver.id && (
                  <GradientButton
                    labelKey="actions.modifySubscription"
                    onPress={() => setIsInstanceModalVisible(true)}
                    style={{ marginVertical: theme.sizings.mediumLarge }}
                  />
                )}

                <RideDescription course={ride} driver={currentDriver} />
              </View>
            )}
          </View>
        </ScrollView>
      </View>

      {/* TODO : modal formulaire modif abonnement */}
      {ride && currentUser?.id !== currentDriver?.id && (
        <InstanceModal
          isVisible={isInstanceModalVisible}
          onClose={() => setIsInstanceModalVisible(false)}
          titleKey={
            currentInstanceSubscription
              ? 'actions.modifySubscription'
              : 'actions.subscribeInstance'
          }
          ride={ride}
          onSave={() => handleSaveSubscribeInstance()}
        />
      )}
    </>
  );
};

export default InstanceDetailScreen;
