const computeAge = (birthDate: Date | undefined): string => {
  if (birthDate) {
    const birth = new Date(birthDate);
    return Number(
      (new Date().getTime() - birth.getTime()) / 31536000000,
    ).toFixed(0);
  }
  return '';
};

export default computeAge;
