import React from 'react';
import { Text, View, ScrollView } from 'react-native';
import { GradientButton } from '../../components/common/GradientButton/GradientButton';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const GradientButtonScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="GradientButton"
        description="This component renders a button with a gradient background. There are optional icons on the left and the right."
      >
        <GradientButton
          onPress={() => {}}
          leftIconType="AntDesign"
          leftIconName="left"
          leftIconSizeName="largest"
        >
          <Text style={{ color: 'white' }}>A gradient button</Text>
        </GradientButton>
        <View style={{ height: 10 }} />
        <GradientButton
          label="A gradient button"
          onPress={() => {}}
          rightIconType="AntDesign"
          rightIconName="caretright"
          leftIconSizeName="largest"
          width={300}
          colors={['red', '#81D593']}
        />
        <View style={{ height: 10 }} />
        <GradientButton
          label="A gradient button"
          onPress={() => {}}
          leftIconType="AntDesign"
          leftIconName="left"
          leftIconSizeName="largest"
          rightIconType="AntDesign"
          rightIconName="caretright"
          rightIconSizeName="largest"
          colors={['red', 'black', '#81D593']}
        />
        <View style={{ height: 10 }} />
        <GradientButton
          label="A gradient button"
          onPress={() => {}}
          width={200}
        />
      </CatalogHeader>
      <CatalogProps
        propName="label"
        description="The text written in the component. You can also pass a child text to the button to span some part of the text."
        type="string"
        topDivider={false}
      />
      <CatalogProps propName="onPress" type="( ) => void" />
      <CatalogProps
        propName="leftIconType"
        type="enum('MaterialCommunity', 'AntDesign', 'Entypo', 'EvilIcons', 'Feather', 'FontAwesome', 'FontAwesome5', 'FontAwesome5Pro', 'Fontisto', 'Material', 'MaterialIcons', 'IonIcons')"
      />
      <CatalogProps propName="leftIconName" type="string" />
      <CatalogProps propName="leftIconSize" type="number" />
      <CatalogProps
        propName="rightIconType"
        type="enum('MaterialCommunity', 'AntDesign', 'Entypo', 'EvilIcons', 'Feather', 'FontAwesome', 'FontAwesome5', 'FontAwesome5Pro', 'Fontisto', 'Material', 'MaterialIcons', 'IonIcons')"
      />
      <CatalogProps propName="rightIconName" type="string" />
      <CatalogProps propName="rightIconSize" type="number" />
      <CatalogProps propName="width" type="number | string" />
      <CatalogProps
        propName="colors"
        description="The colors of the gradient in an array, starting with the left color"
        type="string[ ]"
      />
    </ScrollView>
  );
};

export default GradientButtonScreen;
