import { Theme } from 'providers/ThemeProvider';
import { StyleSheet } from 'react-native';

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      alignItems: 'flex-start',
    },
    subtitle: {
      marginLeft: theme.normalize(5),
    },
    list: {
      flexWrap: 'wrap',
      flexDirection: 'row',
      width: '100%',
    },
  });
