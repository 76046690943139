import React, { useState } from 'react';
import { ScrollView } from 'react-native';
import { CheckBox } from '../../components/common/CheckBox/CheckBox';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const CheckBoxScreen = () => {
  const [box, setBox] = useState(false);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="CheckBox"
        description="This component renders a CheckBox."
      >
        <CheckBox
          value={box}
          onPress={setBox}
          backgroundColor="#14437A"
          sizeName="large"
        />
      </CatalogHeader>
      <CatalogProps propName="value" type="boolean" topDivider={false} />
      <CatalogProps propName="onPress" type="(value: boolean) => void" />
      <CatalogProps propName="backgroundColor" type="string" />
      <CatalogProps propName="borderColor" type="string" />
      <CatalogProps propName="iconColor" type="string" />
      <CatalogProps propName="size" type="number" />
    </ScrollView>
  );
};

export default CheckBoxScreen;
