import { Avatar } from 'components/common/Avatar/Avatar';
import { CircularButton } from 'components/common/CircularButton/CircularButton';
import { ClassicButton } from 'components/common/ClassicButton/ClassicButton';
import { JText } from 'components/common/Text/Text';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import useTheme from 'hooks/useTheme';
import React from 'react';
import { ImageSourcePropType, View } from 'react-native';
import { StrapiFile } from 'types/StrapiFile';
import isDesktopMode from 'utils/desktopMode';

type Props = {
  onPress: () => void;
  title: string;
  place: string;
  trajects?: number;
  image: ImageSourcePropType | StrapiFile;
};

const EventItem = ({ onPress, title, place, trajects, image }: Props) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();

  return (
    <ViewRow style={{ marginBottom: theme.sizings.mediumLarge }}>
      <ViewCol style={{ width: theme.sizings.sz60 + theme.sizings.large }}>
        <Avatar sizeName="sz60" image={image} />
      </ViewCol>
      <ViewCol size={1}>
        <JText isBold label={title} />
        <JText label={place} />
        {trajects && (
          <View style={{ flexDirection: 'row' }}>
            <JText isItalic label={`${trajects}`} />
            <JText isItalic labelKey="event.associated" />
          </View>
        )}
        <ClassicButton
          onPress={onPress}
          labelKey="event.goOrCome"
          type="main"
          style={{ maxWidth: desktopMode ? 200 : undefined }}
        />
      </ViewCol>
      <ViewCol
        style={{
          width: theme.sizings.sz60,
          alignItems: 'flex-end',
        }}
      >
        <CircularButton
          backgroundColorName="blueLight"
          iconColorName="blueHigh"
          onPress={onPress}
          iconName="eye"
          iconType="FontAwesome5"
          sizeName="high"
          style={{ alignSelf: 'flex-end' }}
        />
      </ViewCol>
    </ViewRow>
  );
};

export default EventItem;
