import { Theme } from 'providers/ThemeProvider';
import { StyleSheet } from 'react-native';

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      position: 'relative',
      paddingTop: theme.sizings.small,
      marginBottom: theme.sizings.smallMedium,
    },
    avatar: {
      alignSelf: 'flex-end',
      marginBottom: -theme.normalize(40),
      marginRight: theme.sizings.mediumLarge,
      elevation: 4,
      zIndex: 1,
      position: 'relative',
    },
    card: {
      padding: 0,
    },
    cardTopIcon: {
      width: theme.sizings.larger,
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    cardTop: {
      width: '100%',
      paddingVertical: theme.sizings.medium,
      paddingLeft: theme.sizings.medium,
      paddingRight: '25%',
    },
    cardBottom: {
      paddingVertical: theme.sizings.medium,
      paddingLeft: theme.sizings.medium,
      width: '100%',
    },
    cardDates: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      paddingHorizontal: theme.sizings.medium,
      marginBottom: theme.sizings.medium,
    },
  });
