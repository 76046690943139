import React from 'react';
import { View } from 'react-native';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Card } from 'components/common/Card/Card';
import { Icon } from 'components/common/Icon/Icon';
import { JText } from 'components/common/Text/Text';
import { IconicText } from 'components/common/IconicText/IconicText';
import { Divider } from 'components/common/Divider/Divider';
import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import { DestinationStraightLine } from 'components/common/DestinationStraightLine/DestinationStraightLine';
import useTheme from 'hooks/useTheme';
import dayjs from 'dayjs';
import { Image } from 'react-native-elements/dist/image/Image';
import { ROOT_URL } from 'constants/Constants';
import { Ride, User, Section } from 'types';
import renderOverridedField from 'utils/computeOverridedField';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../../../i18n';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'CourseDetail'
>;
type Props = {
  course: Ride;
  section?: Section | null;
  driver: User;
};

const RideDescription = ({ course, section, driver }: Props) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  const currentVehicle = course.vehicle;
  const date1 = dayjs(course.arrivalTime);
  const date2 = dayjs(course.departureTime);
  const duration = dayjs.duration(date1.diff(date2)).format('HH[h]mm');

  // conditionnal values
  const renderPets = renderOverridedField(course.pets, driver.pets);
  const renderMusic = renderOverridedField(course.music, driver.music);
  const renderSmoking = renderOverridedField(course.smoking, driver.smoking);
  const renderChatting = renderOverridedField(course.chatting, driver.chatting);
  const renderSeats = renderOverridedField(course.seats, currentVehicle.seats);

  const days =
    course?.parent?.recurrentDays?.length &&
    course?.parent?.recurrentDays
      .map((rd: string, rdIndex) => {
        const day = t(`formOptions.${rd}` as I18nKey) as string;
        return day;
      })
      .toString();

  return (
    <Card
      style={{
        padding: theme.sizings.mediumLarge,
        marginBottom: theme.sizings.mediumLarge * 2,
      }}
    >
      {/* top */}

      <View style={{ width: '100%', marginBottom: theme.sizings.medium }}>
        <JText isBold label={`${dayjs(course.date).format('LL')}`} />
      </View>

      <ViewRow>
        <ViewCol size={1}>
          {course.parent?.isRecurrent && (
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                marginBottom: theme.sizings.medium,
              }}
            >
              <Icon
                sizeName="large"
                colorName="blueHigh"
                type="FontAwesome5"
                name="infinity"
              />
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  marginLeft: theme.sizings.medium,
                }}
              >
                <JText
                  labelKey="courses.isReccurentOnText"
                  valuesKey={{ days }}
                />
              </View>
            </View>
          )}
          <JText
            isBold
            labelKey="sentences.durationKilometers"
            valuesKey={{
              duration,
              km: section ? section.length : course.length || '?',
            }}
          />
        </ViewCol>
        {course.price !== undefined && (
          <ViewCol style={{ width: '20%', alignItems: 'flex-end' }}>
            <JText isBold label={`${course.price} €`} colorName="blueHigh" />
          </ViewCol>
        )}
      </ViewRow>

      {course.otherDetails && (
        <View style={{ marginTop: theme.sizings.mediumLarge, width: '100%' }}>
          <JText label={course.otherDetails} />
        </View>
      )}

      {!section && course.departureLocation && course.arrivalLocation && (
        <DestinationStraightLine
          departureTime={course.departureTime}
          departureLocation={course.departureLocation}
          arrivalTime={course.arrivalTime}
          arrivalLocation={course.arrivalLocation}
        />
      )}

      {section && section.departureLocation && section.arrivalLocation && (
        <DestinationStraightLine
          departureTime={section.departureTime}
          departureLocation={section.departureLocation}
          arrivalTime={section.arrivalTime}
          arrivalLocation={section.arrivalLocation}
        />
      )}

      <Divider />

      {/* bottom */}

      {course.vehicle?.picture?.url && (
        <View style={{ width: '100%', borderRadius: theme.radius.large }}>
          <Image
            style={{
              width: '100%',
              height: theme.normalize(200),
              marginBottom: theme.sizings.mediumLarge,
              borderRadius: theme.radius.medium,
            }}
            source={{
              uri: `${ROOT_URL}${course.vehicle.picture?.url}`,
            }}
            resizeMode="cover"
          />
        </View>
      )}

      <ViewRow>
        <ViewCol style={{ width: theme.normalize(80) }}>
          <Icon
            type="FontAwesome5"
            name="car"
            sizeName="fs50"
            colorName="greyLight"
          />
        </ViewCol>
        <ViewCol size={1} style={{ alignItems: 'flex-start' }}>
          <IconicText
            labelKey="sentences.vehicleNameAndSeats"
            valuesKey={{ vehicle: currentVehicle?.name, seats: renderSeats }}
            iconType="FontAwesome5"
            iconName="car"
            iconColorName="blueHigh"
          />

          <IconicText
            labelKey="sentences.luggageSize"
            valuesKey={{
              size: currentVehicle?.luggageSize,
            }}
            iconType="Material"
            iconName="luggage"
            iconColorName="blueHigh"
          />

          {renderPets !== null && (
            <IconicText
              labelKey={renderPets ? 'forms.petDegree' : 'forms.petDegreeNo'}
              iconType="FontAwesome5"
              iconName="paw"
              iconColorName={renderPets ? 'greenMedium' : 'statusDangerHigh'}
            />
          )}

          {renderMusic !== null && (
            <IconicText
              labelKey={
                renderPets ? 'forms.musicDegree' : 'forms.musicDegreeNo'
              }
              iconType="FontAwesome5"
              iconName="music"
              iconColorName={renderPets ? 'greenMedium' : 'statusDangerHigh'}
            />
          )}

          {renderSmoking !== null && (
            <IconicText
              labelKey={
                renderSmoking ? 'forms.smokeDegree' : 'forms.smokeDegreeNo'
              }
              iconType="FontAwesome5"
              iconName="smoking"
              iconColorName={renderSmoking ? 'greenMedium' : 'statusDangerHigh'}
            />
          )}

          {renderChatting !== null && (
            <IconicText
              labelKey={
                renderChatting ? 'forms.talkDegree' : 'forms.talkDegreeNo'
              }
              iconType="FontAwesome5"
              iconName="comment"
              iconColorName={
                renderChatting ? 'greenMedium' : 'statusDangerHigh'
              }
            />
          )}

          {currentVehicle?.airConditioner && (
            <IconicText
              labelKey="forms.airConditioner"
              iconType="FontAwesome5"
              iconName="plus"
              iconColorName="greenMedium"
            />
          )}

          {currentVehicle?.heatedSeats && (
            <IconicText
              labelKey="forms.heatedSeat"
              iconType="FontAwesome5"
              iconName="plus"
              iconColorName="greenMedium"
            />
          )}
        </ViewCol>
      </ViewRow>
    </Card>
  );
};

export default RideDescription;
