import { ViewCol, ViewRow } from 'components/layouts/FlexLayout/FlexViews';
import React, { useEffect, useState } from 'react';
import { JText } from 'components/common/Text/Text';
import useTheme from 'hooks/useTheme';
import Modal from 'components/common/Modal/Modal';
import { GradientButton } from 'components/common/GradientButton/GradientButton';
import { FormControl } from 'components/common/FormControl/FormControl';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import dayjs from 'dayjs';
import { Form } from 'react-final-form';
import { DestinationStraightLine } from 'components/common/DestinationStraightLine/DestinationStraightLine';
import { Ride } from 'types';
import renderOverridedField from 'utils/computeOverridedField';
import { Icon } from 'components/common/Icon/Icon';
import { Divider } from 'components/common/Divider/Divider';
import { IconicText } from 'components/common/IconicText/IconicText';
import computeAge from 'utils/computeAge';
import { View } from 'react-native';
import { Avatar } from 'components/common/Avatar/Avatar';
import { I18nKey } from '../../../../i18n';
import fnStyles from '../CourseItemGlobalStyles';

type PropsModal = {
  isVisible: boolean;
  onClose: () => void;
  onSave: () => void;
  titleKey: I18nKey;
  ride: Ride;
};

export const InstanceModal = ({
  isVisible,
  onClose,
  onSave,
  titleKey,
  ride,
}: PropsModal) => {
  const { theme } = useTheme();
  const desktopStyles = fnDesktopStyles(theme);
  const styles = fnStyles(theme);
  const desktopMode = isDesktopMode();

  const [daysSelected, setDaysSelected] = useState<boolean>(false);
  const initialValues = {
    recurrentDays: [],
    begin: undefined,
    ride,
  };

  const handleChangeFooter = (value: any) => {
    if (value.length > 0) {
      setDaysSelected(true);
    } else {
      setDaysSelected(false);
    }
  };

  useEffect(() => {
    if (initialValues.recurrentDays.length > 0) {
      setDaysSelected(true);
    }
  }, [initialValues.recurrentDays]);

  /* const valuesDays: string[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]; */

  /* const labelKeysDays: I18nKey[] = [
    'formOptions.monday',
    'formOptions.tuesday',
    'formOptions.wednesday',
    'formOptions.thursday',
    'formOptions.friday',
    'formOptions.saturday',
    'formOptions.sunday',
  ]; */

  const valuesDays: string[] = ride.recurrentDays.map((rd: string) => rd);

  const labelKeysDays = ride.recurrentDays.map(
    (rd: string) => `formOptions.${rd}` as I18nKey,
  );

  const driverBirth = new Date(ride.driver?.birthDate);
  const driverAge = computeAge(driverBirth);

  // conditionnal values
  const music = renderOverridedField(ride.music, ride.driver?.music);
  const smoking = renderOverridedField(ride.smoking, ride.driver?.smoking);
  const chatting = renderOverridedField(ride.chatting, ride.driver?.chatting);

  const modalContent = (
    <Form
      initialValues={initialValues}
      onSubmit={onSave}
      render={({ handleSubmit, values, submitError }) => (
        <>
          <ViewRow style={styles.cardTop}>
            <ViewCol
              style={{ width: theme.sizings.sz60 + theme.sizings.mediumLarge }}
            >
              <Avatar
                sizeName="sz60"
                image={ride.driver?.profilePicture || undefined}
                style={styles.avatar}
                isWoman={ride.driver?.gender === 'W'}
              />
            </ViewCol>
            <ViewCol size={1}>
              <JText
                isBold
                sizeName="larger"
                labelKey="sentences.userNameAge"
                valuesKey={{
                  name: ride.driver?.username || ride.driver?.firstname,
                  age: driverAge,
                }}
              />
              <View style={styles.row}>
                <IconicText
                  iconType="FontAwesome5"
                  iconName="car"
                  iconColorName="greenMedium"
                  label={ride.vehicle?.name}
                />
                <Divider vertical />
                <Icon
                  type="FontAwesome5"
                  colorName={chatting ? 'greenMedium' : 'statusDangerHigh'}
                  name="comment"
                />

                <Divider vertical transparent />
                <Icon
                  type="FontAwesome5"
                  colorName={music ? 'greenMedium' : 'statusDangerHigh'}
                  name="music"
                />
                <Divider vertical transparent />

                <Icon
                  type="FontAwesome5"
                  colorName={smoking ? 'greenMedium' : 'statusDangerHigh'}
                  name="smoking"
                />
              </View>
            </ViewCol>

            {ride.price && (
              <ViewCol justify="flex-end">
                <JText label={`${ride.price} €`} isBold sizeName="largest" />
              </ViewCol>
            )}
          </ViewRow>
          <Divider />
          {ride.departureLocation && ride.arrivalLocation && (
            <DestinationStraightLine
              departureTime={ride.departureTime}
              departureLocation={ride.departureLocation}
              arrivalTime={ride.arrivalTime}
              arrivalLocation={ride.arrivalLocation}
            />
          )}
          <Divider />
          <FormControl
            name="recurrentDays"
            required
            type="select-list"
            titleKey="forms.recurrentDaysSubscribe"
            subtitleKey="forms.recurrentDaysSubscribeLegende"
            values={valuesDays}
            labelKeys={labelKeysDays}
            selectedValue={values.recurrentDays as string[]}
            style={{ marginVertical: theme.sizings.small, width: '100%' }}
            onChange={(value: any) => {
              handleChangeFooter(value);
            }}
          />
          <FormControl
            name="begin"
            titleKey="forms.begin"
            type="date"
            value={values.begin || dayjs().toDate()}
          />
        </>
      )}
    />
  );

  const modalFooter = (
    <GradientButton
      onPress={onSave}
      labelKey={
        daysSelected
          ? 'actions.modifySubscription'
          : 'actions.deleteSubscription'
      }
    />
  );

  return (
    <Modal
      titleKey={titleKey}
      isVisible={isVisible}
      onBackDropPress={onClose}
      renderContent={modalContent}
      renderFooter={modalFooter}
      iconName="infinity"
      iconType="FontAwesome5"
    />
  );
};
