import React, { useEffect, useState } from 'react';
import { Types } from 'types/Types';
import { ViewCol } from 'components/layouts/FlexLayout/FlexViews';
import useTheme from 'hooks/useTheme';
import { UserVehicle } from 'types';
import { StrapiFile } from 'types/StrapiFile';
import { ViewRow } from '../../layouts/FlexLayout/FlexViews';
import { CircularIconRow } from '../CircularIconRow/CircularIconRow';
import { I18nKey } from '../../../../i18n';

import fnStyles from './VehicleStyle';
import { JText } from '../Text/Text';
import { Icon } from '../Icon/Icon';
import {TouchableOpacity} from "react-native";

// TODO remove optional to value and onChange
type Props = {
  label?: string;
  labelKey?: I18nKey;
  iconType?: Types['iconTypes'];
  iconName?: string;
  vehicle: UserVehicle;
  modifyVehicle?: () => void;
  deleteVehicle?: () => void
};

const colors = {
  accepted: {
    iconType: 'IonIcons' as Types['iconTypes'],
    iconName: 'checkmark-outline',
  },
  refused: {
    iconType: 'IonIcons' as Types['iconTypes'],
    iconName: 'close-outline',
  },
  in_waiting: {
    iconType: 'IonIcons' as Types['iconTypes'],
    iconName: 'hourglass-outline',
  },
};

export const Vehicle = ({
  label,
  labelKey,
  iconType = 'FontAwesome5',
  iconName = 'car',
  vehicle,
  modifyVehicle = () => {},
  deleteVehicle = () => {},
}: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const [status, setStatus] = useState<Types['documentStatus']>('');

  useEffect(() => {
    setStatus(vehicle.status as Types['documentStatus']);
  }, [vehicle.status]);

  return (
    <CircularIconRow
      iconBackgroundColorName={
        status === 'accepted'
          ? 'statusSuccessLight'
          : status === 'in_waiting'
          ? 'statusWarningLight'
          : 'statusDangerLight'
      }
      iconType={iconType}
      iconName={iconName}
      iconSizeName="fs50"
      iconColorName={
        status === 'accepted'
          ? 'statusSuccessHigh'
          : status === 'in_waiting'
          ? 'statusWarningHigh'
          : 'statusDangerHigh'
      }
      statusIconType={
        status === 'accepted'
          ? colors.accepted.iconType
          : status === 'in_waiting'
          ? colors.in_waiting.iconType
          : colors.refused.iconType
      }
      statusIconName={
        status === 'accepted'
          ? colors.accepted.iconName
          : status === 'in_waiting'
          ? colors.in_waiting.iconName
          : colors.refused.iconName
      }
      statusIconColorName={
        status === 'accepted'
          ? 'statusSuccessHigh'
          : status === 'in_waiting'
          ? 'statusWarningHigh'
          : 'statusDangerHigh'
      }
      style={[styles.icon]}
      image={vehicle.picture as StrapiFile}
    >
      <ViewRow justify="space-between">
        <ViewCol>
          <JText isBold labelKey={labelKey} label={label} />
          <JText
            colorName={
              status === 'accepted'
                ? 'statusSuccessHigh'
                : status === 'in_waiting'
                ? 'statusWarningHigh'
                : 'statusDangerHigh'
            }
            labelKey={
              status === 'accepted'
                ? 'common.stateApproved'
                : status === 'in_waiting'
                ? 'common.stateWaitingForApproval'
                : status === 'refused'
                ? 'common.stateRefused'
                : 'common.stateMissing'
            }
          />
        </ViewCol>
        <TouchableOpacity
        onPress={deleteVehicle}
        >
        <Icon
          type="FontAwesome"
          name="trash"
          sizeName="larger"
          colorName="blueHigh"
          style={{
            position: 'absolute',
            top: theme.sizings.smallMedium,
            right: theme.sizings.smallMedium,
            backgroundColor: theme.colors.light,
            borderRadius: theme.sizings.ten,
          }}
        />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={modifyVehicle}
        >
        <Icon
          type="FontAwesome"
          name="pencil"
          sizeName="larger"
          colorName="blueHigh"
          style={{
            position: 'absolute',
            top: theme.sizings.smallMedium,
            right: theme.sizings.sz50,

            backgroundColor: theme.colors.light,
            borderRadius: theme.sizings.ten,
          }}
        />
        </TouchableOpacity>

      </ViewRow>
    </CircularIconRow>
  );
};
