import React from 'react';
import { JText } from 'components/common/Text/Text';
import useItem from 'hooks/useItem';
import { Platform, View } from 'react-native';
import { User } from 'types/User';
import useTheme from 'hooks/useTheme';
import { Avatar } from 'components/common/Avatar/Avatar';
import useAuth from 'hooks/useAuth';
import { populateUserSummary } from 'utils/populateModels';
import isDesktopMode from 'utils/desktopMode';

type Props = {
  profileId: number;
};

/* =========== ME ============ */

export const MyProfileTitle = () => {
  const { user: currentUser } = useAuth();
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();

  const computeProgress = (user: any) => {
    const checkFields = [
      'username',
      'firstname',
      'lastname',
      'gender',
      'phoneNumber',
      'birthDate',
      'address',
      'postalCode',
      'city',
      'profilePicture',
      'smoking',
      'music',
      'chatting',
      'pets',
    ];
    let missingFields = 0;

    checkFields.map((field: string) => {
      // console.log(field, user[field]);
      if (user) {
        if (user[field] === null || user[field] === undefined) {
          missingFields += 1;
        }
      }
      return null;
    });

    const res = (
      ((checkFields.length - missingFields) / checkFields.length) *
      100
    ).toFixed(0);
    return res;
  };

  // console.log(computeProgress(currentUser));

  return (
    <View
      style={{
        height:
          desktopMode || Platform.OS === 'web'
            ? undefined
            : theme.headerSizes.mobile,
      }}
    >
      <JText
        centered
        label={currentUser?.username || currentUser?.firstname}
        sizeName="fs18"
        colorName="blueHigh"
        isTitleText
        isBold
        style={{ marginBottom: 0 }}
      />
      <JText
        centered
        labelKey="sentences.profileCompleteRatio"
        valuesKey={{ ratio: computeProgress(currentUser) }}
        sizeName="large"
        isTitleText
        colorName="blueHigh"
        style={{ marginBottom: 0 }}
      />
    </View>
  );
};

export const MyProfileRightAvatar = () => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();
  const { user: currentUser } = useAuth();

  return (
    <View
      style={{
        paddingHorizontal: theme.sizings.smallMedium,
        height:
          desktopMode || Platform.OS === 'web'
            ? undefined
            : theme.headerSizes.mobile,
      }}
    >
      <Avatar
        sizeName="sz60"
        image={currentUser?.profilePicture}
        isWoman={currentUser?.gender === 'W'}
      />
    </View>
  );
};

/* ======== THEM ========= */

export const ProfileTitle = ({ profileId }: Props) => {
  const { item: user } = useItem<User>(`users`, profileId.toString());
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();

  return (
    <View
      style={{
        height:
          desktopMode || Platform.OS === 'web'
            ? undefined
            : theme.headerSizes.mobile,
      }}
    >
      <JText
        centered
        label={user?.username || user?.firstname}
        sizeName="fs18"
        colorName="blueHigh"
        isTitleText
        isBold
        style={{ marginBottom: 0 }}
      />
    </View>
  );
};

export const ProfileRightAvatar = ({ profileId }: Props) => {
  const { theme } = useTheme();
  const desktopMode = isDesktopMode();

  const { item: user } = useItem<User>('users', profileId, {
    populate: populateUserSummary,
    cachePrefix: 'header',
  });

  return (
    <View
      style={{
        paddingHorizontal: theme.sizings.smallMedium,
        height:
          desktopMode || Platform.OS === 'web'
            ? undefined
            : theme.headerSizes.mobile,
      }}
    >
      <Avatar
        sizeName="sz60"
        image={user?.profilePicture}
        isWoman={user?.gender === 'W'}
      />
    </View>
  );
};
