import React from 'react';
import { ScrollView } from 'react-native';
import { TextButton } from '../../components/common/TextButton/TextButton';
import { CatalogProps } from './common/CatalogProps';
import { CatalogHeader } from './common/CatalogHeader';
import styles from './common/CommonCatalogStyle';

const TextButtonScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="TextButton"
        description="This component renders a clickable text."
      >
        <TextButton
          label="A text button"
          onPress={() => {}}
          colorName="statusDangerHigh"
          isBold
          fontSizeName="medium"
        />
      </CatalogHeader>
      <CatalogProps
        propName="label"
        description="The text writtent in the component."
        type="string"
        topDivider={false}
      />
      <CatalogProps propName="onPress" type="( ) => void" />
      <CatalogProps propName="color" type="string" />
      <CatalogProps
        propName="fontWeight"
        type="enum('normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900')"
      />
      <CatalogProps propName="fontSize" type="number" />
    </ScrollView>
  );
};

export default TextButtonScreen;
