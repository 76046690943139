import React from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from 'navigation/RootStackParamList';
import { BackgroundLayout } from 'components/layouts/BackgroundLayout/BackgroundLayout';
import { Card } from 'components/common/Card/Card';
import { JText } from 'components/common/Text/Text';
import useAuth from 'hooks/useAuth';
import { View } from 'react-native';
import isDesktopMode from 'utils/desktopMode';
import fnDesktopStyles from 'utils/desktopModeStyle';
import useTheme from '../../../hooks/useTheme';
import fnStyles from '../AuthGlobalStyle';

export type ScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'ValidateAccount'
>;

const ValidateAccountScreen = () => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const desktopStyles = fnDesktopStyles(theme);
  const desktopMode = isDesktopMode();

  return (
    <BackgroundLayout padding>
      <View
        style={[
          desktopMode && desktopStyles.boxed,
          { marginTop: desktopMode ? theme.sizings.mediumLarge : undefined },
        ]}
      >
        <Card style={styles.card}>
          <JText
            centered
            style={{ marginBottom: theme.sizings.ten }}
            labelKey="auth.welcome"
          />
          <JText centered labelKey="auth.pleaseConfirmAccount" />
        </Card>
      </View>
    </BackgroundLayout>
  );
};

export default ValidateAccountScreen;
