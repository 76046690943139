import { Theme } from 'providers/ThemeProvider';
import { StyleSheet } from 'react-native';
import { getStatusBarHeight } from 'react-native-status-bar-height';

export default (theme: Theme) =>
  StyleSheet.create({
    blocTop: {
      paddingTop: theme.headerSizes.mobile + getStatusBarHeight(),
    },
    blocTopRight: {
      flex: 1,
      paddingHorizontal: theme.sizings.mediumLarge,
    },
    background: {
      flex: 1,
      position: 'absolute',
      left: 0,
      top: 0,
    },
    blocIllustration: {
      width: theme.normalize(200),
      height: theme.normalize(200),
      position: 'absolute',
      left: 0,
      top: theme.normalize(90) + getStatusBarHeight(),
    },
    blocIllustrationDesktop: {
      width: '50%',
      height: 200,
      marginTop: 40,
    },
    topWelcome: {
      flex: 1,
      alignItems: 'flex-end',
      paddingTop: theme.sizings.large,
    },
    topBadges: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    topBadgeItem: {
      height: theme.normalize(110),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    blocBottom: {
      padding: theme.sizings.mediumLarge,
    },
    blocBottomFirst: {
      padding: theme.sizings.high,
      marginTop: theme.sizings.high,
    },
  });
