import {
  GoogleApiWrapper,
  Map,
  Polyline,
  Marker,
  GoogleAPI,
} from 'google-maps-react';
import { Text, View } from 'react-native';
import React from 'react';
import useTheme from 'hooks/useTheme';
import fnStyles, { generatedMapStyle } from './MapStyle';

type Props = {
  google: GoogleAPI;
  points?: {
    id: number;
    name: string;
    latitude: string;
    longitude: string;
  }[];
  height?: number;
};

const containerStyle = {
  position: 'relative',
  width: '100%',
};

const MapContainer = ({ points = [], google, height = 300 }: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  // TODO : V2 : adapter quand on aura toutes les sections
  const coordinates = points.map(p => ({
    lat: parseFloat(p?.latitude),
    lng: parseFloat(p?.longitude),
  }));

  const initMap = (map: google.maps.Map | undefined) => {
    map?.setOptions({
      styles: generatedMapStyle,
    });
    const bounds = new window.google.maps.LatLngBounds();
    coordinates.forEach(item => {
      bounds.extend(item);
    });
    map?.fitBounds(bounds);
    map?.controls.forEach(c => c.clear());
  };

  // initialCenter={initialCenter}
  return (
    <Map
      google={google}
      containerStyle={{ ...containerStyle, height }}
      zoom={14}
      onReady={(_, map) => initMap(map)}
    >
      {points.map((point, index) => {
        const anchor = new window.google.maps.Point(0.5, 0.5);

        return (
          <Marker
            title={point?.name}
            key={point?.id}
            position={{
              lat: parseFloat(point?.latitude),
              lng: parseFloat(point?.longitude),
            }}
            anchorPoint={anchor}
          >
            <View style={styles.pointCircle}>
              <Text style={styles.pointCirclelabel}>{index + 1}</Text>
            </View>
          </Marker>
        );
      })}
      <Polyline
        path={coordinates}
        strokeColor={theme.colors.greenHigh}
        strokeOpacity={0.8}
        strokeWeight={theme.normalize(4)}
      />
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCLl6TofrB7AtT0tTdobdW1Z5Q3Pi83HsM',
})(MapContainer);
